import React from 'react'
import replyIcon from '../../assets/icons/reply.svg'
import editIcon from '../../assets/icons/edit.svg'
import deleteIcon from '../../assets/icons/trash.svg'

const OutgoingControls = ({ id, onReply, onEdit, onDelete, className }) => {
  return (
    <div className={className}>
      <button onClick={() => onReply(id)}>
        <img src={replyIcon} alt="reply message" />
      </button>

      <button onClick={() => onEdit(id)}>
        <img src={editIcon} alt="edit message" />
      </button>

      <button onClick={() => onDelete(id)}>
        <img src={deleteIcon} alt="delete icon" />
      </button>
    </div>
  )
}

export default OutgoingControls
