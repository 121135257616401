import { helperEditDataVessel } from '../../helpers/vesselManager.helper'
import { VESSEL } from './vesselManager.types'

const initialState = {
  list: [],
  shipowners: [],
  deletedList: [],
  vesselCounter: null,
  deletedVesselCount: null,
  form: {
    vessel: { id: null, name: '' },
    owner: { id: null, name: '' },
    country: {
      id: null,
      name: '',
    },
    vessel_type: { id: null, name: '' },
    crew_nationality: { id: null, name: '' },
    engine: { id: null, name: '' },
    vessel_flag: { id: null, name: '' },
    imo: null,
    dwt: null,
    grt: null,
    teu: '',
    hp: '',
    kw: '',
    year_of_build: '',
  },
  inEditId: null,
  isButtonEnable: false,
  isRestoreLoading: false,
}

export default function vesselManagerReducer(state = initialState, action) {
  switch (action.type) {
    case VESSEL.VESSEL_ALL_COUNTER:
      return {
        ...state,

        vesselCounter: action.payload,
      }

    case VESSEL.VESSEL_DELETED_COUNTER:
      return {
        ...state,

        deletedVesselCount: action.payload,
      }

    case VESSEL.UPDATE_VESSELS_LIST:
      return {
        ...state,

        list: [...state.list, ...action.payload],
      }

    case VESSEL.ADD_EDITED_DATA: {
      const { vessel } = action.payload

      return {
        ...state,

        list: [
          ...state.list.map((item) => {
            return item.vessel.id === vessel.id ? action.payload : item
          }),
        ],
        inEditId: null,
      }
    }

    case VESSEL.ADD_NEW_VESSEL:
      return {
        ...state,

        list: [action.payload, ...state.list],
      }

    case VESSEL.SET_IS_BUTTON_ENABLE:
      return {
        ...state,

        isButtonEnable: action.payload,
      }

    case VESSEL.DELETE_VESSEL_UPDATE:
      return {
        ...state,

        list: [
          ...state.list.filter(({ vessel }) => {
            return vessel.id !== action.payload
          }),
        ],
        deletedList: [
          ...state.deletedList,
          ...state.list.filter(({ vessel }) => {
            return vessel.id === action.payload
          }),
        ],
      }

    // case 'DELETE_VESSEL_RESTORE':
    //   return {
    //     ...state,
    //
    //     deletedList: state.deletedList.filter((vessel) => vessel.id !== action.payload),
    //     deletedVesselCount: state.deletedVesselCount - 1,
    //   }

    case VESSEL.RESTORE_VESSEL: {
      const vesselRestore = state.deletedList.find(({ vessel }) => vessel.id === action.payload)

      return {
        ...state,

        list: [vesselRestore, ...state.list],
        deletedList: state.deletedList.filter(({ vessel }) => vessel.id !== action.payload),
      }
    }

    case VESSEL.EDIT_VESSEL:
      return {
        ...state,

        form: helperEditDataVessel(state.list.find(({ vessel }) => vessel.id === action.payload)),
        inEditId: action.payload,
      }

    case VESSEL.UPDATE_DELETED_VESSELS_LIST:
      return {
        ...state,

        deletedList: [...state.deletedList, ...action.payload],
      }

    case VESSEL.SET_IS_RESTORE_LOADING:
      return {
        ...state,

        isRestoreLoading: action.payload,
      }

    case VESSEL.CLEAR_VESSELS_LIST:
      return {
        ...state,

        list: [],
        deletedList: [],
      }

    case VESSEL.RESET_VESSELS:
      return {
        list: [],
        deletedList: [],
        form: initialState.form,
        inEditId: null,
        isButtonEnable: false,
      }

    case VESSEL.CLEAR_VESSEL_FORM:
      return {
        ...state,

        form: initialState.form,
        inEditId: null,
      }

    default:
      return state
  }
}
