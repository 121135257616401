import { useCallback, useEffect, useState } from 'react'

import { getAuthCompanyVacancies, getAuthProfileFilters } from '../../api/getApi'
import { useNotify } from '../../hooks/useNotify'

import { profileFilters } from './config'

import { getDocumentFilter, getTemplateFilter, getTemplateOptions } from './utils'

const useFilterForm = (setValues, initialValues) => {
  const [filters, setFilters] = useState({})
  const [templateOptions, setTemplateOptions] = useState([])

  const { notify } = useNotify()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [filters, vacancies] = await Promise.all([
          getAuthProfileFilters(...profileFilters),
          getAuthCompanyVacancies(null, 'all=true'),
        ])

        setFilters({
          ...filters,
          template: getTemplateFilter(vacancies),
          documents: getDocumentFilter(filters.document_types),
        })
        setTemplateOptions(getTemplateOptions(vacancies))
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    fetchData()

    //eslint-disable-next-line
  }, [])

  const handleSelectClick = useCallback(
    (name, value) => {
      setValues((prev) => ({
        ...prev,
        [name]: { ...prev[name], value },
      }))
    },
    [setValues]
  )

  const handleTemplateClick = useCallback(
    (name, value) => {
      const _values = templateOptions.find((item) => item.id === Number(value.id)) ?? initialValues
      const mix = _values.nationalities.value[0].id === 'Mix'
      setValues((prev) => ({
        ..._values,
        nationalities: mix ? initialValues.nationalities : _values.nationalities,
        [name]: { ...prev[name], value },
      }))
    },
    [initialValues, setValues, templateOptions]
  )

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target

      if (value.length > 50) return

      setValues((prev) => ({
        ...prev,
        [name]: { ...prev[name], value },
      }))
    },
    [setValues]
  )

  return {
    filters,

    handleSelectClick,
    handleTemplateClick,
    handleInputChange,
  }
}

export default useFilterForm
