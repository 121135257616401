import { labelType } from '../../components/SeafarersSearchPanel/config'

const searchFilter = {
  ranks: { type: labelType.multiple, value: [] },
  vessel_types: { type: labelType.multiple, value: [] },
  vessel_engines: { type: labelType.multiple, value: [] },
  nationalities: { type: labelType.multiple, value: [] },
  documents: { type: labelType.multiple, value: [] },
  template: { type: labelType.select, value: { name: '', id: null } },
  teu: { type: labelType.string, value: '', label: 'TEU' },
  dwt: { type: labelType.string, value: '', label: 'DWT' },
  grt: { type: labelType.string, value: '', label: 'GRT' },
  hp: { type: labelType.string, value: '', label: 'HP' },
  kw: { type: labelType.string, value: '', label: 'KW' },
  search: { type: labelType.default, value: '' },
  ordering: '',
}

const initialState = {
  list: [],
  filter: searchFilter,
  tabs: [],
  favourites: [],
  favouritesFilter: searchFilter,
  vacancy_requests: [],
  requestFilter: {
    ...searchFilter,

    sort: { type: labelType.select, value: { name: '', id: null } },
    candidates: { type: labelType.select, value: { name: '', id: null } },
  },
  vacancy_templates: [],

  scrollValue: 0,
  comments: {
    is_open_comments: false,
    user_id: null,
    user_name: null,
    comments_list: [],
    filters: {
      ordering: 'id',
      search: '',
    },
  },
  idDeletedUsers: [],
  requestCount: 0,
}

export default function seafarersSearchReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SEAFARERS_LIST':
      return {
        ...state,
        list: [...state.list, ...action.payload],
      }

    case 'SET_SEAFARERS_REQUESTS_LIST':
      const result = [...state.vacancy_requests, ...action.payload].reduce((acc, cur) => {
        return acc.some((el) => el.id === cur.id) ? acc : [...acc, cur]
      }, [])

      return {
        ...state,
        vacancy_requests: result,
      }

    case 'SET_VACANCY_TEMPLATES_REQUESTS_LIST':
      return {
        ...state,
        vacancy_templates: [...state.vacancy_templates, ...action.payload],
      }

    case 'CLEAR_VACANCY_TEMPLATES_REQUESTS_LIST':
      return {
        ...state,
        vacancy_templates: [],
      }

    case 'ADD_TABS_PANEL_ITEM':
      return state.tabs.some((tab) => tab.id === action.payload.id)
        ? state
        : { ...state, tabs: [action.payload, ...state.tabs] }

    case 'DELETE_TABS_PANEL_ITEM':
      return {
        ...state,
        tabs: state.tabs.filter((tab) => +tab.id !== +action.payload),
      }

    case 'DELETE_ALL_TABS':
      return {
        ...state,
        tabs: [],
      }

    case 'SET_FAVOURITE_STATUS':
      return {
        ...state,
        list:
          !!state.list &&
          state.list.map((item) => ({
            ...item,
            is_favourite: item.user_id === action.payload.id ? !action.payload.status : item.is_favourite,
          })),
        vacancy_requests:
          !!state.vacancy_requests &&
          state.vacancy_requests.map((item) => ({
            ...item,
            is_favourite: item.user_id === action.payload.id ? !action.payload.status : item.is_favourite,
          })),
      }

    case 'SET_SEAFARERS_FAVOURITE_LIST':
      return {
        ...state,
        favourites: [...state.favourites, ...action.payload],
      }

    case 'SET_FAVOURITE_FILTER':
      return {
        ...state,
        favouritesFilter: {
          ...state.favouritesFilter,
          ...action.payload,
        },
      }

    case 'REMOVE_SEAFARER_FROM_FAVOURITES':
      return {
        ...state,
        list:
          !!state.list &&
          state.list.map((item) => ({
            ...item,
            is_favourite: item.user_id === action.payload ? false : item.is_favourite,
          })),
        favourites: !!state.favourites && state.favourites.filter((item) => item.user_id !== action.payload),
        vacancy_requests:
          !!state.vacancy_requests &&
          state.vacancy_requests.map((item) => ({
            ...item,
            is_favourite: item.user_id === action.payload ? false : item.is_favourite,
          })),
      }

    case 'CLEAR_SEAFARERS_LIST':
      return {
        ...state,
        list: [],
      }

    case 'CLEAR_SEAFARERS_REQUESTS_LIST':
      return {
        ...state,
        vacancy_requests: [],
      }

    case 'CLEAR_SEAFARERS_FAVOURITES_LIST':
      return {
        ...state,
        favourites: [],
      }

    case 'SET_SEARCH_FILTER_OPTIONS':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      }

    case 'SET_REQUESTS_FILTER':
      return {
        ...state,
        requestFilter: {
          ...state.requestFilter,
          ...action.payload,
        },
      }

    case 'REMOVE_REQUEST':
      return {
        ...state,
        vacancy_requests: state.vacancy_requests.filter((item) => item.id !== action.payload),
      }

    case 'CHANGE_SCROLL':
      return {
        ...state,
        scrollValue: action.payload,
      }

    case 'RESET_FILTER_PARAMS':
      return {
        ...initialState,
        tabs: state.tabs,
      }

    case 'SET_NEW_VACANCY_REQUEST': {
      const { vacancies } = action.payload
      return {
        ...state,
        vacancy_requests: [...vacancies, ...state.vacancy_requests],
      }
    }

    case 'SET_COMMENTS_GENERAL': {
      const { user_name, user_id } = action.payload

      return {
        ...state,
        comments: {
          ...state.comments,
          user_name,
          user_id,
          is_open_comments: true,
        },
      }
    }

    case 'SET_COMMENTS_LIST':
      return {
        ...state,
        comments: {
          ...state.comments,
          comments_list: [...state.comments.comments_list, ...action.payload],
        },
      }

    case 'CLEAR_COMMENTS_LIST':
      return {
        ...state,
        comments: {
          ...state.comments,
          comments_list: [],
        },
      }

    case 'ADD_NEW_COMMENT':
      return {
        ...state,
        comments: {
          ...state.comments,
          comments_list: [action.payload, ...state.comments.comments_list],
        },
      }

    case 'CLEAR_COMMENTS_DATA':
      return {
        ...state,
        comments: {
          ...initialState.comments,
          comments_list: [],
        },
      }

    case 'SET_ID_DELETED_USER':
      return {
        ...state,
        idDeletedUsers: [...state.idDeletedUsers, action.payload],
      }

    case 'REMOVE_SEAFARER_FROM_LIST': {
      return {
        ...state,
        list: state.list.filter((item) => item.user_id !== action.payload),
      }
    }

    case 'SET_VACANCY_REQUEST_COUNT': {
      const { count } = action.payload

      return {
        ...state,
        requestCount: count,
      }
    }

    case 'DELETE_VACANCY_REQUEST': {
      const { count, vacancy } = action.payload

      return {
        ...state,
        requestCount: count,
        vacancy_requests: state.vacancy_requests.filter((item) => item.id !== vacancy.id),
      }
    }

    case 'UPDATE_VACANCY_REQUEST': {
      const { count, vacancy } = action.payload

      return {
        ...state,
        requestCount: count,
        vacancy_requests: state.vacancy_requests.map((item) => {
          if (item.id === vacancy.id) {
            return { ...item, is_viewed: true }
          }

          return item
        }, []),
      }
    }

    default:
      return state
  }
}
