import React, { useEffect, useRef, useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import SeafarersItem from './SeafarersItem'
import { useDispatch, useSelector } from 'react-redux'
import { changeScroll } from '../../redux/actions/seafarersSearch'
import Spinner from '../../UI/Spinner/Spinner'
import FlipMove from 'react-flip-move'

const SeafarersList = ({ list = [], onScroll, onWheel, noContentMessage, isLoading, tab, ...rest }) => {
  const { is_open_comments } = useSelector(({ seafarersSearch }) => seafarersSearch.comments)
  const { scrollValue: scroll } = useSelector(({ seafarersSearch }) => seafarersSearch)
  const [removing, setRemoving] = useState(null)

  const dispatch = useDispatch()

  const listRef = useRef(null)

  useEffect(() => {
    const currentRef = listRef.current

    listRef.current.scrollTop = scroll

    return () => {
      dispatch(changeScroll(currentRef.scrollTop))
    }

    // eslint-disable-next-line
  }, [scroll])

  return (
    <div
      ref={listRef}
      className={cn(styles.list, {
        [styles.hide_list]: is_open_comments,
        // [styles.isFilterOpen]: rest.isFilterOpen,
      })}
      onScroll={(event) => {
        onScroll(event)
      }}
      // onWheel={(e) => onWheel(e)}
    >
      {list.length !== 0 ? (
        <>
          <FlipMove
            typeName="ul"
            duration={300}
            staggerDelayBy={list.length ? 300 / list.length : 300 / 15}
            appearAnimation="accordionVertical"
            enterAnimation="fade"
            leaveAnimation="none"
            verticalAlignment="bottom"
          >
            {list &&
              list.map((item) => (
                <li key={item.id} className={cn('list-item', { [removing === item.id]: 'removing' })}>
                  <SeafarersItem data={item} tab={tab} {...rest} setRemoving={setRemoving} />
                </li>
              ))}
          </FlipMove>

          {isLoading === null && list.length > 7 ? (
            <div className={styles.noContent} style={{ padding: '50px 0' }}>
              You've seen everything
            </div>
          ) : (
            isLoading && <Spinner type="magnifier" size={60} withoutPosition />
          )}
        </>
      ) : (
        <div className={styles.noContent}>{noContentMessage}</div>
      )}
    </div>
  )
}

export default SeafarersList
