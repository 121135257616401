import React from 'react'
import { useFormik } from 'formik'
import Button from '../../UI/Button/Button'
import { Link } from 'react-router-dom'
import InputPassword from '../../UI/Input/InputPassword'
import Input from '../../UI/Input/Input'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../../redux/actions/auth'
import './auth-form.scss'
import { setLoginEmail } from '../../redux/actions/user.action'
import { useNotify } from '../../hooks/useNotify'
import { getCurrentDomain } from '../../helpers/apiHelper'
import { signInSchema } from './sign.schemas'

export const SignInForm = () => {
  const dispatch = useDispatch()
  const { notify } = useNotify()
  const { isButtonEnable } = useSelector(({ authentication }) => authentication)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: true,
    validationSchema: signInSchema,
    onSubmit: (values) => {
      dispatch(
        getToken(
          {
            username: values.email,
            password: values.password,
          },
          notify
        )
      )
    },
  })

  const handleChangeFormikValue = (event) => {
    if (!formik.touched[event.target.name]) {
      formik.setFieldTouched(event.target.name, true)
    }

    if (event.target.name === 'email') {
      dispatch(setLoginEmail(event.target.value))
    }

    formik.handleChange(event)
  }

  const { values, errors, touched } = formik

  return (
    <div className="auth-form auth-form-employers-signin">
      <form onSubmit={formik.handleSubmit}>
        <div className="auth-form-container">
          <div className="auth-form__title">Log In as</div>
          <div className="auth-form__scope_selection">
            <span className="scope_selection_active">CrewManager</span>
            <a className="scope_selection_link" href={`${getCurrentDomain()}nextship.net/`}>
              Seafarer
            </a>
          </div>
          <fieldset className="auth-form__fieldset">
            <div className="form-group">
              <Input
                className="auth-form__input"
                name="email"
                isInvalid={!!(errors.email && touched.email)}
                placeholder="Email.example@domainname"
                value={values.email}
                onChange={handleChangeFormikValue}
              />
              {errors.email && <div className="input-error-message-static">{errors.email}</div>}
            </div>
            <div className="form-group">
              <InputPassword
                theme="password"
                className="auth-form__input"
                name="password"
                isInvalid={!!(errors.password && touched.password)}
                placeholder="Password"
                value={values.password}
                onChange={handleChangeFormikValue}
              />
              {errors.password && <div className="input-error-message-static">{errors.password}</div>}
            </div>
          </fieldset>

          <div className="auth-form__agreement">
            <div style={{ textAlign: 'right' }}>
              <Link to="/password-reset">Forgot password?</Link>
            </div>
          </div>
          <Button theme="default-dark" type="submit" disabled={!isButtonEnable}>
            Log in
          </Button>
        </div>
      </form>
      <div className="auth-form__footer">
        Don't have an account?{' '}
        <b>
          <Link to="/auth/signup">Create It?</Link>
        </b>
      </div>
    </div>
  )
}
