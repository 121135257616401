import React from "react";
import styles from "./Operator.module.scss";
import cn from "classnames";

function Operator({ isActive, children, ...rest }) {
  return (
    <button
      {...rest}
      className={cn(styles.block, { [styles.active]: isActive })}
    >
      {children}
    </button>
  );
}

export default Operator;
