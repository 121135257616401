import React from 'react'

export const RatingStarSvg = ({ width = '24', height = '24', fill = '#43927C' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7865_24059)">
        <path
          d="M12 0.25L14.5819 8.1963H22.9371L16.1776 13.1074L18.7595 21.0537L12 16.1426L5.24047 21.0537L7.82238 13.1074L1.06285 8.1963H9.41809L12 0.25Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7865_24059">
          <rect width="23" height="23" fill="white" transform="translate(0.5 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
