import React, { useEffect } from 'react'
import { setProfileTitle } from '../redux/actions/ui.action'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import TabNav from '../components/TabNav/TabNav'
import Search from '../tabs/seafarers-search/Search'
import Requests from '../tabs/seafarers-search/Requests'
import Favourites from '../tabs/seafarers-search/Favourites'
import { changeScroll, resetFilterParams } from '../redux/actions/seafarersSearch'
import { Comments } from '../components/Comments/Comments'
import { billingPermission, profilePaths } from '../constants/constants'
import { isServiceAvailable } from '../helpers/helpers'
import UnpaidSearchAccess from '../components/SeafarersList/UnpaidAccess/UnpaidSearchAccess'
import UnpaidFavoritesAccess from '../components/SeafarersList/UnpaidAccess/UnpaidFavoritesAccess'

const SearchPage = () => {
  const { requestCount } = useSelector(({ seafarersSearch }) => seafarersSearch)
  const isOpenComments = useSelector(({ seafarersSearch }) => seafarersSearch.comments.is_open_comments)

  const dispatch = useDispatch()

  const isResumeSearchAccess = isServiceAvailable(billingPermission.resume_search_access)
  const isFavoritesAccess = isServiceAvailable(billingPermission.candidate_management)

  useEffect(() => {
    dispatch(setProfileTitle('Search for seafarers'))

    return () => {
      dispatch(resetFilterParams())
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(changeScroll(0))

    // eslint-disable-next-line
  }, [isOpenComments])

  const links = [
    {
      url: profilePaths.seafarers_search,
      title: 'Search',
      component: isResumeSearchAccess ? Search : UnpaidSearchAccess,
      exact: false,
    },
    {
      url: profilePaths.seafarers_favorites,
      title: 'Favourites',
      component: isFavoritesAccess ? Favourites : UnpaidFavoritesAccess,
      exact: false,
    },
    {
      url: profilePaths.seafarers_request,
      title: 'Vacancy requests',
      component: Requests,
      indicator: requestCount || null,
      exact: false,
    },
  ]

  return (
    <>
      <TabNav links={links} />

      <div className="container search-page-container" style={{ paddingTop: 10 }}>
        <Switch>
          {links.map(({ url, component: Component, exact }) => (
            <Route exact={exact} key={url} path={url}>
              <Component />
            </Route>
          ))}

          <Redirect to={profilePaths.seafarers_search} />
        </Switch>

        {Boolean(isOpenComments) && <Comments />}
      </div>
    </>
  )
}

export default SearchPage
