import React, { useEffect, useState } from 'react'

import Tooltip from 'react-tooltip-lite'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import styles from './controls.module.scss'
import mainStyles from '../styles.module.scss'
import cn from 'classnames'

import { ChatIcon, FavouriteIcon } from '../../../UI/Icons/Icons'
import Spinner from '../../../UI/Spinner/Spinner'

import { setIsSeafarerFavorite } from '../../../redux/actions/seafarersSearch'
import { createChatRoom } from '../../../api/postApi'
import { useNotify } from '../../../hooks/useNotify'
import useCanEdit from '../../../hooks/useCanEdit'
import { isServiceAvailable } from '../../../helpers/helpers'
import { billingPermission, profilePaths } from '../../../constants/constants'
import LastChatDateInfo from '../../LastChatDateInfo/LastChatDateInfo'

const Controls = ({ status, isFavourite, chatId, userId, chatInfo, setRemoving }) => {
  const [createdChat, setCreatedChat] = useState(null)

  const { notify } = useNotify()
  const dispatch = useDispatch()
  const history = useHistory()
  const isCanEdit = useCanEdit()

  const isChatActive = isServiceAvailable(billingPermission.instant_messaging)
  const isCandidateManagementActive = isServiceAvailable(billingPermission.candidate_management)

  useEffect(() => {
    if (createdChat) {
      history.push(`${profilePaths.chat}/${createdChat}`)
    }

    // eslint-disable-next-line
  }, [createdChat])

  const link = (() => {
    if (isChatActive) return `${profilePaths.chat}/${chatId}`

    return ''
  })()

  const tooltipContent = (() => {
    if (isFavourite) return <span>Remove from favourite</span>

    return <div style={{ width: '138px' }}>In favourite</div>
  })()

  const stylesButton = {
    color: isFavourite && 'var(--color-white)',
    backgroundColor: isFavourite && 'var(--color-accent-light)',
    alignItems: 'flex-start',
  }

  const handleFavoriteClick = (event) => {
    event.stopPropagation()
    if (isFavourite) setRemoving(userId)
    dispatch(setIsSeafarerFavorite(userId, isFavourite))
  }

  const handleCreateChatClick = async (event) => {
    event.stopPropagation()

    setCreatedChat(false)

    try {
      const response = await createChatRoom(userId)

      setCreatedChat(response.chat_id)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  return (
    <div className={cn(styles.controls, mainStyles.section, { [styles.controls__inactive]: !isCanEdit })}>
      <p className={styles.availabilityStatus}>
        Now: <span>{status}</span>
      </p>

      <div className={styles.controlsContainer}>
        <Tooltip
          arrow={true}
          direction="up"
          arrowSize={7}
          content={isCandidateManagementActive ? tooltipContent : 'Service not paid'}
          padding={8}
        >
          <button
            className={cn(styles.controlsButton, {
              [styles.active]: isFavourite,
              [styles.inactive]: !isCandidateManagementActive,
            })}
            style={stylesButton}
            onClick={handleFavoriteClick}
            disabled={!isCandidateManagementActive}
          >
            <FavouriteIcon strokeColor={isFavourite ? 'var(--color-white)' : 'var(--color-accent-light)'} />
          </button>
        </Tooltip>

        <Tooltip
          arrow={true}
          direction="up"
          arrowSize={7}
          content={isChatActive ? 'to chat' : 'service not paid'}
          padding={8}
        >
          {chatId && (
            <>
              <Link
                onClick={(e) => e.stopPropagation()}
                to={link}
                style={{ color: 'var(--color-accent-light)' }}
                className={cn(styles.controlsButton, {
                  [styles.controlsButtonDisabled]: !isChatActive,
                })}
              >
                <ChatIcon strokeColor="var(--color-accent-light)" />
              </Link>

              {chatInfo.last_message_date && (
                <LastChatDateInfo date={chatInfo.last_message_date} to={link} className={styles.lastChatInfo} />
              )}
            </>
          )}

          {!chatId && (
            <button
              onClick={handleCreateChatClick}
              style={{ color: 'var(--color-accent-light)', position: 'relative' }}
              className={styles.controlsButton}
            >
              {createdChat === false ? (
                <Spinner size={20} strokeWidth={3} />
              ) : (
                <ChatIcon strokeColor="var(--color-accent-light)" />
              )}
            </button>
          )}
        </Tooltip>
      </div>
    </div>
  )
}

export default Controls
