import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './styles.scss'
import cn from 'classnames'

import eyeOpenSvg from '../../assets/icons/eye-open.svg'
import eyeCloseSvg from '../../assets/icons/eye-close.svg'

const InputPassword = ({ field, form, theme = 'default', isInvalid, errorMessage = '', className, ...rest }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleEyeClick = () => {
    setIsVisible(() => !isVisible)
  }

  return (
    <>
      <div className="input-password-wrapper">
        <input
          type={isVisible ? 'text' : 'password'}
          className={cn(`input input-${theme}`, className, { input_invalid: isInvalid })}
          {...field}
          {...rest}
        />

        <button tabIndex="-1" onClick={handleEyeClick} type="button" className="eye-button">
          <img src={isVisible ? eyeOpenSvg : eyeCloseSvg} alt="eye" />
        </button>
      </div>

      {isInvalid && errorMessage && <div className="input-error-message">{errorMessage}</div>}
    </>
  )
}

InputPassword.propTypes = {
  placeholder: PropTypes.string,
}

export default InputPassword
