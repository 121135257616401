import { toast } from 'react-toastify'
import React from 'react'

function createContent(title, message) {
  return (
    <>
      <figure className="toast-circle-figure" />
      <div className="toast-body-content">
        <span className="toast-body-title">{title}</span>
        <span className="toast-body-text">{message}</span>
      </div>
    </>
  )
}

function createNotifyByType(type) {
  return (title, message, options) => {
    toast[type](createContent(title, message), options)
  }
}

function createErrorsList() {
  return (errors, options = {}) => {
    if (Array.isArray(errors) && errors.length) {
      errors.forEach((error) => {
        if (Array.isArray(error.message)) {
          error.message.forEach((message) => toast.error(createContent('Error', message), options))

          return
        }

        toast.error(createContent('Error', error.message), options)
      })
    }
  }
}

const notify = (title, message, options) => {
  toast.success(createContent(title, message), options)
}

notify.error = createNotifyByType('error')
notify.info = createNotifyByType('info')
notify.warning = createNotifyByType('warning')
notify.errorsList = createErrorsList()

export { notify }
