import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import styles from '../styles.module.scss'

import Select from '../../../../UI/Select/Select'
import MultiSelect from '../../../../UI/Select/MultiSelect'
import Input from '../../../../UI/Input/Input'
import Button from '../../../../UI/Button/Button'

import {
  clearVacancyTemplatesRequestsList,
  setVacancyTemplatesRequestsList,
} from '../../../../redux/actions/seafarersSearch'
import { getAuthCompanyVacancies } from '../../../../api/getApi'
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll'
import { fetchTemplatesHelper } from '../../../../helpers/apiHelper'
import useFilterForm from '../../useFilterForm'
import useDistanceToBottom from '../../../../hooks/useDistanceToBottom'
import { initialValues } from './config'

const candidates = [
  { id: 'my', name: 'View My Candidates' },
  { id: 'all', name: 'View All Candidates' },
]

const RequestFilterForm = memo(({ values, setValues, onSubmitEvent, onClearEvent }) => {
  const { vacancy_templates } = useSelector(({ seafarersSearch }) => seafarersSearch)

  const {
    filters,

    handleSelectClick,
    handleInputChange,
  } = useFilterForm(setValues)

  const { handleScroll, loading } = useInfiniteScroll(
    getAuthCompanyVacancies,
    setVacancyTemplatesRequestsList,
    fetchTemplatesHelper,
    'short=true',
    clearVacancyTemplatesRequestsList
  )

  const { ref, distanceToBottom } = useDistanceToBottom()

  const handleSubmit = (e) => {
    e.preventDefault()

    onSubmitEvent(values)
  }

  const handleClear = () => {
    onClearEvent(initialValues)
  }

  return (
    <form ref={ref} className={styles.form} onSubmit={handleSubmit} style={{ height: distanceToBottom - 10 }}>
      <div className={styles.field}>
        <Input
          name="search"
          label="Search (First name, Last name, Email)"
          value={values.search.value}
          onChange={handleInputChange}
        />
      </div>

      <div className={styles.field}>
        <Select
          name="sort"
          label="Vacancy template"
          id={values.sort.value.id}
          selectedItem={values.sort.value}
          items={vacancy_templates}
          onClick={handleSelectClick}
          onScroll={handleScroll}
          isLoading={loading}
        />
      </div>

      <div className={styles.field}>
        <MultiSelect
          name="ranks"
          label="Rank"
          items={filters.rank}
          value={values.ranks.value}
          onClick={handleSelectClick}
        />
      </div>

      <div className={styles.field}>
        <MultiSelect
          name="vessel_types"
          label="Vessel type"
          items={filters.vessel_type}
          value={values.vessel_types.value}
          onClick={handleSelectClick}
        />
      </div>

      <div className={styles.field}>
        <MultiSelect
          name="vessel_engines"
          label="Engine type"
          items={filters.vessel_engine_type}
          value={values.vessel_engines.value}
          onClick={handleSelectClick}
        />
      </div>

      <div className={styles.rowTop}>
        <div className={styles.field}>
          <Input name="teu" label="TEU" value={values.teu.value} onChange={handleInputChange} />
        </div>

        <div className={styles.field}>
          <Input name="dwt" label="DWT" value={values.dwt.value} onChange={handleInputChange} />
        </div>
      </div>

      <div className={styles.rowBottom}>
        <div className={styles.field}>
          <Input name="grt" label="GRT" value={values.grt.value} onChange={handleInputChange} />
        </div>

        <div className={styles.field}>
          <Input name="hp" label="HP" value={values.hp.value} onChange={handleInputChange} />
        </div>

        <div className={styles.field}>
          <Input name="kw" label="KW" value={values.kw.value} onChange={handleInputChange} />
        </div>
      </div>

      <div className={styles.field}>
        <MultiSelect
          name="nationalities"
          defaultName=""
          label="Citizenship"
          items={filters.country}
          value={values.nationalities.value}
          onClick={handleSelectClick}
        />
      </div>

      <div className={styles.field}>
        <MultiSelect
          name="documents"
          defaultName=""
          label="Documents"
          items={filters.documents}
          value={values.documents.value}
          onClick={handleSelectClick}
        />
      </div>

      <div className={styles.field}>
        <Select
          name="candidates"
          label="Candidates"
          defaultName=""
          id={values.candidates.value.id}
          selectedItem={values.candidates.value}
          items={candidates}
          onClick={handleSelectClick}
        />
      </div>

      <Button type="submit" size="middle-full">
        Save
      </Button>
      <Button type="button" onClick={handleClear} size="transparent" className={styles.clearBtn}>
        Clear
      </Button>
    </form>
  )
})

export default RequestFilterForm
