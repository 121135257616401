import { useEffect, useRef, useState } from 'react'
import { getUniqueCursorHelper } from '../helpers/apiHelper'
import { notify } from '../helpers/notify'

export default function useInfiniteScrollSearch({ requestFunction, setItems, name, initialCursor, apiHelper = null }) {
  const [loading, setLoading] = useState(false)
  const [nextCursor, setNextCursor] = useState(initialCursor)

  const currentCursor = useRef()

  currentCursor.current = nextCursor

  useEffect(() => {
    if (initialCursor) {
      setNextCursor(initialCursor)
      currentCursor.current = initialCursor

      return
    }

    setNextCursor(null)
    currentCursor.current = null
  }, [initialCursor])

  const handleScroll = async ({ target }) => {
    try {
      if (nextCursor) {
        if (target.scrollTop + 300 >= target.scrollHeight && !loading) {
          setLoading(true)

          const response = await requestFunction(currentCursor.current, name)

          setNextCursor(response.next ? getUniqueCursorHelper(response.next) : null)

          setItems((prev) => (apiHelper ? [...prev, ...apiHelper(response.results)] : [...prev, ...response.results]))
        }
      }
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    nextCursor,
    handleScroll,
    setNextCursor,
  }
}
