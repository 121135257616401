import React, { useEffect, useState } from 'react'
import Select from '../../../UI/Select/Select'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import { employerTypes } from '../../../constants/default-values'
import Input from '../../../UI/Input/Input'
import Switcher from '../../../UI/Switcher/Switcher'
import Button from '../../../UI/Button/Button'
import { useNotify } from '../../../hooks/useNotify'
import { getAuthProfileFilters } from '../../../api/getApi'
import cn from 'classnames'
import useCanEdit from '../../../hooks/useCanEdit'

const Form = ({ formik, updateUsersList, isLoading, isUploadingAvatar }) => {
  const [filters, setFilters] = useState({
    company_user_type: [],
    company_user_position: [],
  })

  const { notify } = useNotify()

  const isCanEdit = useCanEdit()

  const { values, dirty } = formik

  useEffect(() => {
    getFilters()

    return () => {
      setFilters({
        company_user_type: [],
        company_user_position: [],
      })
    }

    //eslint-disable-next-line
  }, [])

  const getFilters = async () => {
    try {
      const data = await getAuthProfileFilters('company_user_type', 'company_user_position')
      setFilters({
        ...data,
        company_user_position: data.company_user_position.filter((item) => item.name !== 'Admin'),
        company_user_type: data.company_user_type.filter((item) => item.name !== 'Owner'),
      })
      await updateUsersList()
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const handleFieldChange = (type, data) => {
    handleChangeFormikValue({ target: { value: data, name: type } })
  }

  const handleChangeFormikValue = (value) => {
    if (!formik.touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  const setIsEnabled = () => {
    formik.setValues({
      ...formik.values,
      is_active: !formik.values.is_active,
    })
  }

  const completelyFilled =
    values.user_type?.id && values.first_name && values.last_name && values.position?.id && values.email

  return (
    <form className={cn('profile-form', { 'disabled-users-profile': !isCanEdit })} onSubmit={formik.handleSubmit}>
      <div className="profile-form-main">
        <div className="profile-form-fields">
          <div className="profile-form-column">
            <div className="field-item">
              <Select
                label="User role"
                required
                name="user_type"
                id={formik.values.user_type.id}
                selectedItem={formik.values.user_type}
                items={filters.company_user_type}
                onClick={handleFieldChange}
                isInvalid={Boolean(formik.errors.user_type && formik.touched.user_type)}
                disabled={formik.values.user_type.id === 1}
              />

              <Tooltip theme="white">
                <div className="employer-type">
                  {employerTypes.map(({ name, description }, index) => (
                    <React.Fragment key={index}>
                      <div className="employer-type_title">{name}</div>
                      <div className="employer-type_text">{description}</div>
                    </React.Fragment>
                  ))}
                </div>
              </Tooltip>
            </div>

            <div className="field-item">
              <Input
                label="First name"
                name="first_name"
                required
                value={formik.values.first_name || ''}
                onChange={handleChangeFormikValue}
                placeholder="Name"
                isInvalid={Boolean(formik.errors.first_name && formik.touched.first_name)}
              />
            </div>

            <div className="field-item">
              <Input
                label="Last name"
                name="last_name"
                required
                value={formik.values.last_name || ''}
                onChange={handleChangeFormikValue}
                placeholder="Last name"
                isInvalid={Boolean(formik.errors.last_name && formik.touched.last_name)}
              />
            </div>

            <div className="field-item">
              <Select
                label="User position"
                required
                name="position"
                id={formik.values.position?.id}
                selectedItem={formik.values.position}
                items={filters.company_user_position}
                onClick={handleFieldChange}
                isInvalid={Boolean(formik.errors.position && formik.touched.position)}
              />
            </div>

            <div className="field-item">
              <Input
                label="Email"
                required
                name="email"
                value={formik.values.email || ''}
                onChange={handleChangeFormikValue}
                placeholder="Email"
                isInvalid={Boolean(formik.errors.email && formik.touched.email)}
                disabled={formik.values.user_type.id === 1}
              />
            </div>

            <div className="field-item">
              <div className="field-label">User status</div>
              <div
                className={cn('create-field-wrapper', {
                  'create-field-wrapper-disabled': formik.values.user_type.id === 1,
                })}
              >
                <div className="create-field-wrapper__title">Enable</div>
                <Switcher
                  isActive={formik.values.is_active}
                  onClick={setIsEnabled}
                  id="form-switcher"
                  disabled={formik.values.user_type.id === 1}
                />
              </div>
            </div>

            <div className="field-item" style={{ marginTop: 18, height: 41 }}>
              <Button
                style={{ lineHeight: '16px' }}
                loading={isLoading}
                disabled={(completelyFilled && !dirty) || (isUploadingAvatar && !dirty)}
                type="submit"
                size="middle-full"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Form
