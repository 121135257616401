export const operationType = {
  edit: 'Edit',
  reply: 'Reply',
  delete: 'Delete',
}

export const priorityType = {
  usual: 'usual',
  urgent: 'urgent',
}

export const vacancyPriorityMap = {
  usual: {
    value: priorityType.usual,
    label: 'Usual vacancy',
  },
  urgent: {
    value: priorityType.urgent,
    label: 'Urgent vacancy',
  },
}

export const profilePaths = {
  auth: '/auth/signin',
  seafarers: '/profile/seafarers',
  seafarers_search: '/profile/seafarers/search',
  seafarers_favorites: '/profile/seafarers/favorites',
  seafarers_request: '/profile/seafarers/requests',
  chat: '/profile/chat',
  vacancy_manager: '/profile/vacancy-manager',
  create_vacancy: '/profile/vacancy-manager/vacancy',
  create_vessel: '/profile/vacancy-manager/vessel',
  users: '/profile/users',
  users_create: '/profile/users/users-create',
  role_permissions: '/profile/users/role-permissions',
  company_data: '/profile/company-data',
  billing: '/profile/billing',
  billing_payment: '/profile/billing/payment',
  billing_history: '/profile/billing/history',
  statistic: '/profile/statistic',
  settings: '/profile/settings',
}

export const userPermission = {
  seafarers_favourites: 'seafarers_favourites',
  vacancy_requests: 'vacancy_requests',
  seafarer_chat: 'seafarer_chat',
  vacancies: 'vacancies',
  vessels: 'vessels',
  users: 'users',
  company_profile: 'company_profile',
  billing: 'billing',
}

export const permissionsByPathMap = {
  [profilePaths.seafarers_search]: userPermission.seafarers_favourites,
  [profilePaths.seafarers_favorites]: userPermission.seafarers_favourites,
  [profilePaths.seafarers_request]: userPermission.vacancy_requests,
  [profilePaths.chat]: userPermission.seafarer_chat,
  [profilePaths.create_vacancy]: userPermission.vacancies,
  [profilePaths.create_vessel]: userPermission.vessels,
  [profilePaths.users_create]: userPermission.users,
  [profilePaths.role_permissions]: userPermission.users,
  [profilePaths.company_data]: userPermission.company_profile,
  [profilePaths.billing_payment]: userPermission.billing,
  [profilePaths.billing_history]: userPermission.billing,
}

export const billingPermission = {
  candidate_management: 'candidate_management',
  instant_messaging: 'instant_messaging',
  resume_search_access: 'resume_search_access',
  job_posting_api: 'job_posting_api',
  application_alerts: 'application_alerts',
  ai_powered_matches: 'ai_powered_matches',
  targeted_recruitment_emails: 'targeted_recruitment_emails',
}

export const events = {
  chat: {
    rooms: 'chat.rooms',
    messageDelivered: 'chat.message.delivered',
    messages: 'chat.messages',
    newMessage: 'new.chat.message',
    searchMessage: 'search.chat.message',
    editMessage: 'chat.edit.message',
    sendMessage: 'send.message',
    delete: 'chat.delete',
  },
  billing: {
    paymentRequiresConfirmation: 'billing.payment_intent.requires_confirmation',
    cardDetach: 'billing.card.detached',
    cardAdded: 'billing.card.added',
    subscriptionPaid: 'billing.subscription.paid',
    subscriptionUnPaid: 'billing.subscription.unpaid',
    subscriptionUpdated: 'billing.subscription.updated',
    permissionsCurrent: 'billing.permissions.current',
    permissionsUpdated: 'billing.permissions.updated',
    subscriptionCanceled: 'billing.subscription.canceled',
    newEvent: 'events.event.new',
  },
}
