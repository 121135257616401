import { postCompanyVessel } from '../../api/postApi'
// import { getAuthCompanyVessels, getAuthDeletedVessels } from '../../api/getApi'
import { vesselDataPrettier, vesselSubmitHelper } from '../../helpers/vesselManager.helper'
import { deleteCompanyVessel } from '../../api/deleteApi'
import { putCompanyVessel } from '../../api/putApi'
import { pathRestoreDeletedVessel } from '../../api/patchApi'
import { notify } from '../../helpers/notify'
import { batch } from 'react-redux'
import store, { createAction } from '../store'
import { VESSEL } from './vesselManager.types'

export const postVessel = (data) => (dispatch) => {
  const { deletedList, vesselCounter: all } = store.getState().vessels

  dispatch(setIsButtonEnable(false))

  const { id } = data.vessel

  const isExistVessel = deletedList.some((item) => item.id === id)

  // if such vessel is on the deleted list
  if (isExistVessel) {
    batch(() => {
      dispatch(clearForm())
      dispatch(restoreDeletedVessel(id))
    })

    return
  }

  const postNewVessel = async () => {
    try {
      const response = await postCompanyVessel(vesselSubmitHelper(data))

      batch(() => {
        dispatch(clearForm())
        dispatch(addNewVessel(vesselDataPrettier(response)))
        dispatch(vesselAllCounter(all + 1))
        dispatch(setIsButtonEnable(false))
      })

      notify('Success', 'Vessel was successfully updated')
    } catch (error) {
      dispatch(setIsButtonEnable(true))

      notify.errorsList(error.errors)
    }
  }

  postNewVessel()
}

export const updateVessel = (data, id) => (dispatch) => {
  dispatch(setIsButtonEnable(false))

  const putVessel = async () => {
    try {
      const response = await putCompanyVessel(vesselSubmitHelper(data), id)

      batch(() => {
        dispatch(clearForm())
        dispatch(addEditedData(vesselDataPrettier(response)))
      })

      notify('Success', 'Vessel was successfully updated')
    } catch (error) {
      dispatch(setIsButtonEnable(true))

      notify.errorsList(error.errors)
    }
  }

  putVessel()
}

export const setIsButtonEnable = (payload) => ({
  type: VESSEL.SET_IS_BUTTON_ENABLE,
  payload,
})

export const addEditedData = (payload) => ({
  type: VESSEL.ADD_EDITED_DATA,
  payload,
})

export const addNewVessel = (payload) => ({
  type: VESSEL.ADD_NEW_VESSEL,
  payload,
})

export const deleteVesselUpdate = (payload) => ({
  type: VESSEL.DELETE_VESSEL_UPDATE,
  payload,
})

// export const deleteVesselRestore = (payload) => ({
//   type: 'DELETE_VESSEL_RESTORE',
//   payload,
// })

export const restoreVessel = (payload) => ({
  type: VESSEL.RESTORE_VESSEL,
  payload,
})

export const deleteVessel = (id) => {
  const { vesselCounter: all, deletedVesselCount: del } = store.getState().vessels

  return async (dispatch) => {
    try {
      await deleteCompanyVessel(id)

      dispatch(deleteVesselUpdate(id))
      dispatch(vesselAllCounter(all - 1))
      dispatch(vesselDeletedCounter(del + 1))
      dispatch(clearForm())

      notify('Success', 'Vessel was successfully deleted')
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }
}

const setIsRestoreLoading = (payload) => ({
  type: VESSEL.SET_IS_RESTORE_LOADING,
  payload,
})

export const restoreDeletedVessel = (id) => {
  const { vesselCounter: all, deletedVesselCount: del } = store.getState().vessels

  return async (dispatch) => {
    dispatch(setIsRestoreLoading(true))

    try {
      await pathRestoreDeletedVessel(id)

      batch(() => {
        dispatch(restoreVessel(id))
        dispatch(vesselAllCounter(all + 1))
        dispatch(vesselDeletedCounter(del - 1))
      })
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      dispatch(setIsRestoreLoading(false))
    }
  }
}

export const editVessel = (id) => ({
  type: VESSEL.EDIT_VESSEL,
  payload: id,
})

// export const getVesselsList = (search) => {
//   const query = search ? `search=${search}` : ''
//
//   return (dispatch) => {
//     getAuthCompanyVessels(query, 1)
//       .then((data) => {
//         dispatch(updateVesselsList(vesselDataPrettier(data.results)))
//         getAuthDeletedVessels(1, query)
//           .then((data) => {
//             dispatch(updateDeletedVesselsList(vesselDataPrettier(data.results)))
//           })
//           .catch((error) => notify.errorsList(error.errors))
//       })
//       .catch((error) => notify.errorsList(error.errors))
//   }
// }

export const vesselAllCounter = (payload) => ({
  type: VESSEL.VESSEL_ALL_COUNTER,
  payload,
})

export const vesselDeletedCounter = (payload) => ({
  type: VESSEL.VESSEL_DELETED_COUNTER,
  payload,
})

export const updateVesselsList = (payload) => ({
  type: VESSEL.UPDATE_VESSELS_LIST,
  payload,
})

export const updateDeletedVesselsList = (payload) => ({
  type: VESSEL.UPDATE_DELETED_VESSELS_LIST,
  payload,
})

export const clearVesselsList = createAction(VESSEL.CLEAR_VESSELS_LIST)

export const resetVessels = createAction(VESSEL.RESET_VESSELS)

export const clearForm = createAction(VESSEL.CLEAR_VESSEL_FORM)
