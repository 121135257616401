import { fieldsToObjectCreate } from './constants'

const secondComma = (str) => {
  const comma = ','
  const firstIndex = str.indexOf(comma)

  if (firstIndex > -1) {
    const rest = str
      .slice(firstIndex + 1)
      .split(comma)
      .join('')

    return str.slice(0, firstIndex + 1) + rest
  }

  return str
}

export const powerConverter = (unit, value) => {
  if (!value) return ''

  value = secondComma(value).replace(',', '.')

  switch (unit) {
    case 'hp':
      return (value / 1.341).toFixed(2)
    case 'kw':
      return (value * 1.341).toFixed(2)
    default:
      return value
  }
}

const createObjectByFields = (fields, data) => {
  return fields.reduce((acc, field) => {
    if (!data[field]) {
      acc[field] = { id: null, name: '' }
    } else {
      const { id, name } = data[field]

      acc[field] = { id, name }
    }

    return acc
  }, {})
}

export const autoPasteFieldsByVessel = (data) => {
  if (!data) return

  const { id, name, owner, year_of_build, hp, kw, teu, ...restData } = data

  const result = createObjectByFields(fieldsToObjectCreate, data)

  const country = (() => {
    if (!owner?.country) return { id: null, name: '' }

    const { id, name } = owner.country

    return { id, name }
  })()

  const { engine_type: engine, flag: vessel_flag, ...restResult } = result

  return {
    ...restData,
    ...restResult,

    country,
    engine,
    vessel_flag,

    vessel: { id, name },

    year_of_build: year_of_build ? String(year_of_build) : '',
    hp: hp ? String(hp) : '',
    kw: kw ? String(kw) : '',
    teu: teu ? String(teu) : '',
  }
}
