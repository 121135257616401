import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../redux/actions/auth'
import { Link } from 'react-router-dom'
import useActiveCompany from '../../hooks/useActiveCompany'

const UserProfileMore = ({ picture, setIsOpen, email, firstName, lastName, scope }) => {
  const [lastLogin, setLastLogin] = useState('loading...')

  const { last_login } = useSelector(({ company }) => company)

  const dispatch = useDispatch()
  const popup = useRef(null)

  const isActiveCompany = useActiveCompany()

  const logOut = () => {
    dispatch(userLogout())
  }

  const isProfile = window.location.pathname.includes('/profile')

  useEffect(() => {
    if (scope) setLastLogin(last_login)
  }, [last_login, scope])

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      !popup.current.contains(target) && setIsOpen(false)
    }

    if (popup.current) {
      document.addEventListener('click', handleClickOutside, false)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [setIsOpen])

  return (
    <div ref={popup} className={styles.more}>
      <div className={styles.more_header}>
        {picture && <img src={picture.file_mini || picture.file} alt="user profile" />}

        <div className={styles.more_header_info}>
          <div className={styles.more_username}>
            {firstName} {lastName}
          </div>

          <div className={styles.more_email}> {email} </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.signButtons}>
          <div className={styles.buttons}>
            <Link to={isProfile ? '/' : isActiveCompany ? '/profile/seafarers/search' : '/profile/personal-data'}>
              <button onClick={() => setIsOpen(false)} className={styles.buttons_profile}>
                {isProfile ? 'Go to main page' : 'Go to profile'}
              </button>
            </Link>
          </div>

          <div className={styles.buttons}>
            <button onClick={logOut} className={styles.buttons_logout}>
              Sign out
            </button>
          </div>
        </div>

        <div className={styles.more_footer}>Last session: {lastLogin}</div>
      </div>
    </div>
  )
}

UserProfileMore.propTypes = {
  picture: PropTypes.object,
  setIsOpen: PropTypes.func.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
}

export default UserProfileMore
