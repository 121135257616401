export const defaultUserPictureOptions = {
  file: 'https://publicnextship.s3-eu-west-1.amazonaws.com/empty-profile-photo.png',
  file_name: 'empty-profile-photo.png',
  quality_status: true,
  quality_message: '',
  file_to_send: null,
  id: null,
}

export const employerTypes = [
  {
    id: 1,
    name: 'Owner',
    description:
      "The user is created automatically when registering a company. It cannot be removed or made inactive. Access to any section of the employer's personal account is open.",
  },
  {
    id: 2,
    name: 'Administrator',
    description: "Access to any section of the CrewManager's personal account is open",
  },
  {
    id: 3,
    name: 'Manager',
    description: 'Access to all sections except Billing is open',
  },
  {
    id: 4,
    name: 'Operator',
    description: 'Limited access to Billing and vacancy publishing sections',
  },
  {
    id: 5,
    name: 'Accountant',
    description: 'Access is open only to the Billing section',
  },
]
