import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import TabNav from '../components/TabNav/TabNav'
import VacancyManagerForm from '../components/Company/VacancyManagerForm/VacancyManagerForm'
import { setProfileTitle } from '../redux/actions/ui.action'
import VacancyManagerListHeader from '../components/Company/VacancyManagerList/VacancyManagerListHeader'
import VesselManagerForm from '../components/Company/VesselManagerForm/VesselManagerForm'
import VesselManagerList from '../components/Company/VesselManagerList/VesselManagerList'
// import { resetVacancies } from '../redux/vacancyManager/vacancyManager.action'
// import { resetVessels } from '../redux/actions/vesselManager.action'
import { profilePaths } from '../constants/constants'

const classes = {
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 34,
  },
}

const links = [
  {
    url: profilePaths.create_vacancy,
    title: 'Vacancy creation',
  },
  {
    url: profilePaths.create_vessel,
    title: 'Vessel  registration',
  },
]

const VacancyManagerPage = () => {
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  useEffect(() => {
    dispatch(setProfileTitle('Vacancy Manager'))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <TabNav links={links} />

      <div ref={containerRef} className="container">
        <div style={classes.content}>
          <Switch>
            <Route exact path={profilePaths.create_vessel}>
              <VesselManagerForm container={containerRef} />
              <VesselManagerList />
            </Route>

            <Route exact path={profilePaths.create_vacancy}>
              <VacancyManagerForm container={containerRef} />
              <VacancyManagerListHeader />
            </Route>

            <Redirect to={profilePaths.create_vacancy} />
          </Switch>
        </div>
      </div>
    </>
  )
}

export default VacancyManagerPage
