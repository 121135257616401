import React from 'react'

import styles from './styles.module.scss'

import ProfileNavigationItem from './ProfileNavigationItem'
import useNavigationProfile from './useNavigationProfile'

const ProfileNavigation = () => {
  const { navigation } = useNavigationProfile()

  return (
    <div className={styles.nav}>
      {Object.keys(navigation).map((path) => {
        const { ...rest } = navigation[path]

        return <ProfileNavigationItem key={path} link={path} {...rest} />
      })}
    </div>
  )
}

ProfileNavigation.propTypes = {}

export default ProfileNavigation
