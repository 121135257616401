import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as StatusIcon } from './icons/status.svg'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'

const PersonalStatus = ({ status, id, className = '' }) => {
  return (
    <>
      <ReactTooltip id={`${id}-status`} />

      <div data-tip={status} data-for={`${id}-status`} className={cn(styles.statusWrapper, className)}>
        <StatusIcon />

        <span className={styles.status}>{`"${status}"`}</span>
      </div>
    </>
  )
}

export default PersonalStatus
