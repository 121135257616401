import React, { useEffect, useState } from 'react'

import styles from './publishedVacancies.module.scss'

import Tabs from '../Tabs/Tabs'
import ViewTab from './PublishedVacanciesChart/ViewTab/ViewTab'
import PublishedVacanciesList from './PublishedVacanciesList/PublishedVacanciesList'
import PublishedVacanciesChart from './PublishedVacanciesChart/PublishedVacanciesChart'
import { tab } from '../config'
import { view } from './config'

const PublishedVacancies = ({ data, handleChart, category }) => {
  const [activeTab, setActiveTab] = useState(tab.year)
  const [viewTab, setViewTab] = useState(view.chart)
  const [searchParams, setSearchParams] = useState(null)

  useEffect(() => {
    if (searchParams && viewTab === view.chart) {
      handleChart(category, searchParams)
    }

    // eslint-disable-next-line
  }, [viewTab])

  const handleTabClick = (tab, dates, group) => {
    setActiveTab(tab)
    setSearchParams({ dates, group })

    if (viewTab === view.chart) {
      handleChart(category, { dates, group })
    }
  }

  const handleViewTabClick = (tab) => {
    setViewTab(tab)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <h5 className={styles.title}>Published vacancies by</h5>

          <Tabs className={styles.tabs} activeTab={activeTab} onClick={handleTabClick} />
        </div>

        <ViewTab activeTab={viewTab} onClick={handleViewTabClick} />
      </div>

      {viewTab === view.chart && <PublishedVacanciesChart data={data} activeTab={activeTab} />}

      {viewTab === view.list && <PublishedVacanciesList searchParams={searchParams} />}
    </div>
  )
}

export default PublishedVacancies
