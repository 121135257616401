export const fields = ['duration', 'duration_period', 'salary_currency', 'salary', 'salary_rate']

export const fieldsToCheckTypeSea = [
  'rank',
  'vessel_type',
  'join_date',
  'duration',
  'duration_period',
  'salary',
  'salary_currency',
  'salary_rate',
]

export const fieldsToCheckTypeLand = [
  'rank',
  'country',
  'join_date',
  'duration',
  'duration_period',
  'salary',
  'salary_currency',
  'salary_rate',
]

export const vacancyTypes = {
  sea: 'sea',
  land: 'land',
}

export const periods = [
  { name: 'day', id: 'day' },
  { name: 'week', id: 'week' },
  { name: 'month', id: 'month' },
  { name: 'year', id: 'year' },
]

export const range = [
  { name: 'Beg month (1-10)', id: 'beg' },
  { name: 'Mid month (11-20)', id: 'mid' },
  { name: 'End month (21-31)', id: 'end' },
]

export const rangeMap = {
  beg: 'Beg month (1-10)',
  mid: 'Mid month (11-20)',
  end: 'End month (21-31)',
}
