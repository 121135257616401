import React from 'react'
import styles from './searchTabsPanel.module.scss'

export const parseSeafarersSearchLink = (link) => {
  const [category, id] = link.pathname.replace('/profile/seafarers/', '').split('/')

  return { category, id: Number(id) || null }
}

export const getNextIndex = (id, array) => {
  const index = array.findIndex((item) => item.id === id)

  if (index >= array.length - 1) {
    return index - 1
  }

  return index + 1
}

const tooltipContent = (
  <p className={styles.tooltip_content}>This user has deleted his profile. You can only close his card</p>
)

export const tooltipProps = {
  className: styles.tooltip,
  direction: 'up',
  content: tooltipContent,
  padding: 0,
  arrow: false,
}

export const getUserIdFromUrl = (location) => {
  // safari version 15 does not support the arr.at(-1) method
  const arr = location.split('/')
  const lastElement = arr[arr.length - 1]

  return !isNaN(Number(lastElement)) ? Number(lastElement) : null
}
