import React, { useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import AnimateHeight from 'react-animate-height'
import Button from '../../../UI/Button/Button'
import VacancyRequestItem from './VacancyRequestItem'
import { deleteSeafarerFromRequests } from '../../../api/deleteApi'
import { removeRequest } from '../../../redux/actions/seafarersSearch'
import { useNotify } from '../../../hooks/useNotify'
import { useDispatch } from 'react-redux'
import { setViewedVacancy } from '../../../api/postApi'
import useCanEdit from '../../../hooks/useCanEdit'

const VacancyRequest = ({
  requestId,
  vacancies,
  requestDate = null,
  isVacancyRequest = false,
  isViewed = true,
  newRequest = false,
}) => {
  const [isShowMore, setIsShowMore] = useState(false)
  const [viewControlsId, setViewControlsId] = useState(null)
  const [loading, setLoading] = useState(false)

  const { notify } = useNotify()
  const dispatch = useDispatch()

  const isCanEdit = useCanEdit()

  const handleMouseEnter = (id) => {
    setViewControlsId(id)
  }

  const handleMouseLeave = (id) => {
    setViewControlsId(id)
  }

  const handleViewClick = async () => {
    setLoading(true)

    try {
      await setViewedVacancy(requestId)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }
  }

  const handleRejectClick = async () => {
    setLoading(true)

    try {
      await deleteSeafarerFromRequests(requestId)

      dispatch(removeRequest(requestId))
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AnimateHeight duration={300} height={isShowMore ? 'auto' : 30}>
      <div className={styles.wrapper}>
        <div className={styles.applyInfo}>
          <span className={styles.infoTitle}>Apply by:</span>

          <div className={styles.vacancies}>
            {vacancies.map((vacancy, index) => (
              <VacancyRequestItem
                key={index}
                id={viewControlsId}
                requestId={requestId}
                vacancy={vacancy}
                date={requestDate}
                isVacancyRequest={isVacancyRequest}
                isViewed={isViewed}
                newRequest={newRequest}
                loading={loading}
                isActiveControls={isCanEdit}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                onViewClick={handleViewClick}
                onRejectClick={handleRejectClick}
              />
            ))}
          </div>

          {vacancies.length > 1 && (
            <Button
              onMouseEnter={() => setViewControlsId(false)}
              className={cn(styles.showMoreButton, { [styles.isOpenMore]: isShowMore })}
              theme="text"
              onClick={() => setIsShowMore(!isShowMore)}
            >
              {isShowMore ? 'Close' : 'Show more'}
            </Button>
          )}
        </div>
      </div>
    </AnimateHeight>
  )
}

export default VacancyRequest
