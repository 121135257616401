import React from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import ContactInfoItem from './ContactInfoItem/ContactInfoItem'
import styles from '../userCard.module.scss'

const ContactInfo = ({ userCardData }) => {
  const { contact_info } = userCardData
  const { mainContacts, socialMediaContacts, relativeContacts } = contact_info

  if (isEmpty(contact_info)) {
    return <div className={styles.noInfo}>No information</div>
  } else {
    return (
      <Masonry columnsCount={2} gutter="16px">
        {!isEmpty(mainContacts) && <ContactInfoItem title="My contacts" items={mainContacts} />}

        {!isEmpty(socialMediaContacts) && <ContactInfoItem title="Social networks" items={socialMediaContacts} />}

        {!isEmpty(relativeContacts) &&
          relativeContacts.map((relativeContact, index) => {
            return (
              <ContactInfoItem
                key={index}
                title={`${relativeContact.type} contacts`}
                nextOfKin={relativeContact.nextOfKin}
                items={relativeContact.values}
              />
            )
          })}
      </Masonry>
    )
  }
}

export default ContactInfo
