import { API_BASE_V2, METHOD } from './constans'
import { request, upload } from './auth.service'

export const putCompanyPicture = async (id, file, type) => {
  return await upload(`company/uploads/${id}/`, {
    method: METHOD.PUT,
    type,
    file,
  })
}

export const putCompanyAdditionalInfo = async (data, id) => {
  return await request(`company/profile_additional_data/${id}/`, { method: METHOD.PUT, body: JSON.stringify(data) })
}

export const putCompanyAdditionalContact = async (data, id) => {
  return await request(`company/profile_contact/${id}/`, { method: METHOD.PUT, body: JSON.stringify(data) })
}

export const sendToModeration = async (data) => {
  return await request(`company/profile/`, {
    method: METHOD.PUT,
    body: JSON.stringify({
      ...data,
      on_moderation: true,
    }),
  })
}

export const putCompanyUser = async (data, id) => {
  return await request(`company/users/${id}/`, { method: METHOD.PUT, body: JSON.stringify(data) })
}

export const putCompanyFile = async (file, type, picture_id) => {
  return await upload(`company/uploads/${picture_id}/`, { method: METHOD.PUT, file, type })
}

export const putCompanyVessel = async (data, id) => {
  return await request(`company/vessels/${id}/`, { api: API_BASE_V2, method: METHOD.PUT, body: JSON.stringify(data) })
}

export const putCompanyVacancy = async (data, id) => {
  return await request(`company/vacancies/${id}/`, { method: METHOD.PUT, body: JSON.stringify(data) })
}

// export const putUkrainianLocationInfo = async (id, data) => {
//   return await authPutRequest(API_BASE + `seafarer/out-of-residence/?id=${id}`, JSON.stringify(data))
// }
