import React, { useEffect } from 'react'
import { setProfileTitle } from '../redux/actions/ui.action'
import { useDispatch } from 'react-redux'
import Charts from '../components/Statistic/Charts/Charts'
import LoginHistoryTable from '../components/Statistic/LoginHistoryTable/LoginHistoryTable'

const StatisticPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setProfileTitle('Statistic'))

    // eslint-disable-next-line
  }, [])

  return (
    <div className="container">
      <main className="company-statistic">
        <Charts />
        <LoginHistoryTable />
      </main>
    </div>
  )
}

export default StatisticPage
