import React from 'react'

import styles from './styles.module.scss'

import ProductItem from './ProductItem/ProductItem'
import Masonry from 'react-responsive-masonry'

const Products = ({ products, ...rest }) => {
  const count = (() => {
    const length = rest.markedUnpaidIds.length

    if (length) return `Selected ${length} services`

    return 'Selected services'
  })()

  return (
    <div className={styles.wrapper}>
      <p className={styles.selectedServices}>{count}</p>

      <Masonry columnsCount={2} gutter="12px" className={styles.masonry} style={{ placeContent: 'stretch flex-start' }}>
        {products.map((product) => (
          <ProductItem key={product.id} data={product} {...rest} />
        ))}
      </Masonry>
    </div>
  )
}

export default Products
