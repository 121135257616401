import React from 'react'
import styles from './styles.module.scss'
import warningSvg from '../../../assets/icons/warning.svg'

const QualityStatus = ({ picture }) => {
  return (
    !picture.quality_status && (
      <div className={styles.qualityStatus}>
        <div className={styles.qualityStatusIconContainer}>
          <img className={styles.qualityStatusIcon} src={warningSvg} alt="warning" />
        </div>
        <div className={styles.qualityStatusToolTip}>{picture.quality_message}</div>
      </div>
    )
  )
}

export default QualityStatus
