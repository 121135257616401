import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ModalAlert from '../components/ModalAlert/ModalAlert'
import WithPreloader from '../HOC/WithPreloader'
import { notify } from '../helpers/notify'
import { confirmUnsubscribeAnonymous } from '../api/postApi'

const UnsubscribeAnonymous = () => {
  const [isUnsubscribeSuccess, setIsUnsubscribeSuccess] = useState(null)
  const [error, setError] = useState('Something went wrong. Try again.')

  const getUnsubscribeQueries = () => {
    const searchUrl = new URL(window.location.href)

    const decodedSearch = searchUrl.search.replace(/&amp;/g, '&')

    const decodedUrl = new URL('http://localhost:3003/unsubscribe/' + decodedSearch)

    const secret = decodeURIComponent(decodedUrl.searchParams.get('secret'))
    const email = decodeURIComponent(decodedUrl.searchParams.get('email'))

    return {
      secret,
      email,
    }
  }

  useEffect(() => {
    confirmUnsubscribeAnonymous(getUnsubscribeQueries())
      .then((data) => {
        setIsUnsubscribeSuccess(data.status)
      })
      .catch(({ errors }) => {
        setError(errors[0].message[0])
        setIsUnsubscribeSuccess(false)
        notify.error(errors[0].message[0])
      })
  }, [])

  return (
    <div className="page" style={{ height: '100%' }}>
      <main>
        <WithPreloader
          component={ModalAlert}
          isActive={isUnsubscribeSuccess === null}
          size={120}
          type="magnifier"
          isOk={isUnsubscribeSuccess}
          title={isUnsubscribeSuccess ? 'Success!' : 'Oops..'}
          content={isUnsubscribeSuccess ? 'Your email successfully unsubscribed.' : error}
        />
      </main>
    </div>
  )
}

export default UnsubscribeAnonymous
