import React from 'react'
import { useSelector } from 'react-redux'
import Chat from '../components/Chat/Chat'
import Spinner from '../UI/Spinner/Spinner'

const MessagesPage = () => {
  const isSocketConnected = useSelector((state) => state.ui.isSocketConnected)
  const loading = useSelector((state) => state.chat.dialogs.loading)

  if (loading) {
    return <Spinner size={100} />
  }

  return <div className="container chat-page-wrapper">{isSocketConnected && !loading && <Chat />}</div>
}

export default MessagesPage
