import React from 'react'
import Spinner from '../UI/Spinner/Spinner'

export default function WithPreloader({
  component: Component,
  preloader: Preloader,
  isActive,
  size = 30,
  type,
  ...props
}) {
  const classes = {
    wrapper: {
      position: 'relative',
      width: '100%',
      height: 'calc(100vh - 300px)',
    },
  }

  return isActive ? (
    <div style={classes.wrapper}>{Preloader ? <Preloader {...props} /> : <Spinner type={type} size={size} />}</div>
  ) : (
    <Component {...props} />
  )
}
