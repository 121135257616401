import React from 'react'
import ReactTooltip from 'react-tooltip'
import { debounce } from 'lodash'
import { ReactComponent as MainContactIcon } from '../../../assets/icons/mainContact.svg'
import { ReactComponent as GeneralMailIcon } from '../../../assets/icons/generalMail.svg'
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/whatsapp.svg'
import { ReactComponent as ViberIcon } from '../../../assets/icons/viber.svg'
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/outOfUkraine.svg'
import styles from './userCardSmartTooltip.module.scss'

export const messengersIcons = [
  { name: 'WhatsApp', icon: WhatsAppIcon },
  { name: 'Viber', icon: ViberIcon },
  { name: 'Telegram', icon: TelegramIcon },
]

const UKRAINE_NATIONALITY = 'Ukraine'

const UserCardSmartTooltip = ({
  type = 'default',
  title,
  value,
  id = '',
  isMain,
  isGeneral = false,
  messengers = [],
  containerClass = '',
  titleClass = '',
  valueClass = '',
  tooltipClass = '',
  ...rest
}) => {
  const [visible, setVisible] = React.useState(false)
  const [titleWidth, setTitleWidth] = React.useState(0)
  const [titleScrollWidth, setTitleScrollWidth] = React.useState(0)
  const [titleHeight, setTitleHeight] = React.useState(0)
  const [titleScrollHeight, setTitleScrollHeight] = React.useState(0)
  const [windowSize, setWindowSize] = React.useState(window.innerWidth)
  const [maxWidthFieldLicense, setMaxWidthFieldLicense] = React.useState(null)
  const titleRef = React.useRef(null)
  const labelRef = React.useRef(null)
  const elemRef = React.useRef(null)
  const handleWindowResize = debounce(() => {
    setWindowSize(window.innerWidth)
  }, 250)

  React.useEffect(() => {
    if (titleRef) {
      setTitleWidth(titleRef.current.offsetWidth)
      setTitleScrollWidth(titleRef.current.scrollWidth)
      setTitleHeight(titleRef.current.offsetHeight)
      setTitleScrollHeight(titleRef.current.scrollHeight)
    }
  }, [windowSize])

  React.useEffect(() => {
    if (titleScrollWidth >= titleWidth || titleScrollHeight >= titleHeight) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [titleWidth, titleScrollWidth, titleHeight, titleScrollHeight])

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    switch (title) {
      case 'Issued':
        setMaxWidthFieldLicense('300px')
        break
      case 'Number':
        setMaxWidthFieldLicense('300px')
        break
      case 'Address':
        setMaxWidthFieldLicense('400px')
        break
      default:
        setMaxWidthFieldLicense(
          `calc(${elemRef.current?.offsetWidth}px - ${labelRef.current?.offsetWidth ?? 0}px - 8px)`
        )
    }
  }, [title])

  switch (type) {
    case 'link':
      return (
        <div className={containerClass}>
          <div ref={labelRef} className={titleClass}>
            {title}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              maxWidth: `calc(100% - ${labelRef.current?.offsetWidth ?? 0}px - 16px)`,
            }}
          >
            <a ref={titleRef} data-tip={true} data-for={id} href={value} className={valueClass}>
              {value}
            </a>

            {visible && (
              <ReactTooltip id={id} backgroundColor="#06251e" className={tooltipClass}>
                {value}
              </ReactTooltip>
            )}
            <span style={{ paddingLeft: 7, display: 'flex', alignItems: 'center' }}>
              {isMain && <MainContactIcon />}
            </span>
          </div>
        </div>
      )

    case 'simple':
      return (
        <>
          <span ref={titleRef} data-tip={true} data-for={id} {...rest} className={titleClass}>
            {title}
          </span>

          {visible && (
            <ReactTooltip id={id} backgroundColor="#06251e" className={tooltipClass}>
              {title}
            </ReactTooltip>
          )}
        </>
      )

    default:
      return (
        <div ref={elemRef} className={containerClass}>
          <b ref={labelRef} className={titleClass}>
            {title && `${title}:`}
          </b>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              maxWidth: `${maxWidthFieldLicense}`,
            }}
          >
            <span ref={titleRef} data-tip={true} data-for={id} className={valueClass}>
              {value}
            </span>

            <span style={{ paddingLeft: 7, display: 'flex', alignItems: 'center' }}>
              {isMain && <MainContactIcon />}
              {isGeneral && <GeneralMailIcon />}
            </span>

            {value === UKRAINE_NATIONALITY && title === 'Citizenship' && (
              <span data-tip={true} data-for={id} className={styles.location}>
                <LocationIcon />
              </span>
            )}

            <div style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
              {messengersIcons.map(
                ({ name, icon: Icon }) =>
                  messengers?.includes(name.toLowerCase()) && (
                    <span style={{ paddingRight: 5 }} key={name}>
                      <Icon key={name} />
                    </span>
                  )
              )}
            </div>

            {title !== 'Period' && visible && (
              <ReactTooltip id={id} backgroundColor="#06251e" className={tooltipClass}>
                {value}
              </ReactTooltip>
            )}

            {value === UKRAINE_NATIONALITY && title === 'Citizenship' && (
              <ReactTooltip id={id} className={styles.locationTooltip}>
                <span>This seafarer live now in:</span>
                <span>{!rest.location ? <span className={styles.emptyLocation}>—</span> : rest.location}</span>
              </ReactTooltip>
            )}
          </div>
        </div>
      )
  }
}

export default UserCardSmartTooltip
