import * as Yup from 'yup'
import { maxDate } from '../../../helpers/time-helper/time-helper'
import { containsEmailAddress, containsLink, containsPhoneNumber } from '../../../helpers/helpers'

export const validationSchemaTypeSea = Yup.object().shape({
  rank: Yup.object().shape({
    id: Yup.number().required('Field is required').nullable(),
  }),

  vessel: Yup.object().shape({
    id: Yup.number().nullable(),
  }),

  vessel_type: Yup.string().when('vessel', (field) => {
    if (!field?.id) {
      return Yup.object().shape({
        id: Yup.number().required('Field is required').nullable(),
      })
    } else return Yup.object()
  }),

  join_date: Yup.date()
    .min(new Date(maxDate(new Date())), 'Date cannot be past')
    .typeError('Field is required'),

  join_date_range: Yup.string().when('is_exact_date', (value) => {
    if (!value)
      return Yup.object().shape({
        id: Yup.string().required('Field is required').nullable(),
      })
  }),

  duration: Yup.number().positive('Must be positive!').integer('Must be integer').required('Field is required'),

  duration_period: Yup.object().shape({
    id: Yup.string().required('Field is required').nullable(),
  }),

  duration_range: Yup.number().min(0, 'Сannot be negative').max(12, 'Max 12 month').nullable(),

  salary_currency: Yup.object().shape({
    id: Yup.string().required('Field is required').nullable(),
  }),

  salary: Yup.number().positive('Must be positive!').required('Field is required'),

  salary_rate: Yup.object().shape({
    id: Yup.string().required('Field is required').nullable(),
  }),

  salary_description: Yup.string()
    .max(70, 'Too long!')
    .test('Check string', 'Adding links, phone or email addresses is prohibited!', (value) => {
      if (containsLink(value)) return false

      if (containsPhoneNumber(value)) return false

      return !containsEmailAddress(value)
    }),

  published_at: Yup.date()
    .min(new Date(maxDate(new Date())), 'Date cannot be past')
    .max(Yup.ref('join_date'), 'Date cannot be greater join date!')
    .typeError('Field is required'),

  title: Yup.string(),
  // .max(255, 'Too long!'),

  description: Yup.string()
    .max(133, 'Too long!')
    .test('Check string', 'Adding links, phone or email addresses is prohibited!', (value) => {
      if (containsLink(value)) return false

      if (containsPhoneNumber(value)) return false

      return !containsEmailAddress(value)
    }),

  shipowner: Yup.object().shape({
    id: Yup.mixed().test('Name check', 'Select a shipowner name from the list please', function (value) {
      const { name } = this.parent

      return name ? Boolean(value) : true
    }),
  }),

  is_exact_date: Yup.boolean(),
})
export const validationSchemaTypeLand = Yup.object().shape({
  rank: Yup.object().shape({
    id: Yup.number().required('Field is required').nullable(),
  }),

  country: Yup.object().shape({
    id: Yup.number().required('Field is required').nullable(),
  }),

  city: Yup.object().shape({
    id: Yup.mixed().test('Name check', 'Select city from list', function (value) {
      const { name } = this.parent
      if (name) {
        return !!value
      } else return true
    }),
  }),

  join_date: Yup.date()
    .min(new Date(maxDate(new Date())), 'Date cannot be past')
    .typeError('Field is required'),

  join_date_range: Yup.string().when('is_exact_date', (value) => {
    if (!value)
      return Yup.object().shape({
        id: Yup.string().required('Field is required').nullable(),
      })
  }),

  duration: Yup.number().positive('Must be positive!').integer('Must be integer').required('Field is required'),

  duration_period: Yup.object().shape({
    id: Yup.string().required('Field is required').nullable(),
  }),

  duration_range: Yup.number().min(0, 'Сannot be negative').max(12, 'Max 12 month').nullable(),

  salary_currency: Yup.object().shape({
    id: Yup.string().required('Field is required').nullable(),
  }),

  salary: Yup.number().positive('Must be positive!').required('Field is required'),

  salary_rate: Yup.object().shape({
    id: Yup.string().required('Field is required').nullable(),
  }),

  salary_description: Yup.string()
    .max(70, 'Too long!')
    .test('Check string', 'Adding links, phone or email addresses is prohibited!', (value) => {
      if (containsLink(value)) return false

      if (containsPhoneNumber(value)) return false

      return !containsEmailAddress(value)
    }),

  published_at: Yup.date()
    .min(new Date(maxDate(new Date())), 'Date cannot be past')
    .max(Yup.ref('join_date'), 'Date cannot be greater join date!')
    .typeError('Field is required'),

  title: Yup.string(),
  // .max(255, 'Too long!'),

  description: Yup.string()
    .max(133, 'Too long!')
    .test('Check string', 'Adding links, phone or email addresses is prohibited!', (value) => {
      if (containsLink(value)) return false

      if (containsPhoneNumber(value)) return false

      return !containsEmailAddress(value)
    }),

  is_exact_date: Yup.boolean(),
})
