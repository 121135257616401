import React, { useState } from "react";
import styles from "./Dropdown.module.scss";
import useClickOutside from "../../hooks/useClickOutside";
import { CSSTransition } from "react-transition-group";
import "./show-down.scss";

const Dropdown = ({ children, overlay, clickEverywhere = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useClickOutside(() => {
    setIsOpen(false);
  }, clickEverywhere);

  function handleClick() {
    setIsOpen(!isOpen);
  }
  return (
    <div className={styles.dropdown} ref={ref}>
      <div className={styles.content} onClick={handleClick}>
        {children}
      </div>
      <CSSTransition
        unmountOnExit
        in={isOpen}
        timeout={200}
        classNames="show-down"
      >
        <div className={styles.overlay}>{overlay}</div>
      </CSSTransition>
    </div>
  );
};

export default Dropdown;
