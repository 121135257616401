import store from '../redux/store'

export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorageItem = (key) => {
  const value = JSON.parse(localStorage.getItem(key))

  if (!value) return null

  return value
}

export const containsLink = (text) => {
  const urlPattern = /https?:\/\/\S+|www\.\S+/i

  return urlPattern.test(text)
}

export const containsPhoneNumber = (text) => {
  const phonePattern = /(\+\d{1,3}[-.\s]?)?\d{1,4}[-.\s]?\d{1,3}[-.\s]?\d{2,4}[-.\s]?\d{2,4}/

  return phonePattern.test(text)
}

export const containsEmailAddress = (text) => {
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/

  return emailPattern.test(text)
}

export const isPathAvailable = (permission) => {
  const { permissions: userConfig } = store.getState().company

  const availablePath = userConfig.map((item) => item.name)

  return availablePath.includes(permission)
}

export const isServiceAvailable = (permission) => {
  const { billing_permissions: billingConfig } = store.getState().company

  if (!billingConfig.status) return true

  return billingConfig.permissions.includes(permission)
}

export const removeLastSlashAndValue = (pathname) => {
  const lastSlashIndex = pathname.lastIndexOf('/')

  if (lastSlashIndex !== -1) {
    return pathname.slice(0, lastSlashIndex)
  }

  return pathname
}

export const removeLastSlash = (pathname) => {
  if (pathname[pathname.length - 1] === '/') {
    return pathname.slice(0, pathname.length - 1)
  }

  return pathname
}

export const downloadByLink = async (url, filename) => {
  const link = document.createElement('a')
  link.href = url
  link.download = filename

  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
}

export const isEveryFieldFilled = (values, skipKeyId = false) => {
  const areAllFieldsFilled = (fieldValue) => {
    if (typeof fieldValue === 'object' && fieldValue !== null) {
      return Object.values(fieldValue).every((nestedValue) => areAllFieldsFilled(nestedValue))
    }

    const isCompleteFieldId = skipKeyId ? true : fieldValue !== null

    return fieldValue !== '' && isCompleteFieldId
  }

  return Object.values(values).every((value) => areAllFieldsFilled(value))
}

export const isValidFile = (file, extensions) => {
  const extension = `.${file.name.match(/\.([a-z\d]+)$/i)[1]}`.toLowerCase()
  return extensions.findIndex((item) => item === extension)
}

export const isDevEnvironment = () => window.location.href.includes('dev') || window.location.href.includes('localhost')

export const isDefaultVesselImg = (url = '') => {
  const emptyVesselFileName = 'vessel_default.webp'
  const fileName = url.split('/').pop()

  return fileName === emptyVesselFileName
}
