export const userCreateDataPrettier = (arr) => {
  if (arr.length === 0 || !arr) return [];
  return arr.map((item) => {
    return {
      id: item.id,
      user_type: item.user_type.name,
      full_name: `${item.first_name || ""} ${item.last_name || ""}`,
      position: item.position ? item.position.name : "",
      picture: item.picture.file_mini,
      email: item.email,
      is_active: item.is_active,
    };
  });
};
