import { authPostFormData, request, upload } from './auth.service'
import { API_BASE, API_BASE_V2, METHOD } from './constans'

export function createFormDataForFiles(file, type, id, is_main) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)

  return formData
}

export const registerCompanyRequest = async (data) => {
  return await request('company-register/', {
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}
export const postCompanyAdditionalFiles = async (file) => {
  return await authPostFormData('company/uploads/', createFormDataForFiles(file, 'documents'))
}
export const postCompanyAdditionalContact = async (data) => {
  return await request('company/profile_contact/', {
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const signupVerify = async (data) => {
  return await request('activation/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const confirmVerify = async (data) => {
  return await request('change-email-confirm/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const passwordRecoverRequest = async (data, action_type = 'change_password') => {
  return await request(`reset-password/?action_type=${action_type}`, {
    api: API_BASE_V2,
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const changePasswordConfirmRequest = async (data) => {
  return await request('reset-password-confirm/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const postFeedback = async (data) => {
  return await request('anonymous/feedback/', {
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const postEmailSubscribe = async (data) => {
  return await request('anonymous/subscribe/', {
    method: METHOD.POST,
    withoutToken: true,
    body: JSON.stringify(data),
  })
}

export const changePasswordRequest = async (data) => {
  return await request('change-password/', { api: API_BASE_V2, method: METHOD.POST, body: JSON.stringify(data) })
}

export const changeEmailRequest = async (data) => {
  return await request('change-email/', { api: API_BASE_V2, method: METHOD.POST, body: JSON.stringify(data) })
}

export const commentRequest = async (comment, user_id) => {
  return await request(`company/seafarer_comments/${user_id}/`, {
    api: API_BASE,
    method: METHOD.POST,
    body: JSON.stringify(comment),
  })
}

export const deleteAccount = async (data) => {
  return await request('delete-account/', { api: API_BASE_V2, method: METHOD.POST, body: JSON.stringify(data) })
}

export const postProfileLicense = async (data) => {
  return await request(`seafarer/licenses/`, { method: METHOD.POST, body: JSON.stringify(data) })
}

export const createCompanyUser = async (data) => {
  return await request(`company/users/`, { method: METHOD.POST, body: JSON.stringify(data) })
}

export const uploadUserPicture = async (data) => {
  const file = new File([data], `${data.name}`)

  return await upload(`company/photo-status/`, { file })
}

export const postCompanyFile = async (data, type) => {
  const format = data.name.split('.')[data.name.split('.').length - 1]
  const file = new File([data], `License.${format}`, {
    type: data.type,
    lastModified: data.lastModified,
  })

  return await upload(`company/uploads/`, {
    file,
    type,
  })
}

export const postCompanyVessel = async (data) => {
  return await request(`company/vessels/`, { api: API_BASE_V2, method: METHOD.POST, body: JSON.stringify(data) })
}

export const userResetPassword = async (user_id) => {
  return await request(`company/users-reset-password/`, {
    api: API_BASE,
    method: METHOD.POST,
    body: JSON.stringify({ user_id }),
  })
}

export const userRestore = async (user_id) => {
  return await request(`company/users-restore/`, { method: METHOD.POST, body: JSON.stringify({ user_id }) })
}

export const postCompanyVacancy = async (data) => {
  return await request(`company/vacancies/`, { method: METHOD.POST, body: JSON.stringify(data) })
}

export const postVacancyTitle = async (data) => {
  return await request(`company/vacancy_preview_title/`, { method: METHOD.POST, body: JSON.stringify(data) })
}

export const setSeafarerFavorite = async (id) => {
  return await request(`company/favorite/`, {
    method: METHOD.POST,
    body: JSON.stringify({
      seafarer: id,
    }),
  })
}

export const createChatRoom = async (user_id) => {
  return await request(`company/start-chat/`, { method: METHOD.POST, body: JSON.stringify({ user_id }) })
}

export const setViewedVacancy = async (id) => {
  return await request(`company/view_of_vacancy_requests/`, {
    method: METHOD.POST,
    body: JSON.stringify({ vacancy_request: id }),
  })
}

//========== Billing start ===========================

export const setBillingCard = async (pm) => {
  return await request('billing/card/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({
      pm,
    }),
  })
}

export const setBillingSubscription = async (data) => {
  return await request('billing/subscription/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}

export const detachBillingCard = async (id) => {
  return await request('billing/card-detach/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({ id }),
  })
}

export const setBillingSubscriptionPlans = async (products) => {
  return await request('billing/subscription-plan/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({
      products,
    }),
  })
}

export const unsubscribeBillingProduct = async (data) => {
  return await request(`billing/unsubscribe/`, {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}

export const setEmailSubscription = async (id) => {
  return await request('billing/email-subscription/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({
      product_id: id,
    }),
  })
}

//========== Billing end ===========================

// FIREBASE

export const postFCMToken = async (token) => {
  return await request('services/mobile-devices/', {
    method: METHOD.POST,
    body: JSON.stringify({
      name: null,
      registration_id: token,
      device_id: null,
      active: true,
      type: 'web',
    }),
  })
}

// FIREBASE

export const postNotificationSettings = async (data) => {
  return await request('company/notifications/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}

export const confirmUnsubscribe = async (data) => {
  return await request('company/company-notification-unsubscribe/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}
export const confirmUnsubscribeAnonymous = async (data) => {
  return await request('anonymous/unsubscribe/', {
    api: API_BASE,
    method: METHOD.POST,
    body: JSON.stringify(data),
  })
}
