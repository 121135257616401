import React from 'react'
import Image from '../../../UI/Image/Image'
import UserCardSmartTooltip from '../UserCardSmartTooltip/UserCardSmartTooltip'
import CardHeaderLoader from './CardHeaderLoader/CardHeaderLoader'
import CardHeaderAvatarPreloader from './CarHeaderAvatarPreloader/CardHeaderAvatarPreloader'
import styles from './CardHeader.module.scss'
import { uniqueId } from 'lodash'
import PersonalStatus from '../../PersonalStatus/PersonalStatus'

import { ReactComponent as VipIcon } from '../../../assets/icons/vip.svg'

const CardHeader = ({ generalUserCardInfo, loading, controls }) => {
  if (loading) return <CardHeaderLoader />

  const { personal_status, enhanced_visibility } = generalUserCardInfo

  return (
    <>
      <div className={styles.header}>
        <div className={styles.avatarContainer}>
          {/*{generalUserCardInfo?.personal_status.has_right && <HotMsg className={styles.hotMsg} alt="vip" />}*/}
          <Image
            preloader={<CardHeaderAvatarPreloader />}
            className={styles.avatar}
            src={generalUserCardInfo?.avatar?.file}
            alt={generalUserCardInfo?.avatar?.file_name}
          />

          {enhanced_visibility && <VipIcon className={styles.vipIcon} />}
        </div>

        <div className={styles.info}>
          {generalUserCardInfo?.mainInfo?.map((userCardHeaderItem, index) => (
            <UserCardSmartTooltip
              key={index}
              title={userCardHeaderItem.title}
              value={userCardHeaderItem.value}
              id={uniqueId('card-header-tooltip-')}
              containerClass={styles.infoItem}
              titleClass={styles.infoTitle}
              valueClass={styles.infoValue}
              tooltipClass={styles.toolTip}
              location={generalUserCardInfo.locationNow}
            />
          ))}
        </div>

        <div className={styles.controls}>{controls}</div>
      </div>
      {personal_status.has_right && personal_status.status.length !== 0 ? (
        <PersonalStatus status={personal_status.status} id={generalUserCardInfo.avatar.id} className={styles.status} />
      ) : (
        <div className={styles.bottom}></div>
      )}
    </>
  )
}

export default CardHeader
