import { API_BASE, API_BASE_V2, CLIENT_ID, CLIENT_SECRET, initHeaders, METHOD } from './constans'
import { setToken } from '../redux/actions/user.action'
import { userLogout } from '../redux/actions/auth'
import { getUserTimeZone } from '../helpers/time-helper/time-helper'

const getToken = () => {
  return JSON.parse(localStorage.getItem('user'))
}

export const customHeaders = {
  'X-User-Time-Zone': getUserTimeZone(),
  'X-User-Agent': 'web',
}

export const getRefreshToken = async (refreshToken) => {
  const response = await fetch(`${API_BASE_V2}token/`, {
    method: METHOD.POST,
    headers: initHeaders,
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scope: 'company',
    }),
  })

  return await response.json()
}

// check function is valid token
export const isExpired = (exp) => {
  if (!exp) {
    return false
  }

  return Date.now() > exp
}

export async function authPostFormData(url, data = {}) {
  const token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    const response = await fetch(API_BASE + url, {
      method: 'POST',
      headers: {
        ...customHeaders,
        Authorization: `Bearer ${token.access_token}`,
      },
      body: data,
    })
    const result = await response.json()

    result.ok = response.ok
    return result
  }
}

export const request = async (endpoint, options) => {
  const headers = options?.headers ?? initHeaders

  const token = getToken()
  if (token && !options?.withoutToken) {
    if (isExpired(token.expires_date)) {
      const response = await getRefreshToken(token.refresh_token)
      if (response.ok) {
        setToken(response)

        return await request(endpoint, options)
      } else {
        userLogout()
        window.location.reload()
        return
      }
    } else {
      headers.set('Authorization', `Bearer ${token.access_token}`)
    }
  }

  const init = {
    body: options?.body,
    method: options?.method || METHOD.GET,
    headers,
  }
  const domain = options?.api ? options.api : API_BASE

  try {
    const response = await fetch(`${domain}${endpoint}`, init)
    if (response.status === 401) {
      userLogout()
      window.location.reload()

      return
    }

    const data = await response.json()

    if (!response.ok) {
      return Promise.reject(data)
    }

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const upload = async (url, options) => {
  const formData = new FormData()

  formData.append('file', options?.file)

  if (options?.type) formData.append('type', options.type)

  return request(url, {
    body: formData,
    headers: new Headers(),
    method: options?.method || METHOD.POST,
  })
}

export const download = async (endpoint, options) => {
  const headers = options?.headers ?? initHeaders

  const token = getToken()

  if (token) {
    if (isExpired(token.expires_date)) {
      const response = await getRefreshToken(token.refresh_token)
      if (response.ok) {
        setToken(response)

        return await request(endpoint, options)
      } else {
        userLogout()
        window.location.reload()

        return
      }
    } else {
      headers.set('Authorization', `Bearer ${token.access_token}`)
    }
  }

  const init = {
    body: options?.body,
    method: options?.method || METHOD.GET,
    headers,
  }

  const domain = options?.api ? options.api : API_BASE

  try {
    const response = await fetch(`${domain}${endpoint}`, init)

    if (response.status === 401) {
      userLogout()

      return
    }

    const data = await response.blob()

    if (!response.ok) {
      return Promise.reject(data)
    }

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
