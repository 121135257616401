import offSvg from '../../../assets/icons/off.svg'
import loopSvg from '../../../assets/icons/loop.svg'
import returnSvg from '../../../assets/icons/return.svg'
import dbSvg from '../../../assets/icons/db.svg'
import mailSvg from '../../../assets/icons/mail.svg'
import bellSvg from '../../../assets/icons/bell-white.svg'

export const reasons = [
  {
    className: 'off',
    icon: offSvg,
    title: 'Flexible job postings',
    description: 'Start and stop at any time',
    id: 0,
  },
  {
    className: 'return',
    icon: returnSvg,
    title: 'Multiple job postings',
    description: 'Easily edit and adapt for \n a new opening',
    id: 2,
  },
  {
    className: 'mail',
    icon: mailSvg,
    title: 'Built-in text chat and email',
    description: 'You will be able to contact candidates directly',
    id: 4,
  },

  {
    className: 'loop',
    icon: loopSvg,
    title: 'Unlimited job applicants',
    description: 'No limit on the number of candidates to apply for vacancy',
    id: 1,
  },

  {
    className: 'data_base',
    icon: dbSvg,
    title: 'Access to NEXTSHIP database',
    description: 'You can find and view candidates in one click',
    id: 3,
  },

  {
    className: 'bell',
    icon: bellSvg,
    title: 'Email alerts',
    description: 'When new resumes are submitted that match your criteria',
    id: 5,
  },
]
