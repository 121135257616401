import PropTypes from 'prop-types'
import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import SelectItem from './SelectItem'
import Input from '../Input/Input'
import useClickOutside from '../../hooks/useClickOutside'
import useInput from '../../hooks/useInput'
import useDebounce from '../../hooks/useDebounce'
import { getCompleteVesselInfo } from '../../helpers/autocomplete.helper'

const Autocomplete = ({
  name,
  value,
  label,
  placeholder,
  getTitle,
  items,
  onChange,
  onClick,
  handleScroll,
  onFocus,
  className,
  isInvalid,
  required,
  errorMessage,
  disabled,
  container,
}) => {
  const componentRef = useRef(null)
  const selectEl = useClickOutside(() => setIsOpen(false))

  const [isOpen, setIsOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [activeIndex, setActiveIndex] = useState(-1)
  const [distanceFromBottom, setDistanceFromBottom] = useState(null)
  const [distanceFromRight, setDistanceFromRight] = useState(null)

  const { value: inputValue, onChange: handleChange, setValue } = useInput(value)

  const debounceValue = useDebounce(inputValue)

  useEffect(() => {
    const containerRect = container?.current?.getBoundingClientRect()
    const absoluteComponentRect = selectEl?.current?.getBoundingClientRect()
    setDistanceFromBottom(containerRect?.bottom - absoluteComponentRect?.bottom)
    setDistanceFromRight(containerRect?.right - absoluteComponentRect?.right)
  }, [selectEl, container, isOpen, items, value])

  useEffect(() => {
    if (inputValue !== value) {
      onChange(name, { target: { value: inputValue } })
    }

    // eslint-disable-next-line
  }, [debounceValue])

  useEffect(() => {
    setValue(value)

    // eslint-disable-next-line
  }, [value])

  const handleItemClick = (id, value) => {
    setIsOpen(false)
    setSelectedId(id)

    onClick(name, { id, name: value })
  }

  const handleKeyDown = (e) => {
    if (!isOpen) {
      return
    }

    if (e.key === 'ArrowUp' && activeIndex > 0) {
      e.preventDefault()

      setActiveIndex(activeIndex - 1)

      return
    }

    if (e.key === 'ArrowDown' && activeIndex < items.length - 1) {
      e.preventDefault()

      setActiveIndex(activeIndex + 1)

      return
    }

    if (e.key === 'Enter' && activeIndex !== -1) {
      const id = items[activeIndex].id
      const name = items[activeIndex].name

      handleItemClick(id, name)
    }
  }

  return (
    <>
      {label && (
        <label
          className={cn(styles.label, {
            [styles.label_invalid]: isInvalid,
            [styles.label_disabled]: disabled,
          })}
        >
          {label} {required && <span className="required-star">*</span>}
        </label>
      )}

      <div
        ref={selectEl}
        className={cn(styles.wrapper, {
          [styles.wrapper_invalid]: isInvalid,
        })}
        onFocus={() => setIsOpen(true)}
        onKeyDown={handleKeyDown}
      >
        <Input
          autoComplete="off"
          role="presentation"
          placeholder={placeholder}
          name={name}
          value={inputValue ?? ''}
          onChange={handleChange}
          onFocus={(e) => onFocus(name, e)}
          className={className}
          disabled={disabled}
        />

        {isInvalid && errorMessage && <div className={styles.selectErrorMessage}>{errorMessage}</div>}

        {items.length && isOpen ? (
          <ul
            ref={componentRef}
            className={cn(styles.list, {
              [styles.list_top]: distanceFromBottom < 250,
              [styles.list_right]: distanceFromRight < 250,
            })}
            onScroll={handleScroll}
          >
            {items.map((item, index) => {
              const { [getTitle]: name, description, place_id: id, id: alt_id } = item
              const isActive = index === activeIndex || Number(selectedId) === id || Number(selectedId) === alt_id

              return (
                <SelectItem
                  tabIndex="0"
                  key={id || alt_id}
                  name={name || description}
                  id={id || alt_id}
                  displayName={getCompleteVesselInfo(item, getTitle)}
                  isActive={isActive}
                  onClick={handleItemClick}
                />
              )
            })}
          </ul>
        ) : null}
      </div>
    </>
  )
}

Autocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getTitle: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  handleScroll: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
}

Autocomplete.defaultProps = {
  value: '',
  items: [],
  getTitle: 'description',
  isInvalid: false,
  errorMessage: '',
  className: '',
}

export default Autocomplete
