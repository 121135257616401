import React from 'react'

import styles from './styles.module.scss'

import { useHistory } from 'react-router-dom'

import { ReactComponent as UnpaidAccessIcon } from './icons/unpaidSearchAccess.svg'

import Button from '../../../UI/Button/Button'

import { profilePaths } from '../../../constants/constants'

const UnpaidSearchAccess = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push(profilePaths.billing_payment)
  }

  return (
    <div className={styles.stubContainer}>
      <div className={styles.leftColumn}>
        <h1>DISCOVER THE IDEAL CREW FOR YOUR BUSINESS!</h1>

        <p>
          Why wait for seafarers to apply when you can proactively select from a wide range of available candidates?
          <br />
          <br />
          Take advantage of our <span>Resume Search Access</span> service to gain access to a database featuring over
          60,000 active sailor profiles.
        </p>

        <Button onClick={handleClick} children="Go to billing" className={styles.button} />
      </div>

      <UnpaidAccessIcon className={styles.searchAccess} />
    </div>
  )
}

export default UnpaidSearchAccess
