import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Tutorial from "../components/Tutorial/Tutorial";

const PromoPage = () => {
  return (
    <div>
      <div className="page">
        <Header />
        <main>
          <Tutorial />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default PromoPage;
