import React from 'react'
import UserCardSmartTooltip from '../../UserCardSmartTooltip/UserCardSmartTooltip'
import styles from '../../userCard.module.scss'
import { uniqueId } from 'lodash'

const SizeInformationItem = ({ title, items }) => {
  return (
    <div className={styles.contentItem}>
      <div className={styles.header}>
        <UserCardSmartTooltip
          type={'simple'}
          title={title}
          titleClass={styles.contentItemTitle}
          id={uniqueId('size-information-item-')}
        />
      </div>
      <div className={styles.body}>
        {items?.map((item, index) => (
          <UserCardSmartTooltip
            key={index}
            title={item.title}
            value={item.value}
            id={uniqueId('size-information-item-')}
            containerClass={styles.item}
            titleClass={styles.itemTitle}
            valueClass={styles.itemValue}
          />
        ))}
      </div>
    </div>
  )
}

export default SizeInformationItem
