export const setModerUI = (payload) => ({
  type: "MOD_UI_IS_VISIBLE",
  payload,
});

export const setProfileTitle = (string) => ({
  type: "SET_PROFILE_TITLE",
  payload: string,
});

export function setIsSocketConnectedAction(status) {
  return {
    type: "SET_IS_SOCKET_CONNECTED",
    payload: { status },
  };
}
