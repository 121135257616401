import { useEffect, useRef } from 'react'

const CONFIRM = {
  YES: 'yes',
  CLOSE: 'close',
}

const ACTION = {
  CLOSE: 'close',
}

export default function useClickOutside(onOutsideClick) {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      document.addEventListener('click', handleClickOutside, false)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  })

  const handleClickOutside = ({ target }) => {
    const confirm = target.getAttribute('data-confirm')
    const actionClose = target.getAttribute('data-action-close')

    if (actionClose === ACTION.CLOSE) return

    if (confirm === CONFIRM.YES || confirm === CONFIRM.CLOSE) return

    if (ref.current === null || !ref.current.contains(target)) {
      onOutsideClick()
    }
  }

  return ref
}
