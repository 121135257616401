import React from 'react'

import styles from './tabs.module.scss'
import cn from 'classnames'

import { getTabs } from './config'
import Button from '../../../../UI/Button/Button'

const Tabs = ({ activeTab, onClick, className = '' }) => {
  const { tabs } = getTabs()

  return (
    <div className={cn(styles.wrapper, className)}>
      {tabs.map((tab) => (
        <Button
          key={tab.name}
          className={cn(styles.tab, { [styles.activeTab]: activeTab === tab.name })}
          size="small"
          theme="transparent"
          onClick={() => onClick(tab.name, tab.dates, tab.group)}
          disabled={activeTab === tab.name}
        >
          {tab.name}
        </Button>
      ))}
    </div>
  )
}

export default Tabs
