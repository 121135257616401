import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.module.scss'
import { ReactComponent as UkraineFlagIcon } from '../../assets/icons/ukraineFlag.svg'

const Switcher = ({ isActive, disabled, onClick, id, theme }) => {
  const handleClick = () => onClick(id, isActive)
  return (
    <div
      onClick={handleClick}
      className={cn(styles.switcher, {
        [styles.active]: !isActive,
        [styles[theme]]: theme,
        [styles.disabled]: disabled,
      })}
    >
      {theme === 'ukraine' ? (
        <div className={styles.flag}>
          <UkraineFlagIcon />
        </div>
      ) : (
        <div className={styles.flag} />
      )}
    </div>
  )
}

Switcher.propTypes = {
  isActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(null)]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(null)]),
  onClick: PropTypes.func.isRequired,
}

Switcher.defaultProps = {
  isActive: false,
  disabled: false,
}

export default Switcher
