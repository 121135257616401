import React, { useEffect, useRef, useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import Image from '../../../UI/Image/Image'

import { ReactComponent as VideoIcon } from './icons/video.svg'
import { ReactComponent as XIcon } from './icons/x.svg'

import PropTypes from 'prop-types'

const Avatar = ({ src, videoResume }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPlay, setIsPlay] = useState(false)

  const videoRef = useRef(null)

  useEffect(() => {
    const video = videoRef.current

    if (!video) return

    if (video) video.play()

    video.addEventListener('play', onPlayListen)
    video.addEventListener('pause', onPauseListen)

    return () => {
      video.removeEventListener('play', onPlayListen)
      video.removeEventListener('pause', onPauseListen)
    }
  }, [isOpen])

  const onPlayListen = () => {
    setIsPlay(true)
  }

  const onPauseListen = () => {
    setIsPlay(false)
  }

  const handlePlayClick = (e) => {
    e.stopPropagation()

    const video = videoRef.current

    if (!isPlay && video) video.play()
    if (isPlay && video) video.pause()

    setIsPlay(!isPlay)
    setIsOpen(true)
  }

  const handleCloseClick = (e) => {
    e.stopPropagation()

    setIsPlay(false)
    setIsOpen(false)
  }

  const isVideoResumeExist = videoResume.length !== 0
  const videoMp4Src = isVideoResumeExist ? videoResume[0].file : ''
  const videoWebmSrc = isVideoResumeExist ? videoResume[1]?.file : ''

  return (
    <div
      onClick={isVideoResumeExist ? handlePlayClick : undefined}
      className={cn(
        styles.avatar,
        { [styles.avatar_play]: isVideoResumeExist && !isPlay },
        { [styles.avatar_pause]: isPlay },
        { [styles.avatar_play__active]: isOpen && !isPlay }
      )}
    >
      {isVideoResumeExist && <VideoIcon className={styles.videoIcon} />}

      <Image className={styles.avatar_picture} src={src} alt="seafarer avatar" />

      {isOpen && (
        <div className={styles.videoWrapper}>
          <XIcon onClick={handleCloseClick} className={styles.xIcon} />

          <video ref={videoRef} controls>
            <source src={videoMp4Src} type="video/mp4" />
            <source src={videoWebmSrc} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </div>
  )
}

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  videoResume: PropTypes.array.isRequired,
}

Avatar.defaultProps = {
  videoResume: [],
}

export default Avatar
