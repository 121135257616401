import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
export default function Tooltip({ symbol, children, theme = 'green' }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const tooltipEl = React.useRef()

  React.useEffect(() => {
    if (tooltipEl.current) {
      document.addEventListener('click', handleClickOutside, false)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = ({ target }) => {
    if (!tooltipEl.current.contains(target)) {
      setIsOpen(false)
    }
  }

  const toggleOpen = () => {
    setIsOpen(true)
  }
  const toggleClose = () => {
    setIsOpen(false)
  }

  return (
    <div ref={tooltipEl} className={cn(styles.tooltip, { [styles.tooltip_open]: isOpen })}>
      <span className={styles.symbol} onMouseEnter={toggleOpen} onMouseLeave={toggleClose}>
        {symbol}
      </span>
      <span className={cn(styles.content, styles[theme])}>{children}</span>
    </div>
  )
}

Tooltip.propTypes = {
  symbol: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

Tooltip.defaultProps = {
  symbol: '?',
  children: 'Example text',
}
