import React from 'react'
import cn from 'classnames'
import UserCardSmartTooltip from '../../UserCardSmartTooltip/UserCardSmartTooltip'
import FileList from '../../FileList/FileList'
import styles from '../../userCard.module.scss'
import { ReactComponent as CalcOfFiles } from '../../../../assets/icons/calcOfFiles.svg'
import { ReactComponent as CloseSvg } from '../../../../assets/icons/closeIcon.svg'
import { uniqueId } from 'lodash'

const CertificateItem = ({ title, files, items }) => {
  const [showFileList, setShowFileList] = React.useState(false)

  return (
    <div className={styles.contentItem}>
      <div className={cn(styles.header, { [styles.passport]: true })}>
        <UserCardSmartTooltip
          type={'simple'}
          title={title}
          titleClass={styles.contentItemTitle}
          id={uniqueId('certificate-item-')}
        />

        {!showFileList && (
          <div
            className={styles.calcOfFilesContainer}
            onClick={() => {
              files.length && setShowFileList(true)
            }}
          >
            <label className={styles.calcOfFilesContainerLabel}>Photo</label>
            <div className={styles.calcOfFiles}>
              <CalcOfFiles />
              <label className={styles.calcOfFilesLabel}>{files.length}</label>
            </div>
          </div>
        )}

        {showFileList && (
          <button
            className={styles.closeBtn}
            onClick={() => {
              files.length && setShowFileList(false)
            }}
          >
            Close
            <CloseSvg />
          </button>
        )}
      </div>

      <div className={cn(styles.body, { [styles.passport]: true })}>
        {items?.map((item, index) => (
          <UserCardSmartTooltip
            key={index}
            title={item.title}
            value={item.value}
            id={uniqueId('certificate-item-')}
            containerClass={styles.item}
            titleClass={styles.itemTitle}
            valueClass={styles.itemValue}
          />
        ))}

        {files.length ? <FileList files={files} showFiles={showFileList} /> : ''}
      </div>
    </div>
  )
}

export default CertificateItem
