import React, { useState } from 'react'

import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'

import 'animate.css/animate.compat.css'

import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
// import Prices from '../components/sections/SectionEmployersCases/Prices'
import Intro from '../components/sections/SectionEmployersPlan/Intro'
import WhyUs from '../components/sections/WhyUs/WhyUs'
import Features from '../components/sections/Features/Features'
import MobileAppForCompany from '../components/sections/MobileAppForCompany/MobileAppForCompany'
import ForSeafarers from '../components/sections/ForSeafarers/ForSeafarers'
import PopupLoginMobile from '../components/PopupLoginMobile/PopupLoginMobile'
import Support from '../components/Support/Support'
import OurServices from '../components/sections/OurServices/OurServices'
import SupportFab from '../UI/SupportFab/SupportFab'

import useClickOutside from '../hooks/useClickOutside'
import useIndicateBrowserTab from '../hooks/useIndicateBrowserTap'
import { isMobileDevice } from '../helpers/mobileDevice.helper'

const Main = () => {
  const [isSupportOpen, setIsSupportOpen] = useState(false)
  const [isOpenMobilePopup, setIsOpenMobilePopup] = useState(false)

  const isSocketConnected = useSelector((state) => state.ui.isSocketConnected)
  const username = useSelector(({ user }) => user.profile.first_name)

  const supportRef = useClickOutside(() => setIsSupportOpen(false))

  useIndicateBrowserTab(username)

  const handlePopupMobile = (e) => {
    if (isMobileDevice()) {
      e && e.preventDefault()

      setIsOpenMobilePopup(!isOpenMobilePopup)
    }
  }

  return (
    <div className="page page-line-bg-rotated">
      {isOpenMobilePopup && <PopupLoginMobile onClose={handlePopupMobile} />}

      <div style={{ background: 'var(--color-white)' }}>
        <Header onMobilePopup={handlePopupMobile} />
      </div>

      <main>
        <Intro onMobilePopup={handlePopupMobile} />
        <WhyUs />
        {/*<Prices onPopup={onPopup} />*/}
        <OurServices onMobilePopup={handlePopupMobile} />
        <Features />
        <MobileAppForCompany />
        <ForSeafarers />
      </main>

      <Footer />

      {/* Support section */}
      <div ref={supportRef}>
        <CSSTransition classNames="fadein-bottom" appear mountOnEnter unmountOnExit in={isSupportOpen} timeout={300}>
          <Support className="support-main-chat" />
        </CSSTransition>
      </div>

      <SupportFab
        disabled={!isSocketConnected}
        className="support-main-fab"
        isOpen={isSupportOpen}
        handleClick={() => setIsSupportOpen(!isSupportOpen)}
      />
    </div>
  )
}

export default Main
