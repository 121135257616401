import { convertDateToSubmit, getLastDayOfMonth, getParsedDate } from './time-helper/time-helper'

import { priorityType, vacancyPriorityMap } from '../constants/constants'
import { rangeMap, vacancyTypes } from '../components/Company/VacancyManagerForm/constants'

export const vacancySubmitHelper = (
  {
    duration_period,
    join_date,
    published_at,
    rank,
    salary_currency,
    salary_rate,
    vessel,
    vessel_type,
    join_date_range,
    shipowner,
    vacancy_priority = null,
    duration_range,
    country,

    ...data
  },

  type
) => {
  const seaResult = {
    ...data,

    duration_period: duration_period.name,
    join_date: convertDateToSubmit(join_date),
    published_at: convertDateToSubmit(published_at),
    rank: rank.id,
    salary_currency: salary_currency.id,
    salary_rate: salary_rate.name,
    vessel: vessel?.id ?? null,
    vessel_type: vessel_type?.id ?? null,
    join_date_range: join_date_range.id,
    shipowner: shipowner.id,
    urgent: vacancy_priority === priorityType.urgent,
    duration_range: duration_range || 0,
  }

  if (type === vacancyTypes.land) {
    const { vessel, vessel_type, ...landResult } = seaResult

    return { ...landResult, country: country?.id }
  }

  return seaResult
}

export const vacancyDataHelper = (data, count, rest) => {
  return {
    ...rest,
    count,

    list: data.map(
      ({
        join_date: joinDate,
        published_at: publishedAt,

        vessel,
        shipowner,
        title,
        duration,
        duration_period,
        city_place_id,
        city,
        rank,
        salary,
        salary_currency,
        salary_rate,
        salary_description,
        updated_at,
        join_date_range,
        description,
        ...vacancy
      }) => {
        const join_date = (() => {
          const parsedDate = getParsedDate(joinDate)
          const newDate = parsedDate.setHours(0, 0, 1)

          return new Date(newDate)
        })()

        // publication date must be earlier join date
        const published_at = (() => {
          const parsedDate = getParsedDate(publishedAt)
          const newDate = parsedDate.setHours(0, 0, 0)

          return new Date(newDate)
        })()
        // publication date must be earlier join date

        const vacancy_priority = (() => {
          if (vacancy.urgent) return vacancyPriorityMap.urgent.value

          return vacancyPriorityMap.usual.value
        })()

        return {
          ...vacancy,

          join_date,
          published_at,
          vacancy_priority,

          is_exact_date: !join_date_range,
          title: title ?? '',
          duration: duration ?? '',
          salary_description: salary_description ?? '',
          salary: salary ?? '',
          rank: rank ?? { id: null, name: '' },
          description: description ?? '',
          duration_period: { id: duration_period, name: duration_period },
          vessel_type: vessel.vessel_type ?? {
            id: null,
            name: 'Without Type',
          },
          city: { id: city_place_id, name: city },
          salary_currency: !salary_currency
            ? { id: null, name: '' }
            : {
                ...salary_currency,

                name: salary_currency.short_name ?? salary_currency.name,
              },
          salary_rate: { id: salary_rate, name: salary_rate },
          vessel: vessel.id
            ? vessel
            : {
                ...vessel,

                id: null,
                name: '',
              },
          updated_at: updatedDatePrettier(updated_at),
          join_date_range: {
            id: join_date_range,
            name: join_date_range,
          },
          shipowner: !shipowner ? { id: null, name: '' } : shipowner,
        }
      }
    ),
  }
}

export const vacancyDataHelperUpdate = (
  {
    join_date: joinDate,
    published_at: publishedAt,

    vessel,
    shipowner,
    salary_rate,
    join_date_range,
    duration_period,
    description,
    updated_at,
    city_place_id,
    city,

    ...vacancy
  },
  type
) => {
  const join_date = (() => {
    const parsedDate = getParsedDate(joinDate)
    const newDate = parsedDate.setHours(0, 0, 1)

    return new Date(newDate)
  })()

  // publication date must be earlier join date
  const published_at = (() => {
    const parsedDate = getParsedDate(publishedAt)
    const newDate = parsedDate.setHours(0, 0, 0)

    return new Date(newDate)
  })()
  // publication date must be earlier join date

  const vacancy_priority = (() => {
    if (vacancy.urgent) return vacancyPriorityMap.urgent.value

    return vacancyPriorityMap.usual.value
  })()

  const seaResult = {
    ...vacancy,

    join_date,
    published_at,
    vacancy_priority,

    salary_rate: { id: salary_rate, name: salary_rate },
    join_date_range: {
      id: join_date_range,
      name: join_date_range,
    },
    description: description ?? '',
    is_exact_date: !join_date_range,
    duration_period: { id: duration_period, name: duration_period },
    shipowner: !shipowner ? { id: null, name: '' } : shipowner,
    vessel: !vessel ? { id: null, name: '' } : vessel,
    updated_at: updatedDatePrettier(updated_at),
  }

  if (type === vacancyTypes.land) {
    const { vessel, vessel_type, ...landResult } = seaResult

    return {
      ...landResult,

      city: { id: city_place_id, name: city },
    }
  }

  return seaResult
}

const updatedDatePrettier = (str) => {
  const [date, time] = str.split(' ')

  return {
    day: date,
    time: time.slice(0, 5),
  }
}

export const getDateRangeMap = (date) => {
  return {
    beg: 10,
    mid: 20,
    end: date ? getLastDayOfMonth(date) : 30,
  }
}

export const helperEditDataVacancy = (
  { salary_currency, rank, join_date_range, vessel, salary, duration_range, country, ...vacancy },
  type
) => {
  const { id: currencyId, short_name: currencyShortName, name: currencyName } = salary_currency
  const { id: rankId, name: rankName } = rank
  const { id: rangeId } = join_date_range
  const { id: vesselId = null, name: vesselName = '' } = vessel ?? {}

  const newVesselName = (() => {
    if (vesselId) return `${vesselName} / ${vacancy.vessel_type.name}`

    return ''
  })()

  const vacancy_priority = (() => {
    if (vacancy.urgent) return vacancyPriorityMap.urgent.value

    return vacancyPriorityMap.usual.value
  })()

  const seaResult = {
    ...vacancy,

    vacancy_priority,

    salary: String(salary),
    salary_currency: { id: currencyId, name: currencyShortName ?? currencyName },
    rank: { id: rankId, name: rankName },
    vessel: { id: vesselId, name: newVesselName },
    join_date_range: { id: rangeId, name: rangeMap[rangeId] ?? '' },
    duration_range: duration_range || null,
  }

  if (type === vacancyTypes.land) {
    const { vessel, vessel_type, ...landResult } = seaResult

    const { id: countryId, name: countryName } = country

    return {
      ...landResult,

      country: { id: countryId, name: countryName },
    }
  }

  return seaResult
}

export const titleHelper = ({ rank, vessel, vessel_type, vacancy_type, country, city }) => {
  return {
    vacancy_type,

    rank: rank?.id,
    vessel: vessel?.id ?? null,
    vessel_type: vessel_type?.id ?? null,
    city: city ?? null,
    country: country?.id ?? null,
  }
}
