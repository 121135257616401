import React, { useEffect, useState } from 'react'

import styles from './charts.module.scss'

import { categories } from './config'
import { notify } from '../../../helpers/notify'
import { getPopular } from '../../../api/getApi'
import { getChart, getDates, getDefaultParams } from './utils'

const Charts = () => {
  const [chartsData, setChartsData] = useState({
    [categories.vacancies]: [],
    [categories.countries]: [],
    [categories.engine_types]: [],
    [categories.vessel_types]: [],
    [categories.published_vacancies]: [],
  })

  useEffect(() => {
    const getChartsData = async () => {
      try {
        const promises = Object.keys(categories).map((category) => getPopular(category, getDefaultParams()))
        const [vacancies, countries, engine_types, vessel_types, published_vacancies] = await Promise.all(promises)

        setChartsData({ vacancies, countries, engine_types, vessel_types, published_vacancies })
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    getChartsData()
  }, [])

  const getChartData = async (category, searchParams) => {
    const { dates, group } = searchParams

    const params = { ...getDates(dates), group }

    try {
      const data = await getPopular(category, params)

      setChartsData((prev) => ({ ...prev, [category]: data }))
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  return (
    <section className={styles.wrapper}>
      {Object.keys(categories).map((category) => {
        const Chart = getChart(category)

        const props = {
          data: chartsData[category],
          handleChart: getChartData,
          category,
        }

        return <Chart key={category} {...props} />
      })}
    </section>
  )
}

export default Charts
