import React from 'react'

import styles from './chatEmpty.module.scss'

import { ReactComponent as EmptyChatIcon } from './icons/chat_empty_icon.svg'

const ChatEmpty = () => {
  return (
    <div className={styles.block}>
      <div className={styles.content}>
        <EmptyChatIcon className={styles.icon} />

        <div className={styles.textContent}>
          <h3 className={styles.textContentTitle}>You dont have messages yet</h3>
        </div>
      </div>
    </div>
  )
}

export default ChatEmpty
