import { API_BASE_V2, METHOD } from './constans'
import { request } from './auth.service'

export const removeModStatusNotification = async () => {
  return await request(`company/ui/`, {
    method: METHOD.PATCH,
    body: JSON.stringify({ show_is_active: false }),
  })
}

export const setIsCompanyUserEnable = async (id, status) => {
  return await request(`company/users/${id}/`, {
    method: METHOD.PATCH,
    body: JSON.stringify({ is_active: status }),
  })
}

export const pathRestoreDeletedVessel = async (id) => {
  return await request(`company/vessels/${id}/?deleted=true`, {
    api: API_BASE_V2,
    method: METHOD.PATCH,
    body: JSON.stringify({ restore: true }),
  })
}

export const pathRestoreDeletedVacancy = async (id) => {
  return await request(`company/vacancies/${id}/?deleted=true`, {
    method: METHOD.PATCH,
    body: JSON.stringify({ restore: true }),
  })
}

export const setIsVacancyEnable = async (id, status) => {
  return await request(`company/vacancies/${id}/`, {
    method: METHOD.PATCH,
    body: JSON.stringify({ is_active: status }),
  })
}

export const setSettings = async (data) => {
  return await request(`company/settings/`, { method: METHOD.PATCH, body: JSON.stringify(data) })
}

export const setNotificationActive = async (status, id) => {
  return await request(`company/notifications/${id}/`, {
    method: METHOD.PATCH,
    body: JSON.stringify({
      is_active: status,
    }),
  })
}
