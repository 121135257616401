import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { profilePaths } from '../constants/constants'

const PrivateRoute = ({ path, exact = false, component: Component, redirect = profilePaths.auth }) => {
  const isAuth = useSelector((state) => state.authentication.isAuth)

  return isAuth ? <Route path={path} exact={exact} children={Component} /> : <Redirect to={redirect} />
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
  exact: PropTypes.bool,
}

export default PrivateRoute
