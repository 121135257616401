import React, { useState } from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'

import { SignInForm } from '../components/Form/SignInForm'
import { SignUpForm } from '../components/Form/SignUpForm'
import Header from '../components/Header/Header'
import Support from '../components/Support/Support'
import SupportFab from '../UI/SupportFab/SupportFab'

import useClickOutside from '../hooks/useClickOutside'
import { isMobileDevice } from '../helpers/mobileDevice.helper'
import { isDevEnvironment } from '../helpers/helpers'
import { profilePaths } from '../constants/constants'

export const AuthPage = () => {
  const [isSupportOpen, setIsSupportOpen] = useState(false)

  const { isSocketConnected } = useSelector((state) => state.ui)

  const supportRef = useClickOutside(() => setIsSupportOpen(false))

  if (isMobileDevice() && !isDevEnvironment()) return <Redirect to="/" />

  return (
    <div className="page employers-auth-page">
      <Header />

      <main className="auth-section">
        <div className="container auth-section-container">
          <div className="employers-auth-section-wrapper">
            <Switch>
              <Route exact path={profilePaths.auth} component={SignInForm} />
              <Route exact path="/auth/signup" component={SignUpForm} />
            </Switch>
          </div>
        </div>
      </main>
      {/* Support section */}
      <div ref={supportRef}>
        <CSSTransition classNames="fadein-bottom" appear mountOnEnter unmountOnExit in={isSupportOpen} timeout={300}>
          <Support className="support-main-chat" />
        </CSSTransition>
      </div>

      <SupportFab
        disabled={!isSocketConnected}
        className="support-main-fab"
        isOpen={isSupportOpen}
        handleClick={() => setIsSupportOpen(!isSupportOpen)}
      />
    </div>
  )
}
