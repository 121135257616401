import React, { memo } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import CrossIcon from '../../../UI/Icons/CrossIcon'
import { ArchiveIcon, RestoreIcon, TrashIcon } from '../../../UI/Icons/Icons'
import PropTypes from 'prop-types'
import arrowDownSvg from '../../../assets/icons/arrow-down.svg'
import editSvg from '../../../assets/icons/edit.svg'
import Switcher from '../../../UI/Switcher/Switcher'
import ReactTooltip from 'react-tooltip'
import AnimateHeight from 'react-animate-height'
import {
  formatForTimeTag,
  formatWithoutDay,
  getDateByFormat,
  setMaxRangeDate,
} from '../../../helpers/time-helper/time-helper'
import useUserDateFormat from '../../../hooks/useUserDateFormat'
import VacancyPriorityLabel from '../../../UI/VacancyPriorityLabel/VacancyPriorityLabel'
import useCanEdit from '../../../hooks/useCanEdit'
import UseSmartTooltip from '../../../hooks/useSmartTooltip'
import { ReactComponent as Flag } from '../../../assets/icons/flag.svg'
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg'
import { isDefaultVesselImg } from '../../../helpers/helpers'
import Image from '../../../UI/Image/Image'

const VacancyManagerListItem = memo(
  ({
    data,
    isOpen,
    onOpenClick,
    onDeleteClick,
    onRestoreClick,
    onHardDeleteClick,
    onSwitcherClick,
    onEditClick,
    isOnEdit = false,
    isArchived,
  }) => {
    const userFormat = useUserDateFormat()
    const isCanEdit = useCanEdit()

    const joinDate = (() => {
      if (data.join_date_range.name) return formatWithoutDay(data.join_date, userFormat)

      return getDateByFormat(data.join_date, userFormat)
    })()
    const newDate = (() => {
      if (data.join_date_range.id) {
        const newValue = setMaxRangeDate(data.join_date, data.join_date_range.id).setHours(23, 59, 59)

        return new Date(newValue)
      }

      const newValue = new Date(data.join_date).setHours(23, 59, 59)

      return new Date(newValue)
    })()

    const isOverdueVacancy = new Date().getTime() > newDate.getTime()
    const isJoinDateExpired = newDate.getTime() < new Date().getTime()

    return (
      <div
        className={cn(styles.vacancy, {
          [styles.vacancy_open]: isOpen,
          [styles.vacancy_inactive]: !isCanEdit,
          [styles.vacancy_active]: isOnEdit,
        })}
      >
        <div className={styles.topInfo}>
          {data.views > 0 && (
            <div className={styles.views}>
              <Flag />
              <Eye className={styles.views_eye} />
              <div className={styles.views_number}>{data.views}</div>
            </div>
          )}

          <time
            className={cn(styles.join_date, { [styles.inactiveDate]: isOverdueVacancy })}
            dateTime={formatForTimeTag(joinDate)}
          >
            {joinDate}
          </time>
        </div>

        <div className={styles.modifyDate}>
          <time style={{ paddingRight: 10 }} dateTime={formatForTimeTag(data.updated_at.day)}>
            {data.updated_at.day}
          </time>
          <time dateTime={data.updated_at.time}>{data.updated_at.time}</time>
        </div>

        {data.vacancy_type === 'sea' && (
          <div className={cn(styles.vesselName, { [styles.noName]: !data?.vessel?.name })}>
            <b>Vessel name:</b>

            <span
              data-tip={true}
              data-for={`tooltip-${String(data.id)}`}
              className={cn({ [styles.name]: data?.vessel?.name })}
            >
              {data?.vessel?.name || 'No name'}
            </span>

            {data.urgent && <VacancyPriorityLabel label="Urgent" urgent />}

            <ReactTooltip id={`tooltip-${String(data.id)}`} backgroundColor="transparent" className={styles.tooltip}>
              <Image
                src={data.vessel.mt_photo_mini_url}
                alt="vassel"
                className={cn({ [styles.vesselImg]: isDefaultVesselImg(data.vessel.mt_photo_mini_url) })}
              />
            </ReactTooltip>
          </div>
        )}

        <div className={styles.landUrgent}>
          <UseSmartTooltip
            tagType={'div'}
            className={styles.vacancyName}
            id={data?.rank_name ? `${data?.rank_name.split(' ').join('')}` : ''}
            children={
              <>
                <b>Rank: </b>
                {data.rank?.name || data?.rank_name}
              </>
            }
            amount_of_line={1}
          />

          {data.vacancy_type === 'land' && data.urgent && <VacancyPriorityLabel label="Urgent" urgent />}
        </div>

        {/*<div className={styles.vacancyName}>*/}
        {/*  <b>Rank: </b>*/}
        {/*  {data.rank?.name || data?.rank_name}*/}
        {/*</div>*/}
        <div className={styles.vacancyHeader}>
          {data.vacancy_type === 'sea' && (
            <div className={styles.vacancyName}>
              <b>Vessel type: </b> {data.vessel_type?.name}
            </div>
          )}
          {data.vacancy_type === 'land' && (
            <div className={styles.vacancyTypeLandHeader}>
              <div className={styles.vacancyName}>
                <b>Country: </b> {data.country?.name}
              </div>
              <div className={cn(styles.vacancyName, { [styles.noName]: !data.city?.name })}>
                <b>City: </b>
                <span>{data.city?.name || 'No info'}</span>
              </div>
            </div>
          )}
          <div className={styles.controls}>
            {isArchived ? (
              <button data-tip="restore" data-for="default" onClick={() => onRestoreClick(data.id)}>
                <RestoreIcon width={19} height={16} strokeWidth={1} strokeColor="#43927C" />
              </button>
            ) : (
              <>
                <div className={styles.switcherWrapper}>
                  <div
                    className={cn(styles.switcherLabel, {
                      [styles.switcherLabel_disabled]: isOverdueVacancy || isJoinDateExpired,
                    })}
                  >
                    {data.is_active ? 'Enable' : 'Disable'}
                  </div>
                  <Switcher
                    id={data.id}
                    isActive={data.is_active}
                    onClick={onSwitcherClick}
                    disabled={isOverdueVacancy || isJoinDateExpired}
                  />
                </div>
                <button data-tip="delete" data-for="default" onClick={() => onHardDeleteClick(data.id)}>
                  <TrashIcon width={15.42} height={18.74} strokeWidth={1} strokeColor="#C20000" />
                </button>

                <button onClick={() => onDeleteClick(data.id)} data-tip="archive" data-for="default">
                  <ArchiveIcon width={20} height={20} />
                </button>

                <button
                  data-tip={isOnEdit ? 'cancel' : 'edit'}
                  data-for="default"
                  onClick={() => onEditClick(data.id, data.vacancy_type, isOnEdit)}
                >
                  {isOnEdit ? (
                    <CrossIcon width={14} height={14} strokeWidth={1} stroke="#43927C" />
                  ) : (
                    <>
                      <img src={editSvg} alt="edit" />
                    </>
                  )}
                </button>
              </>
            )}
          </div>
        </div>

        <AnimateHeight height={isOpen ? 'auto' : 0}>
          <div className={styles.more}>
            <div className={styles.jobTitle}>
              <b>Job title:</b>
            </div>
            <div className={styles.moreTitle}>{data.title || '–'}</div>

            <div className={styles.description}>
              <b>Description:</b> {data?.description || '–'}
            </div>

            <div className={cn(styles.shipownerName, { [styles.shipownerInactive]: !data.show_shipowner })}>
              <b>Shipowner:</b> {data.vessel?.owner?.name || data.shipowner.name || '–'}
            </div>

            <div className={styles.moreWrapper}>
              <div className={styles.moreRowAlone}>
                <span>
                  <b>Duration: </b> {data.duration} {data.duration_period.name || '–'}
                  {Boolean(data.duration_range) && ` (+/- ${data.duration_range} m)`}
                </span>
                <div>
                  <b>Salary: </b> {data.salary_currency.symbol || '–'}
                  {data.salary}/{data.salary_rate.name || '–'}
                </div>
                <time dateTime={formatForTimeTag(joinDate)}>
                  <b>Join date: </b> {joinDate || '–'}
                </time>
              </div>
            </div>

            {/*{(data.salary_description || data.description) && (*/}
            {/*  <div className={styles[data.salary_description ? 'moreRow' : 'moreRowAlone']}>*/}
            {/*    {data.salary_description && (*/}
            {/*      <div className={styles.moreDescription}>*/}
            {/*        <div className={styles.moreDescriptionTitle}>*/}
            {/*          <b>Salary description:</b>*/}
            {/*        </div>*/}
            {/*        <div className={styles.moreDescriptionContent}>{data.salary_description}</div>*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*    {data.description && (*/}
            {/*      <div className={styles.moreDescription}>*/}
            {/*        <div className={styles.moreDescriptionTitle}>*/}
            {/*          <b>Description:</b>*/}
            {/*        </div>*/}
            {/*        <div className={styles.moreDescriptionContent}>{data.description}</div>*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*)}*/}

            <div className={styles.salaryDescription}>
              <b>Salary description:</b>
            </div>
            <div>{data.salary_description || '–'}</div>
          </div>
        </AnimateHeight>

        <ReactTooltip backgroundColor="#43927C" id="default" padding="10px" />
        <button className={styles.footerButton} onClick={() => onOpenClick(data.id)}>
          <img src={arrowDownSvg} alt="Show more arrow" />
        </button>
      </div>
    )
  }
)

export default VacancyManagerListItem

VacancyManagerListItem.propTypes = {
  data: PropTypes.object.isRequired,
}
