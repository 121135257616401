import React from 'react'
import { useSwiper } from 'swiper/react'
import styles from './SwiperNavButtons.module.scss'
import cn from 'classnames'
import Button from '../../UI/Button/Button'
import { ReactComponent as ArrowPrev } from '../../assets/icons/arrow-left-nav.svg'
import { ReactComponent as ArrowNext } from '../../assets/icons/arrow-right-nav.svg'

export const SwiperNavButtons = ({ className }) => {
  const swiper = useSwiper()
  return (
    <div className={cn(styles.swiper_nav_buttons, className)}>
      <Button className={styles.prev} onClick={() => swiper.slidePrev()}>
        <ArrowPrev />
      </Button>
      <Button className={styles.next} onClick={() => swiper.slideNext()}>
        <ArrowNext />
      </Button>
    </div>
  )
}
