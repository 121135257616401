export const companyInformationHelper = ({ company }) => {
  if (Boolean(company)) {
    return {
      name: company.name,
      phone: company.phone,
      terms_accepted_at: company.terms_accepted_at,
      picture: company.picture,
      is_active: company.is_active,
      on_moderation: company.on_moderation,
      allow_moderation: !company['is_rejected'] || null,
    }
  }
}

export const additionalDataHelper = (document) => {
  const modifiedDocuments = document.documents.map((doc) => {
    const modifiedDoc = { ...doc }
    if (modifiedDoc.file_name) {
      modifiedDoc.name = modifiedDoc.file_name
      delete modifiedDoc.file_name
    }
    return modifiedDoc
  })

  return {
    ...document,
    documents: modifiedDocuments,
  }
}
