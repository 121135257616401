import React from 'react'
import styles from './rolePermissionsTable.module.scss'
import { action } from '../config'
import cn from 'classnames'

const Label = ({ label }) => {
  return (
    <>
      {label === action.can_edit && <span className={styles.label}>Can edit</span>}
      {label === action.can_view && <span className={cn(styles.label, styles.canView)}>Can view</span>}
      {label === action.no_rights && <span className={cn(styles.label, styles.noRights)}>No rights</span>}
    </>
  )
}

export default Label
