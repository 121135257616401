import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import resetSvg from '../../assets/icons/reset.svg'
import styles from './styles.module.scss'
import ReactTooltip from 'react-tooltip'
import useCanEdit from '../../hooks/useCanEdit'

const ItemsGroupUserDeleted = ({ data, onEdit, isActive, isAnim }) => {
  const isCanEdit = useCanEdit()

  return (
    <div
      className={cn(styles.itemWrapper, {
        [styles.active]: isActive,
        [styles.moveAnimation]: isAnim,
      })}
    >
      <div className={styles.item}>
        <div className={styles.itemTitleDeleted}>
          <span>{data.user_type}</span>
        </div>
        <div className={styles.itemContentRow}>
          <img src={data.picture} height={30} alt="user avatar" />
          <div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>Full name:</div> <b className={styles.row_content}>{data.full_name}</b>
            </div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>Position:</div> <b className={styles.row_content}>{data.position}</b>
            </div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>Email:</div> <b className={styles.row_content}>{data.email}</b>
            </div>
          </div>
        </div>
      </div>

      <ReactTooltip backgroundColor="#43927C" id="default" />
      <div className={cn(styles.controls, { [styles.controls_inactive]: !isCanEdit })}>
        <button
          data-tip="recover"
          data-for="default"
          style={{ margin: 0 }}
          className={styles.edit}
          onClick={() => onEdit(data.id)}
        >
          <img src={resetSvg} alt="recover" />
        </button>
      </div>
    </div>
  )
}

ItemsGroupUserDeleted.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  isActive: PropTypes.bool,
  isAnim: PropTypes.bool,
}

export default ItemsGroupUserDeleted
