import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthCompanyUsers, getAuthDeletedCompanyUsers } from '../../api/getApi'
import ItemsGroup from '../../components/ItemsGroup/ItemsGroup'
import { uploadUserPicture, createCompanyUser, userRestore } from '../../api/postApi'
import { userCreateSubmitHelper } from '../../helpers/submit.helper'
import { deleteCompanyUserById, deleteCompanyUserPictureById } from '../../api/deleteApi'
import { confirm } from '../../UI/Confirm/Confirm'
import ItemsGroupUser from '../../components/ItemsGroup/ItemsGroupUser'
import { userCreateDataPrettier } from '../../helpers/itemsGroupHelper'
import { putCompanyUser, putCompanyFile } from '../../api/putApi'
import { setIsCompanyUserEnable } from '../../api/patchApi'
import ItemsGroupUserDeleted from '../../components/ItemsGroup/ItemsGroupUserDeleted'
import { setProfileTitle } from '../../redux/actions/ui.action'
import { changeProfilePicture } from '../../redux/actions/user.action'
import { defaultUserPictureOptions } from '../../constants/default-values'
import UserAvatarEditor from '../../components/AvatarEditor/UserAvatarEditor/UserAvatarEditor'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useNotify } from '../../hooks/useNotify'
import Tabs from './Tabs/Tabs'
import { initialValues, validationSchema } from './Form/config'
import Form from './Form/Form'
import TabNav from '../../components/TabNav/TabNav'
import RolePermissionsTable from './RolePermissionsTable/RolePermissionsTable'
import { profilePaths } from '../../constants/constants'
import useActiveCompany from '../../hooks/useActiveCompany'

const links = [
  {
    url: profilePaths.users_create,
    title: 'User creation',
  },
  {
    url: profilePaths.role_permissions,
    title: 'Role permissions',
  },
]

const UsersCreatePage = () => {
  const user_id = useSelector((state) => state.user.profile.id)
  const { picture: logo } = useSelector(({ company }) => company)

  const dispatch = useDispatch()
  const { notify } = useNotify()

  const isActiveCompany = useActiveCompany()

  const [picture, setPicture] = useState({
    ...defaultUserPictureOptions,
  })
  const [currentId, setCurrentId] = useState(null)
  const [lastElemId, setLastElemId] = useState(null)
  const [allUsers, setAllUsers] = useState([])
  const [allDeletedUsers, setAllDeletedUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isDeletedTab, setIsDeletedTab] = useState(false)
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false)
  const [isPictureDelete, setIsPictureDelete] = useState(false)
  const [document, setDocument] = useState()

  const { file, file_mini } = logo

  const formik = useFormik({
    initialValues: currentId && document ? document : { ...initialValues },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      if (currentId) {
        editSome(userCreateSubmitHelper(values))
      } else {
        postNewContacts(userCreateSubmitHelper(values))
      }
    },
  })

  useEffect(() => {
    dispatch(setProfileTitle('Users Manager'))

    return () => {
      dispatch(setProfileTitle(''))
    }

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setPicture({ ...defaultUserPictureOptions, file, file_mini })

    //eslint-disable-next-line
  }, [isActiveCompany])

  const handleUnsetLastElemId = () => {
    setLastElemId(null)
  }

  const setIsUserEnabled = async (id, isActive) => {
    try {
      await setIsCompanyUserEnable(id, !isActive)
      await updateUsersList()
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const handleDelete = (id) => {
    confirm('Are you sure?', 'You want to delete this user?', async () => {
      try {
        await deleteCompanyUserById(id)

        if (currentId === id) {
          setCurrentId(null)
          formik.resetForm()
        }

        notify('Success', 'You are successfully deleted user!')
        await updateUsersList()
      } catch (error) {
        notify.errorsList(error.errors)
      }
    })
  }

  const handlePictureChange = async (file) => {
    setIsLoading(true)

    try {
      const response = await uploadUserPicture(file)
      const { quality_status, quality_message } = response

      setPicture({
        file: URL.createObjectURL(file),
        file_name: file.name,
        quality_status: quality_status,
        quality_message: quality_message,
        file_to_send: file,
      })

      formik.setValues({
        ...formik.values,
        avatar: {
          file: URL.createObjectURL(file),
          file_name: file.name,
          quality_status: quality_status,
          quality_message: quality_message,
          file_to_send: file,
        },
      })
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePictureDelete = () => {
    confirm('Are you sure ?', 'Are you want to delete user avatar?', () => {
      if (picture.id) {
        setIsPictureDelete(true)
      }

      setPicture({ ...defaultUserPictureOptions, id: picture.id, file, file_mini })

      formik.setValues({ ...formik.values, avatar: {} })

      notify('Success', 'You are successfully deleted user avatar!')
    })
  }

  const postNewContacts = async (values) => {
    setIsLoading(true)

    try {
      const data = await createCompanyUser(values)

      if (picture.file_to_send) {
        await putCompanyFile(picture.file_to_send, data.picture.type, data.picture.id)
      }

      saveChanges()
      setLastElemId(data.id)
    } catch (error) {
      setIsLoading(false)
      notify.errorsList(error.errors)
    }
  }

  const editSome = async (data) => {
    setIsLoading(true)

    try {
      const response = await putCompanyUser(data, currentId)

      if (picture.file_to_send) {
        const file = await putCompanyFile(picture.file_to_send, response.picture.type, response.picture.id)

        // if user changed own picture, will change it in reducer
        if (user_id === currentId) {
          dispatch(changeProfilePicture(file))
        }
      }

      saveChanges()
    } catch (error) {
      setIsLoading(false)
      notify.errorsList(error.errors)
    }
  }

  const handleEdit = async (id, isActive) => {
    try {
      if (isActive) {
        setPicture({ ...defaultUserPictureOptions, file, file_mini })

        formik.resetForm()
        setCurrentId(null)

        return
      }

      const user = await getAuthCompanyUsers(id)

      setCurrentId(id)
      setDocument(user)
      formik.resetForm()

      if (user.picture.file === logo.file) {
        setPicture({ ...defaultUserPictureOptions, file, file_mini })
      } else {
        const { file_mini, type, type_id, ...rest } = user.picture

        setPicture({
          ...rest,
          file_to_send: null,
        })
      }
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const saveChanges = () => {
    setIsLoading(false)
    setCurrentId(null)
    updateUsersList()

    formik.resetForm()

    notify('Success', 'Your changes were successfully saved.')
  }

  const updateUsersList = async () => {
    try {
      if (isPictureDelete && picture.id) {
        await deleteCompanyUserPictureById(picture.id)
      }

      setPicture({ ...defaultUserPictureOptions, file, file_mini })

      const user = await getAuthCompanyUsers()
      setAllUsers(user.results)

      const dataDeletedUsers = await getAuthDeletedCompanyUsers()
      setAllDeletedUsers(dataDeletedUsers.results)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const restoreUser = async (id) => {
    try {
      await userRestore(id)

      await updateUsersList()

      notify('Success', 'User was successfully restored')
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  return (
    <>
      <TabNav links={links} />

      <div className="container">
        <Switch>
          <Route exact path={profilePaths.users_create}>
            <div className="profile-content space-between">
              <UserAvatarEditor
                picture={picture}
                id={currentId}
                onChange={handlePictureChange}
                onDelete={handlePictureDelete}
                setIsUploadingAvatar={setIsUploadingAvatar}
              />

              <Form
                formik={formik}
                updateUsersList={updateUsersList}
                isLoading={isLoading}
                isUploadingAvatar={isUploadingAvatar}
              />

              <ItemsGroup
                ChildComponent={isDeletedTab ? ItemsGroupUserDeleted : ItemsGroupUser}
                lastElemId={lastElemId}
                onDelete={handleDelete}
                onEdit={isDeletedTab ? restoreUser : handleEdit}
                setMain={setIsUserEnabled}
                currentId={currentId}
                title={
                  <Tabs unsetLastElemId={handleUnsetLastElemId} isDeleted={isDeletedTab} onClick={setIsDeletedTab} />
                }
                items={isDeletedTab ? userCreateDataPrettier(allDeletedUsers) : userCreateDataPrettier(allUsers)}
              />
            </div>
          </Route>

          <Route exact path={profilePaths.role_permissions}>
            <RolePermissionsTable />
          </Route>

          <Redirect to={profilePaths.users_create} />
        </Switch>
      </div>
    </>
  )
}

export default UsersCreatePage
