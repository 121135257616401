import React from 'react'

import { useDispatch } from 'react-redux'

import styles from './styles.module.scss'

import ChangePassword from '../../components/Settings/ChangePassword/ChangePassword'
import { setProfileTitle } from '../../redux/actions/ui.action'
import Notification from '../../components/Settings/Notifications/Notifications'
import Select from '../../UI/Select/Select'
import { getAuthProfileFilters, getSettings } from '../../api/getApi'
import { setSettings } from '../../api/patchApi'
import { updateSettings } from '../../redux/actions/user.action'
import format from 'date-fns/format'
import { useNotify } from '../../hooks/useNotify'

const SettingsPage = () => {
  const dispatch = useDispatch()
  const { notify } = useNotify()

  React.useEffect(() => {
    dispatch(setProfileTitle('Settings'))
  }, [dispatch])

  React.useEffect(() => {
    getAuthProfileFilters('date_formats')
      .then(({ date_formats }) => {
        setFilters({
          date_formats: date_formats.map(({ name }) => ({
            name,
            id: name,
          })),
        })
      })
      .catch((error) => notify.errorsList(error.errors))

    getSettings()
      .then(({ date_format }) => {
        setSelectValues({
          date_format: { name: date_format, id: date_format },
        })
      })
      .catch((error) => notify.errorsList(error.errors))
    //eslint-disable-next-line
  }, [])

  const [selectValues, setSelectValues] = React.useState({
    date_format: { name: '', id: null },
  })

  const [filters, setFilters] = React.useState({
    date_formats: [],
  })

  const handleSelectChange = (name, target) => {
    if (target.id !== selectValues[name].id) {
      setSettings({ [name]: target.id })
        .then(() => {
          dispatch(updateSettings())
          notify('Success', 'Your settings were updated')
          setSelectValues((state) => ({
            ...state,
            [name]: target,
          }))
        })
        .catch((error) => notify.errorsList(error.errors))
    }
  }

  const dateExample = format(
    new Date(),
    selectValues.date_format.name.replaceAll('Y', 'y').replaceAll('D', 'd') || 'yyyy:MM:dd'
  )

  return (
    <div className={styles.screen}>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.leftBlock}>
          <h3 className={styles.title}>Change password</h3>

          <div className={styles.changePassBlock}>
            <ChangePassword />
          </div>
        </div>

        <div className={styles.middleBlock}>
          <h3 className={styles.title}>Notifications (Email)</h3>

          <Notification />
        </div>

        <div className={styles.rightBlock}>
          <div className={styles.dateFormat} style={{ marginBottom: 13 }}>
            <div className={styles.dateFormatLabel}>Select date format</div>
          </div>

          <div className={styles.dateFormatSelect}>
            <Select
              id={selectValues.date_format.id}
              name="date_format"
              onClick={handleSelectChange}
              items={filters.date_formats}
              selectedItem={selectValues.date_format}
              defaultName=""
            />

            <div className={styles.dateFormatExample}>Example - {dateExample}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsPage
