import isArray from 'lodash/isArray'

export const sortOptionsPrettier = (obj) => {
  return Object.keys(obj)
    .filter((item) => obj[item] !== 0)
    .map((item) => {
      return obj[item] === 2 ? item : `-${item}`
    })
    .join(',')
}

export const getTokenFromUrl = () => {
  const searchUrl = new URL(window.location).searchParams
  return {
    uid: searchUrl.get('uid'),
    token: searchUrl.get('token'),
  }
}

export const fetchSeafarersHelper = (list) => {
  if (!list[0]) return []

  return list?.map(
    ({
      vacancy,
      request_sent_date,
      vacancies,
      nationality,
      picture,
      out_of_residence,
      availability_status,
      education,
      residence_address,
      ...item
    }) => ({
      ...item,
      vacancy: vacancy || null,
      request_date: request_sent_date || null,
      vacancies: vacancies || null,
      nationality: nationality?.name || null,
      picture: picture.file_mini || picture.file,
      availability_status: availability_status || { id: null, name: 'No info' },
    })
  )
}

export const fetchTemplatesHelper = (list) => {
  return list?.map(({ vessel, rank, vacancy_type, country, rank_name, ...rest }) => {
    const nameLandVacancy = `${country} (${rank_name})`
    const nameSeaVacancy = `${vessel ?? 'No name'} (${rank?.name ?? 'No rank'})`
    const name = vacancy_type === 'land' ? nameLandVacancy : nameSeaVacancy

    return {
      ...rest,

      name,
    }
  })
}

export function getAccessToken() {
  const token = JSON.parse(localStorage.getItem('user') || 'null')

  if (!token) return null

  return token?.access_token
}

export const objToQueryConverter = (obj) => {
  return Object.keys(obj)
    .map((key) => {
      const title = key
      const value = isArray(obj[key]) ? obj[key].join(',') : obj[key]
      if (title === 'search' && value) {
        return searchQueryConverter({ search: value, type: [title] })
      }
      return value ? title + '=' + value : 'empty'
    })
    .filter((el) => el !== 'empty')
    .join('&')
}

export const getQueryParams = (params) => {
  return new URLSearchParams(params)
}

export const isImo = (str) => {
  const IMO_LENGTH = 5

  // imo should be 5 numbers
  return str.length === IMO_LENGTH && !isNaN(Number(str))
}

export const getUniqueCursorHelper = (url) => {
  if (!url) return null

  const query = new URLSearchParams(url.replaceAll('?', '&'))

  return query.get('cursor')
}

export const getUniquePageHelper = (url) => {
  if (!url) return null

  const query = new URLSearchParams(url.replaceAll('?', '&'))
  return query.get('page')
}
export function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getCurrentDomain = () => {
  if (window.location.href.includes('dev') || window.location.href.includes('localhost')) {
    return `https://dev.`
  } else if (window.location.href.includes('stage')) {
    return `https://stage.`
  } else {
    return `https://`
  }
}

export const searchQueryConverter = (obj) => {
  const { search, type } = obj
  if (search.length > 2) {
    const clearValue = search.trim()
    return new URLSearchParams({ [type]: clearValue }).toString()
  } else {
    return ''
  }
}
