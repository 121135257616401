import React, { useCallback, useEffect, useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { useFormik } from 'formik'
import SelectSearch from '../../../UI/Select/SelectSearch'
import { getAuthProfileFilters, getAutocompleteVessels, getShipowners } from '../../../api/getApi'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { postVessel, setIsButtonEnable, updateVessel } from '../../../redux/vesselManager/vesselManager.action'
import { getPrettierShipowners } from '../../../helpers/vesselManager.helper'
import VesselAutocomplete from '../../../UI/Select/VesselAutocomplete/VesselAutocomplete'
import WidgetMT from '../../MTWidget/MTWidget'
import { getUniquePageHelper } from '../../../helpers/apiHelper'
import { setIsSupportOpenedAction, setSupportMessageTextAction } from '../../../redux/support/support.action'
import { getPrettierDate } from '../../../helpers/time-helper/time-helper'
import Autocomplete from '../../../UI/Select/Autocomplete'
import { isCompletelyFilled } from '../../../helpers/form.helper'
import { useNotify } from '../../../hooks/useNotify'
import useInfiniteScrollAutocomplete from '../../../hooks/useInfiniteScrollAutocomplete'
import useCanEdit from '../../../hooks/useCanEdit'
import { fieldsToCheck } from './constants'
import { autoPasteFieldsByVessel, powerConverter } from './utils'
import { validationSchema } from './config'

const VesselManagerForm = ({ container }) => {
  const [filters, setFilters] = useState({
    currency: [],
    vessel_type: [],
    vessel_engine_type: [],
    country: [],
  })
  const [isOpen, setIsOpen] = useState(true)
  const [vessels, setVessels] = useState([])
  const [shipowners, setShipowners] = useState([])
  const [MTWidget, setMTWidget] = useState({
    isOpened: false,
    initialStep: null,
  })
  const [initialPageVessels, setInitialPageVessels] = useState(null)
  const [initialPageShipowners, setInitialPageShipowners] = useState(null)

  const { form: formValues, inEditId: currentInEdit, isButtonEnable } = useSelector(({ vessels }) => vessels)

  const { notify } = useNotify()
  const dispatch = useDispatch()

  const isCanEdit = useCanEdit()

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      if (currentInEdit) {
        dispatch(updateVessel(values, currentInEdit))
      } else {
        dispatch(postVessel(values))

        formik.resetForm()
      }
    },
  })

  const { errors, touched, values, dirty } = formik

  useEffect(() => {
    const getFilters = async () => {
      try {
        const response = await getAuthProfileFilters('currency', 'vessel_type', 'vessel_engine_type', 'country')

        setFilters(response)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    getFilters()

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const isCompletely = isCompletelyFilled(values, fieldsToCheck)

    if (!isCompletely) {
      dispatch(setIsButtonEnable(false))

      return
    }

    dispatch(setIsButtonEnable(isCompletely && dirty))

    // eslint-disable-next-line
  }, [values, dirty])

  const getVessels = async (value) => {
    setVessels([])
    setInitialPageVessels(null)
    try {
      const response = await getAutocompleteVessels(1, value)

      if (response.results.length === 0 && value.length > 2) {
        setMTWidget({ isOpened: true, initialStep: null })
      } else {
        setMTWidget({ isOpened: false, initialStep: null })
      }

      setInitialPageVessels(getUniquePageHelper(response?.next))
      setVessels(response.results)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const getOwners = async (value) => {
    setShipowners([])

    try {
      const response = await getShipowners(1, value)

      setInitialPageShipowners(getUniquePageHelper(response?.next))
      setShipowners(response.results)
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const { handleScroll, nextPage } = useInfiniteScrollAutocomplete({
    requestFunction: getAutocompleteVessels,
    setItems: setVessels,
    search: values.vessel.name,
    initialPage: initialPageVessels,
  })

  const { handleScroll: handleScrollShip } = useInfiniteScrollAutocomplete({
    requestFunction: getShipowners,
    setItems: setShipowners,
    search: values.owner.name,
    initialPage: initialPageShipowners,
    apiHelper: getPrettierShipowners,
  })

  const handleValueChange = (e) => {
    const { name } = e.target

    if (!touched[name]) formik.setFieldTouched(name, true)

    formik.handleChange(e)
  }

  const handleSelectChange = useCallback((name, value) => {
    const clearValue = { id: value.id, name: value.name }

    handleValueChange({ target: { name, value: clearValue } })

    //eslint-disable-next-line
  }, [])

  const handlePowerChange = (e) => {
    const { name, value } = e.target

    const regExFraction = /^(0*[1-9][0-9]*(\.[0-9]*)?(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/gm

    if (regExFraction.test(value)) {
      const neededUnit = name === 'hp' ? 'kw' : 'hp'

      formik.setValues({
        ...values,
        [neededUnit]: powerConverter(name, value),
        [name]: value,
      })
    }

    if (!value) formik.setValues({ ...values, hp: '', kw: '' })
  }

  const setFieldsByVessel = useCallback(
    (id) => {
      const result = autoPasteFieldsByVessel(vessels.find((item) => item.id === id))

      formik.setValues(result)
    },

    // eslint-disable-next-line
    [vessels]
  )

  const handleAutocompleteChange = useCallback((name, { target }) => {
    const value = { id: null, name: target.value }

    handleValueChange({ target: { name, value } })
    changeAutocompleteItems(name, target.value)

    //eslint-disable-next-line
  }, [])

  const handleAutocompleteFocus = useCallback((name, { target }) => {
    changeAutocompleteItems(name, target.value)

    if (name !== 'vessel') setInitialPageVessels(null)

    //eslint-disable-next-line
  }, [])

  const handleAutocompleteClick = useCallback(
    (name, value) => {
      if (name === 'vessel') {
        setFieldsByVessel(Number(value.id))

        return
      }

      handleValueChange({ target: { name, value } })
    },

    // eslint-disable-next-line
    [setFieldsByVessel]
  )

  const changeAutocompleteItems = useCallback((name, value) => {
    if (!value && name === 'vessel') {
      setMTWidget({ isOpened: false, initialStep: null })
    }

    if (value.length > 1) {
      if (name === 'vessel') getVessels(value)
      if (name === 'owner') getOwners(value)
    }

    // eslint-disable-next-line
  }, [])

  const handleMtError = useCallback(
    (step) => {
      if (step) {
        setMTWidget({ isOpened: true, initialStep: step })
        setVessels([])

        setInitialPageVessels(null)
      } else {
        dispatch(setIsSupportOpenedAction(true))
        dispatch(
          setSupportMessageTextAction(
            `Hi!\nI need help to finding a vessel name "${values.vessel.name}" when I try to add vessel registration.\nThanks!`
          )
        )

        setMTWidget({ isOpened: false, initialStep: null })
      }
    },

    // eslint-disable-next-line
    [values.vessel.name]
  )

  const handleMtSuccess = useCallback(
    async (mtData) => {
      try {
        if (mtData.next) {
          const response = await getAutocompleteVessels(initialPageVessels ?? 1, values.vessel.name)

          setInitialPageVessels(getUniquePageHelper(response.next))
          setVessels(response.results)
        } else {
          setVessels(mtData.results)
          setInitialPageVessels(null)
        }

        setIsOpen(true)
        setMTWidget({ isOpened: false, initialStep: null })
      } catch (error) {
        notify.errorsList(error.errors)
      }
    },

    // eslint-disable-next-line
    [initialPageVessels, values.vessel.name]
  )

  return (
    <div className={cn(styles.form, { [styles.form__inactive]: !isCanEdit })}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.row}>
          <div className={styles.field}>
            <VesselAutocomplete
              name="vessel"
              label="Vessel name"
              placeholder="Vessel name"
              getTitle="name"
              value={values.vessel.name}
              onChange={handleAutocompleteChange}
              onFocus={handleAutocompleteFocus}
              onClick={handleAutocompleteClick}
              handleScroll={handleScroll}
              onSuccess={handleMtSuccess}
              onError={handleMtError}
              items={vessels}
              cursor={nextPage}
              isInvalid={Boolean(errors.vessel?.name && touched.vessel)}
              errorMessage={errors.vessel?.name}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              required
            />

            {MTWidget.isOpened && (
              <WidgetMT
                onError={handleMtError}
                onSuccess={handleMtSuccess}
                value={values.vessel.name}
                stepErr={MTWidget.initialStep}
                setMTWidget={setMTWidget}
              />
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <Autocomplete
              label="Shipowner name"
              name="owner"
              placeholder="Shipowner name"
              getTitle="name"
              value={values.owner.name}
              onChange={handleAutocompleteChange}
              onFocus={handleAutocompleteFocus}
              onClick={handleAutocompleteClick}
              handleScroll={handleScrollShip}
              items={shipowners}
              isInvalid={Boolean(errors.owner?.id && touched.owner)}
              errorMessage={errors.owner?.id}
              required
              container={container}
            />
          </div>

          <div className={cn(styles.field, { [styles.field__disabled]: true })}>
            <Input
              name="country"
              label="Shipowner country"
              placeholder="Shipowner country"
              value={values.country?.name}
              onChange={handleAutocompleteChange}
              disabled
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <Input
              name="imo"
              label="IMO"
              placeholder="IMO"
              type="number"
              value={values.imo}
              onChange={handleValueChange}
              isInvalid={Boolean(errors.imo && touched.imo)}
              errorMessage={errors.imo}
              required
            />
          </div>

          <div className={styles.field}>
            <SelectSearch
              label="Vessel type"
              name="vessel_type"
              id={values.vessel_type?.id}
              selectedItem={values.vessel_type}
              items={filters.vessel_type}
              onClick={handleSelectChange}
              isInvalid={Boolean(errors.vessel_type?.id && touched.vessel_type)}
              errorMessage={errors.vessel_type?.id}
              required
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <SelectSearch
              label="Engine type"
              name="engine"
              id={values.engine.id}
              selectedItem={values.engine}
              items={filters.vessel_engine_type}
              onClick={handleSelectChange}
              isInvalid={Boolean(errors.engine?.id && touched.engine)}
              errorMessage={errors.engine?.id}
              required
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <SelectSearch
              label="Vessel flag"
              name="vessel_flag"
              id={values.vessel_flag.id}
              selectedItem={values.vessel_flag}
              items={filters.country}
              onClick={handleSelectChange}
              isInvalid={Boolean(errors.vessel_flag?.id && touched.vessel_flag)}
              errorMessage={errors.vessel_flag?.id}
              required
            />
          </div>

          <div className={styles.field} style={{ width: 250 }}>
            <Input
              name="year_of_build"
              label="Build year"
              placeholder="Year"
              value={getPrettierDate(values.year_of_build)}
              onChange={handleValueChange}
              isInvalid={Boolean(errors.year_of_build && touched.year_of_build)}
              errorMessage={errors.year_of_build}
              required
            />
          </div>

          <div className={styles.field}>
            <SelectSearch
              label="Crew"
              name="crew_nationality"
              id={values.crew_nationality.id}
              selectedItem={values?.crew_nationality}
              items={[{ id: 0, name: 'Mix' }, ...filters.country]}
              onClick={handleSelectChange}
              isInvalid={Boolean(errors.crew_nationality?.name && touched.crew_nationality)}
              errorMessage={errors.crew_nationality?.name}
              required
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <Input
              name="dwt"
              label="DWT"
              placeholder="DWT"
              type="number"
              value={values.dwt}
              onChange={handleValueChange}
              isInvalid={Boolean(errors.dwt && touched.dwt)}
              errorMessage={errors.dwt}
              required
            />
          </div>

          <div className={styles.field}>
            <Input
              name="grt"
              label="GRT"
              placeholder="GRT"
              type="number"
              value={values.grt}
              onChange={handleValueChange}
              isInvalid={Boolean(errors.grt && touched.grt)}
              errorMessage={errors.grt}
              required
            />
          </div>

          <div className={styles.field}>
            <Input
              name="teu"
              label="TEU"
              placeholder="TEU"
              value={values.teu}
              onChange={handleValueChange}
              isInvalid={Boolean(errors.teu && touched.teu)}
              errorMessage={errors.teu}
            />
          </div>

          <div className={styles.field}>
            <Input
              name="hp"
              label="HP"
              placeholder="HP"
              value={values.hp}
              onChange={handlePowerChange}
              isInvalid={Boolean(errors.hp && touched.hp)}
              errorMessage={errors.hp}
              required
            />
          </div>

          <div className={styles.field}>
            <Input
              name="kw"
              label="KW"
              placeholder="KW"
              value={values.kw}
              onChange={handlePowerChange}
              isInvalid={Boolean(errors.kw && touched.kw)}
              errorMessage={errors.kw}
              required
            />
          </div>
        </div>

        <div className={styles.footer}>
          <Button type="submit" size="middle" disabled={!isButtonEnable}>
            {currentInEdit ? 'Update' : 'Save'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default VesselManagerForm
