export const getDisplayString = (value, maxLength) => {
  const stringValue = !isNaN(value) ? String(value) : value
  const newString = `${stringValue.slice(0, maxLength)}...`
  const longString = stringValue.length > maxLength

  return longString ? newString : value
}

export const getPercentValue = (total, value) => {
  return `${Math.round((value / total) * 100)}%`
}

export const getClearData = (data) => {
  const clearArr = data.filter(({ name, value }) => Boolean(name && value))
  const resultArray = clearArr.slice(0, 4)
  const otherValue = clearArr.slice(4).reduce((acc, cur) => acc + cur.value, 0)

  return [...resultArray, { name: 'Other', value: otherValue }].filter(({ name, value }) => Boolean(name && value))
}
