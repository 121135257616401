export const userCreateSubmitHelper = (values) => {
  return {
    user_type: values.user_type.name.toLowerCase(),
    first_name: values.first_name,
    last_name: values.last_name,
    position: values.position.id,
    email: values.email,
    is_active: values.is_active,
  }
}
