import React, { useEffect, useRef } from 'react'
import styles from './searchTabsPanel.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { addTab, deleteTab } from '../../redux/actions/seafarersSearch'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import { getNextIndex, parseSeafarersSearchLink } from './utils'
import Tab from './Tab'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, Navigation, Keyboard } from 'swiper'
import Button from '../../UI/Button/Button'
import arrowMonolith from '../../assets/icons/arrow-monolith.svg'
import Image from '../../UI/Image/Image'
import cn from 'classnames'
import { removeLastSlash } from '../../helpers/helpers'

const SearchTabsPanel = ({ route }) => {
  const { tabs, idDeletedUsers } = useSelector(({ seafarersSearch }) => seafarersSearch)

  const dispatch = useDispatch()

  const swiperRef = useRef(null)
  const history = useHistory()
  const location = useLocation()

  const activeTab = parseSeafarersSearchLink(location)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const cardId = params.get('card_id')
    const tabName = params.get('tab_name')

    if (cardId && tabName) {
      history.push(`/profile/seafarers/${activeTab.category}/${cardId}`)
      dispatch(addTab(Number(cardId), tabName))

      return
    }

    if (!tabs.length && activeTab.id) history.push(`/profile/seafarers/${activeTab.category}`)

    // eslint-disable-next-line
  }, [activeTab.category, activeTab.id, history, location.search, tabs.length])

  useEffect(() => {
    if ((activeTab.category === 'search' || 'requests') && activeTab.id) {
      const query = new URLSearchParams(location.search)
      const name = query.get('name')
      const isExistTab = tabs.some((tab) => tab.id === activeTab.id)

      if (name) {
        query.delete('name')

        const newLocation = removeLastSlash(location.pathname)

        history.replace(newLocation)

        if (!isExistTab) dispatch(addTab(activeTab.id, name))
      }
    }

    // eslint-disable-next-line
  }, [])

  const handleTabDelete = (event, id) => {
    event.preventDefault()

    const nextIndex = getNextIndex(id, tabs)

    if (activeTab.id === id && tabs.length > 1) {
      history.push(`/profile/seafarers/${activeTab.category}/${tabs[nextIndex].id}`)
    } else if (tabs.length === 1) {
      history.push(`/profile/seafarers/${activeTab.category}`)
    }

    dispatch(deleteTab(id))
  }

  return (
    <div className={cn(styles.panel, { [styles.panel_without_nav_btn]: tabs.length < 6 })}>
      <NavLink to={route} className={`${styles.tab} ${styles.main}`} activeClassName={styles.tab_active}>
        Seafarers list
      </NavLink>

      <Swiper
        ref={swiperRef}
        spaceBetween={4}
        slidesPerView={'auto'}
        grabCursor={true}
        modules={[Mousewheel, Navigation, Keyboard]}
        className={cn('search_tabs_swiper', { search_tabs_btn_hidden: tabs.length < 6 })}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hiddenClass: '.swiper-button-hidden',
        }}
        mousewheel={{
          invert: false,
          sensitivity: 1,
        }}
        keyboard={{
          enabled: true,
        }}
      >
        {tabs.map((tab) => (
          <SwiperSlide key={tab.id}>
            <Tab tab={tab} route={route} onTabDelete={handleTabDelete} idDeletedUsers={idDeletedUsers} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Button
        className={cn('swiper-button-hidden', styles.swiper_button_prev, { [styles.hide_nav_btn]: tabs.length < 6 })}
        onClick={() => swiperRef.current.swiper.slidePrev()}
      >
        <Image src={arrowMonolith} />
      </Button>

      <Button
        className={cn('swiper-button-hidden', styles.swiper_button_next, { [styles.hide_nav_btn]: tabs.length < 6 })}
        onClick={() => swiperRef.current.swiper.slideNext()}
      >
        <Image src={arrowMonolith} />
      </Button>
    </div>
  )
}

SearchTabsPanel.propTypes = {
  route: PropTypes.string.isRequired,
}

export default SearchTabsPanel
