import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

const FormWrapper = ({ children, onSubmit, className }) => {
  return (
    <form onSubmit={onSubmit} className={cn(styles.formWrapper, className)}>
      {children}
    </form>
  )
}

export default FormWrapper
