import React, { useState, memo } from 'react'

import styles from './popularityChart.module.scss'

import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'
import Tabs from '../Tabs/Tabs'
import Tooltip from 'react-tooltip-lite'
import { getClearData, getDisplayString, getPercentValue } from './utils'
import { tab } from '../config'
import NoInfo from '../NoInfo/NoInfo'

const MAX_STR_LENGTH = 10
const COLORS = ['#F06A6A', '#F3D466', '#717DED', '#4CCBF3', '#62CBAE']

const isEqual = (prevProps, nextProps) => {
  const { data: prevData } = prevProps
  const { data: nextData } = nextProps

  return JSON.stringify(prevData) === JSON.stringify(nextData)
}

const PopularityChart = memo(({ data, category, handleChart }) => {
  const [activeTab, setActiveTab] = useState(tab.year)

  const displayCategory = category.split('_').join(' ')

  const clearData = getClearData(data)
  const total = clearData.reduce((acc, cur) => {
    return cur.name ? cur.value + acc : acc
  }, 0)

  const emptyStateData = Array.from({ length: 3 }).fill({ name: '_', value: 1 })
  const isDataEmpty = clearData.length === 0
  const chartData = isDataEmpty ? emptyStateData : clearData

  const renderCustomizedLabel = ({ cx, cy }) => {
    return (
      <g>
        <text fontSize={12} x={cx} y={cy - 16} dy={8} textAnchor="middle" fill="#06251E">
          Total
        </text>

        <text fontSize={22} fontWeight={600} x={cx} y={cy + 8} dy={8} textAnchor="middle" fill="#06251E">
          {total}
        </text>
      </g>
    )
  }

  const renderColorfulLegendText = (_, { payload: entry }) => {
    const { name, value } = entry.payload

    const styles = {
      fontSize: 14,
      fontWeight: 500,
      color: '#06251E',
    }
    const tooltipProps = {
      styles: {
        display: 'inline-block',
      },
      content: name,
    }

    const displayName = getDisplayString(name, MAX_STR_LENGTH)
    const percentValue = getPercentValue(total, value)
    const longName = displayName?.length > MAX_STR_LENGTH

    const variantDisplay = {
      tooltip: (
        <Tooltip {...tooltipProps}>
          <span style={styles}>{`${displayName} - ${value}(${percentValue})`}</span>
        </Tooltip>
      ),
      withoutTooltip: <span style={styles}>{`${displayName} - ${value}(${percentValue})`}</span>,
    }

    return longName ? variantDisplay.tooltip : variantDisplay.withoutTooltip
  }

  const handleTabClick = (tab, dates, group) => {
    setActiveTab(tab)
    handleChart(category, { dates, group })
  }

  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>{`Popular ${displayCategory}`}</h5>

      <div className={styles.main}>
        <ResponsiveContainer width="100%" height={105}>
          <PieChart>
            <Pie
              nameKey="name"
              dataKey="value"
              data={chartData}
              cx={47}
              cy={47}
              innerRadius={33}
              outerRadius={53}
              label={renderCustomizedLabel}
              labelLine={false}
              fill="#DFF0EB"
            >
              {clearData.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>

            {!isDataEmpty && (
              <Legend
                width="100%"
                layout="vertical"
                iconType="circle"
                iconSize={7}
                formatter={renderColorfulLegendText}
              />
            )}
          </PieChart>
        </ResponsiveContainer>

        <Tabs activeTab={activeTab} onClick={handleTabClick} />
      </div>

      {isDataEmpty && <NoInfo className={styles.noInfo} />}
    </div>
  )
}, isEqual)

export default PopularityChart
