import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  information: Yup.string().max(300, 'Too long!').nullable(),
  country: Yup.object().nullable().shape({
    name: Yup.string(),
  }),
  website: Yup.string().url('Please enter correct url').nullable(),
  city: Yup.object().shape({
    name: Yup.string(),
  }),
  address: Yup.object().shape({
    name: Yup.string(),
  }),
  zip: Yup.string().max(30, 'Max 30 digits'),
})

export const initialValues = {
  id: null,
  company: null,
  country: { id: null, name: '', iso2: '', iso3: '' },
  address: { id: '', name: '' },
  city: { id: '', name: '' },
  zip: '',
  website: '',
  office: '',
  information: '',
  documents: [],
  contact_types: [],
  phone_contacts: {
    count: null,
    next: null,
    previous: null,
    results: [],
  },
  email_contacts: {
    count: null,
    next: null,
    previous: null,
    results: [],
  },
}
