import React, { useState } from 'react'
import ReactAvatarEditor from 'react-avatar-editor'
import { useDispatch } from 'react-redux'
import { Slider } from 'antd'
import { putCompanyPicture } from '../../../api/putApi'
import { changeCompanyPicture, getCompanyPictureFetch, getCompanyProfile } from '../../../redux/actions/company.action'
import Spinner from '../../../UI/Spinner/Spinner'
import styles from './styles.module.scss'
import '../../../../src/scss/antd.scss'
import { useNotify } from '../../../hooks/useNotify'
import cn from 'classnames'

const PICTURE_TYPE = 'company_profile'

const AvatarCropper = ({
  picture,
  handleHideCropper,
  width = 211,
  height = 211,
  setEditorLoading,
  id,
  onChange,
  formik,
}) => {
  const dispatch = useDispatch()
  const { notify } = useNotify()

  const splitPath = window.location.pathname.split('/')
  const location = splitPath[splitPath.length - 1]

  const [avatarEditor, setAvatarEditor] = useState({
    image: picture,
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 0.5,
    rotate: 0,
    borderRadius: 100,
    preview: null,
    width: width,
    height: height,
  })
  const [editor, setEditor] = useState('')
  const [loading, setLoading] = useState(false)

  const handleScale = (scale) => {
    if (isNaN(scale)) {
      return
    }

    setAvatarEditor({ ...avatarEditor, scale })
  }

  const setEditorRef = (editor) => {
    setEditor(editor)
  }

  const handleNewImage = (e) => {
    if (e.target.files[0]) {
      setAvatarEditor({ ...avatarEditor, image: e.target.files[0] })
    }
  }

  const handlePositionChange = (position) => {
    setAvatarEditor({ ...avatarEditor, position })
  }

  const handleSave = () => {
    const croppedImage = editor.getImage().toDataURL(editor.props.image.type)
    const arr = []

    let blobBin = atob(croppedImage.split(',')[1])

    for (let i = 0; i < blobBin.length; i++) {
      arr.push(blobBin.charCodeAt(i))
    }

    const pictureForPut = editor.props.image
    const file = new File([new Uint8Array(arr)], pictureForPut.name, {
      type: pictureForPut.type,
    })

    setLoading(true)
    if (location === 'company-data') {
      putCompanyPicture(id, file, PICTURE_TYPE)
        .then((response) => {
          if (formik) {
            formik.setValues({
              ...formik.values,
              company_logo: response,
            })
          }
          dispatch(getCompanyPictureFetch(id))
          dispatch(changeCompanyPicture(null))
          dispatch(getCompanyProfile())
          setEditorLoading(true)
          handleHideCropper()
          notify('Success', 'You are successfully uploaded new company logo!')
        })
        .catch((error) => {
          setLoading(false)
          setEditorLoading(false)
          notify.errorsList(error.errors)
        })
    }

    if (location === 'users-create') {
      setEditorLoading(true)
      onChange(file)
      setTimeout(() => {
        handleHideCropper()
      }, 1000)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {loading && <Spinner />}
        <div
          className={cn(styles.logoContainer, {
            [styles.logoContainer_error]: formik
              ? Boolean(formik.errors.company_logo?.file_name && formik.touched.company_logo?.file_name)
              : false,
          })}
          style={loading ? { filter: 'blur(3px)' } : {}}
        >
          <ReactAvatarEditor
            ref={setEditorRef}
            scale={parseFloat(avatarEditor.scale)}
            width={avatarEditor.width}
            height={avatarEditor.height}
            position={avatarEditor.position}
            onPositionChange={handlePositionChange}
            rotate={parseFloat(avatarEditor.rotate)}
            borderRadius={avatarEditor.width / (100 / avatarEditor.borderRadius)}
            image={avatarEditor.image}
            className="editor-canvas"
          />
        </div>
        {Boolean(formik?.errors.company_logo?.file_name && formik?.touched.company_logo?.file_name) && (
          <p className={styles.error_message}>{formik?.errors.company_logo.file_name}</p>
        )}
      </div>

      <div className={styles.body}>
        <div className={styles.fileInfo}>
          <div className={styles.file}>
            <p className={styles.fileName}>{avatarEditor.image.name}</p>
            {avatarEditor.image.name.length >= 25 && (
              <p className={styles.fileFormat}>.{avatarEditor.image.name.split('.')[1]}</p>
            )}
          </div>

          {/*<p className={styles.formats}>*/}
          {/*  <b className={styles.formatsOfImages}>(jpg, jpeg, png)</b>*/}
          {/*</p>*/}
        </div>

        {typeof avatarEditor.image === 'object' && (
          <Slider
            style={{ width: '100%' }}
            min={0}
            max={1}
            step={0.01}
            tooltipVisible={false}
            onChange={handleScale}
            value={typeof avatarEditor.scale === 'number' ? avatarEditor.scale : 0}
          />
        )}

        <input
          className={styles.fileInput}
          onChange={handleNewImage}
          accept="image/png, .jpeg, .jpg"
          type="file"
          id="avatar-editor"
        />

        <div className={styles.controls}>
          <label className={styles.chooseLogoControl} htmlFor="avatar-editor">
            Choose logo
          </label>
          {typeof avatarEditor.image === 'object' && (
            <label className={styles.closeControl} onClick={handleHideCropper}>
              Close
            </label>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <label className={styles.saveLogoControl} onClick={handleSave}>
          Upload now
        </label>
      </div>
    </div>
  )
}

export default AvatarCropper
