import React from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import styles from '../userCard.module.scss'
import VisaItem from './VisaItem/VisaItem'
import { ReactComponent as NoInfoImage } from '../../../assets/images/visas_stub.svg'

const Visas = ({ userCardData, userName }) => {
  const { visas } = userCardData

  if (isEmpty(visas)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={385} height={260} />
        <p>
          {' '}
          <b>{userName}</b> doesn’t have any visas yet
        </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter="16px">
        {visas?.map((visa, index) => {
          return <VisaItem key={index} title={visa.name} files={visa.files} items={visa.values} />
        })}
      </Masonry>
    )
  }
}

export default Visas
