export const MAIN_DATE_FORMAT = 'yyyy-MM-dd'

export const categories = {
  vacancies: 'vacancies',
  countries: 'countries',
  engine_types: 'engine_types',
  vessel_types: 'vessel_types',
  published_vacancies: 'published_vacancies',
}

export const categoryPaths = {
  [categories.vacancies]: 'vacancies-statistic/rate/rank',
  [categories.countries]: 'seafarers-statistic/country',
  [categories.engine_types]: 'vacancies-statistic/rate/engine-type',
  [categories.vessel_types]: 'vacancies-statistic/rate/vessel-type',
  [categories.published_vacancies]: 'vacancies-statistic/count',
}

export const tab = {
  year: '12M',
  month: '6M',
  week: '1M',
  day: '7D',
}

export const groupName = {
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
}
