import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import editSvg from '../../assets/icons/edit.svg'
import styles from './styles.module.scss'
import CrossIcon from '../../UI/Icons/CrossIcon'
import { TrashIcon } from '../../UI/Icons/Icons'
import attachSVG from '../../assets/icons/attach.svg'
import ReactTooltip from 'react-tooltip'
const ItemsGroupItem = ({ data, onDelete, onEdit, isActive, isAnim, handleAttachHover, handleAttachBlur }) => {
  return (
    <div
      className={cn(styles.itemWrapper, {
        [styles.active]: isActive,
        [styles.moveAnimation]: isAnim,
      })}
    >
      <div className={styles.item}>
        <div className={styles.itemTitle}>
          {data.header.map((item, index) => (
            <span title={item.length > 50 ? item : undefined} className={styles.itemTitle_i} key={index}>
              {typeof item === 'string' ? (item.length > 50 ? item.slice(0, 49) + '...' : item) : item}
              {index === 0 && data.files && data.files.length ? (
                <img
                  onMouseEnter={() => handleAttachHover(data.files, data.header[0])}
                  onMouseLeave={handleAttachBlur}
                  className={styles.attach}
                  src={attachSVG}
                  alt="attach"
                />
              ) : null}
            </span>
          ))}
        </div>
        <div className={styles.itemContent}>
          {data.period.from !== 'current' && (
            <div className={styles.itemContentElem}>
              {' '}
              Period:{' '}
              <b>
                {data.period.from}
                {data.period.to ? (
                  <span className={styles.date_from} style={{ color: data.period.to.color }}>
                    <span style={{ color: '#000' }}> - </span>
                    {data.period.to.value}
                    {data.period.to.warning && <div className={styles.tooltip}>{data.period.to.warning}</div>}
                  </span>
                ) : null}
              </b>
            </div>
          )}

          {data.withPeriod && (
            <div className={styles.itemContentElem}>
              {data.withPeriod.title}: <b>{data.withPeriod.value}</b>
            </div>
          )}
        </div>
        {data.body.length ? (
          <div className={styles.itemContent}>
            {data.body.map(({ title, value }, index) => (
              <div key={index} className={styles.itemContentElem}>
                {title}: <b>{value}</b>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <div className={styles.controls}>
        <button
          data-tip={isActive ? 'cancel' : 'edit'}
          data-for="default"
          className={styles.edit}
          onClick={() => onEdit(data.id, isActive)}
        >
          {isActive ? (
            <CrossIcon width={14} height={14} strokeWidth={1} stroke="#43927C" />
          ) : (
            <img src={editSvg} alt="edit" />
          )}
        </button>

        <button
          data-tip={isActive ? 'cancel' : 'edit'}
          data-for="default"
          className={styles.delete}
          onClick={() => onDelete(data.id)}
        >
          <TrashIcon width={15.42} height={18.74} strokeWidth={1} strokeColor="#C20000" />
        </button>
      </div>

      <ReactTooltip backgroundColor="#43927C" id="default" />
    </div>
  )
}

ItemsGroupItem.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  isActive: PropTypes.bool,
  isAnim: PropTypes.bool,
}

export default ItemsGroupItem
