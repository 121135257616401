import React, { useCallback, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import styles from './OurServices.module.scss'
import cn from 'classnames'
import '../../../scss/animation.scss'

import OurServiceItem from './OurServiceItem'

import { getBillingCommercialServices } from '../../../api/getApi'
import { _count_services } from '../../../helpers/services'
import { profilePaths } from '../../../constants/constants'
import { notify } from '../../../helpers/notify'
import { isMobileDevice } from '../../../helpers/mobileDevice.helper'
import Button from '../../../UI/Button/Button'

const OurServices = ({ onMobilePopup }) => {
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(null)
  const [services, setServices] = useState([])

  const { isAuth } = useSelector(({ authentication }) => authentication)

  const handleOpenService = useCallback((id, isOpen) => {
    setIsOpen(isOpen ? null : id)
  }, [])

  useEffect(() => {
    const getServices = async () => {
      try {
        const { results } = await getBillingCommercialServices()

        setServices(results)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    getServices()
  }, [])

  return (
    <section className={styles.our_services}>
      <div className={styles.wrapper}>
        <div className={styles.our_services_container}>
          <div className={styles.our_services_subscription_free_plan}>
            <h3 className={styles.our_services_subscription_free_plan_title}>Introducing our Subscription Plan!</h3>

            <p className={styles.our_services_subscription_free_plan_description}>
              We offer a job search service for seafarers with <span>free basic features.</span> You can post job
              vacancies, browse profiles, and communicate with seafarers for free. During the promotional period, we are
              pleased to offer free access to all features for CrewManagers.
            </p>

            <div className={styles.our_services_subscription_free_plan_image} />

            <p className={styles.our_services_subscription_free_plan_description}>
              Discover enhanced search filters and find the best maritime professionals effortlessly. These options
              allow you to optimize your crew selection process and access to additional data
            </p>

            {isMobileDevice() && <Button onClick={onMobilePopup}>Choose FREE TRIAL</Button>}

            {!isMobileDevice() && (
              <Link
                to={!isAuth ? '/auth/signup' : profilePaths.billing_payment}
                className={styles.our_services_subscription_free_plan_choose}
              >
                Choose FREE TRIAL
              </Link>
            )}
          </div>

          <div className={styles.our_services_list}>
            <h2 className={styles.our_services_title}>
              Our <span>Services</span>
            </h2>

            {(!services.length && loading) || loading
              ? _count_services.map((item, index) => {
                  return (
                    <div key={index} className={cn(styles.our_services_preloader, 'preloader')}>
                      <div className={cn(styles.our_services_preloader_title, 'preloader-text')}></div>
                      <div className={cn(styles.our_services_preloader_description, 'preloader-text')}></div>
                      <div className={cn(styles.our_services_preloader_description, 'preloader-text')}></div>
                      {/*<div className={cn(styles.our_services_preloader_marker, 'preloader-text')}></div>*/}
                    </div>
                  )
                })
              : services.map((service) => {
                  return (
                    <OurServiceItem
                      key={service.order}
                      service={service}
                      handleOpenService={handleOpenService}
                      isOpen={isOpen === service.order}
                    />
                  )
                })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurServices
