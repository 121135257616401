import React from "react";
import styles from "./Menu.module.scss";
import cn from "classnames";

const MenuItem = ({ children, onClick, name, className = "" }) => {
  const classes = cn(styles.menu_item, className);

  function handleClick() {
    if (onClick && name) {
      onClick(name);
    }
  }

  return (
    <div className={classes} onClick={handleClick}>
      {children}
    </div>
  );
};

export default MenuItem;
