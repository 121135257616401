import React, { useState } from 'react'
import styles from './SeafarersSearchSort.module.scss'
import Select from '../../UI/Select/Select'
import cn from 'classnames'

export default function SeafarersSearchSort({ onChange }) {
  const filters = [
    { name: 'By Date Available', id: 'available_from' },
    { name: 'By teu', id: 'teu' },
    { name: 'By dwt', id: 'dwt' },
    { name: 'By grt', id: 'grt' },
    { name: 'By hp', id: 'hp' },
    { name: 'By kw', id: 'kw' },
    { name: 'By Last name', id: 'user__last_name' },
    { name: 'By Experience', id: 'total_days' },
  ]

  const [value, setValue] = useState(filters[0])
  const [isPositive, setIsPositive] = useState(false)

  const handleSelectClick = (name, val) => {
    if (value.name === val.name) return
    setValue(val)
    onChange(val.id, isPositive)
  }

  const handleButtonClick = () => {
    setIsPositive(!isPositive)
    onChange(value.id, !isPositive)
  }

  return (
    <div className={styles.sort}>
      <Select
        name="sort"
        id={value.id}
        selectedItem={value}
        items={filters}
        onClick={handleSelectClick}
        defaultName=""
        className={styles.sort_select}
      />
      <button onClick={handleButtonClick} className={cn(styles.button, { [styles.button_active]: isPositive })}>
        <div className={styles.triangle} />
      </button>
    </div>
  )
}
