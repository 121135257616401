export const getDocuments = (expiredDocuments, firstToExpire) => {
  const expDocuments = (() => {
    if (expiredDocuments.length) return expiredDocuments.map((item) => ({ ...item, color: '#C20000' }))

    return []
  })()

  const firstToExpDocuments = (() => {
    if (firstToExpire.length) return firstToExpire.map((item) => ({ ...item, color: '#E7B92A' }))

    return []
  })()

  return [...expDocuments, ...firstToExpDocuments]
}
