import format from 'date-fns/format'
import { addWeeks } from 'date-fns'
import { tab } from '../../config'

export const getWidth = (data) => {
  switch (data.length) {
    case 2:
      return 110

    case 3:
      return 150

    case 4:
      return 190

    case 5:
      return 230

    default:
      return 284
  }
}

export const getTick = (activeTab, value) => {
  switch (activeTab) {
    case tab.day:
      return `${value}d`

    case tab.week:
      return `${value}w`

    default:
      return `${value}m`
  }
}

export const getDate = (activeTab, date) => {
  switch (activeTab) {
    case tab.day: {
      const newDate = new Date(date)

      return format(newDate, 'dd.MM')
    }

    case tab.week: {
      const newDate = new Date(date)
      const startWeek = format(newDate, 'dd.MM')
      const newWeek = addWeeks(newDate, 1)
      const endWeek = format(newWeek, 'dd.MM')

      return `${startWeek} - ${endWeek}`
    }

    default:
      return format(new Date(date), 'MMM')
  }
}
