import React, { useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

const Visas = ({ visas }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const TooltipContent = () => {
    return (
      <div onScroll={(e) => e.stopPropagation()} onWheel={(e) => e.stopPropagation()} className={styles.columnTooltip}>
        {visas.map((item, idx) => (
          <span style={{ whiteSpace: 'nowrap' }} key={idx}>
            {item}
          </span>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.field}>
      <span>Visas –</span>

      {visas.length > 0 && (
        <div
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
          className={cn(styles.semi_bold)}
        >
          {visas[0]}
          {visas.length > 1 && <span style={{ color: 'var(--color-accent-light)' }}>{` +${visas.length - 1}`}</span>}

          {tooltipVisible && <TooltipContent />}
        </div>
      )}

      {visas.length === 0 && <span className={styles.semi_bold}>No Info</span>}
    </div>
  )
}

export default Visas
