import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.module.scss'
import SelectItem from './SelectItem'
import BottomArrowSvg from '../Icons/BottomArrowSvg'
import Input from '../Input/Input'
import useClickOutside from '../../hooks/useClickOutside'

const MultiSelect = memo(({ label, name, value, items, onClick, isInvalid, required }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [filteredItems, setFilteredItems] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [activeIndex, setActiveIndex] = useState(-1)
  const [isFocused, setIsFocused] = useState(false)

  const selectEl = useClickOutside(() => setIsSelectOpen(false))

  const list = filteredItems.length !== 0 ? filteredItems : items
  const activeItemsList = list.filter((el) => value.map((el) => el.id).includes(el.id))
  const otherItemsList = list.filter((el) => !value.map((el) => el.id).includes(el.id))
  const newList = [...activeItemsList, ...otherItemsList]

  const handleItemClick = (id, _) => {
    const rest = items.find((item) => item.id === Number(id))

    if (value.map((item) => item.id).includes(rest.id)) {
      onClick(
        name,
        value.filter((item) => item.id !== rest.id)
      )

      return
    }

    onClick(name, [...value, { ...rest }])
  }

  const handleInputChange = ({ target }) => {
    const { value } = target

    setInputValue(() => value)

    setFilteredItems(
      items.filter((item) => {
        const name = item.name.toLowerCase()

        return name.includes(value.toLowerCase())
      })
    )
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleKeyDown = (e) => {
    if (!isSelectOpen && e.key === 'Enter' && isFocused) {
      setIsSelectOpen(true)

      return
    }

    if (e.key === 'ArrowUp' && activeIndex > 0) {
      e.preventDefault()

      setActiveIndex(activeIndex - 1)

      return
    }

    if (e.key === 'ArrowDown' && activeIndex < newList.length - 1) {
      e.preventDefault()

      setActiveIndex(activeIndex + 1)

      return
    }

    if (e.key === 'Enter' && activeIndex !== -1) {
      const id = newList[activeIndex].id
      const name = newList[activeIndex].name

      handleItemClick(id, name)
    }
  }

  return (
    <>
      {label && (
        <label className={styles.label}>
          {label} {required && <span className="required-star">*</span>}
        </label>
      )}

      <div ref={selectEl} className={styles.wrapper}>
        <div
          tabIndex="0"
          className={cn(styles.header, 'select-search', {
            [styles.header_invalid]: isInvalid,
          })}
          onClick={() => setIsSelectOpen(!isSelectOpen)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        >
          <div className={styles.title}>{value.map((item) => item.name).join(', ')}</div>
        </div>

        {isSelectOpen && (
          <div className={cn(styles.search_menu)} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDown}>
            <Input
              placeholder="Search"
              value={inputValue}
              onChange={handleInputChange}
              clear={handleInputChange}
              className={styles.search}
              focus
            />

            <ul className={`${styles.list} ${styles.multiList}`}>
              {newList.map(({ name, id }, index) => {
                const isActive = index === activeIndex || value.map((el) => el.id).includes(id)

                return (
                  <SelectItem
                    tabIndex="0"
                    key={id}
                    name={name}
                    id={id}
                    isActive={isActive}
                    onClick={handleItemClick}
                    withIcon
                  />
                )
              })}
            </ul>
          </div>
        )}

        <div
          onClick={() => setIsSelectOpen(!isSelectOpen)}
          className={cn(styles.icon, {
            [styles.icon_active]: isSelectOpen,
            [styles.icon_invalid]: isInvalid,
          })}
        >
          <BottomArrowSvg width={14} height={7} fill="var(--color-accent-light)" />
        </div>
      </div>
    </>
  )
})

MultiSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  items: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
}

MultiSelect.defaultProps = {
  value: [],
  items: [],
  isInvalid: false,
  required: false,
}

export default MultiSelect
