import React from 'react'
import styles from './styles.module.scss'
import warningSVG from '../../assets/icons/circle-warning.svg'
import successSVG from '../../assets/icons/circle-check.svg'

export default function ModerationStatus({ data }) {
  const getCurrentMessage = () => {
    let alert
    if (data.allow_moderation && !data.on_moderation) {
      alert = 'Check information and submit for moderation'
    } else if (!data.allow_moderation && !data.on_moderation && !data.is_active) {
      alert = 'You must fill out the information and go through the moderation process'
    } else if (data.on_moderation && !data.is_active) {
      alert = 'Your information is under moderation'
    } else if (data.on_moderation && data.is_active) {
      alert =
        'If you need to change the basic information, you can contact support, after which our administrators will give you access. \n' +
        'Also you can enter additional information about your company'
    } else {
      alert = null
    }
    return alert
  }

  return (
    <div className={styles.block}>
      <div className={styles.content}>
        <img className={styles.icon_status} src={data.is_active ? successSVG : warningSVG} alt="to your information" />
        <div className={styles.message}>
          <div className={styles.title}>
            {data.is_active ? (
              <p>
                Your account status is{' '}
                <span className={styles.accent} style={{ color: '#1CCF9E' }}>
                  ENABLED
                </span>
              </p>
            ) : (
              <p>
                Your account status is{' '}
                <span className={styles.accent} style={{ color: '#c20000' }}>
                  {' '}
                  not active yet{' '}
                </span>{' '}
              </p>
            )}
          </div>
          <div className={styles.description}>{getCurrentMessage()}</div>
        </div>
      </div>
    </div>
  )
}
