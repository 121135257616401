import React from 'react'
import styles from './popupDeletedUser.module.scss'
import Portal from '../../Portal/Portal'
import { ReactComponent as Image } from './assets/icons/closeImage.svg'
import Button from '../../../UI/Button/Button'

const PopupDeletedUser = ({ onClose }) => {
  return (
    <Portal>
      <div className={styles.inactive_card_message}>
        <Image />

        <h4 className={styles.title}>oops!</h4>

        <p className={styles.message}>
          This user has deleted his profile. The page with the seafarer's card will be closed
        </p>

        <Button size="small" onClick={onClose}>
          OK
        </Button>
      </div>
    </Portal>
  )
}

export default PopupDeletedUser
