import React from 'react'

import styles from './rangeDatePicker.module.scss'

import KeyBoardDatePicker from '../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import { useFormik } from 'formik'
import { getValidationSchema } from './config'
import Buttons from '../RangePopupItems/Buttons'
import InputsWrapper from '../RangePopupItems/InputsWrapper'
import FormWrapper from '../RangePopupItems/FormWrapper'

const DateRangePopup = ({ name, range, maxDate = '', onClose, onChange }) => {
  const formik = useFormik({
    initialValues: { ...range },
    validationSchema: getValidationSchema(maxDate),
    enableReinitialize: true,

    onSubmit: (values) => {
      onChange(name, values)
      onClose()
    },
  })

  const { values, errors } = formik

  const handleChangeValue = (name, value) => {
    formik.handleChange({ target: { name, value } })
  }

  const handleDateChange = (name, value) => {
    if (!value) return ''

    const date = new Date(value)

    handleChangeValue(name, date)
  }

  return (
    <FormWrapper onSubmit={formik.handleSubmit}>
      <InputsWrapper>
        <div className={styles.datePickerWrapper}>
          <KeyBoardDatePicker
            name="from"
            keyBoardLabel="From"
            value={values.from}
            onChange={handleDateChange}
            placeholder="dd/MM/yyyy"
            format="dd/MM/yyyy"
            minDate={new Date('1900/01/01')}
            maxDate={maxDate}
            isInvalid={Boolean(errors.from)}
            errorMessage={errors.from}
          />
        </div>

        <div className={styles.datePickerWrapper}>
          <KeyBoardDatePicker
            name="to"
            keyBoardLabel="To"
            value={values.to}
            onChange={handleDateChange}
            placeholder="dd/MM/yyyy"
            format="dd/MM/yyyy"
            minDate={new Date('1900/01/01')}
            maxDate={maxDate}
            isInvalid={Boolean(errors.to)}
            errorMessage={errors.to}
          />
        </div>
      </InputsWrapper>

      <Buttons onClick={onClose} />
    </FormWrapper>
  )
}

export default DateRangePopup
