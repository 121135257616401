import React from 'react'

import { userPermission, profilePaths, billingPermission } from './constants'

import CompanyDataPage from '../pages/CompanyDataPage/CompanyDataPage'
import UsersCreatePage from '../pages/UsersCreatePage/UsersCreatePage'
import VacancyManagerPage from '../pages/VacancyManagerPage'
import BillingPage from '../pages/BillingPage'
import MessagesPage from '../pages/MessagesPage'
import SearchPage from '../pages/SearchPage'
import SettingsPage from '../pages/SettingsPage/SettingsPage'
import StatisticPage from '../pages/StatisticPage'
import { isPathAvailable, isServiceAvailable } from '../helpers/helpers'
import store from '../redux/store'

export const getProfileRoutes = () => {
  const { is_active: isActiveCompany } = store.getState().company

  return {
    ...(isPathAvailable(userPermission.seafarers_favourites) &&
      isPathAvailable(userPermission.vacancy_requests) &&
      isActiveCompany && {
        [profilePaths.seafarers]: {
          Component: <SearchPage />,
        },
      }),

    ...(isPathAvailable(userPermission.seafarer_chat) &&
      isServiceAvailable(billingPermission.instant_messaging) &&
      isActiveCompany && {
        [`${profilePaths.chat}/`]: {
          Component: <MessagesPage />,
          exact: true,
        },

        [`${profilePaths.chat}/:id`]: {
          Component: <MessagesPage />,
        },
      }),

    ...(isPathAvailable(userPermission.vacancies) &&
      isPathAvailable(userPermission.vessels) &&
      isActiveCompany && {
        [profilePaths.vacancy_manager]: {
          Component: <VacancyManagerPage />,
        },
      }),

    ...(isPathAvailable(userPermission.users) &&
      isActiveCompany && {
        [profilePaths.users]: {
          Component: <UsersCreatePage />,
        },
      }),

    ...(isPathAvailable(userPermission.company_profile) && {
      [profilePaths.company_data]: {
        Component: <CompanyDataPage />,
        exact: true,
      },
    }),

    ...(isPathAvailable(userPermission.billing) &&
      isActiveCompany && {
        [profilePaths.billing]: {
          Component: <BillingPage />,
        },
      }),

    ...(isActiveCompany && {
      [profilePaths.statistic]: {
        Component: <StatisticPage />,
      },
    }),

    [profilePaths.settings]: {
      Component: <SettingsPage />,
    },
  }
}
