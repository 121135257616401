import React, { useRef } from 'react'
import { toast } from 'react-toastify'

export const useNotify = () => {
  const toastId = useRef(null)

  function createContent(title, message) {
    return (
      <>
        <figure className="toast-circle-figure" />
        <div className="toast-body-content">
          <span className="toast-body-title">{title}</span>
          <span className="toast-body-text">{message}</span>
        </div>
      </>
    )
  }

  function createNotifyByType(type) {
    return (title, message, options) => {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast[type](createContent(title, message), options)
      }
    }
  }

  function createErrorsList() {
    return (errors, options = {}) => {
      if (Array.isArray(errors) && errors.length) {
        errors.forEach((error) => {
          if (Array.isArray(error.message)) {
            error.message.forEach((message) => {
              if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(createContent('Error', message), options)
              }
            })

            return
          }

          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(createContent('Error', error.message), options)
          }
        })
      }
    }
  }

  const notify = (title, message, options) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(createContent(title, message), options)
    }
  }

  notify.error = createNotifyByType('error')
  notify.info = createNotifyByType('info')
  notify.warning = createNotifyByType('warning')
  notify.errorsList = createErrorsList()

  return { notify }
}
