import React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'

import PrivateRoute from '../HOC/PrivateRoute'
import Header from '../components/Company/Header/Header'
import ProfileAside from '../components/ProfileAside/ProfileAside'
import { getUserIdFromUrl } from '../components/SearchTabsPanel/utils'

import useIndicateBrowserTab from '../hooks/useIndicateBrowserTap'
import useActiveCompany from '../hooks/useActiveCompany'
import { isMobileDevice } from '../helpers/mobileDevice.helper'
import { isDevEnvironment } from '../helpers/helpers'
import { getProfileRoutes } from '../constants/routesConfig'
import { profilePaths } from '../constants/constants'

export const Profile = () => {
  const isAuth = useSelector(({ authentication }) => authentication.isAuth)
  const username = useSelector(({ user }) => user.profile.first_name)
  const { tabs } = useSelector(({ seafarersSearch }) => seafarersSearch)

  const location = useLocation()

  useIndicateBrowserTab(username)

  const isActiveCompany = useActiveCompany()

  const getNewUrlAtCardIdPresence = (pathname, search) => {
    const params = new URLSearchParams(search)
    const cardIdFromUrlQuery = params.get('card_id')
    const tabNameFromUrlQuery = params.get('tab_name')

    if (!cardIdFromUrlQuery && !tabNameFromUrlQuery) {
      const cardId = getUserIdFromUrl(pathname)

      const clearUrl = pathname
        .split('/')
        .reduce((acc, cur) => {
          return cur !== String(cardId) ? [...acc, cur] : acc
        }, [])
        .join('/')

      const tabTitle = tabs.find((tab) => tab.id === cardId)?.title

      const queryStr = `/?card_id=${cardId}&tab_name=${tabTitle}`

      const newUrl = encodeURIComponent(`${clearUrl}${queryStr}`)

      return cardId && tabTitle ? newUrl : clearUrl
    }

    return encodeURIComponent(`${pathname}/${search}`)
  }

  const callbackUrl = getNewUrlAtCardIdPresence(location.pathname, location.search)

  const routes = Object.keys(getProfileRoutes())

  const redirectLink = (() => {
    const isCompanyPath = routes.includes(profilePaths.company_data)

    if (!isCompanyPath) return profilePaths.seafarers_search
    if (!isActiveCompany && !isCompanyPath) return profilePaths.settings

    return profilePaths.company_data
  })()

  if (!isAuth) {
    return <Redirect to={`${profilePaths.auth}/?callback_url=${callbackUrl}`} />
  }

  if (isMobileDevice() && !isDevEnvironment()) return <Redirect to="/" />

  return (
    <div className="page profile-page">
      <ProfileAside />

      <div className="profile-page-tab">
        <Header />

        <Switch>
          {routes.map((path) => {
            const { exact, Component } = getProfileRoutes()[path]

            return <PrivateRoute key={path} exact={exact} path={path} component={Component} />
          })}

          <Redirect to={redirectLink} />
        </Switch>
      </div>
    </div>
  )
}
