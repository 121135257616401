let user = JSON.parse(localStorage.getItem('user'))

const initialState = user
  ? { isAuth: true, user, isButtonEnable: true }
  : { isAuth: false, user: { scope: null }, isButtonEnable: true }

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        isAuth: true,
        user: action.payload,
        errors: [],
      }
    case 'LOGIN_FAIL':
      return {
        ...state,
        isAuth: false,
        errors: action.payload,
      }
    case 'USER_LOGOUT':
      return {
        ...state,
        isAuth: false,
        user: {
          scope: null,
        },
      }
    case 'SET_BUTTON_STATE':
      return {
        ...state,
        isButtonEnable: action.payload,
      }

    default:
      return state
  }
}
