import { subYears } from 'date-fns'
import { getDateByFormat } from '../../../helpers/time-helper/time-helper'
import { categories, groupName, MAIN_DATE_FORMAT } from './config'
import PublishedVacancies from './PublishedVacancies/PublishedVacancies'
import PopularityChart from './PopularityChart/PopularityChart'

export const getDates = (dates) => {
  const start_date = getDateByFormat(dates.start_date, MAIN_DATE_FORMAT)
  const end_date = getDateByFormat(dates.end_date, MAIN_DATE_FORMAT)

  return {
    start_date,
    end_date,
  }
}

export const getDefaultParams = () => {
  const currentDate = new Date()
  const lastYear = subYears(currentDate, 1)

  const dates = getDates({ start_date: lastYear, end_date: currentDate })
  const group = groupName.month

  return {
    ...dates,
    group,
  }
}

export const getChart = (category) => {
  switch (category) {
    case categories.published_vacancies:
      return PublishedVacancies

    default:
      return PopularityChart
  }
}
