import React from 'react'
import Input from '../../../../UI/Input/Input'
import VacancyPriorityLabel from '../../../../UI/VacancyPriorityLabel/VacancyPriorityLabel'
import styles from './vacancyPriority.module.scss'
import { priorityType, vacancyPriorityMap } from '../../../../constants/constants'

const VacancyPriority = ({ name, value, onChange }) => {
  return (
    <div className={styles.vacancyPriorityWrapper}>
      {Object.keys(vacancyPriorityMap).map((priority) => (
        <div className={styles.priority} key={priority}>
          <Input
            name={name}
            type="radio"
            theme="radio"
            onChange={onChange}
            value={vacancyPriorityMap[priority].value}
            checked={value === vacancyPriorityMap[priority].value}
          />

          <VacancyPriorityLabel label={vacancyPriorityMap[priority].label} urgent={priority === priorityType.urgent} />
        </div>
      ))}
    </div>
  )
}

export default VacancyPriority
