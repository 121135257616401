import React from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import CertificateItem from './CertificatesItem/CertificateItem'
import styles from '../userCard.module.scss'
import { ReactComponent as NoInfoImage } from '../../../assets/images/certificates_stub.svg'

const Certificates = ({ userCardData, userName }) => {
  const { certificates } = userCardData

  if (isEmpty(certificates)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={425} height={265} />
        <p>
          <b>{userName}</b> doesn’t have any certificates yet.{' '}
        </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter="16px">
        {certificates?.map((certificate, index) => {
          return (
            <CertificateItem
              key={index}
              title={certificate.name}
              files={certificate.files}
              items={certificate.values}
            />
          )
        })}
      </Masonry>
    )
  }
}

export default Certificates
