import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'

import styles from './styles.module.scss'

import Button from '../../../UI/Button/Button'
import InputPassword from '../../../UI/Input/InputPassword'

import { changePasswordRequest } from '../../../api/postApi'
import { userLogout } from '../../../redux/actions/auth'
import { useNotify } from '../../../hooks/useNotify'
import { validationSchema } from './config'

const ChangePassword = () => {
  const [isButtonDisable, setIsButtonDisable] = useState(false)

  const dispatch = useDispatch()
  const { notify } = useNotify()

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm: '',
    },

    validationSchema,

    validateOnChange: true,
    validateOnBlue: true,

    onSubmit: (values) => handleChangePassword(values),
  })

  const handleChangeFormikValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  const handleChangePassword = async ({ old_password, new_password }) => {
    setIsButtonDisable(true)

    try {
      await changePasswordRequest({ old_password, new_password })

      notify('Success', 'Your password was successfully changed')

      dispatch(userLogout())
    } catch (error) {
      setIsButtonDisable(false)

      notify.errorsList(error.errors)
    }
  }

  const { values, errors, touched } = formik

  const isButtonEnable = Boolean(values.new_password && values.old_password && values.confirm) || isButtonDisable

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <div className={styles.field}>
        <div className={styles.label}>Old password</div>

        <InputPassword
          theme="password"
          autoComplete="new-password"
          name="old_password"
          value={values.old_password}
          onChange={handleChangeFormikValue}
          isInvalid={Boolean(errors.old_password && touched.old_password)}
          errorMessage={errors.old_password}
        />
      </div>

      <div className={styles.field}>
        <div className={styles.label}>New password</div>

        <InputPassword
          theme="password"
          autoComplete="new-password"
          name="new_password"
          value={values.new_password}
          onChange={handleChangeFormikValue}
          isInvalid={Boolean(errors.new_password && touched.new_password)}
          errorMessage={errors.new_password}
        />
      </div>

      <div className={styles.field}>
        <div className={styles.label}>Confirm new password</div>

        <InputPassword
          theme="password"
          autoComplete="new-password"
          name="confirm"
          value={values.confirm}
          onChange={handleChangeFormikValue}
          isInvalid={Boolean(errors.confirm && touched.confirm)}
          errorMessage={errors.confirm}
        />
      </div>

      <Button className={styles.button} disabled={!isButtonEnable} type="submit" size="middle">
        Confirm
      </Button>
    </form>
  )
}

export default ChangePassword
