import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ReactComponent as LockIcon } from './icons/lock.svg'

const ProfileNavigationItem = ({ link, icon: Icon, title, menuItems, paths, disabled }) => {
  const onPaths = (paths) => {
    return (_, location) => {
      return paths.includes(location.pathname)
    }
  }

  return (
    <div className={styles.link_wrapper}>
      <NavLink
        isActive={paths ? onPaths(paths) : undefined}
        className={cn(styles.link, { [styles.link_inactive]: disabled })}
        to={link}
        activeClassName={styles.active}
      >
        <span className={styles.icon}>{Icon}</span>

        <span className={styles.title}>{title}</span>

        {disabled && (
          <span className={styles.lock}>
            <LockIcon />
          </span>
        )}
      </NavLink>

      {menuItems && (
        <div className={styles.link_menu}>
          {menuItems.map((link) => (
            <NavLink
              isActive={link.paths ? onPaths(link.paths) : undefined}
              className={styles.link_menu_item}
              activeClassName={styles.active}
              to={link.path}
            >
              {link.name}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  )
}

ProfileNavigationItem.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default ProfileNavigationItem
