import React, { useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import Table from '../../../UI/Table/Table'
import Row from '../../../UI/Table/Row'
import TableHeadField from '../../../UI/Table/TableHeadField'
import TableField from '../../../UI/Table/TableField'
import Input from '../../../UI/Input/Input'
import DateRangePopup from '../../RangeDatePicker/DateRangePopup'

import { ReactComponent as CalendarIcon } from './icons/calendar.svg'
import { ReactComponent as LoginIcon } from './icons/login.svg'
import { ReactComponent as LogoutIcon } from './icons/logout.svg'

import useClickOutside from '../../../hooks/useClickOutside'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import { getDefaultParams } from '../Charts/utils'
import { getDateByFormat } from '../../../helpers/time-helper/time-helper'
import { getLoginHistoryList } from '../../../api/getApi'
import { objToQueryConverter } from '../../../helpers/apiHelper'
import { tHead } from './config'

const emptyMessage = <div className={styles.noContent}>You've seen everything</div>

const LoginHistoryTable = () => {
  const [list, setList] = useState([])
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false)
  const [viewHistorySession, setViewHistorySession] = useState(null)
  const [params, setParams] = useState(getDefaultParams)

  const ref = useClickOutside(() => setViewHistorySession(null))

  const { handleScroll, loading } = useInfiniteScroll(
    getLoginHistoryList,
    setList,
    null,
    objToQueryConverter(params),
    null,
    true
  )

  const renderHead = () => {
    return (
      <Row isHead isSortLabel>
        {tHead.map((item) => (
          <TableHeadField key={item}>{item}</TableHeadField>
        ))}
      </Row>
    )
  }

  const renderHistorySession = (items) => {
    return items.map(({ created_at, closed_at }) => (
      <p className={styles.historyItem}>
        <div className={styles.time}>
          <span>
            <LoginIcon />
          </span>
          <span>{getDateByFormat(created_at, 'HH:mm')}</span>
        </div>

        <div className={styles.time}>
          <span>
            <LogoutIcon />
          </span>
          <span>{getDateByFormat(closed_at, 'HH:mm')}</span>
        </div>
      </p>
    ))
  }

  const renderBody = () => {
    return list.map(({ min_date, max_date, full_name, position, work_time, date, items }, index) => {
      const minDate = getDateByFormat(min_date, 'hh:mm aa')
      const maxDate = getDateByFormat(max_date, 'hh:mm aa')
      const userLoginDate = getDateByFormat(date, 'dd.MM.yy')
      const workTime = `${work_time.hours}h ${work_time.minutes}m`

      return (
        <Row key={index}>
          <TableField>{full_name}</TableField>
          <TableField>{position.name}</TableField>
          <TableField>{minDate}</TableField>
          <TableField>{maxDate}</TableField>
          <TableField>
            <div className={styles.totalTime}>
              <span>{userLoginDate}</span>

              <span
                className={cn(styles.workTime, { [styles.workTime__underline]: viewHistorySession === index })}
                onClick={() => handleHistoryView(index)}
              >
                {workTime}
              </span>

              {viewHistorySession === index && (
                <div className={styles.historyContainer}>
                  <div ref={ref} className={styles.history}>
                    {renderHistorySession(items)}
                  </div>
                </div>
              )}
            </div>
          </TableField>
        </Row>
      )
    })
  }

  const handleButtonClick = () => {
    setIsOpenDatePicker(!isOpenDatePicker)
  }

  const handleHistoryView = (id) => {
    setViewHistorySession(id)
  }

  const handleInputChange = (e) => {
    const { value } = e.target

    setParams({ ...params, search: value })
  }

  const handleRangeChange = (_, dates) => {
    const start_date = getDateByFormat(dates.from, 'yyyy-MM-dd')
    const end_date = getDateByFormat(dates.to, 'yyyy-MM-dd')

    setParams({ ...params, start_date, end_date })
  }

  const isDateEqual = getDefaultParams().start_date === params.start_date
  const range = (() => {
    if (!isDateEqual)
      return {
        from: new Date(params.start_date),
        to: new Date(params.end_date),
      }

    return null
  })()

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h5 className={styles.title}>User login history table</h5>

        <div className={styles.filter}>
          <Input
            name="search"
            placeholder="First name, second name"
            className={styles.input}
            value={params?.search}
            onChange={handleInputChange}
            loupe
          />

          <div className={styles.groupWrapper}>
            <button
              className={cn(
                styles.button,
                { [styles.activeButton]: isOpenDatePicker },
                { [styles.activeWithValue]: !isDateEqual }
              )}
              onClick={handleButtonClick}
            >
              <CalendarIcon />
            </button>

            {isOpenDatePicker && (
              <DateRangePopup
                onChange={handleRangeChange}
                range={range}
                maxDate={new Date()}
                onClose={() => setIsOpenDatePicker(false)}
              />
            )}
          </div>
        </div>
      </div>

      <Table
        head={renderHead()}
        body={renderBody()}
        onScroll={handleScroll}
        loading={loading}
        colSpan={5}
        emptyMessage={emptyMessage}
      />
    </div>
  )
}

export default LoginHistoryTable
