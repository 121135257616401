import React from 'react'
import styles from './styles.module.scss'

const Avatar = ({ picture, loading, loaded }) => {
  return picture.file_name === 'empty-profile-photo.png' ? (
    // eslint-disable-next-line
    <img
      src={picture.file}
      alt={!loading ? 'company avatar' : undefined}
      className={styles.defaultLogo}
      style={loading ? { filter: 'blur(3px)' } : {}}
      onLoad={loaded}
    />
  ) : (
    // eslint-disable-next-line
    <img
      src={picture.file}
      alt={!loading ? 'company avatar' : undefined}
      className={styles.companyLogo}
      style={loading ? { filter: 'blur(3px)' } : {}}
      onLoad={loaded}
    />
  )
}

export default Avatar
