import React from 'react'
import styles from './styles.module.scss'
import { connect } from 'react-redux'
import { useState } from 'react'
import UserProfileMore from './UserProfileMore'
import { CSSTransition } from 'react-transition-group'
const UserProfile = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={styles.userProfile}>
      <div className={styles.userAvatar} onClick={() => setIsOpen(!isOpen)}>
        {props.picture && <img src={props.picture.file_mini || props.picture.file} alt="user profile" />}
      </div>
      <CSSTransition classNames="fadein-top" appear mountOnEnter unmountOnExit in={isOpen} timeout={300}>
        <UserProfileMore setIsOpen={setIsOpen} {...props} />
      </CSSTransition>
    </div>
  )
}

const mapStateToProps = ({ user, authentication }) => ({
  picture: user.profile.picture,
  firstName: user.profile.first_name,
  lastName: user.profile.last_name,
  email: user.profile.email,
  last_login: user.profile.last_login,
  scope: authentication.user.scope,
})

export default connect(mapStateToProps)(UserProfile)
