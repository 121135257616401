import React from 'react'
import { Link } from 'react-router-dom'
import styles from './profileAside.module.scss'
import logo from '../../assets/icons/logo.svg'
import ProfileNavigation from '../ProfileNavigation/ProfileNavigation'

const ProfileAside = () => {
  return (
    <div className={styles.aside}>
      <div className={styles.header}>
        <Link to="/">
          <img src={logo} alt="small logotype" />
        </Link>
      </div>

      <div className={styles.navbar}>
        <ProfileNavigation />
      </div>

      <div className={styles.empty_object} />
    </div>
  )
}

export default ProfileAside
