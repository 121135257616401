import React, { useCallback } from 'react'
import styles from '../Comments.module.scss'
import { Highlighting } from '../../../helpers/comments.helper'
import useUserDateFormat from '../../../hooks/useUserDateFormat'
import { getParsedDate } from '../../../helpers/time-helper/time-helper'
import moment from 'moment'

export const CommentItem = ({ comment, search }) => {
  const { company_user, created_at, message } = comment
  const light = useCallback((message) => <Highlighting string={message} match={search} />, [search])
  const userFormat = useUserDateFormat()
  const date = getParsedDate(moment(created_at).format(userFormat))
  const time = moment(created_at).format('LT')

  return (
    <div className={styles.comment_item}>
      <div className={styles.comment_item_header}>
        <p className={styles.comment_item__company_name}>
          {company_user.first_name} {company_user.last_name}
          <time className={styles.comment_item_time}>
            {moment(date).format(userFormat)} {time.split(' ').join('')}
          </time>
        </p>
      </div>
      <p className={styles.comment_item_message}>{light(message)}</p>
    </div>
  )
}
