import React from 'react'
import {regExp} from "./regExp";

const linksIdentifier = (string, styles) => (
  string.split(' ').map((message, index) => (
    regExp.regExpLinkInString.test(message)
      ? <React.Fragment key={index}>
        <a
          className={styles.message_text_link}
          href={message}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          {message}
        </a> <br/>
      </React.Fragment>
      : <React.Fragment key={index}> <span>{message}</span> </React.Fragment>
  ))
);

const phoneIdentifier = (string) => (
  string.split(' ').map((message, index) => (
    regExp.regExpPhoneInString.test(message)
      ? <React.Fragment key={index}> <a href={`tel:${message}`}>{message}</a> </React.Fragment>
      : <React.Fragment key={index}> <span>{message}</span> </React.Fragment>
  ))
);

const emailIdentifier = (string, styles) => (
  string.split(' ').map((message, index) => (
    !message.match(regExp.email)?.input
      ? <React.Fragment key={index}> <span>{message}</span> </React.Fragment>
      : <React.Fragment key={index}>
        <a className={styles.message_text_link} href={`mailto:${message}`}>{message}</a><br/>
      </React.Fragment>
  ))
);

export const messageDefinition = (message, styles) => {
  if (message && typeof message === 'string') {
    switch (message) {
      case message.match(regExp.email)?.input:
        return emailIdentifier(message, styles);
      case message.match(regExp.link)?.input:
        return linksIdentifier(message, styles);
      case message.match(regExp.phone)?.input:
        return phoneIdentifier(message);
      default:
        return message;
    };
  };
};