import React from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import SizeInformationItem from './SizeInformationItem/SizeInformationItem'
import styles from '../userCard.module.scss'

const SizeInformation = ({ userCardData }) => {
  const { anthropometry } = userCardData
  if (isEmpty(anthropometry)) {
    return <div className={styles.noInfo}>No information</div>
  } else {
    return (
      <Masonry columnsCount={2} gutter="16px">
        <SizeInformationItem title={anthropometry.title} items={anthropometry.values} />
      </Masonry>
    )
  }
}

export default SizeInformation
