import { combineReducers } from 'redux'

import authentication from './auth'
import user from './user.reducer'
import companyReducer from './company.reducer'
import uiReducer from './ui.reducer'
import vesselManagerReducer from '../vesselManager/vesselManager.reducer'
import vacancyManager from '../vacancyManager/vacancyManager.reducer'
import seafarersSearchReducer from './seafarersSearch'
import chatReducer from './chat.reducer'
import supportReducer from '../support/support.reducer'
import reviewsReducer from './reviews.reducer'

const rootReducer = combineReducers({
  company: companyReducer,
  authentication,
  user,
  ui: uiReducer,
  vessels: vesselManagerReducer,
  vacancies: vacancyManager,
  seafarersSearch: seafarersSearchReducer,
  chat: chatReducer,
  support: supportReducer,
  reviewsList: reviewsReducer,
})

export default rootReducer
