import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import './Сonfirm.scss'

export const confirm = (title, text, onTrue, onFalse = null) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div id="confirm" className="custom-ui-wrapper">
          <div className="custom-ui">
            <div className="custom-ui-content">
              <h1>{title}</h1>
              <p>{text}</p>
            </div>
            <div className="custom-ui-controls">
              <button
                data-confirm="yes"
                className="custom-ui-controls__yes-btn"
                onClick={() => {
                  onTrue()
                  onClose()
                }}
              >
                Yes
              </button>
              <button
                data-confirm="close"
                className="custom-ui-controls__close-btn"
                onClick={() => {
                  if (onFalse) onFalse()
                  onClose()
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )
    },
  })
}
