import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Field is required'),
  email: Yup.string()
    .email('Email is invalid')
    .min(6, 'Too Short!')
    .max(254, 'Too Long!')
    .required('Field is required'),
  phone: Yup.number()
    .min(8)
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('Field is required'),
  company_logo: Yup.object().shape({
    file_name: Yup.string().test('Add Logo', 'Add your company logo please', (value = '') => {
      if (value === 'company_default.png') {
        return false
      } else return true
    }),
  }),
})
export const initialValues = {
  email: '',
  name: '',
  person_in_charge: '',
  phone: '',
  company_logo: '',
}
