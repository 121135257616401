import React from 'react'
import { isEmpty } from 'lodash'
import Masonry from 'react-responsive-masonry'
import ReviewItem from './ReviewItem/ReviewItem'
import styles from '../userCard.module.scss'
import { ReactComponent as NoInfoImage } from '../../../assets/images/reviews_stub.svg'

const Reviews = ({ userCardData, userName }) => {
  const { reviews } = userCardData

  if (isEmpty(reviews)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={350} height={270} />
        <p>
          {' '}
          <b>{userName}</b> doesn't have any rewievs yet{' '}
        </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter={'16px'}>
        {reviews?.map((review, index) => review.questions && <ReviewItem key={index} review={review} />)}
      </Masonry>
    )
  }
}

export default Reviews
