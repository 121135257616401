import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import styles from './styles.module.scss'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import { passwordRecoverRequest } from '../../api/postApi'
import { useSelector } from 'react-redux'
import { useNotify } from '../../hooks/useNotify'
import { profilePaths } from '../../constants/constants'

const ForgotPassForm = () => {
  const [isButtonEnable, setIsButtonEnable] = React.useState(false)
  const { email: userEmail } = useSelector(({ user }) => user.profile)
  const { notify } = useNotify()

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .min(6, 'Too Short!')
      .max(254, 'Too Long!')
      .required('Field is required'),
  })

  const formik = useFormik({
    initialValues: { email: '' },
    validateOnChange: true,
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      const clearFunction = formik
      setIsButtonEnable(true)
      passwordRecoverRequest({ ...values, is_company: true })
        .then(() => {
          const message = 'A confirmation letter has been sent to your email!'
          notify('Success', message)
          formik.setValues({
            ...formik.initialValues,
          })
          setIsButtonEnable(false)

          clearFunction.resetForm()
        })
        .catch((error) => {
          setIsButtonEnable(false)
          notify.errorsList(error.errors)
        })
    },
  })

  const handleChangeFormikValue = (event) => {
    if (!formik.touched[event.target.name]) {
      formik.setFieldTouched(event.target.name, true)
    }

    formik.handleChange(event)
  }

  useEffect(() => {
    if (userEmail) {
      formik.setValues({ email: userEmail })
    }
    //eslint-disable-next-line
  }, [userEmail])

  const { values, errors, touched } = formik

  return (
    <div>
      <h2 className={styles.title}>Enter the email address associated with your account</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <Input
            name="email"
            label="Enter your Email"
            placeholder="email.example@domainname"
            value={values.email}
            isInvalid={!!(errors.email && touched.email)}
            onChange={handleChangeFormikValue}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.footerLeft}>
            <Button type="submit" size="large-full" disabled={isButtonEnable}>
              Recover Password
            </Button>
          </div>
          <div className={styles.footerRight}>
            Back to{' '}
            <b>
              <Link to={profilePaths.auth}>Log In</Link>
            </b>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassForm
