import React, { useCallback, useEffect, useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { useFormik } from 'formik'
import Input from '../../../UI/Input/Input'
import {
  getSearchCompanyVessels,
  getAuthProfileFilters,
  getShipowners,
  getGooglePlacesCities,
} from '../../../api/getApi'
import Select from '../../../UI/Select/Select'
import SelectSearch from '../../../UI/Select/SelectSearch'
import Button from '../../../UI/Button/Button'
import Switcher from '../../../UI/Switcher/Switcher'
import { useDispatch, useSelector } from 'react-redux'
import {
  postVacancy,
  setIsButtonEnable,
  setVacancyType,
  updateVacancy,
} from '../../../redux/vacancyManager/vacancyManager.action'
import Checkbox from '../../../UI/CheckBox/CheckBox'
import { getDatePickerFormat, setMaxRangeDate } from '../../../helpers/time-helper/time-helper'
import KeyBoardDatePicker from '../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import { isCompletelyFilled } from '../../../helpers/form.helper'
import Autocomplete from '../../../UI/Select/Autocomplete'
import { getPrettierShipowners } from '../../../helpers/vesselManager.helper'
import { useNotify } from '../../../hooks/useNotify'
import VacancyPriority from './VacancyPriority/VacancyPriority'
import VesselSelectSearch from '../../../UI/Select/VesselSelectSearch'
import { getUniqueCursorHelper, getUniquePageHelper } from '../../../helpers/apiHelper'
import useInfiniteScrollAutocomplete from '../../../hooks/useInfiniteScrollAutocomplete'
import useCanEdit from '../../../hooks/useCanEdit'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { validationSchemaTypeLand, validationSchemaTypeSea } from './config'
import { getPrettierCities, getPrettierVessels, setRequiredField } from './utils'
import { fields, fieldsToCheckTypeLand, fieldsToCheckTypeSea, vacancyTypes, range, periods } from './constants'
import { postVacancyTitle } from '../../../api/postApi'
import { titleHelper } from '../../../helpers/vacancyManager.helper'

const VacancyManagerForm = ({ container }) => {
  const [filters, setFilters] = useState({
    currency: [],
    vessel_type: [],
    rank: [],
    country: [],
  })
  const [vessels, setVessels] = useState([])
  const [shipowners, setShipowners] = useState([])
  const [cities, setCities] = useState([])
  const [vesselSearchValue, setVesselSearchValue] = useState('')
  const [initialPageVessels, setInitialPageVessels] = useState(null)
  const [initialPageShipowners, setInitialPageShipowners] = useState(null)

  const formValuesTypeSea = useSelector(({ vacancies }) => vacancies.formTypeSea)
  const formValuesTypeLand = useSelector(({ vacancies }) => vacancies.formTypeLand)
  const currentInEdit = useSelector(({ vacancies }) => vacancies.inEditId)
  const tab = useSelector(({ vacancies }) => vacancies.filters.deleted)
  const isButtonEnable = useSelector(({ vacancies }) => vacancies.isButtonEnable)
  const vacancyType = useSelector(({ vacancies }) => vacancies.vacancyType)

  const dispatch = useDispatch()
  const { notify } = useNotify()

  const isCanEdit = useCanEdit()

  const currentDate = new Date()

  const formik = useFormik({
    initialValues: vacancyType === vacancyTypes.sea ? formValuesTypeSea : formValuesTypeLand,
    validationSchema: vacancyType === vacancyTypes.sea ? validationSchemaTypeSea : validationSchemaTypeLand,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      if (currentInEdit) {
        dispatch(updateVacancy(values, currentInEdit, vacancyType))
      } else {
        dispatch(postVacancy(values, tab, formik.resetForm, vacancyType))
      }
    },
  })

  const { errors, values, touched, dirty } = formik

  const datePickerFormat = getDatePickerFormat(values?.is_exact_date)

  useEffect(() => {
    const getFilters = async () => {
      try {
        const response = await getAuthProfileFilters('currency', 'vessel_type', 'rank', 'country')

        setFilters(response)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    getFilters()

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const postTitle = async () => {
      if (!values.rank?.name) return
      if (
        (vacancyType === vacancyTypes.sea && !values.vessel_type?.name) ||
        (vacancyType === vacancyTypes.land && !values.country?.name)
      )
        return

      try {
        const { title } = await postVacancyTitle(titleHelper(values))

        formik.setValues({
          ...values,
          title,
        })
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    postTitle()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.rank, values.vessel_type, values.country?.name])

  useEffect(() => {
    const getVessels = async () => {
      setVessels([])

      try {
        const response = await getSearchCompanyVessels('', vesselSearchValue)

        setInitialPageVessels(getUniqueCursorHelper(response.next))
        setVessels(getPrettierVessels(response.results))
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    getVessels()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vesselSearchValue])

  useEffect(() => {
    const fieldsToCheck = (() => {
      if (vacancyType === vacancyTypes.sea) return fieldsToCheckTypeSea

      return fieldsToCheckTypeLand
    })()

    if (!values.is_exact_date) setRequiredField(fieldsToCheck, 'join_date_range')
    if (values.is_exact_date) setRequiredField(fieldsToCheck, 'join_date_range', true)

    const isCompletely = isCompletelyFilled(values, fieldsToCheck)

    if (!isCompletely) {
      dispatch(setIsButtonEnable(false))

      return
    }

    dispatch(setIsButtonEnable(isCompletely && dirty))

    // eslint-disable-next-line
  }, [values, dirty])

  useEffect(() => {
    const errorsToNotify = []

    fields.forEach((field) => {
      if (touched[field] && errors[field]) {
        const isWithoutValue = !values[field] || Number(values[field]) <= 0

        if ((values[field].name && !values[field].id) || isWithoutValue) {
          errorsToNotify.push({ field, message: errors[field]?.id ?? errors[field] })
        }
      }
    })

    if (!errorsToNotify.length) return

    const content = errorsToNotify.map((item) => <p key={item.field}>{`Field "${item.field}" - ${item.message}`}</p>)

    notify('Form validation issue!', content, {
      type: 'warning',
    })

    // eslint-disable-next-line
  }, [errors, values])

  const handleValueChange = (e) => {
    const { name } = e.target

    if (!touched[name]) formik.setFieldTouched(name, true)

    formik.handleChange(e)
  }

  const getVesselSearch = useCallback((value) => {
    setVesselSearchValue(value)
  }, [])

  const getOwners = useCallback(async (value) => {
    setShipowners([])

    try {
      const response = await getShipowners(1, value)

      setInitialPageShipowners(getUniquePageHelper(response.next))
      setShipowners(response.results)
    } catch (error) {
      notify.errorsList(error.errors)
    }

    // eslint-disable-next-line
  }, [])

  const { handleScroll } = useInfiniteScrollAutocomplete({
    requestFunction: getSearchCompanyVessels,
    setItems: setVessels,
    name: vesselSearchValue,
    initialPage: initialPageVessels,
    apiHelper: getPrettierVessels,
  })

  const { handleScroll: handleScrollShip } = useInfiniteScrollAutocomplete({
    requestFunction: getShipowners,
    setItems: setShipowners,
    search: values.owner?.name,
    initialPage: initialPageShipowners,
    apiHelper: getPrettierShipowners,
  })

  const getCities = useCallback(
    async (value) => {
      setCities([])

      try {
        const iso = filters.country.find((country) => country?.id === values.country?.id)?.iso2

        const response = await getGooglePlacesCities(value, iso)

        setCities(getPrettierCities(response))
      } catch (error) {
        notify.errorsList(error.errors)
      }
    },

    // eslint-disable-next-line
    [filters.country, values.country?.id]
  )

  const changeAutocompleteItems = useCallback(
    (name, value) => {
      if (name === 'shipowner' && value.length > 1) getOwners(value)

      if (name === 'city' && value.length > 2) getCities(value)
    },
    [getOwners, getCities]
  )

  const handleAutocompleteChange = useCallback(
    (name, { target }) => {
      const value = { id: null, name: target.value }

      handleValueChange({
        target: { name, value },
      })

      changeAutocompleteItems(name, target.value)
    },

    [changeAutocompleteItems] // eslint-disable-line
  )

  const handleAutocompleteFocus = useCallback(
    (name, { target }) => {
      changeAutocompleteItems(name, target.value)
    },
    [changeAutocompleteItems]
  )

  const handleAutocompleteClick = useCallback(
    (name, value) => {
      const clearValue = { id: value.id, name: value.name }

      handleValueChange({ target: { name, value: clearValue } })
    },

    // eslint-disable-next-line
    []
  )

  const handleSelectChange = (name, value) => {
    const clearValue = { id: value.id, name: value.name }

    if (name === 'join_date_range') {
      if (!value.id) {
        formik.setValues({ ...values, join_date: null, [name]: clearValue })
      } else {
        // publication date must be earlier join date
        const newValue = (() => {
          if (values.join_date) {
            const newValue = new Date(values.join_date).setHours(0, 0, 1)

            return new Date(newValue)
          }
          const newValue = currentDate.setHours(0, 0, 1)

          return new Date(newValue)
        })()
        // publication date must be earlier join date

        const newDate = setMaxRangeDate(newValue, value.id)

        formik.setValues({ ...values, join_date: newDate, [name]: clearValue })

        return
      }
    }

    if (name === 'vessel') {
      if (value.id) {
        formik.setValues({
          ...values,
          vessel_type: { id: value.type.id, name: value.type.name },
          shipowner: { id: value.shipowner.id, name: value.shipowner.name },
          [name]: clearValue,
        })
      }

      if (!value.id) {
        formik.setValues({
          ...values,
          shipowner: { id: null, name: '' },
          [name]: clearValue,
        })
      }

      setVesselSearchValue('')

      return
    }

    if (name === 'country') {
      formik.setValues({
        ...values,
        city: { id: null, name: '' },
        [name]: clearValue,
      })

      return
    }

    handleValueChange({ target: { name, value: clearValue } })
  }

  const handleSalaryChange = useCallback((e) => {
    const { value } = e.target

    const decimalIndex = value.indexOf('.')
    const lengthBeforeDecimal = decimalIndex !== -1 ? decimalIndex : value.length
    const lengthAfterDecimal = decimalIndex !== -1 ? value.length - decimalIndex - 1 : 0

    if (lengthBeforeDecimal > 15 || lengthAfterDecimal > 2 || isNaN(value)) return

    handleValueChange(e)

    // eslint-disable-next-line
  }, [])

  const handleSwitcherClick = () => {
    formik.setValues({ ...values, is_active: !values.is_active })
  }

  const handleCheckBoxClick = () => {
    const join_date_range = (() => {
      if (!values.is_exact_date) return { id: null, name: '' }

      return values.join_date_range
    })()

    formik.setValues({ ...values, is_exact_date: !values.is_exact_date, join_date_range })
  }

  const handleDateChange = useCallback(
    (name, value) => {
      if (!value) {
        // set value, clear field
        handleValueChange({ target: { name, value } })

        return
      }

      if (name === 'join_date') {
        const newValue = (() => {
          // publication date must be earlier join date
          const newValue = new Date(value).setHours(0, 0, 1)
          const newDate = new Date(newValue)

          if (values.join_date_range.id) return setMaxRangeDate(newDate, values.join_date_range.id)

          return newDate
        })()

        handleValueChange({ target: { name, value: newValue } })

        return
      }

      // publication date must be earlier join date
      const newValue = new Date(value).setHours(0, 0, 0)
      const newDate = new Date(newValue)

      handleValueChange({ target: { name, value: newDate } })
    },

    // eslint-disable-next-line
    [values.join_date_range.id]
  )

  const handleVacancyTypeChange = useCallback((type) => {
    dispatch(setVacancyType(type))

    // eslint-disable-next-line
  }, [])

  return (
    <div className={cn(styles.form, { [styles.form__inactive]: !isCanEdit })}>
      <div
        className={cn(styles.switcherTypeVacancyWrapper, {
          [styles.switcherTypeVacancyWrapper__disabled]: Boolean(currentInEdit),
        })}
      >
        <div className={styles.switcherTypeVacancy}>
          <Button
            theme="text"
            className={cn(styles.typeVacancyButton, {
              [styles.typeVacancyButtonActive]: vacancyType === vacancyTypes.land,
            })}
            type="button"
            onClick={() => handleVacancyTypeChange(vacancyTypes.land)}
          >
            Land
          </Button>

          <Button
            theme={'text'}
            className={cn(styles.typeVacancyButton, {
              [styles.typeVacancyButtonActive]: vacancyType === vacancyTypes.sea,
            })}
            type="button"
            onClick={() => handleVacancyTypeChange(vacancyTypes.sea)}
          >
            At sea
          </Button>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={values.vacancy_type}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false)
            }}
            classNames="fade"
          >
            <div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <SelectSearch
                    label="Rank"
                    name="rank"
                    items={filters.rank}
                    id={values.rank.id}
                    selectedItem={values.rank}
                    onClick={handleSelectChange}
                    isInvalid={Boolean(errors.rank && touched.rank)}
                    errorMessage={errors.rank?.id}
                    required
                  />
                </div>

                <VacancyPriority name="vacancy_priority" value={values.vacancy_priority} onChange={handleValueChange} />
              </div>

              {vacancyType === vacancyTypes.sea && (
                <div className={styles.row}>
                  <div className={styles.field} style={{ maxWidth: 252 }}>
                    <VesselSelectSearch
                      label="Vessel Name"
                      name="vessel"
                      id={values.vessel?.id}
                      value={vesselSearchValue}
                      items={vessels}
                      selectedItem={values.vessel}
                      onClick={handleSelectChange}
                      onScroll={handleScroll}
                      getValueSearch={getVesselSearch}
                    />
                  </div>

                  <div
                    className={cn(styles.field, {
                      [styles.field__disabled]: values.vessel?.id,
                    })}
                  >
                    <SelectSearch
                      label="Vessel Type"
                      name="vessel_type"
                      items={filters.vessel_type}
                      id={values.vessel_type?.id}
                      selectedItem={values.vessel_type}
                      onClick={handleSelectChange}
                      isInvalid={Boolean(errors.vessel_type?.id && touched.vessel_type)}
                      errorMessage={errors.vessel_type?.id}
                      required
                    />
                  </div>
                </div>
              )}

              {vacancyType === vacancyTypes.land && (
                <div className={styles.row}>
                  <div className={styles.field} style={{ minWidth: '311px' }}>
                    <SelectSearch
                      label="Country"
                      name="country"
                      items={filters.country}
                      id={values.country?.id}
                      onClick={handleSelectChange}
                      selectedItem={values.country}
                      isInvalid={Boolean(errors.country?.id && touched.country)}
                      errorMessage={errors.country?.id}
                      required
                    />
                  </div>

                  <div className={styles.field}>
                    <Autocomplete
                      label="City"
                      name="city"
                      placeholder="City"
                      getTitle="name"
                      value={values.city?.name}
                      items={cities}
                      onChange={handleAutocompleteChange}
                      onFocus={handleAutocompleteFocus}
                      onClick={handleAutocompleteClick}
                      handleScroll={handleScrollShip}
                      isInvalid={Boolean(errors.city?.id && touched.city)}
                      errorMessage={errors.city?.id}
                      disabled={!values.country?.id}
                      container={container}
                    />
                  </div>
                </div>
              )}

              <div className={styles.row}>
                <div className={cn(styles.field, styles.exactJoinDate)}>
                  <Checkbox
                    id="exact-date"
                    name="exact-date"
                    checked={values.is_exact_date}
                    onChange={handleCheckBoxClick}
                  >
                    Exact join date
                  </Checkbox>
                </div>

                <div className={styles.field} style={{ maxWidth: 150 }}>
                  <KeyBoardDatePicker
                    name="join_date"
                    keyBoardLabel="Join date"
                    value={values.join_date}
                    onChange={handleDateChange}
                    format={datePickerFormat}
                    placeholder={datePickerFormat}
                    minDate={currentDate}
                    openTo={values.is_exact_date ? 'date' : 'month'}
                    views={values.is_exact_date ? ['date'] : ['month']}
                    isInvalid={Boolean(errors.join_date && touched.join_date)}
                    errorMessage={errors.join_date}
                    required
                  />
                </div>

                <div className={styles.field}>
                  <Select
                    label="Range"
                    name="join_date_range"
                    title="Month"
                    id={values.join_date_range.id}
                    selectedItem={values.join_date_range}
                    items={range}
                    onClick={handleSelectChange}
                    isInvalid={Boolean(errors.join_date_range && touched.join_date_range)}
                    errorMessage={errors.join_date_range?.id}
                    required={!values.is_exact_date}
                    disabled={values.is_exact_date}
                  />
                </div>
              </div>

              <div className={styles.grid}>
                <div className={styles.field}>
                  <Input
                    name="duration"
                    label="Duration"
                    value={values.duration}
                    onChange={handleValueChange}
                    type="number"
                    min="1"
                    isInvalid={Boolean(errors.duration && touched.duration)}
                    required
                  />
                </div>

                <div className={styles.field}>
                  <Select
                    label="Period"
                    title="Month"
                    name="duration_period"
                    id={values.duration_period.id}
                    selectedItem={values.duration_period}
                    items={periods}
                    onClick={handleSelectChange}
                    isInvalid={Boolean(errors.duration_period?.id && touched.duration_period)}
                    required
                  />
                </div>

                <div className={styles.field}>
                  <div className={styles.durationRange}>
                    <span>+/-</span>

                    <Input
                      name="duration_range"
                      errorMessage={errors.duration_range}
                      value={values.duration_range}
                      onChange={handleValueChange}
                      isInvalid={Boolean(errors.duration_range && touched.duration_range)}
                      type="number"
                      min="0"
                      max="12"
                    />

                    <span>Mon</span>
                  </div>
                </div>

                <div className={styles.field}>
                  <Select
                    label="Cur"
                    name="salary_currency"
                    id={values.salary_currency.id}
                    selectedItem={values.salary_currency}
                    items={filters.currency}
                    onClick={handleSelectChange}
                    isInvalid={Boolean(errors.salary_currency && touched.salary_currency)}
                    required
                  />
                </div>

                <div className={styles.field}>
                  <Input
                    name="salary"
                    label="Salary"
                    value={values.salary}
                    onChange={handleSalaryChange}
                    isInvalid={Boolean(errors.salary && touched.salary)}
                    required
                  />
                </div>

                <div className={styles.field}>
                  <Select
                    label="Rate"
                    name="salary_rate"
                    id={values.salary_rate.id}
                    selectedItem={values.salary_rate}
                    items={periods}
                    onClick={handleSelectChange}
                    isInvalid={Boolean(errors.salary_rate && touched.salary_rate)}
                    required
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.field}>
                  <Input
                    name="salary_description"
                    label="Salary details"
                    placeholder="Write salary details..."
                    value={values.salary_description}
                    onChange={handleValueChange}
                    isInvalid={Boolean(errors.salary_description && touched.salary_description)}
                    errorMessage={errors.salary_description}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.fieldRow}>
                  <label className={styles.fieldTitleBold}>Create a vacancy</label>

                  <div
                    className={cn(styles.switcher, {
                      [styles.switcher__active]: values.is_active,
                    })}
                  >
                    <span>{values.is_active ? 'Enabled' : 'Disabled'}</span>

                    <Switcher isActive={values.is_active} onClick={handleSwitcherClick} />
                  </div>
                </div>

                <div className={styles.fieldRow} style={{ width: '200px' }}>
                  <label className={styles.fieldTitleBold}>Publish</label>

                  <KeyBoardDatePicker
                    name="published_at"
                    value={values.published_at}
                    onChange={handleDateChange}
                    minDate={currentDate}
                    isInvalid={Boolean(errors.published_at && touched.published_at)}
                    errorMessage={errors.published_at}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.field}>
                  <Input
                    name="title"
                    label="Job title"
                    placeholder="Write description..."
                    value={values.title}
                    onChange={handleValueChange}
                    isInvalid={Boolean(errors.title && touched.title)}
                    errorMessage={errors.title}
                    disabled
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.field}>
                  <Input
                    textarea
                    name="description"
                    label="Description"
                    placeholder="Write description..."
                    value={values.description}
                    onChange={handleValueChange}
                    isInvalid={Boolean(errors.description && touched.description)}
                    errorMessage={errors.description}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.field}>
                  <Autocomplete
                    label="Shipowner name"
                    name="shipowner"
                    placeholder="Shipowner name"
                    getTitle="name"
                    value={values.shipowner.name}
                    onChange={handleAutocompleteChange}
                    onFocus={handleAutocompleteFocus}
                    onClick={handleAutocompleteClick}
                    handleScroll={handleScrollShip}
                    items={shipowners}
                    isInvalid={Boolean(errors.shipowner?.id && touched.shipowner)}
                    errorMessage={errors.shipowner?.id}
                    disabled={Boolean(values.vessel?.id) || vacancyType === vacancyTypes.land}
                    container={container}
                  />
                </div>
                {/*<div className={styles.field}>*/}
                {/*    <CheckBox*/}
                {/*        name="show_shipowner"*/}
                {/*        className={styles.checkboxShipowner}*/}
                {/*        onChange={handleCheckBoxClickShow}*/}
                {/*        checked={!formik.values.show_shipowner}*/}
                {/*        id="show_shipowner"*/}
                {/*        theme="filled"*/}
                {/*    >*/}
                {/*        Don’t display shipowner’s name on the posted vacancy*/}
                {/*    </CheckBox>*/}
                {/*</div>*/}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>

        <div className={styles.footer}>
          <Button
            type="button"
            size="middle"
            disabled={currentInEdit}
            theme="transparent"
            style={{ marginRight: 6, padding: '10px 23px' }}
            onClick={formik.resetForm}
          >
            Clear
          </Button>

          <Button type="submit" size="middle" disabled={!isButtonEnable}>
            {currentInEdit ? 'Update' : 'Save'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default VacancyManagerForm
