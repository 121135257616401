export const fieldsToCheck = [
  'name',
  'owner',
  'imo',
  'vessel_type',
  'engine',
  'vessel_flag',
  'year_of_build',
  'crew_nationality',
  'dwt',
  'grt',
  'hp',
  'kw',
]

export const fieldsToObjectCreate = ['crew_nationality', 'owner', 'engine_type', 'flag']
