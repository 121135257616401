import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './userCard.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, Navigation } from 'swiper'
import 'swiper/swiper.css'
import './slider-style.scss'

import WithPreloader from '../../HOC/WithPreloader'
import CardHeader from './CardHeader/CardHeader'
import ContactInfo from './ContactInfo/ContactInfo'
import Education from './Education/Education'
import Experience from './Experience/Experience'
import Passports from './Passports/Passports'
import Visas from './Visas/Visas'
import Licenses from './Licenses/Licenses'
import Certificates from './Certificates/Certificates'
import SizeInformation from './SizeInformation/SizeInformation'
import Reviews from './Reviews/Reviews'
import VideoResume from './VideoResume/VideoResume'
import { getUserInfoForCompany } from '../../api/getApi'
import UserCardHelper from '../../helpers/userCard.helper'
import { useNotify } from '../../hooks/useNotify'
import { isEmpty } from 'lodash'
import { UserCardPreloader } from './UserCardPreloader/UserCardPreloader'
import Colleagues from './Colleagues/Colleagues'
import { batch, useDispatch, useSelector } from 'react-redux'
import PopupDeletedUser from './PopupDeletedUser/PopupDeletedUser'
import { useHistory, useLocation } from 'react-router-dom'
import { parseSeafarersSearchLink } from '../SearchTabsPanel/utils'
import { deleteTab, removeSeafarerFromList } from '../../redux/actions/seafarersSearch'
import { SwiperNavButtons } from '../SwiperNavButtons/SwiperNavButtons'
import { isServiceAvailable } from '../../helpers/helpers'
import { billingPermission } from '../../constants/constants'

const navigation = [
  { title: 'Contact Info', component: ContactInfo, preloader: UserCardPreloader },
  { title: 'Education', component: Education, preloader: UserCardPreloader },
  { title: 'Work Experience', component: Experience, preloader: UserCardPreloader, column: true },
  { title: 'Passports', component: Passports, preloader: UserCardPreloader },
  { title: 'Visas', component: Visas, preloader: UserCardPreloader },
  { title: 'Licenses', component: Licenses, preloader: UserCardPreloader, column: true },
  { title: 'Certificates', component: Certificates, preloader: UserCardPreloader },
  { title: 'Size information', component: SizeInformation, preloader: UserCardPreloader, items: [1] },
  { title: 'Reviews', component: Reviews, preloader: UserCardPreloader },
  { title: 'VideoResume', component: VideoResume },
  { title: 'Colleagues', component: Colleagues },
]

const accountDeletionMessage = 'Wrong seafarer requested!'

export const UserCard = ({ data, controls = null, loadingGeneral, userId }) => {
  const [loading, setLoading] = useState(true)
  const [dataComponent, setDataComponent] = useState(null)
  const [currentTab, setCurrentTab] = useState('Contact Info')

  const { is_open_comments } = useSelector(({ seafarersSearch }) => seafarersSearch.comments)
  const { idDeletedUsers } = useSelector(({ seafarersSearch }) => seafarersSearch)

  const { notify } = useNotify()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const isResumeSearchAccess = isServiceAvailable(billingPermission.resume_search_access)

  const Component = navigation.find((r) => r.title === currentTab)
  const activeTab = parseSeafarersSearchLink(location)

  useEffect(() => {
    setLoading(true)

    const getUserInfo = async () => {
      const section = UserCardHelper.transformTitleToQueryParameter(currentTab)
      const middleFuncName = currentTab.split(' ').join('')

      try {
        const response = await getUserInfoForCompany(userId, section)

        setLoading(false)

        setDataComponent((prev) => {
          return {
            ...prev,
            [section]: UserCardHelper[`get${middleFuncName}Transform`](response[section]),
          }
        })
      } catch (error) {
        if (!isResumeSearchAccess) {
          history.replace(`/profile/seafarers/search`)
          dispatch(deleteTab(userId))
        }
        if (error && error.errors && error.errors[0] && error.errors[0].message === accountDeletionMessage) {
          history.replace(`/profile/seafarers/${activeTab.category}`)
        }

        setLoading(false)
        notify.errorsList(error.errors)
      }
    }

    getUserInfo()

    //eslint-disable-next-line
  }, [currentTab, userId])

  const handleClosePopup = () => {
    history.replace(`/profile/seafarers/${activeTab.category}`)

    batch(() => {
      dispatch(deleteTab(Number(userId)))
      dispatch(removeSeafarerFromList(Number(userId)))
    })
  }

  return (
    <div
      className={cn(
        styles.card,
        { [styles.isHideCard]: is_open_comments },
        { [styles.inactive_card]: idDeletedUsers.includes(Number(userId)) }
      )}
    >
      <CardHeader generalUserCardInfo={data.general} loading={loadingGeneral} controls={controls} />

      <div className={styles.nav}>
        <Swiper
          spaceBetween={48}
          slidesPerView={9}
          grabCursor={true}
          modules={[Mousewheel, Navigation]}
          className={'user_card_tabs_swiper'}
          mousewheel={{
            invert: false,
            sensitivity: 1,
          }}
        >
          {navigation.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                onClick={() => setCurrentTab(item.title)}
                className={cn(styles.navItem, {
                  [styles.active]: item.title === currentTab,
                })}
              >
                {item.title}
              </div>
            </SwiperSlide>
          ))}
          <SwiperNavButtons />
        </Swiper>
      </div>

      <div className={styles.content}>
        {!isEmpty(dataComponent) && (
          <WithPreloader
            size={50}
            isActive={loading}
            userCardData={dataComponent}
            component={Component.component}
            preloader={Component.preloader}
            column={Component.column}
            items={Component.items}
            userName={data?.general?.mainInfo[0].value}
          />
        )}
      </div>

      {idDeletedUsers.includes(Number(userId)) && <PopupDeletedUser onClose={handleClosePopup} />}
    </div>
  )
}

export default UserCard
