import React, { useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import PropTypes from 'prop-types'
import editSvg from '../../assets/icons/edit.svg'
import CrossIcon from '../../UI/Icons/CrossIcon'
import { TrashIcon } from '../../UI/Icons/Icons'
import Switcher from '../../UI/Switcher/Switcher'
import resetPassSvg from '../../assets/icons/reset-pass.svg'
import { userResetPassword } from '../../api/postApi'
import ReactTooltip from 'react-tooltip'
import { useNotify } from '../../hooks/useNotify'
import { useSelector } from 'react-redux'
import useCanEdit from '../../hooks/useCanEdit'

const ItemsGroupUser = ({ data, onDelete, onEdit, isActive, isAnim, setMain }) => {
  const [loading, setLoading] = useState(false)

  const { profile } = useSelector(({ user }) => user)

  const { notify } = useNotify()

  const isCanEdit = useCanEdit()

  const handleResetClick = async () => {
    setLoading(true)

    try {
      await userResetPassword(data.id)

      notify('Success', `Password change letter was sent to ${data.email}`)
    } catch (error) {
      setLoading(false)

      notify.errorsList(error.errors)
    }
  }

  return (
    <div
      className={cn(styles.itemWrapper, {
        [styles.active]: isActive,
        [styles.moveAnimation]: isAnim,
        [styles.item_inactive]: !isCanEdit,
      })}
    >
      <div className={styles.item}>
        <div className={styles.itemTitle}>
          <span>{data.user_type}</span>

          <button
            className={cn(styles.resetButton, { [styles.resetButton__inactive]: loading })}
            onClick={handleResetClick}
          >
            <img src={resetPassSvg} alt="reset password icon" />

            <span> Reset the password </span>
          </button>

          <div className={cn(styles.itemLabel, { [styles.disabled]: profile?.email === data.email })}>
            <p>User enable</p>
            <Switcher
              onClick={setMain}
              id={data.id}
              isActive={data.is_active}
              disabled={profile?.email === data.email}
            />
          </div>
        </div>
        <div className={styles.itemContentRow}>
          <img src={data.picture} height={30} alt="user avatar" />
          <div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>Full name:</div>
              <b className={styles.row_content}>{data.full_name}</b>
            </div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>Position:</div>
              <b className={styles.row_content}>{data.position}</b>
            </div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>Email:</div>
              <b className={styles.row_content}>{data.email}</b>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.controls}>
        <button
          className={styles.edit}
          onClick={() => onEdit(data.id, isActive)}
          data-tip={isActive ? 'cancel' : 'edit'}
          data-for="default"
        >
          {isActive ? (
            <CrossIcon width={14} height={14} strokeWidth={1} stroke="#43927C" />
          ) : (
            <img src={editSvg} alt="edit" />
          )}
        </button>
        {data.user_type !== 'owner' && (
          <button className={styles.delete} onClick={() => onDelete(data.id)} data-tip={'delete'} data-for="default">
            <TrashIcon width={15.42} height={18.74} strokeWidth={1} stroke="#C20000" />
          </button>
        )}
      </div>
      <ReactTooltip backgroundColor="#43927C" id="default" />
    </div>
  )
}

ItemsGroupUser.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  isActive: PropTypes.bool,
  isAnim: PropTypes.bool,
}

export default ItemsGroupUser
