import React from 'react'
import StarRating from '../../../../StarRating/StarRating'
import styles from '../../../userCard.module.scss'

const ReviewQuestion = ({ reviewQuestion }) => {
  return (
    <div className={styles.question}>
      <h4 className={styles.questionTitle}>{reviewQuestion.question}</h4>

      <div className={styles.questionRating}>
        <StarRating rating={reviewQuestion.answer} />
      </div>
    </div>
  )
}

export default ReviewQuestion
