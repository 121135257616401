import React from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

const Controls = ({ picture, loading, handleDelete }) => {
  return (
    <div className={styles.controls}>
      <label
        className={cn(styles.chooseLogoControl, {
          [styles.disable]: loading,
        })}
        htmlFor="avatar-editor"
      >
        {picture.file_name === 'empty-profile-photo.png' ? 'Choose photo' : 'Change photo'}
      </label>

      {picture.file_name !== 'empty-profile-photo.png' && (
        <label
          className={cn(styles.deleteLogoControl, {
            [styles.disable]: loading,
          })}
          onClick={handleDelete}
        >
          Delete
        </label>
      )}
    </div>
  )
}

export default Controls
