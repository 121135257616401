import { REVIEWS } from '../constants'
const initialState = {
  reviews: [
    {
      name: 'Martino',
      rank: 'Capitan',
      vesselName: 'Vessel Name Example',
      date: '08/2018-18/2018',
      questions: [
        {
          question: 'Seafarer`s performance/hard skills onboard on previous contracts',
          answer_rate: 5,
        },
        {
          question: 'Soft skills, cooperation and flexibility in a team on previous contract',
          answer_rate: 5,
        },
      ],
      text:
        'But I must explain to you how all this mistaken idea of denouncing of a pleasure and praising pain was born and I will give you a complete ',
    },
  ],
}

export default function reviewsReducer(state = initialState, action) {
  switch (action.type) {
    case REVIEWS.SET_REVIEWS:
      return {
        ...state.reviews,
        reviews: [...state.reviews, ...action.payload],
      }
    case REVIEWS.CLEAR_REVIEWS:
      return {
        reviews: [],
      }
    default:
      return state
  }
}
