import React, { memo } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import noAvatar from '../../../../assets/images/no-avatar.svg'

import Image from '../../../../UI/Image/Image'
import OutgoingControls from '../../../MessageControls/OutgoingControls'
import CompanionControls from '../../../MessageControls/CompanionControls'
import ReplyMessage from '../../../ReplyMessage/ReplyMessage'

import { messageDefinition } from '../../../../helpers/support.helper'
import { dateToTwelveHourFormat } from '../../../../helpers/time-helper/time-helper'
import { SENDER_TYPE_COMPANY } from '../../constants'

export const Message = memo(
  ({
    message,
    avatar,
    userName,
    isOutgoing,
    date,
    onDelete,
    id,
    onEdit,
    replyMessage,
    editMessage,
    onReply,
    senderType,
    onReplyClick,
    searchMessageId,
    refs,
  }) => {
    const onClick = (id) => {
      if (!id) return

      if (id === replyMessage?.id) {
        onReplyClick(id)
      }
    }

    const name = userName?.toLowerCase()
    const userAvatar = avatar.includes('empty-profile-photo') ? noAvatar : avatar

    return (
      <div
        className={cn(styles.message, {
          [styles.message__outgoing]: isOutgoing || SENDER_TYPE_COMPANY === senderType,
        })}
      >
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Image className={styles.avatar} src={userAvatar} alt="user avatar" />

              <p className={styles.name}>{name}</p>
            </div>

            <div
              ref={refs}
              className={cn(styles.messageText, { [styles.messageText__active]: searchMessageId === id })}
              onClick={() => onClick(replyMessage?.id)}
            >
              {replyMessage && <ReplyMessage message={replyMessage} isSupport isOutgoing={isOutgoing} />}

              {messageDefinition(message, styles)}
            </div>
          </div>

          <div className={styles.footer}>
            {editMessage && <span className={styles.edited}>edited</span>}

            <time>{dateToTwelveHourFormat(date)}</time>
          </div>

          {senderType === SENDER_TYPE_COMPANY && (
            <OutgoingControls
              id={id}
              onReply={onReply}
              onEdit={onEdit}
              onDelete={onDelete}
              className={styles.controls}
            />
          )}

          {!isOutgoing && senderType !== SENDER_TYPE_COMPANY && (
            <CompanionControls id={id} onReply={onReply} className={styles.companionControls} />
          )}
        </div>
      </div>
    )
  }
)
