import React from 'react'
import DateExpiredChecker from '../UI/DateExpiredChecker/DateExpiredChecker'
import { getPeriodInYears } from './time-helper/time-helper'

class UserCardHelper {
  getFullName = (object) => {
    let fullName = '—'

    if (object.first_name && object.last_name) {
      if (object.middle_name) {
        fullName = `${object.first_name} ${object.middle_name} ${object.last_name}`
      } else {
        fullName = object.first_name + ' ' + object.last_name
      }
    }

    return fullName
  }
  getPlaceOfBirth = (general) => {
    if (general.city_of_birth && general.country_of_birth) {
      return `${general.city_of_birth}, ${general.country_of_birth}`
    } else if (general.country_of_birth) {
      return `${general.country_of_birth}`
    } else {
      return '—'
    }
  }
  getMinWage = (salary, symbol, rate) => {
    return salary ? `${symbol} ${salary}/${rate}` : '—'
  }
  getRanks = (ranks) => {
    return ranks.filter((el) => !!el).join(', ') || '—'
  }
  getDateOfAvailable = (from, status) => {
    return from ? `${from} ${status}` !== 'ready' && `${from} (${status})` : '—'
  }
  transformMainInfo = (mainInfo) => {
    const titles = [
      'Full name',
      'Date of birth',
      'Place of birth',
      'Rank',
      'Date available',
      'Marital status',
      'Address residence',
      'Address',
      'Citizenship',
      'English level',
      'Min wage',
      'Sex',
    ]

    const values = Object.values(mainInfo)

    return titles.map((title, index) => {
      return { title, value: values[index] }
    })
  }
  capitalizeFirstLetter = (string) => {
    if (!!string && string.length) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return '—'
    }
  }
  transformSocialMediaContacts = (socialMediaContacts) => {
    if (socialMediaContacts.length) {
      const transformedSocialMediaContacts = socialMediaContacts.map((socialMediaContact) => {
        const name = socialMediaContact.name
        const links = socialMediaContact.contacts

        return links.map((link) => {
          let linkValue = ''
          let isMain = false

          if (link.contact.indexOf('https://') === -1 && name === 'instagram') {
            linkValue = `${'https://'}instagram.com/${link.contact}/`
            isMain = link.is_main
          } else if (link.contact.indexOf('https://') === -1 && name === 'facebook') {
            linkValue = `${'https://'}facebook.com/${link.contact}/`
            isMain = link.is_main
          } else if (link.contact.indexOf('skype:') === -1 && name === 'skype') {
            linkValue = `${'skype:'}${link.contact}?call/`
            isMain = link.is_main
          } else if (link.contact.indexOf('https://') === -1 && name === 'linkedin') {
            linkValue = `${'https://'}linkedin.com/${link.contact}/`
            isMain = link.is_main
          } else {
            linkValue = link.contact
            isMain = link.is_main
          }

          return { title: name, value: linkValue, is_main: isMain }
        })
      })

      let links = []

      transformedSocialMediaContacts.forEach((socialMediaContacts) => {
        links = [...links, ...socialMediaContacts]
      })

      return links
    } else {
      return []
    }
  }
  getUserCard = (general) => {
    const {
      date_of_birth,
      rank,
      rank_secondary,
      available_from,
      availability_status,
      marital_status,
      address_residence,
      address,
      nationality,
      english_level,
      salary,
      currency_symbol,
      salary_rate,
      gender,
      photo,
      geolocation,
      address_apartment,
      address_zip,
      address_residence_apartment,
      address_residence_zip,
      personal_status,
      enhanced_visibility,
    } = general

    const mainInfo = {
      fullName: this.getFullName(general),
      dateOfBirth: date_of_birth || '—',
      placeOfBirth: this.getPlaceOfBirth(general),
      ranks: this.getRanks([rank, rank_secondary]),
      dateOfAvailable: this.getDateOfAvailable(available_from, availability_status),
      maritalStatus: this.capitalizeFirstLetter(marital_status),
      residenceAddress: address_residence
        ? `${address_residence}, apt: ${address_residence_apartment || '—'}, zip: ${address_residence_zip || '—'}`
        : '—',
      registrationAddress: address ? `${address}, apt: ${address_apartment || '—'}, zip: ${address_zip || '—'}` : '—',
      citizenship: nationality || '—',
      englishLevel: english_level || '—',
      minWage: this.getMinWage(salary, currency_symbol, salary_rate),
      sex: gender || '—',
    }

    return {
      general: {
        avatar: photo,
        mainInfo: this.transformMainInfo(mainInfo),
        locationNow: geolocation?.city?.name,
        personal_status,
        enhanced_visibility,
      },
    }
  }
  transformRelativeContactValues = (relativeContact) => {
    const fullName = this.getFullName(relativeContact)

    const address = `${relativeContact.country}
            ${
              relativeContact.city
                ? `${relativeContact.city}, ${relativeContact.address}, apt: ${
                    relativeContact.apartment || '—'
                  }, zip: ${relativeContact.zip || '—'}`
                : '—'
            }`

    let phones = []

    relativeContact.contacts.forEach((contact) => {
      phones = [...phones, contact.contact]
    })

    const relativeType = this.capitalizeFirstLetter(relativeContact.type?.name)

    return {
      nextOfKin: relativeContact.next_of_kin,
      type: relativeType,
      values: [
        { title: 'Full name', value: fullName },
        {
          title: 'Res. Country',
          value: address,
        },
        { title: 'Phones', value: phones.join(', ') },
      ],
    }
  }
  transformRelativeContacts = (relativeContacts) => {
    if (relativeContacts.length) {
      return relativeContacts.map((relativeContact) => {
        return this.transformRelativeContactValues(relativeContact)
      })
    } else {
      return []
    }
  }
  transformTitleToQueryParameter = (title) => {
    if (title === 'Size information') title = 'anthropometry'
    if (title === 'VideoResume') title = 'video_resume'
    const titleArr = title
      .split(' ')
      .map((item) => item.toLowerCase())
      .join('_')
    switch (titleArr) {
      case 'size_information':
        return 'anthropometry'
      case 'work_experience':
        return 'experience_all'
      default:
        return titleArr
    }
  }
  transformEducation = (seafarerEducation) => {
    if (seafarerEducation.length) {
      return seafarerEducation.map((education) => {
        const transformedEducation = {
          city: education.city,
          degree: education.degree,
          fieldOfStudy: education.field_of_study || '—',
          additionalInfo: education.description || '—',
          period: getPeriodInYears(education.date_from, education.date_to),
        }

        const titles = ['City', 'Education degree', 'Field of study', 'Additional info', 'Period']

        const values = Object.values(transformedEducation)

        return {
          collegeName: education.college,
          files: education.files,
          values: titles.map((title, index) => {
            return { title, value: values[index] }
          }),
        }
      })
    } else {
      return []
    }
  }
  transformMainContacts = (data) => {
    const { phone, email } = data
    const stack = []

    phone.forEach((item) => stack.push({ ...item, title: 'Phone' }))
    email.forEach((item) => stack.push({ ...item, title: 'Email' }))

    return stack.map(({ messangers, contact, ...item }) => ({
      ...item,
      value: contact,
      messengers: messangers,
    }))
  }
  transformExperience = (seafarerExperience) => {
    if (seafarerExperience.length) {
      return seafarerExperience.map((experience) => {
        const expType = experience.experience_type
        const transformExperience =
          expType === 'sea'
            ? {
                vessel_photo: experience.vessel_photo || null,
                rank: experience.rank,
                crewing: experience.crewing || '—',
                wage:
                  experience['salary'] && experience['currency_symbol']
                    ? `${experience['salary']} ${experience['currency_symbol']}`
                    : '—',
                IMO: String(experience.imo || '—'),
                DWT: String(experience.dwt || '—'),
                flag: experience['flag'] || '—',
                vesselType: experience.vessel_type || '—',
                GRT: String(experience.grt || '—'),
                EOC: experience['eoc'] || '—',
                engine: experience.engine_type || '—',
                TEU: String(experience.teu || '—'),
                info: experience['description'] || '—',
                shipowner: experience.shipowner || '—',
                yearBuild: String(experience['delivered'] || '—'),
                empty: '',
                shipownerCountry: experience.country || '—',
                power: experience.hp ? `${experience.hp}HP/${experience.kw}KW` : '—',
              }
            : {
                vessel_photo: experience.vessel_photo || null,
                rank: experience.rank,
                city: experience.city || '—',
                crewing: experience.crewing || '—',
                country: experience.country || '—',
                wage:
                  experience['salary'] && experience['currency_symbol']
                    ? `${experience['salary']} ${experience['currency_symbol']}`
                    : '—',
                EOC: experience['eoc'] || '—',
                info: experience['description'] || '—',
              }

        const period = `${experience.date_from}-${experience.date_to ? experience.date_to : 'current'}`

        const values = Object.values(transformExperience)

        const titles =
          expType === 'sea'
            ? [
                'Photo',
                'Rank',
                'Ship/Crew Manager',
                'Wage',
                'IMO',
                'DWT',
                'Flag',
                'Vessel type',
                'GRT',
                'EOC',
                'Engine type',
                'TEU',
                'Info',
                'Shipowner',
                'Year build',
                '',
                'Ship.country',
                'Power',
              ]
            : ['Photo', 'Rank', 'City', 'Ship/Crew Manager', 'Country', 'Wage', 'EOC', 'Info']

        return {
          experience_type: expType,
          vesselName: expType === 'sea' ? experience.vessel_name : experience.company || '—',
          period: period,
          files: experience.files,
          values: titles.map((title, index) => {
            return {
              title,
              value: values[index],
            }
          }),
        }
      })
    } else {
      return []
    }
  }
  transformPassports = (seafarerPassports) => {
    if (seafarerPassports.length) {
      return seafarerPassports.map((passport) => {
        const transformPassport = {
          number: passport.number,
          issuedBy: passport.issued_by || passport.city || passport.country || '—',
          period: passport.date_of_issue ? (
            <>
              {passport.date_of_issue}-<DateExpiredChecker>{passport.date_of_expire}</DateExpiredChecker>
            </>
          ) : (
            '—'
          ),
        }

        const titles = ['Number', 'Issued', 'Period']

        const values = Object.values(transformPassport)

        return {
          name: passport.passport_type
            ? `${passport.country} ${passport.passport_type.toLowerCase()}`
            : `${passport.country}`,
          files: passport.files,
          values: titles.map((title, index) => {
            return { title, value: values[index] }
          }),
        }
      })
    } else {
      return []
    }
  }
  transformVisas = (seafarerVisas) => {
    if (seafarerVisas.length) {
      return seafarerVisas.map((visa) => {
        const transformVisa = {
          number: visa.number || '—',
          issuedBy: visa.city || visa.country || '—',
          period: visa.date_of_issue ? (
            <>
              {visa.date_of_issue}-<DateExpiredChecker>{visa.date_of_expire}</DateExpiredChecker>
            </>
          ) : (
            'No info'
          ),
        }

        const titles = ['Number', 'Issued', 'Period']

        const values = Object.values(transformVisa)

        return {
          name: visa.country
            ? `${visa.country} ${visa.visa_type.toLowerCase()}`
            : `${this.capitalizeFirstLetter(visa.visa_type)}`,
          files: visa.files,
          values: titles.map((title, index) => {
            return { title, value: values[index] }
          }),
        }
      })
    } else {
      return []
    }
  }
  transformLicenses = (seafarerLicenses) => {
    if (seafarerLicenses.length) {
      return seafarerLicenses.map((license) => {
        const { endorsement } = license

        const transformLicense = {
          number: license.number,
          city: license.city || license.country || '—',
          period: license.date_of_issue ? (
            <>
              {license.date_of_issue}-<DateExpiredChecker>{license.date_of_expire}</DateExpiredChecker>
            </>
          ) : (
            'No info'
          ),
        }

        const transformEndorsement = {
          number: endorsement?.number || '—',
          city: endorsement?.city || endorsement?.country || '—',
          period: endorsement?.date_of_issue ? (
            <>
              {endorsement.date_of_issue}-<DateExpiredChecker>{endorsement.date_of_expire}</DateExpiredChecker>
            </>
          ) : (
            'No info'
          ),
        }

        const titles = ['Number', 'Issued', 'Period']

        const licenseValues = Object.values(transformLicense)
        const endorsementValues = Object.values(transformEndorsement)

        return {
          name: `${license.country} ${
            license.license_type === 'CoC' ? license.license_type : license.license_type.toLowerCase()
          }`,
          licenseFiles: license.files,
          endorsementFiles: endorsement?.files ? endorsement.files : [],
          values: [
            ...titles.map((title, index) => {
              return { title, value: licenseValues[index] }
            }),
            ...titles.map((title, index) => {
              return { title, value: endorsementValues[index] }
            }),
          ],
        }
      })
    } else {
      return []
    }
  }
  transformCertificates = (seafarerCertificates) => {
    if (seafarerCertificates.length) {
      return seafarerCertificates.map((certificate) => {
        const transformCertificate = {
          number: certificate.number || '—',
          issuedBy: certificate.city || certificate.country || '—',
          period: certificate.date_of_issue ? (
            <>
              {certificate.date_of_issue}-<DateExpiredChecker>{certificate.date_of_expire}</DateExpiredChecker>
            </>
          ) : (
            'No info'
          ),
        }

        const titles = ['Number', 'Issued', 'Period']

        const values = Object.values(transformCertificate)

        return {
          name: `${certificate.country} ${certificate.name.toLowerCase()}`,
          files: certificate.files,
          values: titles.map((title, index) => {
            return { title, value: values[index] }
          }),
        }
      })
    } else {
      return []
    }
  }
  transformSizeInformation = (seafarerSizeInformation) => {
    const transformedSizeInformation = {
      hairColor: seafarerSizeInformation?.hair_color || '—',
      height: seafarerSizeInformation?.height || '—',
      footSize: seafarerSizeInformation?.foot_size || '—',
      collar: seafarerSizeInformation?.collar || '—',
      eyeColor: seafarerSizeInformation?.eye_color || '—',
      weight: seafarerSizeInformation?.weight || '—',
      suitSize: seafarerSizeInformation?.suit_size || '—',
      waist: seafarerSizeInformation?.waist || '—',
    }

    const titles = ['Hair color', 'Height', 'Shoes size', 'Collar', 'Eye color', 'Weight', 'Suit size', 'Waist']

    const values = Object.values(transformedSizeInformation)

    return {
      title: 'Size information',
      values: titles.map((title, index) => {
        return { title, value: values[index] }
      }),
    }
  }
  reviewsHelper = (reviews) => {
    if (reviews.length) {
      return reviews.map((review) => {
        return {
          id: review.review_id,
          seafarerName: review.name,
          rank: review.rank,
          vesselName: review.vessel_name,
          date: review.date,
          questions: review.questions,
          description: review.description,
        }
      })
    } else {
      return []
    }
  }
  videoResumeHelper = (videoResume) => {
    if (videoResume) {
      return videoResume
    } else {
      return null
    }
  }
  transformColleagues = (colleagues) => {
    return colleagues
  }

  getContactInfoTransform = (data) => {
    return {
      mainContacts: this.transformMainContacts(data),
      socialMediaContacts: this.transformSocialMediaContacts(data.social),
      relativeContacts: this.transformRelativeContacts(data.relatives),
    }
  }
  getEducationTransform = (data) => this.transformEducation(data)
  getWorkExperienceTransform = (data) => this.transformExperience(data)
  getPassportsTransform = (data) => this.transformPassports(data)
  getVisasTransform = (data) => this.transformVisas(data)
  getLicensesTransform = (data) => this.transformLicenses(data)
  getCertificatesTransform = (data) => this.transformCertificates(data)
  getSizeinformationTransform = (data) => this.transformSizeInformation(data)
  getReviewsTransform = (data) => this.reviewsHelper(data)
  getVideoResumeTransform = (data) => this.videoResumeHelper(data)
  getColleaguesTransform = (data) => this.transformColleagues(data)
}

export default new UserCardHelper()
