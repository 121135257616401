import React, { useState, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.module.scss'
import SelectItem from './SelectItem'
import BottomArrowSvg from '../Icons/BottomArrowSvg'
import Input from '../Input/Input'
import useClickOutside from '../../hooks/useClickOutside'
import { createItemRef } from '../../helpers/createItemRef'
import useHeightWithScroll from '../../hooks/useHeightWithScroll'

const VesselSelect = memo(
  ({
    name,
    value,
    getValueSearch,
    id,
    label,
    selectedItem,
    defaultName,
    items,
    onClick,
    onScroll,
    isInvalid,
    required,
    errorMessage,
    disabled,
  }) => {
    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const [inputValue, setInputValue] = useState(value)
    const [activeIndex, setActiveIndex] = useState(-1)
    const [isFocused, setIsFocused] = useState(false)

    const selectEl = useClickOutside(() => setIsSelectOpen(false))

    const isOnTop = selectEl.current && window.innerHeight - selectEl.current.getBoundingClientRect().bottom < 250
    const newItems = defaultName ? [{ id: null, name: defaultName }, ...items] : items

    const refs = createItemRef(newItems)
    const { divRef: ulRef } = useHeightWithScroll({ state: isSelectOpen })

    // useEffect(() => {
    //   if (!isOpen) {
    //     setInputValue('')
    //   }
    // }, [isOpen])

    useEffect(() => {
      if (isSelectOpen && activeIndex !== -1) {
        const childElement = refs[newItems[activeIndex]?.id]?.current
        const parentElement = ulRef.current

        if (childElement) {
          const childRect = childElement.getBoundingClientRect()
          const parentRect = parentElement.getBoundingClientRect()

          if (childRect.bottom > parentRect.bottom) {
            parentElement.scrollTop += childRect.bottom - parentRect.bottom
          } else if (childRect.top < parentRect.top) {
            parentElement.scrollTop -= parentRect.top - childRect.top
          }
        }
      }
    }, [isSelectOpen, activeIndex, newItems, refs, ulRef])

    const handleItemClick = (id, _) => {
      setIsSelectOpen(false)

      const rest = items.find((item) => item.id === Number(id))

      if (id === null) {
        onClick(name, { id, name: '' })

        return
      }

      onClick(name, { ...rest })
    }

    const handleFocus = () => {
      setIsFocused(true)
    }

    const handleBlur = () => {
      setIsFocused(false)
    }

    const handleKeyDown = (e) => {
      if (!isSelectOpen && e.key === 'Enter' && isFocused) {
        setIsSelectOpen(true)

        return
      }

      if (e.key === 'ArrowUp' && activeIndex > 0) {
        e.preventDefault()

        setActiveIndex(activeIndex - 1)

        return
      }

      if (e.key === 'ArrowDown' && activeIndex < newItems.length - 1) {
        e.preventDefault()

        setActiveIndex(activeIndex + 1)

        return
      }

      if (e.key === 'Enter' && activeIndex !== -1) {
        const id = newItems[activeIndex].id
        const name = newItems[activeIndex].name

        handleItemClick(id, name)
      }
    }

    const handleInputChange = ({ target }) => {
      setInputValue(() => target.value)

      getValueSearch(target.value)
    }

    return (
      <>
        {label && (
          <label
            className={cn(styles.label, {
              [styles.label_disabled]: disabled,
            })}
          >
            {label} {required && <span className="required-star">*</span>}
          </label>
        )}

        <div ref={selectEl} className={cn(styles.wrapper, { [styles.no_event]: disabled })}>
          <div
            tabIndex="0"
            className={cn(styles.header, 'select-search', {
              [styles.header_invalid]: isInvalid,
              [styles.header_disabled]: disabled,
            })}
            onClick={() => setIsSelectOpen(!isSelectOpen)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          >
            <div
              className={cn(styles.title, {
                [styles.title_default]: id === null,
              })}
            >
              {selectedItem?.name || label}
            </div>
          </div>

          {isInvalid && errorMessage && <div className={styles.selectErrorMessage}>{errorMessage}</div>}

          {isSelectOpen && (
            <div
              className={cn(styles.search_menu, { [styles.list_top]: isOnTop })}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
            >
              <Input
                placeholder="Search"
                value={inputValue}
                onChange={handleInputChange}
                clear={handleInputChange}
                className={styles.search}
                focus
              />

              <ul ref={ulRef} className={styles.list} onScroll={onScroll}>
                {newItems.map(({ name: item, id }, index) => {
                  const isActive = index === activeIndex || id === Number(selectedItem.id)

                  return (
                    <SelectItem
                      refs={refs[id]}
                      tabIndex="0"
                      key={id}
                      name={item}
                      id={id}
                      isActive={isActive}
                      onClick={handleItemClick}
                    />
                  )
                })}
              </ul>
            </div>
          )}

          <div
            onClick={() => setIsSelectOpen(!isSelectOpen)}
            className={cn(styles.icon, {
              [styles.icon_active]: isSelectOpen,
            })}
          >
            <BottomArrowSvg width={14} height={7} fill="var(--color-accent-light)" />
          </div>
        </div>
      </>
    )
  }
)

VesselSelect.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  selectedItem: PropTypes.object.isRequired,
  defaultName: PropTypes.string,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  isValid: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
}

VesselSelect.defaultProps = {
  defaultName: '–',
  items: [],
  className: '',
  isInvalid: false,
  required: false,
  errorMessage: '',
  disabled: false,
}

export default VesselSelect
