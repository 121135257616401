import React from 'react'
import cn from 'classnames'
import styles from '../styles.module.scss'
import { dateToTwelveHourFormat } from '../../../helpers/time-helper/time-helper'
import { messageDefinition } from '../../../helpers/support.helper'
import { getFirstName } from '../helpers'
import ReplyMessage from '../../ReplyMessage/ReplyMessage'
import CompanionControls from '../../MessageControls/CompanionControls'
import OutgoingControls from '../../MessageControls/OutgoingControls'

const SupportMessage = ({
  id,
  is_outgoing,
  message,
  date,
  user,
  replyMessage,
  editMessage,
  onReply,
  onEdit,
  onDelete,
  onReplyClick,
  searchMessageId,
  refs,
}) => {
  const onClick = (id) => {
    if (!id) return

    if (id === replyMessage?.id) {
      onReplyClick(id)
    }
  }

  return (
    <div
      className={cn(styles.message, {
        [styles.outgoing]: is_outgoing,
      })}
    >
      <div className={styles.message_body}>
        <div
          className={cn(styles.chatName, {
            [styles.outgoing]: is_outgoing,
          })}
        >
          {getFirstName(user)}
        </div>

        <div
          ref={refs}
          className={cn(styles.message_text, { [styles.message_text__active]: searchMessageId === id })}
          onClick={() => onClick(replyMessage?.id)}
        >
          {replyMessage && <ReplyMessage message={replyMessage} isSupport isOutgoing={is_outgoing} />}

          {messageDefinition(message, styles)}
        </div>

        <div className={styles.message_footer}>
          {editMessage && <span className={styles.edited}>edited</span>}

          <span className={styles.message_date}>
            <time>{dateToTwelveHourFormat(date)}</time>
          </span>
        </div>

        {is_outgoing && (
          <OutgoingControls id={id} onReply={onReply} onEdit={onEdit} onDelete={onDelete} className={styles.controls} />
        )}

        {!is_outgoing && <CompanionControls id={id} onReply={onReply} className={styles.companion_controls} />}
      </div>
    </div>
  )
}

export default SupportMessage
