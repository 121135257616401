import styles from '../CompanyDataPage.module.scss'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import CompanyLogoEditor from '../../../components/AvatarEditor/CompanyLogoEditor'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from '../config'
import { sendToModeration } from '../../../api/putApi'
import { setUserInfo } from '../../../redux/actions/user.action'
import { getCompanyProfile, updateCompanyInfo } from '../../../redux/actions/company.action'
import { batch, useDispatch } from 'react-redux'
import { setModerUI, setProfileTitle } from '../../../redux/actions/ui.action'
import { getCompaniesUI, getCompanyInfo } from '../../../api/getApi'
import { useNotify } from '../../../hooks/useNotify'

const GeneralForm = ({ isCanEdit, companyData }) => {
  const { notify } = useNotify()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [showCropper, setShowCropper] = React.useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isButtonVisible, setIsButtonVisible] = useState(Boolean(!companyData.is_active && !companyData.on_moderation))

  const isInputsDisabled = companyData.is_active || companyData.on_moderation || loading || !isCanEdit

  useEffect(() => {
    setLoading(true)
    batch(() => {
      dispatch(setProfileTitle('CrewManager data'))
      dispatch(getCompanyProfile())
    })
    getUi()
    getInfo()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setIsButtonDisabled(companyData.is_active || companyData.on_moderation)
    setIsButtonVisible(Boolean(!companyData.is_active && !companyData.on_moderation))

    //eslint-disable-next-line
  }, [companyData])
  useEffect(() => {
    showCropper ? setIsButtonDisabled(true) : setIsButtonDisabled(false)

    //eslint-disable-next-line
  }, [showCropper])

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      setShowCropper(false)
      setIsButtonDisabled(true)
      Promise.all([
        sendToModeration({
          name: values.name,
          phone: values.phone,
          person_in_charge: values.person_in_charge,
        }),
      ])
        .then((data) => {
          const { is_active, picture, last_login } = data[1]
          dispatch(setUserInfo(data[1]))
          dispatch(updateCompanyInfo({ is_active, last_login, picture }))

          setIsButtonDisabled(false)
          notify('Success', 'Your changes were successfully saved.')
        })
        .catch((error) => {
          setIsButtonDisabled(false)
          notify.errorsList(error.errors)
        })
    },
  })

  const { values, errors, touched, setValues, setFieldTouched, handleChange, handleSubmit } = formik

  const getUi = async () => {
    try {
      const response = await getCompaniesUI()

      dispatch(setModerUI(response.show_is_active))
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }
  }
  const getInfo = async () => {
    try {
      const response = await getCompanyInfo()

      await setValues({
        ...values,
        ...response,
        company_logo: response.picture,
      })
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeFormikValue = (value) => {
    if (!touched[value.target.name]) {
      setFieldTouched(value.target.name, true)
    }

    handleChange(value)
  }

  return (
    <div className={cn(styles.company_general, 'profile-content', 'space-between')}>
      <div className={styles.company_general_logo__editor}>
        <CompanyLogoEditor formik={formik} showCropper={showCropper} setShowCropper={setShowCropper} />
      </div>
      <form className={styles.company_general_form} onSubmit={handleSubmit}>
        <div className="profile-form-main">
          <div className="profile-form-fields">
            <div className="profile-form-column">
              <div className="field-item">
                <Input
                  className={styles.company_data_input}
                  name="email"
                  label="Email"
                  placeholder="Email"
                  role="presentation"
                  autoComplete="off"
                  value={values.email}
                  onChange={handleChangeFormikValue}
                  isInvalid={Boolean(errors.email && touched.email)}
                  errorMessage={errors.email}
                  required
                  disabled
                />
              </div>

              <div className="field-item">
                <Input
                  className={styles.company_data_input}
                  name="name"
                  label="Company Name"
                  placeholder="Company Name"
                  role="presentation"
                  autoComplete="off"
                  value={values.name}
                  onChange={handleChangeFormikValue}
                  isInvalid={Boolean(errors.name && touched.name)}
                  errorMessage={errors.name}
                  disabled={isInputsDisabled}
                  required
                />
              </div>

              <div className="field-item">
                <Input
                  className={styles.company_data_input}
                  name="person_in_charge"
                  label="Person in Charge"
                  placeholder="Person in Charge"
                  role="presentation"
                  autoComplete="off"
                  value={values.person_in_charge}
                  onChange={handleChangeFormikValue}
                  disabled={isInputsDisabled}
                />
              </div>

              <div className="field-item">
                <Input
                  className={styles.company_data_input}
                  name="phone"
                  label="Phone"
                  placeholder="Phone"
                  role="presentation"
                  autoComplete="off"
                  value={values.phone}
                  onChange={handleChangeFormikValue}
                  isInvalid={Boolean(errors.phone && touched.phone)}
                  errorMessage={errors.phone}
                  disabled={isInputsDisabled}
                  required
                />
              </div>

              {isButtonVisible && (
                <Button type="submit" className={styles.save_btn} disabled={isButtonDisabled}>
                  Save & send to approval
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default GeneralForm
