import { isObject } from 'lodash'

export const isCompletelyFilled = (values, fieldsToCheck = '') => {
  const valuesArr = Object.entries(values)

  const arrToCheck = fieldsToCheck ? valuesArr.filter(([key]) => fieldsToCheck.includes(key)) : valuesArr

  return arrToCheck.every(([, value]) => {
    const isDate = value instanceof Date

    return isObject(value) && !isDate ? value?.id || value.name : value
  })
}
