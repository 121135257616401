export const downloadFile = (file, name) => {
  const link = document.createElement('a')

  if (link.download === undefined) return

  const url = URL.createObjectURL(file)

  link.setAttribute('href', url)
  link.setAttribute('download', `${name}`)

  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
}
