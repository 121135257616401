import React from 'react'

import AnimateHeight from 'react-animate-height'

import styles from './OurServices.module.scss'
import cn from 'classnames'

import { centToDollar } from '../../Store/Services/utils'

const OurServiceItem = ({ handleOpenService, service, isOpen }) => {
  const { order, name, description, is_soon: soon, is_testing: on_testing, prices } = service
  const { price, currency } = prices

  return (
    <div key={order} onClick={() => handleOpenService(order, isOpen)} className={styles.our_services_service}>
      <div className={styles.our_services_service_arrow} />
      <div className={styles.our_services_service_title}>
        <span>{name}</span>

        <div className={styles.our_services_service_title_markers}>
          {soon && <span className={styles.our_services_service_title_markers__soon}>soon</span>}

          {price === 0 && <span className={styles.our_services_service_title_markers__price_free}>Free</span>}

          {on_testing && !soon && (
            <span className={styles.our_services_service_title_markers__testing}>Testing in progress</span>
          )}

          {price > 0 && (
            <span className={styles.our_services_service_markers__price}>{`${centToDollar(price)}${
              currency?.symbol
            }/mth`}</span>
          )}
        </div>
      </div>

      <div
        className={cn(styles.our_services_service_description, {
          [styles.our_services_service_description__show_more]: isOpen,
        })}
      >
        {description}
      </div>

      <div
        className={cn(styles.our_services_service_arrow__down, {
          [styles.our_services_service_arrow__active]: isOpen,
        })}
      />

      <AnimateHeight
        style={{ flexShrink: 0, background: 'var(--color-white)', borderRadius: '8px' }}
        height={isOpen ? 'auto' : 0}
        duration={300}
      >
        <div className={styles.our_services_service_description_more}>{description}</div>
      </AnimateHeight>

      <div className={styles.our_services_service_markers}>
        {soon && <span className={styles.our_services_service_markers__soon}>soon</span>}

        {price === 0 && <span className={styles.our_services_service_markers__price_free}>Free</span>}

        {on_testing && !soon && (
          <span className={styles.our_services_service_markers__testing}>Testing in progress</span>
        )}

        {price > 0 && (
          <span className={styles.our_services_service_markers__price}>{`${centToDollar(price)}${
            currency?.symbol
          }/mth`}</span>
        )}
      </div>
    </div>
  )
}

export default OurServiceItem
