import React from 'react'

import styles from './styles.module.scss'

import { useHistory } from 'react-router-dom'

import { ReactComponent as UnpaidAccessIcon } from './icons/unpaidFavoritesAccess.svg'

import Button from '../../../UI/Button/Button'

import { profilePaths } from '../../../constants/constants'

const UnpaidFavoritesAccess = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push(profilePaths.billing_payment)
  }

  return (
    <div className={styles.stubContainer}>
      <div className={styles.leftColumn}>
        <h1>
          HIGHLIGHT THE <br /> SEAFARER PROFILES <br /> THAT INTEREST YOU!
        </h1>

        <p>
          Take advantage of the opportunity to add sailors <br />
          that pique your interest to your list of favorite <br /> profiles. This allows you to easily identify those
          candidates who are most suitable for your needs.
        </p>

        <Button onClick={handleClick} children="Go to billing" className={styles.button} />
      </div>

      <UnpaidAccessIcon className={styles.searchAccess} />
    </div>
  )
}

export default UnpaidFavoritesAccess
