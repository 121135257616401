import { WS_API_BASE } from '../api/constans'
import { socketEvent } from './events'
import store from '../redux/store'
import { getAccessToken } from '../helpers/apiHelper'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { setIsSocketConnectedAction } from '../redux/actions/ui.action'
import { getLocalStorageItem } from '../helpers/helpers'

class CustomWebSocket extends ReconnectingWebSocket {
  get isReady() {
    return this.readyState === 1
  }

  emit(event, data) {
    if (this.isReady) {
      this.send(JSON.stringify({ event, data: data || {} }))
    }
  }

  addEvent(callback) {
    this.addEventListener('message', callback)
  }

  clearEvent(callback) {
    this.removeEventListener('message', callback)
  }
}

function urlProvider() {
  const token = getAccessToken()
  const uuid = getLocalStorageItem('anonymous_uuid')

  let url

  if (token) {
    url = `${WS_API_BASE}company/channels/?token=${token}`
  } else {
    url = uuid ? `${WS_API_BASE}anonymous/channels/?uuid=${uuid}` : `${WS_API_BASE}anonymous/channels/`
  }

  return url
}

const Socket = new CustomWebSocket(urlProvider, [], {
  startClosed: true,
})

Socket.addEvent((event) => {
  const response = JSON.parse(event.data)

  store.dispatch(socketEvent(response.event, response.data))
})

Socket.addEventListener('open', () => {
  store.dispatch(setIsSocketConnectedAction(true))
})

Socket.addEventListener('close', () => {
  store.dispatch(setIsSocketConnectedAction(false))

  console.log('%c%s', 'color: red; font-size: 20px', 'Socket was disconnected!')
})

export default Socket
