import React, { useState } from 'react'

import styles from './publishedVacanciesList.module.scss'
import mainStyles from '../publishedVacancies.module.scss'

import { getPublishedVacanciesList } from '../../../../../api/getApi'
import { getDates, getDefaultParams } from '../../utils'
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll'
import { objToQueryConverter } from '../../../../../helpers/apiHelper'
import { getDateByFormat } from '../../../../../helpers/time-helper/time-helper'
import NoInfo from '../../NoInfo/NoInfo'
import cn from 'classnames'
import Spinner from '../../../../../UI/Spinner/Spinner'

const PublishedVacanciesList = ({ searchParams }) => {
  const [list, setList] = useState([])

  const params = searchParams ? { ...getDates(searchParams.dates), group: searchParams.group } : getDefaultParams()

  const { handleScroll, loading } = useInfiniteScroll(
    getPublishedVacanciesList,
    setList,
    null,
    objToQueryConverter(params),
    null,
    true
  )

  return (
    <div className={cn(styles.list, { [styles.list__noIfo]: list.length === 0 && !loading })} onScroll={handleScroll}>
      {loading && <Spinner type="magnifier" size={60} withoutPosition />}

      {list.length === 0 && !loading && <NoInfo className={mainStyles.noInfo} />}

      {list.map(({ date, vacancy_count, items }) => (
        <div key={date} className={styles.listItem}>
          <p className={styles.date}>{`${getDateByFormat(date, 'MMMM yyyy')} (${vacancy_count})`}</p>

          <div className={styles.items}>
            {items.map(({ rank, created_at }) => (
              <p key={created_at} className={styles.item}>{`${rank?.name ?? 'No information'} (${getDateByFormat(
                created_at,
                'dd/MM hh:mmaa'
              )})`}</p>
            ))}

            {loading && <Spinner type="magnifier" size={60} withoutPosition />}
          </div>
        </div>
      ))}
    </div>
  )
}

export default PublishedVacanciesList
