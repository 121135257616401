import React from 'react'
import { getMtVessels } from '../../../../api/getApi'
import MTButton from '../MTButton/MTButton'
import PropTypes from 'prop-types'
import { useNotify } from '../../../../hooks/useNotify'

function MTButtonWithSearch({ value, onError, onSuccess, Icon }) {
  const { notify } = useNotify()

  function runNextStep() {
    getMtVessels(value)
      .then((data) => {
        if (data.results?.length) {
          onSuccess(data)
        } else {
          onError('NOT_FOUND')
        }
      })
      .catch(({ errors }) => notify.info('Info', errors[0].message))
  }

  return (
    <MTButton onClick={runNextStep} Icon={Icon}>
      Search in
    </MTButton>
  )
}

MTButtonWithSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default MTButtonWithSearch
