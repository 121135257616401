import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.module.scss'
import SelectItem from './SelectItem'
import BottomArrowSvg from '../Icons/BottomArrowSvg'
// import { ReactComponent as OutOfUkraineIcon } from '../../assets/icons/outOfUkraine.svg'
import useClickOutside from '../../hooks/useClickOutside'
import scrollPreloader from '../../assets/icons/scroll-preloader.svg'
import { createItemRef } from '../../helpers/createItemRef'
import useHeightWithScroll from '../../hooks/useHeightWithScroll'

const Select = ({
  name,
  id,
  label,
  selectedItem,
  defaultName,
  items,
  className,
  onClick,
  onScroll,
  isInvalid,
  required,
  errorMessage,
  // theme,
  isLoading,
  disabled,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const [isFocused, setIsFocused] = useState(false)

  const selectEl = useClickOutside(() => setIsSelectOpen(false))

  const isOnTop = selectEl.current && window.innerHeight - selectEl.current.getBoundingClientRect().bottom < 250
  const newItems = defaultName ? [{ id: null, name: defaultName }, ...items] : items

  const refs = createItemRef(newItems)
  const { divRef: ulRef } = useHeightWithScroll({ state: isSelectOpen })

  useEffect(() => {
    if (isSelectOpen && activeIndex !== -1) {
      const childElement = refs[newItems[activeIndex]?.id]?.current
      const parentElement = ulRef.current

      if (childElement) {
        const childRect = childElement.getBoundingClientRect()
        const parentRect = parentElement.getBoundingClientRect()

        if (childRect.bottom > parentRect.bottom) {
          parentElement.scrollTop += childRect.bottom - parentRect.bottom
        } else if (childRect.top < parentRect.top) {
          parentElement.scrollTop -= parentRect.top - childRect.top
        }
      }
    }
  }, [isSelectOpen, activeIndex, newItems, refs, ulRef])

  const handleClick = (id, value) => {
    setIsSelectOpen(false)

    if (id === null) {
      onClick(name, { id: null, name: '' })

      return
    }

    onClick(name, { id, name: value })
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleKeyDown = (e) => {
    if (!isSelectOpen && e.key === 'Enter' && isFocused) {
      setIsSelectOpen(true)

      return
    }

    if (e.key === 'ArrowUp' && activeIndex > 0) {
      e.preventDefault()

      setActiveIndex(activeIndex - 1)

      return
    }

    if (e.key === 'ArrowDown' && activeIndex < newItems.length - 1) {
      e.preventDefault()

      setActiveIndex(activeIndex + 1)

      return
    }

    if (e.key === 'Enter' && activeIndex !== -1) {
      const id = newItems[activeIndex].id
      const name = newItems[activeIndex].name

      handleClick(id, name)
    }
  }

  return (
    <>
      {label && (
        <label className={styles.label}>
          {label} {required && <span className="required-star">*</span>}
        </label>
      )}

      <div
        tabIndex="0"
        ref={selectEl}
        className={cn(styles.wrapper, {
          [styles.wrapper_disabled]: disabled,
          // [styles.outOfUkraine]: theme === 'outOfUkraine',
        })}
        onClick={() => setIsSelectOpen(!isSelectOpen)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      >
        {/*{theme === 'outOfUkraine' && (*/}
        {/*  <div className={styles.outOfUkraineIcon}>*/}
        {/*    <OutOfUkraineIcon />*/}
        {/*  </div>*/}
        {/*)}*/}

        <div
          className={cn(styles.header, className, {
            [styles.header_invalid]: isInvalid,
            [styles.header_disabled]: disabled,
          })}
        >
          <div
            className={cn(styles.title, {
              [styles.title_default]: id === null,
            })}
          >
            {selectedItem?.name || label}
          </div>
        </div>

        {isInvalid && errorMessage && <div className={styles.selectErrorMessage}>{errorMessage}</div>}

        {isSelectOpen && (
          <ul ref={ulRef} className={cn(styles.list, { [styles.list_top]: isOnTop })} onScroll={onScroll}>
            {newItems.map(({ name: item, id }, index) => {
              const isActive = index === activeIndex || id === Number(selectedItem.id)

              return (
                <SelectItem
                  refs={refs[id]}
                  tabIndex="0"
                  key={id}
                  name={item}
                  id={id}
                  isActive={isActive}
                  onClick={handleClick}
                />
              )
            })}

            {isLoading && (
              <div style={{ margin: '40px auto', width: 60, height: 60 }} className="scroll-preloader">
                <img src={scrollPreloader} alt="preloader" />
              </div>
            )}
          </ul>
        )}

        <div
          className={cn(styles.icon, {
            [styles.icon_active]: isSelectOpen,
          })}
        >
          <BottomArrowSvg width={14} height={7} fill="var(--color-accent-light)" />
        </div>
      </div>
    </>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  selectedItem: PropTypes.object.isRequired,
  defaultName: PropTypes.string,
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  // onClick: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
}

Select.defaultProps = {
  defaultName: 'None',
  items: [],
  className: '',
  isInvalid: false,
  required: false,
  errorMessage: '',
  disabled: false,
}

export default Select
