import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

export function createAction(type) {
  return function () {
    return { type };
  };
}

const composeEnhancers =
  window.location.href.includes("dev.employers.nextship.net") ||
  window.location.href.includes("localhost")
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
