import React from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import PassportItem from './PassportItem/PassportItem'
import styles from '../userCard.module.scss'
import { ReactComponent as NoInfoImage } from '../../../assets/images/passports_stub.svg'

const Passports = ({ userCardData, userName }) => {
  const { passports } = userCardData

  if (isEmpty(passports)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={480} height={270} />
        <p>
          <b>{userName}</b> doesn’t have any passports yet.{' '}
        </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter="16px">
        {passports?.map((passport, index) => {
          return <PassportItem key={index} title={passport.name} files={passport.files} items={passport.values} />
        })}
      </Masonry>
    )
  }
}

export default Passports
