import React, { useEffect, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'

const UseSmartTooltip = ({
  id,
  children,
  tagType = 'span',
  className,
  typeLink = '',
  onClick,
  update,
  amount_of_line = 1,
}) => {
  const [titleWidth, setTitleWidth] = useState(0)
  const [titleScrollWidth, setTitleScrollWidth] = useState(0)
  const [visible, setVisible] = useState(false)

  const contentRef = useRef(null)

  useEffect(() => {
    if (contentRef.current) {
      setTitleWidth(contentRef.current.offsetWidth)
      setTitleScrollWidth(contentRef.current.scrollWidth)
    }
  }, [contentRef, update, children])

  let Tag = tagType

  useEffect(() => {
    if (tagType === 'a') {
      contentRef.current.setAttribute(
        'href',
        `${typeLink === 'tel' ? 'tel:' : typeLink === 'email' ? 'mailto:' : ''}${children}`
      )
    }
    const isVisible = amount_of_line > 1 ? titleScrollWidth > titleWidth * 2 : titleScrollWidth > titleWidth
    if (isVisible) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [titleWidth, titleScrollWidth, children, tagType, typeLink, update, amount_of_line])

  return (
    <>
      <Tag ref={contentRef} className={className} data-tip={true} data-for={`${id}`} onClick={onClick}>
        {children}
      </Tag>
      {visible && (
        <ReactTooltip className="use_smart_tooltip" id={`${id}`}>
          {children}
        </ReactTooltip>
      )}
    </>
  )
}

export default UseSmartTooltip
