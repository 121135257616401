import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import Spinner from '../../UI/Spinner/Spinner'
import { confirm } from '../../UI/Confirm/Confirm'
import { deleteCompanyPicture } from '../../api/deleteApi'
import { changeCompanyPicture, getCompanyProfile } from '../../redux/actions/company.action'
import AvatarCropper from './AvatarCropper/AvatarCropper'
import styles from './styles.module.scss'
import { useNotify } from '../../hooks/useNotify'
import Input from '../../UI/Input/Input'

const CompanyLogoEditor = ({ formik, showCropper, setShowCropper }) => {
  const { errors } = formik
  const dispatch = useDispatch()
  const { notify } = useNotify()
  const companyPicture = useSelector(({ company }) => ({
    picture: company.picture,
  }))
  const onModeration = useSelector(({ company }) => company.on_moderation)
  const isActive = useSelector(({ company }) => company.is_active)

  const [loading, setLoading] = React.useState(true)
  const [companyLogo, setCompanyLogo] = React.useState('')

  const loaded = () => {
    setLoading(false)
  }

  const handleHideCropper = () => {
    setShowCropper(false)
  }

  const handleDelete = (id) => {
    confirm('Are you sure?', 'Are you want to delete your company logo?', () => {
      deleteCompanyPicture(id)
        .then((response) => {
          dispatch(changeCompanyPicture(null))
          dispatch(getCompanyProfile())
          setLoading(true)

          return response
        })
        .then((data) => {
          dispatch(changeCompanyPicture(data))
          notify('Success', 'You are successfully deleted company logo!')
        })
        .catch((error) => {
          setLoading(false)
          notify.errorsList(error.errors)
        })
    })
  }

  const handleNewImage = (e) => {
    if (['image/png', 'image/jpeg', 'image/jpg'].includes(e.target.files[0].type)) {
      if (e.target.files[0]) {
        setCompanyLogo(e.target.files[0])
        setShowCropper(true)
      }
    } else {
      notify.error('Error', 'Type of your image not supported. Supported: jpg, png, jpeg')
    }
  }

  return (
    <form className={styles.form}>
      <p className={styles.field}>
        Company logo <span>*</span>
      </p>
      {companyPicture.picture &&
        (showCropper && companyLogo ? (
          <>
            <AvatarCropper
              formik={formik}
              picture={companyLogo}
              id={companyPicture.picture.id}
              handleHideCropper={handleHideCropper}
              setEditorLoading={setLoading}
              width={225}
              height={225}
            />
          </>
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>
              {loading && <Spinner />}
              <div
                className={styles.logoContainer}
                style={loading ? { backgroundColor: '#E0E0E0', filter: 'blur(3px)' } : {}}
              >
                <Input
                  name="company_logo"
                  className={styles.fileInput}
                  onChange={handleNewImage}
                  accept="image/png, .jpeg, .jpg"
                  type="file"
                  id="avatar-editor"
                  isInvalid={Boolean(formik.errors.company_logo?.file_name && formik.touched.company_logo?.file_name)}
                />

                {companyPicture.picture.file_name === 'company_default.png' ? (
                  // eslint-disable-next-line
                  <img
                    src={companyPicture.picture.file}
                    alt={!loading ? 'company avatar' : undefined}
                    className={cn(styles.defaultLogo, {
                      [styles.defaultLogo_error]: Boolean(
                        formik.errors.company_logo?.file_name && formik.touched.company_logo?.file_name
                      ),
                    })}
                    style={loading ? { filter: 'blur(3px)' } : {}}
                    onLoad={loaded}
                    onError={loaded}
                  />
                ) : (
                  // eslint-disable-next-line
                  <img
                    src={companyPicture.picture.file}
                    alt={!loading ? 'company avatar' : undefined}
                    className={styles.companyLogo}
                    style={loading ? { filter: 'blur(3px)' } : {}}
                    onLoad={loaded}
                    onError={loaded}
                  />
                )}
                {Boolean(formik.errors.company_logo?.file_name && formik.touched.company_logo?.file_name) && (
                  <p className={styles.error_message}>{errors.company_logo.file_name}</p>
                )}
              </div>
            </div>
            <div className={styles.body}>
              {onModeration || isActive ? (
                ''
              ) : (
                <div className={styles.controls}>
                  <label
                    className={cn(styles.chooseLogoControl, {
                      [styles.disable]: loading,
                    })}
                    htmlFor="avatar-editor"
                  >
                    {companyPicture.picture.file_name === 'company_default.png' ? 'Choose logo' : 'Change logo'}
                  </label>
                  {companyPicture.picture.file_name !== 'company_default.png' && (
                    <label
                      className={cn(styles.deleteLogoControl, {
                        [styles.disable]: loading,
                      })}
                      onClick={() => handleDelete(companyPicture.picture.id)}
                    >
                      Delete
                    </label>
                  )}
                </div>
              )}
            </div>
            <div className={styles.footer}>
              <p>
                Please use formats:
                <b className={styles.formatsOfImage}>jpg, jpeg, png</b>
              </p>
              <p className={styles.formats_description}>
                For the correct display of the company logo, please use a graphic image of a square size, for example
                400x400 pixels
              </p>
            </div>
          </div>
        ))}
    </form>
  )
}

export default CompanyLogoEditor
