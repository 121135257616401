import ReactDOM from 'react-dom'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import 'normalize.css'
import './scss/App.scss'
import '../src/scss/global.module.scss'
import Root from './pages/Root'
import store from './redux/store'

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <Root />
      <ToastContainer position="top-right" autoClose={3000} limit={3} />
    </Provider>
  </Router>,
  document.getElementById('root')
)
