import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import styles from './styles.module.scss'
import cn from 'classnames'

import { confirm } from '../../../UI/Confirm/Confirm'
import { Header } from './Header/Header'
import { Message } from './Message/Message'
import { Footer } from './Footer/Footer'
import ChatEmpty from './ChatEmpty/ChatEmpty'
import Spinner from '../../../UI/Spinner/Spinner'

import useCanEdit from '../../../hooks/useCanEdit'
import { createItemRef } from '../../../helpers/createItemRef'
import {
  actionMessageClose,
  changeMessageValue,
  editMessageStart,
  replyMessageStart,
  startPaginationLoading,
} from '../../../redux/actions/chat.action'
import { getDateBadge } from './utils'
import socket from '../../../websocket'
import { events } from '../../../constants/constants'

export const ChatRoom = memo(({ chat_id }) => {
  const [scrollDirection, setScrollDirection] = useState({ up: true, down: false })
  const [scrolling, setScrolling] = useState(false)

  const dialog = useSelector(({ chat }) => chat.open_dialog)
  const { list } = useSelector(({ chat }) => chat.dialogs)
  const pagination = useSelector(({ chat }) => chat.pagination)

  const dispatch = useDispatch()

  const messagesRef = useRef(null)
  const refs = createItemRef(dialog?.messages)

  const isCanEdit = useCanEdit()

  const { chat } = events

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scroll({ top: 0 })
    }

    dispatch(changeMessageValue(''))
    dispatch(actionMessageClose())

    // eslint-disable-next-line
  }, [chat_id])

  useEffect(() => {
    if (dialog?.searchMessageId)
      refs[dialog.searchMessageId].current.scrollIntoView({
        behavior: 'smooth',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog?.searchMessageId])

  useEffect(() => {
    const onMessageCallback = (socketEvent) => {
      const { event, data } = JSON.parse(socketEvent.data)

      if (event === chat.newMessage && data.chat_id === Number(chat_id) && messagesRef.current) {
        // if new incoming message was in current chat room, send emmit that message was delivered
        if (!data.is_outgoing) {
          socket.emit(chat.messageDelivered, { chat_id })
        }

        // smooth scroll to bottom after new message in current chat room
        if (messagesRef.current) {
          messagesRef.current.scroll({ top: 0, behavior: 'smooth' })
        }
      }
    }

    socket.addEvent(onMessageCallback)

    return () => {
      socket.clearEvent(onMessageCallback)
    }

    // eslint-disable-next-line
  }, [chat_id])

  const handleReply = useCallback(
    (id) => {
      dispatch(replyMessageStart(id))
    },

    // eslint-disable-next-line
    []
  )

  const handleEdit = useCallback(
    (id) => {
      dispatch(editMessageStart(id))
    },

    // eslint-disable-next-line
    []
  )

  const handleDelete = useCallback(
    (id) => {
      confirm('Are you sure?', 'Do you want to delete this message?', () => {
        socket.emit('chat.delete.message', { message_id: id, chat_id: Number(chat_id) })
      })
    },
    [chat_id]
  )

  const scrollEndTimer = () => {
    setTimeout(() => {
      setScrolling(false)
    }, 2000)
  }

  const handleScroll = (e) => {
    setScrollDirection({ up: false, down: false })
    setScrolling(true)

    const { scrollTop, scrollHeight } = e.target

    if (pagination?.has_more_up && !pagination.loading && scrollDirection.up) {
      if (scrollTop + scrollHeight - 800 < 0) {
        dispatch(startPaginationLoading())

        socket.emit(chat.messages, {
          chat_id: Number(chat_id),
          last_message_id: dialog.messages[dialog.messages.length - 1].id,
        })
      }

      return
    }

    if (pagination?.has_more_down && !pagination.loading && scrollDirection.down) {
      if (scrollTop - scrollHeight + 800 < 0) {
        dispatch(startPaginationLoading())

        socket.emit(chat.messages, {
          chat_id: Number(chat_id),
          reverse: true,
          last_message_id: dialog.messages[0].id,
        })
      }
    }

    clearTimeout(scrollEndTimer())
  }

  const handleReplyClick = useCallback(
    (id) => {
      dispatch(startPaginationLoading())

      socket.emit(chat.searchMessage, {
        page_size: 5,
        message_id: id,
        chat_id: Number(chat_id),
      })
    },

    // eslint-disable-next-line
    [chat_id]
  )

  const handleScrollDirection = useCallback((e) => {
    if (e.nativeEvent.wheelDelta > 0) {
      setScrollDirection({ up: true, down: false })
    } else {
      setScrollDirection({ down: true, up: false })
    }
  }, [])

  const isOnline = useMemo(() => {
    return list.find((item) => Number(item.chat_id) === Number(chat_id))?.is_online
  }, [chat_id, list])

  const messages = useMemo(() => {
    if (!dialog) return

    const stack = {}

    return dialog.messages.reduce((acc, cur) => {
      const [date, time] = cur.date.split(' ')
      const dateBadge = getDateBadge(`${date} ${time}`, date)

      if (stack[date]) {
        return acc.map((dateObj) => {
          if (dateObj.date !== date) return dateObj

          return { ...dateObj, items: [...dateObj.items, cur] }
        })
      }

      stack[date] = true

      return [...acc, { date, dateBadge, items: [cur] }]
    }, [])
  }, [dialog])

  return (
    <div className={styles.room}>
      {list.length === 0 && <ChatEmpty />}

      {dialog && chat_id && (
        <>
          {dialog.interlocutor?.name && <Header interlocutor={dialog.interlocutor} isOnline={isOnline} />}

          <div className={styles.main} ref={messagesRef} onScroll={handleScroll} onWheel={handleScrollDirection}>
            {pagination?.has_more_down && pagination.loading && (
              <div className={styles.spinnerWrapper}>
                <Spinner />
              </div>
            )}

            {messages &&
              messages.map(({ date, dateBadge, items }) => (
                <div key={date}>
                  <div className={cn(styles.dateBadge, { [styles.dateBadge__active]: scrolling })}>
                    <span>{dateBadge}</span>
                  </div>

                  <div className={styles.messages}>
                    {items.map((message) => (
                      <Message
                        key={message.id}
                        refs={refs[message.id]}
                        id={message.id}
                        avatar={message?.user?.avatar}
                        userName={message?.user?.name?.split(' ')[1]}
                        message={message.message}
                        replyMessage={message.reply}
                        editMessage={message.edited}
                        isOutgoing={message.is_outgoing}
                        date={message.date}
                        onReply={handleReply}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        senderType={message.sender_type}
                        onReplyClick={handleReplyClick}
                        searchMessageId={dialog?.searchMessageId}
                      />
                    ))}
                  </div>
                </div>
              ))}

            {pagination?.has_more_up && pagination.loading && (
              <div className={styles.spinnerWrapper}>
                <Spinner />
              </div>
            )}
          </div>

          {isCanEdit && <Footer chat_id={chat_id} />}
        </>
      )}
    </div>
  )
})
