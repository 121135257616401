import React from 'react'

export const DownloadSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="17"
      fill="none"
      viewBox="0 0 11 17"
      stroke="black"
      strokeWidth=".3"
    >
      <path
        fill="#06251E"
        d="M5.853.24a.487.487 0 00-.708 0l-4 4.117a.523.523 0 000 .729.494.494 0 00.708 0L5 1.846v10.08c0 .136.053.267.147.364a.493.493 0 00.707 0 .522.522 0 00.146-.364V1.846l3.146 3.239a.5.5 0 00.354.15c.133 0 .26-.054.354-.15a.523.523 0 000-.73l-4-4.116zM.5 16.03h10c.133 0 .26-.055.354-.151a.523.523 0 000-.728A.493.493 0 0010.5 15H.5a.493.493 0 00-.354.15.522.522 0 000 .729c.094.096.221.15.354.15z"
      />
    </svg>
  )
}

export const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#43927C"
        d="M14.52 16.165c.421 0 .825-.168 1.122-.467a1.599 1.599 0 000-2.256 1.582 1.582 0 00-2.244 0 1.6 1.6 0 000 2.255c.298.3.702.468 1.122.468z"
      />
      <path
        fill="#43927C"
        fillRule="evenodd"
        d="M7.38 5c-.631 0-1.237.252-1.683.7A2.399 2.399 0 005 7.394v9.57c0 .634.25 1.243.697 1.691.446.45 1.052.701 1.683.701h9.52c.631 0 1.237-.252 1.683-.7a2.399 2.399 0 00.697-1.692v-9.57c0-.635-.25-1.244-.697-1.692A2.374 2.374 0 0016.9 5H7.38zm-.793 11.963V8.19h11.106v8.773a.792.792 0 01-.793.798H7.38a.791.791 0 01-.793-.798z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const TrashIcon = ({ width, height, strokeColor }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 17 20">
      <path
        fill={strokeColor}
        stroke={strokeColor}
        strokeWidth="0.3"
        d="M15.411 3.744h-3.01V2.006c0-.554-.451-1.006-1.007-1.006h-5.25c-.556 0-1.008.452-1.008 1.006v1.738h-3.13C1.452 3.744 1 4.196 1 4.751v1.01c0 .556.452 1.008 1.006 1.008h.395l.397 11.898c0 .581.473 1.054 1.054 1.054h9.714a1.05 1.05 0 001.053-1.038l.398-11.914h.394c.555 0 1.007-.452 1.007-1.007V4.75c0-.555-.451-1.007-1.007-1.007zM13.66 18.668a.093.093 0 01-.094.093H3.852c-.052 0-.094-.041-.094-.11L3.361 6.77h10.696l-.397 11.899zm1.799-12.906a.049.049 0 01-.048.048H2.006a.047.047 0 01-.047-.048v-1.01c0-.026.021-.048.047-.048h3.61a.48.48 0 00.48-.48V2.007c0-.026.02-.047.047-.047h5.251c.027 0 .048.021.048.047v2.217c0 .265.214.48.48.48h3.489c.026 0 .048.022.048.047v1.011z"
      />
      <path
        fill={strokeColor}
        stroke={strokeColor}
        strokeWidth="0.3"
        d="M9.772 3.282H7.647a.48.48 0 000 .96h2.125a.48.48 0 100-.96z"
      />
    </svg>
  )
}

export const UsersIcon = ({ width = 30, height = 21, strokeColor = '#43927C', strokeWidth = '0.2' }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 30 21">
      <path
        fill={strokeColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        d="M9.636 3.923c2.683 0 4.841 2.12 4.841 4.757 0 2.58-2.158 4.757-4.841 4.757-2.684 0-4.842-2.178-4.842-4.757 0-2.636 2.158-4.757 4.842-4.757zm0 1.662c-1.75 0-3.15 1.376-3.15 3.095 0 1.72 1.4 3.095 3.15 3.095s3.15-1.376 3.15-3.095c0-1.72-1.4-3.095-3.15-3.095zM2.519 19.799c-.233.401-.758.516-1.108.229-.409-.23-.525-.745-.292-1.146 1.925-2.809 5.075-4.528 8.517-4.528 3.441 0 6.65 1.72 8.516 4.528.234.4.117.917-.233 1.146-.408.287-.933.172-1.167-.23a8.59 8.59 0 00-7.116-3.782A8.59 8.59 0 002.519 19.8zM20.369 1c2.683 0 4.842 2.12 4.842 4.757 0 2.636-2.159 4.757-4.842 4.757s-4.842-2.178-4.842-4.757C15.527 3.121 17.686 1 20.37 1zm0 1.662c-1.75 0-3.15 1.376-3.15 3.095 0 1.72 1.4 3.095 3.15 3.095s3.15-1.376 3.15-3.095c0-1.72-1.4-3.095-3.15-3.095zM15.82 14.411c-.35.23-.876.115-1.11-.286-.29-.344-.174-.86.234-1.146 1.633-.975 3.5-1.548 5.425-1.548 3.442 0 6.65 1.72 8.517 4.528.233.401.117.917-.233 1.146-.409.287-.934.172-1.167-.23a8.59 8.59 0 00-7.117-3.782c-1.633 0-3.15.459-4.55 1.318z"
      />
    </svg>
  )
}

export const CellsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <path
        style={{ fill: 'none' }}
        stroke="#43927C"
        strokeWidth="1.8"
        d="M20.238 6H24a1 1 0 011 1v17a1 1 0 01-1 1H6a1 1 0 01-1-1v-4.7"
      />
      <rect
        width="19.2"
        style={{ fill: 'none' }}
        height="18.2"
        x="0.9"
        y="0.9"
        stroke="#43927C"
        strokeWidth="1.8"
        rx="1.1"
      />
      <mask id="path-3-inside-1" fill="#fff">
        <path
          fillRule="evenodd"
          d="M11 5a.5.5 0 01.5.5v4h4a.5.5 0 010 1h-4v4a.5.5 0 01-1 0v-4h-4a.5.5 0 010-1h4v-4A.5.5 0 0111 5z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fillRule="evenodd"
        d="M11 5a.5.5 0 01.5.5v4h4a.5.5 0 010 1h-4v4a.5.5 0 01-1 0v-4h-4a.5.5 0 010-1h4v-4A.5.5 0 0111 5z"
        clipRule="evenodd"
      />
      <path
        fill="#43927C"
        d="M11.5 9.5h-1v1h1v-1zm0 1v-1h-1v1h1zm-1 0h1v-1h-1v1zm0-1v1h1v-1h-1zM11 6a.5.5 0 01-.5-.5h2A1.5 1.5 0 0011 4v2zm-.5-.5v4h2v-4h-2zm1 5h4v-2h-4v2zm4 0a.5.5 0 01-.5-.5h2a1.5 1.5 0 00-1.5-1.5v2zM15 10a.5.5 0 01.5-.5v2A1.5 1.5 0 0017 10h-2zm.5-.5h-4v2h4v-2zm-5 1v4h2v-4h-2zm0 4a.5.5 0 01.5-.5v2a1.5 1.5 0 001.5-1.5h-2zm.5-.5a.5.5 0 01.5.5h-2A1.5 1.5 0 0011 16v-2zm.5.5v-4h-2v4h2zm-1-5h-4v2h4v-2zm-4 0a.5.5 0 01.5.5H5a1.5 1.5 0 001.5 1.5v-2zm.5.5a.5.5 0 01-.5.5v-2A1.5 1.5 0 005 10h2zm-.5.5h4v-2h-4v2zm5-1v-4h-2v4h2zm0-4a.5.5 0 01-.5.5V4a1.5 1.5 0 00-1.5 1.5h2z"
        mask="url(#path-3-inside-1)"
      />
    </svg>
  )
}

export const RestoreIcon = ({ width, height, strokeWidth, strokeColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9352 5.41919H3.15007L6.08837 2.48076C6.30067 2.25302 6.37886 1.93097 6.2947 1.63138C6.21054 1.33159 5.97632 1.09739 5.67653 1.01321C5.37674 0.929051 5.05487 1.00724 4.82715 1.21954L0.364781 5.68106C0.19779 5.84838 0.104004 6.07529 0.104004 6.31168C0.104004 6.54806 0.19779 6.77496 0.364781 6.9423L4.82715 11.4047C5.05489 11.617 5.37677 11.6952 5.67653 11.611C5.97632 11.5268 6.21052 11.2926 6.2947 10.9928C6.37886 10.693 6.30068 10.3712 6.08837 10.1434L3.15007 7.204H13.9352C15.3699 7.204 16.6955 7.96942 17.413 9.21188C18.1303 10.4543 18.1303 11.9852 17.413 13.2276C16.6955 14.4701 15.3699 15.2355 13.9352 15.2355H4.56514C4.24627 15.2355 3.95178 15.4056 3.79225 15.6817C3.6329 15.9577 3.6329 16.298 3.79225 16.5741C3.95177 16.8501 4.24626 17.0203 4.56514 17.0203H13.9352C16.0075 17.0203 17.9224 15.9147 18.9585 14.12C19.9948 12.3253 19.9948 10.1142 18.9585 8.31949C17.9224 6.52477 16.0075 5.41923 13.9352 5.41923V5.41919Z"
        fill={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export const ArchiveIcon = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 20 21">
      <path
        fill="#E7B92A"
        d="M18.622 7.453a2 2 0 01-.435-1.245V4.575c0-.28-.18-.509-.404-.509a.808.808 0 01-.808-.808v-.716c0-.281-.18-.509-.404-.509a.808.808 0 01-.809-.808V.508c0-.28-.18-.508-.404-.508h-3.637a.368.368 0 00-.286.149l-.494.62a2 2 0 01-1.565.756H4.042c-.224 0-.405.227-.405.508v.717a.808.808 0 01-.808.808c-.223 0-.404.228-.404.508v.717a.808.808 0 01-.808.808c-.224 0-.405.228-.405.509v.18a2 2 0 01-.494 1.315l-.224.257A2 2 0 000 9.168v9.165a2 2 0 002 2h15.4a2 2 0 002-2V9.13a2 2 0 00-.435-1.244l-.343-.432zM4.446 3.05c0-.28.227-.508.508-.508h5.554c.108 0 .21-.054.286-.15l.494-.62a2 2 0 011.565-.756h1.593a.508.508 0 110 1.017h-1.513a.368.368 0 00-.286.15l-.493.62a2 2 0 01-1.566.755H4.954a.508.508 0 01-.508-.508zM3.233 5.083c0-.28.228-.508.509-.508h7.979c.107 0 .21-.054.286-.149l.493-.62a2 2 0 011.566-.756h1.592a.508.508 0 010 1.016h-1.512a.368.368 0 00-.286.15l-.494.62a2 2 0 01-1.565.755H3.74a.508.508 0 01-.508-.508zM2.021 7.117c0-.281.227-.509.508-.509h10.404c.108 0 .21-.053.286-.149l.494-.62a2 2 0 011.565-.756h.83a1.27 1.27 0 110 2.542H2.53a.508.508 0 01-.508-.508zm16.57 12.2H2.809a2 2 0 01-2-2V11.15a2 2 0 012-2h13.784a2 2 0 012 2v8.166z"
      />
      <path
        fill="#E7B92A"
        d="M12.933 11.946a1.78 1.78 0 01-1.78 1.779H8.65a1.78 1.78 0 110-3.559h2.504c.982 0 1.78.797 1.78 1.78zm-5.254 0c0 .42.341.762.762.762h2.921a.763.763 0 000-1.525h-2.92a.762.762 0 00-.763.763z"
      />
    </svg>
  )
}

export const SearchSeafarersIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.2444 5.00027C29.804 5.00027 34.3252 9.52081 34.3252 15.0805C34.3252 20.6402 29.804 25.1614
        24.2444 25.1614C21.6902 25.1614 19.3573 24.2051 17.5791 22.6344L16.4662 23.7474C17.1531 24.8414
        17.0239 26.3099 16.0738 27.26L9.83659 33.4966C8.73378 34.5994 6.93064 34.5994 5.82755 33.4966C4.72474
        32.3938 4.72361 30.5893 5.82645 29.4865L12.063 23.2493C12.6146 22.698 13.3415 22.4227 14.0683
        22.4231C14.5928 22.4233 15.1164 22.569 15.575 22.8562L16.6879 21.7433C15.1186 19.9654 14.1636
        17.633 14.1636 15.0803C14.1636 9.52059 18.6842 5 24.2439 5L24.2444 5.00027ZM24.2444 6.26087C19.3657
        6.26087 15.4247 10.2018 15.4247 15.0805C15.4247 19.9593 19.3656 23.9008 24.2444 23.9008C29.1231
        23.9008 33.0646 19.9592 33.0646 15.0805C33.0646 10.2018 29.1231 6.26087 24.2444 6.26087ZM14.069
        23.6729C13.668 23.6725 13.267 23.8285 12.9548 24.141L6.71759 30.3781C6.09292 31.0028 6.09466
        31.9813 6.71957 32.606C7.34424 33.2307 8.32144 33.2307 8.94611 32.606L15.1822 26.3688C15.8068
        25.7441 15.8068 24.7676 15.1822 24.1429V24.1423C14.8697 23.8298 14.4693 23.6732 14.0685 23.6729L14.069 23.6729Z"
        fill="#43927C"
        stroke="#43927C"
        strokeWidth="0.3"
      />
      <path
        d="M25.0376 9.52064C26.0192 9.49251 27.0225 9.65797 27.9932 10.0368L27.993 10.037C28.1574 10.0911 28.2921
        10.2106 28.3659 10.367C28.4395 10.5237 28.4455 10.7037 28.3826 10.8647C28.3195 11.0258 28.1932 11.1539
        28.0328 11.2192C27.8726 11.2842 27.6924 11.2805 27.5349 11.2088C24.2866 9.94099 20.6429 11.5393 19.375
        14.7876H19.3753C19.2487 15.1119 18.8833 15.2723 18.5589 15.1458C18.2348 15.0192 18.0744 14.6538 18.201
        14.3295C19.3377 11.4175 22.0929 9.60524 25.0378 9.52064L25.0376 9.52064Z"
        fill="#43927C"
      />
    </svg>
  )
}

export const FavouriteIcon = ({ strokeColor }) => {
  return (
    <svg width="11" height="20" fill="none" viewBox="0 0 11 20" style={{ color: strokeColor }}>
      <path
        fill={strokeColor}
        fillRule="evenodd"
        d="M0 .516C0 .231.204 0 .456 0H10.49c.252 0 .456.23.456.516V19.44c0 .564-.313.728-.698.369l-4.424-4.135a.522.522 0 00-.701 0L.699 19.809c-.386.36-.699.197-.699-.369V.516z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const ChatIcon = ({ strokeColor }) => {
  return (
    <svg width="19" height="15" fill="none" viewBox="0 0 19 15" style={{ color: strokeColor }}>
      <path
        fill={strokeColor}
        d="M10.255 3.917H1.54a1.53 1.53 0 00-1.089.462C.162 4.674 0 5.073 0 5.49v4.959a1.586 1.586 0 00.451 1.105 1.532 1.532 0 001.088.456h1.219v1.76c0 .712.366.844.813.296l1.68-2.056h5.004a1.508 1.508 0 001.088-.456 1.564 1.564 0 00.451-1.105V5.489c0-.416-.162-.815-.45-1.11a1.53 1.53 0 00-1.089-.462zM2.891 8.799a.772.772 0 01-.435-.135.81.81 0 01-.119-1.226.778.778 0 01.853-.172c.143.06.265.162.351.293a.807.807 0 01-.097 1.006.775.775 0 01-.553.234zm3.006 0a.772.772 0 01-.435-.135.81.81 0 01-.119-1.226.778.778 0 01.853-.172c.143.06.266.162.352.293a.807.807 0 01-.352 1.18.77.77 0 01-.299.06zm3.006 0a.772.772 0 01-.434-.135.81.81 0 01-.119-1.226.778.778 0 01.853-.172c.143.06.265.162.35.293a.807.807 0 01-.097 1.006.775.775 0 01-.553.234z"
      />
      <path
        fill={strokeColor}
        d="M16.2 0H7.838C6.53 0 5.443 1.082 5.443 2.415v.32h4.814a2.706 2.706 0 012.698 2.724v3.963h1.409l1.415 1.718c.45.545.815.41.812-.3l-.007-1.446c1.128-.195 1.996-1.228 1.996-2.427V2.415C18.58 1.082 17.508 0 16.2 0z"
      />
    </svg>
  )
}

export const BillsIcon = () => {
  return (
    <svg
      style={{ transform: 'translateX(2px)' }}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      fill="none"
      viewBox="0 0 30 29"
    >
      <path
        fill="#43927C"
        stroke="#43927C"
        strokeWidth="0.1"
        d="M22.88 28.007a.876.876 0 01-.622-.254l-2.88-2.888-2.879 2.88a.875.875 0 01-1.234 0l-2.888-2.88-2.88 2.889a.875.875 0 01-1.234 0l-2.888-2.889-2.88 2.889A.876.876 0 011 27.131V3.626A2.626 2.626 0 013.625 1h8.752a.875.875 0 010 1.75H3.625a.875.875 0 00-.876.876v21.397l2.005-2.005a.875.875 0 011.234 0l2.888 2.88 2.88-2.88a.875.875 0 011.234 0l2.888 2.88 2.88-2.88a.875.875 0 011.233 0l2.014 2.005v-4.768a.876.876 0 011.75 0v6.877a.875.875 0 01-.875.875z"
      />
      <path
        fill="#43927C"
        stroke="#43927C"
        strokeWidth="0.875"
        d="M21.13 10.19h-1.75a2.188 2.188 0 010-4.376h1.75a2.188 2.188 0 012.188 2.188.438.438 0 01-.128.31l.31.309-.31-.31a.438.438 0 01-.747-.31A1.313 1.313 0 0021.13 6.69h-1.75a1.313 1.313 0 100 2.626h1.75a.438.438 0 110 .875z"
      />
      <path
        fill="#43927C"
        stroke="#43927C"
        strokeWidth="0.875"
        d="M21.13 13.691h-1.75a2.188 2.188 0 01-2.189-2.188.438.438 0 11.876 0 1.313 1.313 0 001.313 1.313h1.75a1.313 1.313 0 100-2.626h-1.75a.438.438 0 110-.875h1.75a2.188 2.188 0 110 4.376zM20.254 6.69a.438.438 0 01-.438-.439v-1.75a.438.438 0 11.876 0v1.75a.438.438 0 01-.438.438z"
      />
      <path
        fill="#43927C"
        stroke="#43927C"
        strokeWidth="0.875"
        d="M20.254 15.441a.438.438 0 01-.438-.437v-1.75a.437.437 0 11.876 0v1.75a.438.438 0 01-.438.437z"
      />
      <path
        fill="#43927C"
        stroke="#43927C"
        strokeWidth="0.1"
        d="M20.255 18.505a8.753 8.753 0 110-17.505 8.753 8.753 0 010 17.505zm0-15.755a7.002 7.002 0 100 14.004 7.002 7.002 0 000-14.004z"
      />
    </svg>
  )
}

export const ArrowDownSvg = ({ width, height, strokeColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 14 7">
      <path
        fill={strokeColor}
        d="M6.989 6.998a.997.997 0 01-.639-.23L.36 1.77A1.001 1.001 0 011.639.231L6.99 4.71 12.339.39a.998.998 0 011.408.15 1 1 0 01-.14 1.46l-5.99 4.827a.997.997 0 01-.628.17z"
      />
    </svg>
  )
}

export const Ellipsis = () => (
  <svg width="26" height="6" viewBox="0 0 26 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="#828282" />
    <circle cx="13" cy="3" r="3" fill="#828282" />
    <circle cx="23" cy="3" r="3" fill="#828282" />
  </svg>
)

export const StarSvg = ({ size, strokeColor, children, fontSize = 8 }) => {
  const classes = {
    wrapper: {
      position: 'relative',
      display: 'inline-block',
    },
    children: {
      position: 'absolute',
      width: size,
      height: size,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      left: 0,
      top: 0,
      fontWeight: 600,
      fontSize: `${fontSize}px`,
      lineHeight: `${fontSize * 1.22}px`,
      color: 'var(--color-white)',
    },
  }
  return (
    <div style={classes.wrapper}>
      <div style={classes.children}>{children}</div>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox={'0 0 30 30'}>
        <path
          fill={strokeColor}
          d="M15 0l2.61 2.72 3.491-1.423 1.279 3.546 3.767.12-.274 3.76 3.393 1.642-1.78 3.323 2.432 2.88-2.977 2.312 1.05 3.62-3.66.9-.514 3.735-3.71-.665-1.988 3.202L15 27.555l-3.119 2.117-1.988-3.202-3.71.665-.513-3.734-3.66-.901 1.05-3.62-2.978-2.312 2.432-2.88-1.78-3.323 3.393-1.643-.274-3.759 3.767-.12L8.9 1.297l3.49 1.422L15 0z"
        />
      </svg>
    </div>
  )
}

export const StarIcon = ({ strokeColor, size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 28 26">
      <path
        fill={strokeColor}
        d="M14.235 0l3.196 9.836h10.343l-8.367 6.08 3.196 9.836-8.368-6.08-8.367 6.08 3.196-9.836-8.367-6.08h10.342L14.235 0z"
      />
    </svg>
  )
}

export const StatisticIcon = ({ strokeColor = '#43927C' }) => {
  return (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 0C0.835787 0 0.5 0.335787 0.5 0.750001V25.5V26C0.5 26.5523 0.947715 27 1.5 27H2H24.75C25.1642 27 25.5 26.6642 25.5 26.25C25.5 25.8358 25.1642 25.5 24.75 25.5H3C2.44772 25.5 2 25.0523 2 24.5L2 0.75C2 0.335787 1.66421 0 1.25 0Z"
        fill={strokeColor}
      />
      <mask id="path-2-inside-1_18926_13925" fill="white">
        <path d="M3.5 6C3.5 5.44772 3.94772 5 4.5 5H7.5C8.05228 5 8.5 5.44772 8.5 6V27H3.5V6Z" />
      </mask>
      <path
        d="M3.5 6C3.5 5.44772 3.94772 5 4.5 5H7.5C8.05228 5 8.5 5.44772 8.5 6V27H3.5V6Z"
        stroke={strokeColor}
        strokeWidth="3"
        mask="url(#path-2-inside-1_18926_13925)"
        style={{ fill: 'none' }}
      />
      <mask id="path-3-inside-2_18926_13925" fill="white">
        <path d="M10.5 11C10.5 10.4477 10.9477 10 11.5 10H14.5C15.0523 10 15.5 10.4477 15.5 11V27H10.5V11Z" />
      </mask>
      <path
        d="M10.5 11C10.5 10.4477 10.9477 10 11.5 10H14.5C15.0523 10 15.5 10.4477 15.5 11V27H10.5V11Z"
        stroke={strokeColor}
        strokeWidth="3"
        mask="url(#path-3-inside-2_18926_13925)"
        style={{ fill: 'none' }}
      />
      <mask id="path-4-inside-3_18926_13925" fill="white">
        <path d="M17.5 17C17.5 16.4477 17.9477 16 18.5 16H21.5C22.0523 16 22.5 16.4477 22.5 17V27H17.5V17Z" />
      </mask>
      <path
        d="M17.5 17C17.5 16.4477 17.9477 16 18.5 16H21.5C22.0523 16 22.5 16.4477 22.5 17V27H17.5V17Z"
        stroke={strokeColor}
        strokeWidth="3"
        mask="url(#path-4-inside-3_18926_13925)"
        style={{ fill: 'none' }}
      />
    </svg>
  )
}
