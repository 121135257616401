import { labelsMap, labelType } from './config'
import { vacancyTypes } from '../Company/VacancyManagerForm/constants'

export const getSearchLabels = (values) => {
  return Object.entries(values).reduce((acc, [name, item]) => {
    const Label = labelsMap[item.type]

    if (!Label) return acc
    if (!item.value.id && !item.value.length) return acc

    return [...acc, { name, component: Label, ...item }]
  }, [])
}

export const getTemplateFilter = (data) => {
  return data.map(({ id, vacancy_type, vessel = {}, country, rank = {}, rank_name }) => {
    const name = (() => {
      const _name = vacancy_type !== vacancyTypes.land ? vessel.name ?? 'No name' : country.name

      return `${_name} (${rank.name ?? rank_name})`
    })()

    return { id, name }
  })
}

export const getDocumentFilter = (data) => {
  return data.map(({ document_name, document_type, id, document_id }) => ({
    name: `${document_name} (${document_type})`,

    id,
    //this construction (`${document_type}s`) is needed to correctly obtain sailors by document filter
    document_type: `${document_type}s`,
    document_id,
  }))
}

export const getTemplateOptions = (data) => {
  const getDataArr = (data) => {
    if (data && data.id) return [data]

    return []
  }

  return data.map(({ id, rank, vessel = {} }) => ({
    id,

    documents: { type: labelType.multiple, value: [] },
    ranks: { type: labelType.multiple, value: [rank] },

    vessel_engines: { type: labelType.multiple, value: getDataArr(vessel.engine_type) },
    vessel_types: { type: labelType.multiple, value: getDataArr(vessel.vessel_type) },
    nationalities: { type: labelType.multiple, value: getDataArr(vessel.crew_nationality) },

    teu: { type: labelType.string, value: vessel.teu ?? '' },
    dwt: { type: labelType.string, value: vessel.dwt ?? '' },
    grt: { type: labelType.string, value: vessel.grt ?? '' },
    hp: { type: labelType.string, value: vessel.hp ?? '' },
    kw: { type: labelType.string, value: vessel.kw ?? '' },
    search: { type: labelType.default, value: '' },
  }))
}

export const searchHelper = (values) => {
  const documentsHelper = (documents) => {
    return documents.reduce((acc, { document_type, document_id }) => {
      if (!acc[document_type]) {
        return { ...acc, [document_type]: [document_id] }
      }

      return { ...acc, [document_type]: [...acc[document_type], document_id] }
    }, {})
  }

  const getIdArr = (data) => {
    return data.map(({ id }) => id)
  }

  return {
    ...documentsHelper(values.documents.value),

    template: values.template?.value?.id ?? '',
    vacancy_id: values.sort?.value?.id ?? '',
    candidates: values.candidates?.value?.id ?? '',
    teu: values.teu.value,
    dwt: values.dwt.value,
    grt: values.grt.value,
    hp: values.hp.value,
    kw: values.kw.value,
    search: values.search.value,
    ordering: values.ordering,

    ranks: getIdArr(values.ranks.value),
    nationalities: getIdArr(values.nationalities.value),
    vessel_types: getIdArr(values.vessel_types.value),
    vessel_engines: getIdArr(values.vessel_engines.value),
  }
}
