import * as Yup from 'yup'

export const initialValues = {
  user_type: { name: 'User role', id: null },
  first_name: '',
  last_name: '',
  position: { name: 'Position', id: null },
  email: '',
  is_active: true,
  avatar: {},
}

export const validationSchema = Yup.object().shape({
  user_type: Yup.object().shape({
    name: Yup.string().required(),
    id: Yup.number().required().positive().integer(),
  }),
  position: Yup.object().shape({
    name: Yup.string().required(),
    id: Yup.number().required().positive().integer(),
  }),
  first_name: Yup.string().required('Field is required'),
  last_name: Yup.string().required('Field is required'),
  email: Yup.string().required('Field is required'),
})
