import React, { useState } from 'react'

import styles from './styles.module.scss'

const Documents = ({ data }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const firstDocument = data[0] ?? {}

  const TooltipContent = () => {
    return (
      <div onScroll={(e) => e.stopPropagation()} onWheel={(e) => e.stopPropagation()} className={styles.columnTooltip}>
        {data.map(({ name, model_type, date, color }, idx) => (
          <div key={idx} className={styles.fieldModalRow}>
            <b>
              <span>{name}</span>
              <span>{` (${model_type}) `}</span>
            </b>

            <span style={{ color }}>{`- ${date}`}</span>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.field}>
      <span>Expired documents –</span>

      {data.length > 0 && (
        <div
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
          style={{ color: firstDocument.color }}
          className={styles.semi_bold}
        >
          {firstDocument.date}

          {tooltipVisible && <TooltipContent />}
        </div>
      )}

      {data.length === 0 && <p className={styles.semi_bold}>No Info</p>}
    </div>
  )
}

export default Documents
