import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import styles from './styles.module.scss'

import { ChatAside } from './ChatAside/ChatAside'
import { ChatRoom } from './ChatRoom/ChatRoom'

import { setProfileTitle } from '../../redux/actions/ui.action'
import socket from '../../websocket'
import { events } from '../../constants/constants'

const Chat = () => {
  const { id } = useParams()

  const { chat } = events

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setProfileTitle('Chats'))

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    socket.emit(chat.rooms, { chat_id: id })

    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (id) {
      socket.emit(chat.messages, { chat_id: id, last_message_id: null })
      socket.emit(chat.messageDelivered, { chat_id: id })
    }

    // eslint-disable-next-line
  }, [id])

  return (
    <div className={styles.wrapper}>
      <ChatAside chat_id={id} />
      <ChatRoom chat_id={id} />
    </div>
  )
}

export default Chat
