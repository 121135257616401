import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserProfile from '../../UserProfile/UserProfile'
import styles from './styles.module.scss'
import useClickOutside from '../../../hooks/useClickOutside'
import { CSSTransition } from 'react-transition-group'
import Support from '../../Support/Support'
import Operator from '../../Operator/Operator'
import { ReactComponent as Envelope } from '../../../assets/icons/udelivered-convert.svg'
import { ReactComponent as OperatorSvg } from '../../../assets/icons/operator.svg'
import { setIsSupportOpenedAction } from '../../../redux/support/support.action'

export default function Header() {
  const title = useSelector(({ ui }) => ui.profileTitle)
  const isSocketConnected = useSelector((state) => state.ui.isSocketConnected)
  const supportRef = useClickOutside(() => dispatch(setIsSupportOpenedAction(false)))
  const dispatch = useDispatch()
  const { loading, undelivered_count, is_opened, work } = useSelector(({ support }) => support)

  return (
    <header className={styles.header}>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.title}>{title || 'Profile'}</div>

        <div className={styles.right_side}>
          <Operator
            disabled={!isSocketConnected}
            isActive={is_opened}
            onClick={() => dispatch(setIsSupportOpenedAction(true))}
            style={{ marginRight: 6 }}
          >
            {undelivered_count && loading && work ? (
              <div className="envelope">
                <Envelope height={20} width={28} />
              </div>
            ) : (
              <OperatorSvg />
            )}
          </Operator>

          <UserProfile />
        </div>
      </div>

      {/* Support modal */}
      <div ref={supportRef}>
        <CSSTransition classNames="fadein-top" appear mountOnEnter unmountOnExit in={is_opened} timeout={300}>
          <Support className="support-main-chat-profile" />
        </CSSTransition>
      </div>
    </header>
  )
}
