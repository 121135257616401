import styles from '../styles.module.scss'
import React from 'react'

export const ExperienceTooltipContent = ({ data }) => {
  const { experience_by_engine, experience_by_vessel, experience_by_rank } = data

  if (!experience_by_engine.length && !experience_by_vessel.length && !experience_by_rank.length) return null

  return (
    <div className={styles.fieldModal}>
      {experience_by_vessel.length > 0 && (
        <div className={styles.fieldModalColumn}>
          <div className={styles.fieldModalRow}>
            <b className={styles.title}>By vessel</b>
          </div>

          {experience_by_vessel.map(({ name, value }) => (
            <div key={name + value} className={styles.fieldModalRow}>
              <b>{name}</b> - <span>{value}</span>
            </div>
          ))}
        </div>
      )}

      {experience_by_engine.length > 0 && (
        <div className={styles.fieldModalColumn}>
          <div className={styles.fieldModalRow}>
            <b className={styles.title}>By engine</b>
          </div>

          {experience_by_engine.map(({ name, value }) => (
            <div key={name + value} className={styles.fieldModalRow}>
              <b>{name}</b> - <span>{value}</span>
            </div>
          ))}
        </div>
      )}

      {experience_by_rank.length > 0 && (
        <div className={styles.fieldModalColumn}>
          <div className={styles.fieldModalRow}>
            <b className={styles.title}>By rank</b>
          </div>

          {experience_by_rank.map(({ name, value }) => (
            <div key={name + value} className={styles.fieldModalRow}>
              <b>{name}</b> - <span>{value}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
