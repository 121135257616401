export const VACANCY = {
  CLEAR_VACANCY_FORM: 'CLEAR_VACANCY_FORM',
  UPDATE_VACANCIES_LIST: 'UPDATE_VACANCIES_LIST',
  REMOVE_FROM_VACANCIES_LIST: 'REMOVE_FROM_VACANCIES_LIST',
  EDIT_VACANCY: 'EDIT_VACANCY',
  UPDATE_DELETED_VACANCIES_LIST: 'UPDATE_DELETED_VACANCIES_LIST',
  SHOW_VACANCY_ALERT: 'SHOW_VACANCY_ALERT',
  CLEAR_VACANCY_LIST: 'CLEAR_VACANCY_LIST',
  UPDATE_VACANCIES_FILTER: 'UPDATE_VACANCIES_FILTER',
  SET_IS_VACANCY_ENABLED: 'SET_IS_VACANCY_ENABLED',
  UPDATE_VACANCY: 'UPDATE_VACANCY',
  CREATE_NEW_VACANCY: 'CREATE_NEW_VACANCY',
  SET_IS_BUTTON_ENABLE: 'SET_IS_BUTTON_ENABLE',
  SET_DEFAULT_DATE: 'SET_DEFAULT_DATE',
  INCREMENT_ARCHIVE_VACANCY: 'INCREMENT_ARCHIVE_VACANCY',
  DECREMENT_ARCHIVE_VACANCY: 'DECREMENT_ARCHIVE_VACANCY',
  INCREMENT_ACTIVE_VACANCY: 'INCREMENT_ACTIVE_VACANCY',
  DECREMENT_ACTIVE_VACANCY: 'DECREMENT_ACTIVE_VACANCY',
  RESET_VACANCIES: 'RESET_VACANCIES',
  SET_VACANCY_TYPE: 'SET_VACANCY_TYPE',
}
