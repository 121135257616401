import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'
import errorLengthTextMessage from '../../assets/icons/error-length-text-message.svg'
import SupportHeader from './SupportHeader/SupportHeader'
import { useDispatch, useSelector } from 'react-redux'
import SupportMessage from './SupportMessage/SupportMessage'
import socket from '../../websocket'
import {
  editSupportMessage,
  replySupportMessage,
  resetTotalUndelivered,
  supportSetLoading,
} from '../../redux/support/support.action'
import Spinner from '../../UI/Spinner/Spinner'
import SupportOffline from './SupportOffline/SupportOffline'
import { getFirstName } from './helpers'
import { confirm } from '../../UI/Confirm/Confirm'
import SupportFooter from './SupportFooter/SupportFooter'
import { createItemRef } from '../../helpers/createItemRef'
import { getAllDates } from '../../helpers/time-helper/time-helper'

const Support = ({ className }) => {
  const [errorLengthText, setErrorLengthText] = useState(false)
  const [scrollDirection, setScrollDirection] = useState({ up: true, down: false })

  const dispatch = useDispatch()

  const {
    messages,
    support_online,
    loading,
    undelivered_count,
    work,
    has_more_up,
    has_more_down,
    user,
    searchMessageId,
  } = useSelector(({ support }) => support)

  const messagesRef = useRef(null)

  const refs = createItemRef(messages)

  const dates = getAllDates(messages.map(({ date, id }) => ({ date, id })))

  useEffect(() => {
    socket.emit('support.messages')

    socket.emit('support.chat.status')

    return () => {
      dispatch(supportSetLoading(true))
    }

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (work) {
      socket.emit('support.chat.message.delivered')

      dispatch(resetTotalUndelivered())
    }

    //eslint-disable-next-line
  }, [undelivered_count])

  useEffect(() => {
    if (messagesRef.current && !loading) {
      messagesRef.current.scroll({ top: 0 })
    }
  }, [loading, messages])

  useEffect(() => {
    if (searchMessageId) refs[searchMessageId].current.scrollIntoView()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMessageId])

  const handleScroll = (event) => {
    setScrollDirection({ up: false, down: false })

    const { scrollTop, scrollHeight } = event.target

    if (has_more_up && !loading && scrollDirection.up) {
      if (scrollTop + scrollHeight - 800 < 0) {
        dispatch(supportSetLoading(true))

        socket.emit('support.messages', {
          last_message_id: messages[messages.length - 1].id,
        })
      }

      return
    }

    if (has_more_down && !loading && scrollDirection.down) {
      if (scrollTop - scrollHeight + 800 < 0) {
        dispatch(supportSetLoading(true))

        socket.emit('support.messages', {
          reverse: true,
          last_message_id: messages[0].id,
        })
      }
    }
  }

  const handleReply = useCallback(
    (id) => {
      dispatch(replySupportMessage(id))
    },

    // eslint-disable-next-line
    []
  )

  const handleEdit = useCallback(
    (id) => {
      dispatch(editSupportMessage(id))
    },

    // eslint-disable-next-line
    []
  )

  const handleDelete = useCallback((id) => {
    confirm('Are you sure?', 'Do you want to delete this message?', () => {
      socket.emit('support.chat.delete.message', { message_id: id })
    })
  }, [])

  const handleReplyClick = useCallback((id) => {
    dispatch(supportSetLoading(true))

    socket.emit('search.support.message', {
      page_size: 5,
      message_id: id,
    })

    // eslint-disable-next-line
  }, [])

  const handleScrollDirection = (e) => {
    if (e.nativeEvent.wheelDelta > 0) {
      setScrollDirection({ up: true, down: false })
    } else {
      setScrollDirection({ down: true, up: false })
    }
  }

  return (
    <div className={cn(styles.chat, { [className]: className, [styles.offline]: !work })}>
      {work && (
        <>
          <SupportHeader
            isChatStarted={user?.name}
            avatar={user?.avatar}
            name={getFirstName(user)}
            isOnline={support_online}
          />

          <div className={styles.messages} ref={messagesRef} onScroll={handleScroll} onWheel={handleScrollDirection}>
            <div className={styles.container}>
              {messages.map((message) => (
                <Fragment key={message.id}>
                  <SupportMessage
                    refs={refs[message.id]}
                    replyMessage={message.reply}
                    editMessage={message.edited}
                    onReply={handleReply}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onReplyClick={handleReplyClick}
                    searchMessageId={searchMessageId}
                    {...message}
                  />

                  {dates.some((el) => el.id === message.id) && (
                    <div className={styles.message_day}>{dates.find((el) => el.id === message.id).value}</div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>

          <SupportFooter setErrorLengthText={setErrorLengthText} />

          {errorLengthText && (
            <div className={styles.maxLengthMessage}>
              <img src={errorLengthTextMessage} alt="error" />
              Уou entered the maximum number of characters!
            </div>
          )}
        </>
      )}

      {!work && !loading && <SupportOffline />}

      {loading && <Spinner />}
    </div>
  )
}

export default Support
