import { vacancyPriorityMap } from '../../constants/constants'
import { VACANCY } from './vacancyManager.types'
import { vacancyTypes } from '../../components/Company/VacancyManagerForm/constants'
import { helperEditDataVacancy } from '../../helpers/vacancyManager.helper'

const seaForm = {
  vacancy_type: vacancyTypes.sea,
  id: null,
  is_exact_date: false,
  title: '',
  vessel: { id: null, name: '' },
  vessel_type: { id: null, name: '' },
  join_date: null,
  join_date_range: { id: null, name: '' },
  duration: '',
  salary: '',
  salary_description: '',
  is_active: true,
  // publication date must be earlier join date
  published_at: new Date(new Date().setHours(0, 0, 0)),
  // publication date must be earlier join date
  rank: { id: null, name: '' },
  duration_period: { id: null, name: '' },
  salary_currency: { id: null, name: '' },
  salary_rate: { id: null, name: '' },
  description: '',
  show_shipowner: true,
  shipowner: { id: null, name: '' },
  vacancy_priority: vacancyPriorityMap.usual.value,
  duration_range: null,
}

const landForm = (() => {
  const { vessel, vessel_type, ...landRest } = seaForm

  return {
    ...landRest,

    vacancy_type: vacancyTypes.land,
    city: { id: null, name: '' },
    country: { id: null, name: '' },
  }
})()

const initialState = {
  list: [],
  deletedList: [],
  filters: {
    search: '',
    deleted: false,
  },
  formTypeSea: seaForm,
  formTypeLand: landForm,
  vacancyType: vacancyTypes.sea,
  inEditId: null,
  alert: null,
  isButtonEnable: false,
}

export default function vacancyManager(state = initialState, action) {
  switch (action.type) {
    case VACANCY.CLEAR_VACANCY_FORM:
      return {
        ...state,

        formTypeSea: initialState.formTypeSea,
        formTypeLand: initialState.formTypeLand,
        vacancyType: initialState.vacancyType,
        inEditId: null,
      }

    case VACANCY.UPDATE_VACANCIES_LIST:
      return {
        ...state,

        count_all: action.payload.count_all,
        count_deleted: action.payload.count_deleted,
        count_active: action.payload.count_all - action.payload.count_deleted,
        count: action.payload.count,
        list: [...state.list, ...action.payload.list],
      }

    case VACANCY.REMOVE_FROM_VACANCIES_LIST:
      return {
        ...state,

        list: state.list.filter((item) => item.id !== action.payload),
        inEditId: null,
      }

    case VACANCY.EDIT_VACANCY: {
      const { inEditId, vacancyType } = action.payload

      const formValues = state.list.find((item) => item.id === inEditId)

      if (vacancyType === vacancyTypes.land) {
        return {
          ...state,

          formTypeLand: helperEditDataVacancy(formValues, vacancyTypes.land),
          inEditId,
        }
      }

      return {
        ...state,

        formTypeSea: helperEditDataVacancy(formValues, vacancyTypes.sea),
        inEditId,
      }
    }

    case VACANCY.UPDATE_DELETED_VACANCIES_LIST:
      return {
        ...state,

        deletedList: [...action.payload],
      }

    case VACANCY.SHOW_VACANCY_ALERT:
      return {
        ...state,

        alert: action.payload,
      }

    case VACANCY.CLEAR_VACANCY_LIST:
      return {
        ...state,

        list: [],
      }

    case VACANCY.UPDATE_VACANCIES_FILTER:
      return {
        ...state,

        list: [],
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case VACANCY.SET_IS_VACANCY_ENABLED: {
      const { id, status } = action.payload

      return {
        ...state,

        list: state.list.map((item) => {
          return {
            ...item,

            is_active: item.id === id ? status : item.is_active,
          }
        }),
      }
    }

    case VACANCY.UPDATE_VACANCY: {
      const { id } = action.payload

      return {
        ...state,

        list: state.list.map((item) => (item.id === id ? action.payload : item)),
        inEditId: null,
      }
    }

    case VACANCY.CREATE_NEW_VACANCY:
      return {
        ...state,

        list: [action.payload, ...state.list],
        count_all: state.count_all + 1,
        count_active: state.count_active + 1,
      }

    case VACANCY.SET_IS_BUTTON_ENABLE:
      return {
        ...state,

        isButtonEnable: action.payload,
      }

    case VACANCY.INCREMENT_ARCHIVE_VACANCY: {
      return {
        ...state,

        count_deleted: state.count_deleted + 1,
      }
    }

    case VACANCY.DECREMENT_ARCHIVE_VACANCY: {
      return {
        ...state,

        count_deleted: state.count_deleted - 1,
      }
    }

    case VACANCY.INCREMENT_ACTIVE_VACANCY: {
      return {
        ...state,

        count_active: state.count_active + 1,
      }
    }

    case VACANCY.DECREMENT_ACTIVE_VACANCY: {
      return {
        ...state,

        count_active: state.count_active - 1,
      }
    }

    case VACANCY.RESET_VACANCIES: {
      return {
        list: [],
        deletedList: [],
        filters: initialState.filters,
        formTypeSea: initialState.formTypeSea,
        inEditId: null,
        alert: null,
        isButtonEnable: false,
      }
    }

    case VACANCY.SET_VACANCY_TYPE:
      return {
        ...state,

        vacancyType: action.payload,
        formTypeLand: initialState.formTypeLand,
        formTypeSea: initialState.formTypeSea,
        inEditId: initialState.inEditId,
      }

    default:
      return state
  }
}
