import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  old_password: Yup.string()
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/,
      'Password should only contain Latin letters, numbers, and special characters'
    )
    .matches(/[a-zA-Z]+/, 'Password should contain at least one letter')
    .matches(/[0-9]+/, 'Password should contain at least one number')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(20, 'Password is too long - should be 20 chars maximum.')

    .required('Field is required'),
  new_password: Yup.string()
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/,
      'Password should only contain Latin letters, numbers, and special characters'
    )
    .matches(/[a-zA-Z]+/, 'Password should contain at least one letter')
    .matches(/[0-9]+/, 'Password should contain at least one number')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(20, 'Password is too long - should be 20 chars maximum.')

    .required('Field is required')
    .notOneOf([Yup.ref('old_password')], 'Your new password must not be the same as your old password'),
  confirm: Yup.string()
    .required('Field is required')
    .oneOf([Yup.ref('new_password'), null], 'The password must match the new password'),
})
