import React from 'react'

import styles from './vesselNameHistory.module.scss'

import Spinner from '../../../../UI/Spinner/Spinner'

import CrossIcon from '../../../../UI/Icons/CrossIcon'

import { getDateByFormat } from '../../../../helpers/time-helper/time-helper'

const VesselNameHistory = ({ vessels, onClick, onScroll, loading }) => {
  const getLabel = (item) => {
    const { name, date_from, date_till } = item

    const dateFrom = getDateByFormat(date_from, 'MM/yyyy')
    const dateTo = getDateByFormat(date_till, 'MM/yyyy')

    return `${name} (${dateFrom}-${dateTo})`
  }

  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={onClick}>
        <CrossIcon stroke="var(--color-accent-light)" />
      </button>

      <p className={styles.label}>This vessel also had other names:</p>

      <span>(Information entered by other users)</span>

      <div className={styles.body} onScroll={onScroll}>
        <span>{!vessels.length && '—'}</span>

        {vessels.map((item, index) => (
          <p key={index}>{getLabel(item)}</p>
        ))}

        {loading && <Spinner className={styles.spinner} />}
      </div>
    </div>
  )
}

export default VesselNameHistory
