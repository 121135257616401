import React from 'react'

import styles from './styles.module.scss'

import Button from '../../UI/Button/Button'

import useClickOutside from '../../hooks/useClickOutside'

const PopupLoginMobile = ({ onClose }) => {
  const popup = useClickOutside(() => onClose())

  return (
    <div className={styles.wrapper}>
      <div ref={popup} className={styles.modal}>
        <div className={styles.main}>
          <p>In order to log in, you can use the computer version of the site or download the mobile app</p>

          <Button className={styles.button} onClick={onClose}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PopupLoginMobile
