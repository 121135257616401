import React, { memo } from 'react'

import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ReactComponent as FilterIcon } from './icons/filter.svg'
import { ReactComponent as XIcon } from './icons/x.svg'

const FilterForm = memo(({ children, isOpen, onButtonClick }) => {
  const name = (() => {
    if (isOpen) return 'cancel'

    return 'filter'
  })()

  const Icon = (() => {
    if (isOpen) return <XIcon />

    return <FilterIcon />
  })()

  return (
    <div className={styles.wrapper}>
      <button className={cn(styles.button, { [styles.button__active]: isOpen })} onClick={onButtonClick}>
        <span>{name}</span>

        {Icon}
      </button>

      {isOpen && <div className={styles.children}>{children}</div>}
    </div>
  )
})

FilterForm.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FilterForm
