import React from 'react'
import styles from './rolePermissionsTable.module.scss'
import Label from './Label'
import { permission, role, section } from '../config'

const RolePermissionsTable = () => {
  return (
    <div className={styles.contentWrapper}>
      <h3 className={styles.title}>
        This table describes the interaction between user roles and access rights in the program. It helps determine the
        actions available to each role. Administrators can use it to efficiently manage access rights, ensuring security
        and consistency in the system.
      </h3>

      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <th>Access rights</th>
          <th>{role.owner}</th>
          <th>{role.admin}</th>
          <th>{role.manager}</th>
          <th>{role.operator}</th>
          <th>{role.accountant}</th>
        </thead>

        <tr className={styles.tableItem}>
          <th>{section.billing}</th>

          {Object.values(permission[section.billing]).map((item) => (
            <td>
              <Label label={item} />
            </td>
          ))}
        </tr>

        <tr className={styles.tableItem}>
          <th>{section.company_profile}</th>

          {Object.values(permission[section.company_profile]).map((item) => (
            <td>
              <Label label={item} />
            </td>
          ))}
        </tr>

        <tr className={styles.tableItem}>
          <th>{section.users}</th>

          {Object.values(permission[section.users]).map((item) => (
            <td>
              <Label label={item} />
            </td>
          ))}
        </tr>

        <tr className={styles.tableItem}>
          <th>{section.vacancy}</th>

          {Object.values(permission[section.vacancy]).map((item) => (
            <td>
              <Label label={item} />
            </td>
          ))}
        </tr>

        <tr className={styles.tableItem}>
          <th>{section.vessel}</th>

          {Object.values(permission[section.vessel]).map((item) => (
            <td>
              <Label label={item} />
            </td>
          ))}
        </tr>

        <tr className={styles.tableItem}>
          <th>{section.chat}</th>

          {Object.values(permission[section.chat]).map((item) => (
            <td>
              <Label label={item} />
            </td>
          ))}
        </tr>

        <tr className={styles.tableItem}>
          <th>{section.seafarers_favourite}</th>

          {Object.values(permission[section.seafarers_favourite]).map((item) => (
            <td>
              <Label label={item} />
            </td>
          ))}
        </tr>

        <tr className={styles.tableItem}>
          <th>{section.vacancy_request}</th>

          {Object.values(permission[section.vacancy_request]).map((item) => (
            <td>
              <Label label={item} />
            </td>
          ))}
        </tr>
      </table>
    </div>
  )
}

export default RolePermissionsTable
