import React from 'react'
import replyIcon from '../../assets/icons/reply.svg'

const CompanionControls = ({ id, onReply, className }) => {
  return (
    <div className={className}>
      <button onClick={() => onReply(id)}>
        <img src={replyIcon} alt="reply message" />
      </button>
    </div>
  )
}

export default CompanionControls
