import React from 'react'

const MessagesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" fill="none" viewBox="0 0 28 27">
      <path
        fill="#43927C"
        stroke="#43927C"
        strokeWidth="0.2"
        d="M24.484 5.859h-2.43v-2.43A2.43 2.43 0 0019.626 1H3.43A2.43 2.43 0 001 3.43v12.956a2.43 2.43 0 002.43 2.43h2.429v2.429a2.43 2.43 0 002.43 2.43h4.8l2.724 2.723a.811.811 0 001.146 0l2.725-2.724h4.8a2.43 2.43 0 002.43-2.43V8.289a2.43 2.43 0 00-2.43-2.43zM2.62 16.386V3.43a.81.81 0 01.81-.81h16.195a.81.81 0 01.81.81v12.957a.81.81 0 01-.81.81H14.49a.811.811 0 00-.574.236l-2.389 2.39-2.389-2.39a.811.811 0 00-.573-.236H3.43a.81.81 0 01-.81-.81zm22.674 4.859a.81.81 0 01-.81.81h-5.136a.81.81 0 00-.573.236l-2.389 2.39-2.389-2.39a.809.809 0 00-.573-.236H8.288a.81.81 0 01-.81-.81v-2.43h.752l2.724 2.725a.812.812 0 001.147 0l2.724-2.724h4.8a2.43 2.43 0 002.43-2.43V7.478h2.43a.81.81 0 01.809.81v12.957z"
      ></path>
      <path
        fill="#43927C"
        d="M6.67 10.718a.81.81 0 100-1.62.81.81 0 000 1.62zM11.525 10.718a.81.81 0 100-1.62.81.81 0 000 1.62zM16.384 10.718a.81.81 0 100-1.62.81.81 0 000 1.62z"
      ></path>
    </svg>
  )
}

export default MessagesIcon
