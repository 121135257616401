import React, { useState } from 'react'

import { useFormik } from 'formik'

import styles from './styles.module.scss'

import Wrapper from '../Wrapper/Wrapper'
import Input from '../../../../UI/Input/Input'
import Button from '../../../../UI/Button/Button'
import Radio from '../../../../UI/Input/Radio'

import { useNotify } from '../../../../hooks/useNotify'
import { unsubscribeBillingProduct } from '../../../../api/postApi'
import { getBillingSubscription, getBillingSubscriptionPlans } from '../../../../api/getApi'
import { updatePaidIds, updateUnProlongedProducts as helper } from '../../Services/utils'

const UnsubscribeForm = ({
  reasons,
  product,
  successProcessPayment,
  unSuccessProcessPayment,
  updateMarkedPaidIds,
  updateUnProlongedProducts,
  onClose,
}) => {
  const [loading, setLoading] = useState(false)

  const { notify } = useNotify()

  const formik = useFormik({
    initialValues: {
      product_id: product.id,
      reason_id: '',
      comment: '',
    },

    enableReinitialize: true,

    onSubmit: async (values) => {
      setLoading(true)

      const updateProducts = async () => {
        try {
          const [billingSubscription, billingSubscriptionPlan] = await Promise.all([
            getBillingSubscription(),
            getBillingSubscriptionPlans(),
          ])

          const paidResult = updatePaidIds(billingSubscription.subscription)
          const unProlongedResult = helper(billingSubscription.subscription, billingSubscriptionPlan.results)

          updateMarkedPaidIds(paidResult)
          updateUnProlongedProducts(unProlongedResult)

          successProcessPayment(`You have successfully unsubscribed from the ${product.name}`)

          onClose()
        } catch (error) {
          notify.errorsList(error.errors)
        } finally {
          setLoading(false)
        }
      }

      try {
        await unsubscribeBillingProduct(values)

        await updateProducts()
      } catch (error) {
        unSuccessProcessPayment(error.errors)
        onClose()
      } finally {
        setLoading(false)
      }
    },
  })

  const { values, setValues } = formik

  const handleCommentChange = ({ target }) => {
    setValues({
      ...values,
      comment: target.value,
    })
  }

  const handleItemClick = (id) => {
    setValues({
      ...values,
      reason_id: [id],
    })
  }

  return (
    <Wrapper className={styles.form}>
      <form onSubmit={formik.handleSubmit}>
        <h3 className={styles.title}>
          Are you sure you want to unsubscribe from the <span className={styles.productName}>{`${product.name}?`}</span>
        </h3>

        <p className={styles.description}>
          Please select the reason for your subscription cancellation or provide your own comment. Your feedback is
          highly valuable to us!
        </p>

        <ul className={styles.list}>
          {reasons.map((reason) => {
            return (
              <ol key={reason.id} className={styles.item} onClick={() => handleItemClick(reason.id)}>
                <Radio
                  name={reason.id}
                  theme="checked"
                  label={reason.name}
                  value={reason.id}
                  onChange={(e) => e}
                  isChecked={Number(values.reason_id) === reason.id}
                  className={styles.radioBtn}
                />
              </ol>
            )
          })}
        </ul>

        <Input
          name="comment"
          type="text"
          placeholder="Your comment"
          className={styles.comment}
          value={values.comment}
          onChange={handleCommentChange}
          rows={22}
          textarea
        />

        <div className={styles.controls}>
          <Button
            type="submit"
            className={styles.unsubscribe}
            loading={loading}
            disabled={!values.reason_id || loading}
          >
            Unsubscribe
          </Button>

          <Button type="button" theme="transparent" className={styles.close} onClick={onClose} disabled={loading}>
            Close
          </Button>
        </div>
      </form>
    </Wrapper>
  )
}

export default UnsubscribeForm
