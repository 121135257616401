import React from 'react'

import styles from './styles.module.scss'

import Button from '../../UI/Button/Button'

const Buttons = ({ onClick }) => {
  return (
    <div className={styles.buttonsWrapper}>
      <Button type="button" size="small" theme="transparent" onClick={onClick}>
        Close
      </Button>

      <Button type="submit" size="small">
        Save&Apply
      </Button>
    </div>
  )
}

export default Buttons
