import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import Input from '../../../UI/Input/Input'

import { tabName } from './VesselManagerList'

const VesselManagerListHeader = ({ onClick, onChange, value, allCount, delCount, activeTab }) => {
  return (
    <div className={styles.header}>
      <button
        className={cn(styles.tab, { [styles.tab__active]: activeTab === tabName.all })}
        onClick={onClick}
        name={tabName.all}
      >
        All vessels {allCount ? `(${allCount})` : null}
      </button>

      <button
        className={cn(styles.tab, { [styles.tab__active]: activeTab === tabName.del })}
        onClick={onClick}
        name={tabName.del}
      >
        Deleted {delCount ? `(${delCount})` : null}
      </button>

      <div className={styles.input}>
        <Input theme="search" value={value} onChange={onChange} />
      </div>
    </div>
  )
}

export default VesselManagerListHeader
