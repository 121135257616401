import { getCompanyInfo, getCompanyPicture } from '../../api/getApi'
import { notify } from '../../helpers/notify'
import { COMPANY } from '../constants'

export const getCompanyProfile = () => {
  return async (dispatch) => {
    try {
      const profile = await getCompanyInfo()

      dispatch(getUserInfo(profile))
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }
}

export const getUserInfo = (payload) => ({
  type: COMPANY.GET_COMPANY_INFO,
  payload,
})

export const updateCompanyInfo = (payload) => ({
  type: COMPANY.UPDATE_COMPANY_INFO,
  payload,
})

export const getCompanyPictureFetch = (id) => {
  return async (dispatch) => {
    try {
      const data = await getCompanyPicture(id)

      dispatch(changeCompanyPicture(data))
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }
}

export const changeCompanyPicture = (payload) => ({
  type: COMPANY.CHANGE_COMPANY_PICTURE,
  payload,
})

export const setPermissionsList = (payload) => ({
  type: COMPANY.SET_PERMISSIONS_LIST,
  payload,
})

export const setBillingPermissions = (payload) => ({
  type: COMPANY.SET_BILLING_PERMISSIONS_CURRENT,
  payload,
})
