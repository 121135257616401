import { COMPANY } from '../constants'

const initialState = {
  address: { id: null, name: '' },
  city: { id: null, name: '' },
  country: { id: null, name: '' },
  files: [],
  id: null,
  name: '',
  on_moderation: false,
  allow_moderation: false,
  is_active: false,
  last_login: null,
  phone: '',
  picture: null,
  terms_accepted_at: null,
  company_logo: '',
  billing_permissions: {},
  permissions: [],
}

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY.GET_COMPANY_INFO:
      return {
        ...state,
        company_logo: action.payload.picture,
        ...action.payload,
      }

    case COMPANY.CHANGE_COMPANY_PICTURE:
      return {
        ...state,
        picture: action.payload,
      }

    case COMPANY.UPDATE_COMPANY_INFO:
      return {
        ...state,
        ...action.payload,
      }

    case COMPANY.SET_PERMISSIONS_LIST:
      return {
        ...state,
        ...action.payload,
      }

    case COMPANY.SET_BILLING_PERMISSIONS_CURRENT:
      return {
        ...state,
        billing_permissions: action.payload,
      }

    default:
      return state
  }
}

export default companyReducer
