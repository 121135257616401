import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNotify } from './useNotify'

export default function useInfiniteScroll(
  requestFunction,
  updateAction,
  apiHelper = null,
  query = '',
  clearFunction = null,
  withoutDispatch = false
) {
  const [loading, setLoading] = useState(true)
  const [nextPage, setNextPage] = useState(1)
  const [dataCount, setDataCount] = useState(0)

  const currentPage = useRef()
  const isFetching = useRef(false)
  const dispatch = useDispatch()
  const { notify } = useNotify()

  currentPage.current = nextPage

  useEffect(() => {
    if (withoutDispatch) updateAction([])

    setNextPage(1)
    currentPage.current = 1

    if (clearFunction) dispatch(clearFunction())

    // eslint-disable-next-line
  }, [query, clearFunction])

  useEffect(() => {
    if (isFetching.current) return

    if (nextPage) {
      setLoading(true)

      isFetching.current = true

      requestFunction(currentPage.current, query)
        .then((data) => {
          setLoading(false)
          setDataCount(data.count)

          isFetching.current = false

          if (!data.next) setNextPage(null)
          if (!withoutDispatch && apiHelper) dispatch(updateAction(apiHelper(data.results, data.count, data)))
          if (withoutDispatch) updateAction((prev) => [...prev, ...data.results])
        })
        .catch((error) => {
          setLoading(false)

          notify.errorsList(error.errors)

          // notify.error('Error', 'Something went wrong!')
        })
    }
    //eslint-disable-next-line
  }, [nextPage, requestFunction, apiHelper, updateAction, query])

  const handleScroll = ({ target }) => {
    if (nextPage) {
      if (window.innerHeight + target.scrollTop !== target.offsetHeight && !loading) {
        setLoading(true)
        setNextPage(currentPage.current + 1)
      } else {
        return null
      }
    }
  }

  return { loading, nextPage, handleScroll, dataCount, setDataCount }
}
