import React from "react";
import Button from "../../../Button/Button";
import PropTypes from "prop-types";

function MTButton({ onClick, disabled = false, children, Icon }) {
  const stylesButton = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 7,
    maxWidth: 247,
  };

  return (
    <Button
      type="button"
      size="middle-full"
      onClick={onClick}
      disabled={disabled}
      style={stylesButton}
    >
      {children}
      {Icon && <Icon />}
    </Button>
  );
}

MTButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MTButton;
