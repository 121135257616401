import React from 'react'
import { uniqueId } from 'lodash'
import UserCardSmartTooltip from '../../UserCardSmartTooltip/UserCardSmartTooltip'
import styles from '../../userCard.module.scss'
import { ReactComponent as LinkedInIcon } from '../../../../assets/icons/linkedin-dark.svg'
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/facebook-dark.svg'
import { ReactComponent as DefaultSocialMediaIcon } from '../../../../assets/icons/globe.svg'
import { ReactComponent as InstagramIcon } from '../../../../assets/icons/instagram.svg'
import cn from 'classnames'

const ContactInfoItem = ({ title, items, nextOfKin = false }) => {
  const getIcon = (type) => {
    switch (type) {
      case 'facebook':
        return <FacebookIcon />
      case 'linkedin':
        return <LinkedInIcon />
      case 'instagram':
        return <InstagramIcon />
      case 'skype':
        return <DefaultSocialMediaIcon />
      default:
        return <DefaultSocialMediaIcon />
    }
  }

  return (
    <div className={styles.contentItem}>
      <div className={styles.header}>
        <UserCardSmartTooltip
          type={'simple'}
          title={`${title} ${nextOfKin ? '(Next of Kin)' : ''}`}
          titleClass={styles.contentItemTitle}
          id={uniqueId('contact-item-tooltip-')}
        />
      </div>
      <div className={styles.body}>
        {items?.map((item, index) => {
          if (title !== 'Social networks') {
            return (
              <UserCardSmartTooltip
                key={index}
                title={item.title}
                value={item.value}
                id={uniqueId('contact-item-tooltip-')}
                isMain={item.is_main}
                isGeneral={item.is_general}
                messengers={item.messengers}
                containerClass={styles.item}
                titleClass={cn(styles.itemTitle, styles.contactInfoTitle)}
                valueClass={styles.itemValue}
              />
            )
          } else {
            return (
              <UserCardSmartTooltip
                key={index}
                type={'link'}
                title={getIcon(item.title)}
                value={item.value}
                id={uniqueId('social-contact-item-tooltip-')}
                isMain={item.is_main}
                containerClass={styles.item}
                titleClass={styles.itemImageContainer}
                valueClass={styles.link}
              />
            )
          }
        })}
      </div>
    </div>
  )
}

export default ContactInfoItem
