import styles from '../CompanyDataPage.module.scss'
import Autocomplete from '../../../UI/Select/Autocomplete'
import Input from '../../../UI/Input/Input'
import cn from 'classnames'
import React from 'react'

const Address = ({
  handleAutocompleteFocus,
  handleAutocompleteChange,
  handleAutocompleteClick,
  formik,
  address,
  handleInputChange,
  isInputsDisabled,
}) => {
  const { values } = formik
  return (
    <div className={styles.item}>
      <div className={styles.item_body_main}>
        <div className={styles.firstInput}>
          <Autocomplete
            label="Address"
            name="address"
            disabled={!values.city?.id || isInputsDisabled}
            onFocus={handleAutocompleteFocus}
            getTitle="description"
            value={values.address?.name}
            // isInvalid={Boolean(errors.address?.name && touched.address)}
            // errorMessage={errors.address?.name}
            onChange={handleAutocompleteChange}
            onClick={handleAutocompleteClick}
            items={address}
            // required={values.city?.id}
          />
        </div>
        <Input
          className={cn(styles.company_data_input, { [styles.secondInput]: true })}
          name="zip"
          label="ZIP code"
          value={values.zip}
          onChange={handleInputChange}
          disabled={isInputsDisabled}
        />
        <Input
          className={styles.company_data_input}
          name="office"
          label="Office №"
          value={values.office}
          onChange={handleInputChange}
          disabled={isInputsDisabled}
        />
      </div>
    </div>
  )
}
export default Address
