import React, { useCallback, useEffect, useState } from 'react'

import styles from './disabledStore.module.scss'
import cn from 'classnames'

import OurServiceItem from '../sections/OurServices/OurServiceItem'
import stylesServices from '../sections/OurServices/OurServices.module.scss'

import { getBillingCommercialServices } from '../../api/getApi'
import { _count_services } from '../../helpers/services'
import { notify } from '../../helpers/notify'

const DisabledStore = () => {
  const [isOpen, setIsOpen] = useState(null)
  const [services, setServices] = useState([])

  const handleOpenService = useCallback((id, isOpen) => {
    setIsOpen(isOpen ? null : id)
  }, [])

  useEffect(() => {
    const getServices = async () => {
      try {
        const { results } = await getBillingCommercialServices()

        setServices(results)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    getServices()
  }, [])

  return (
    <section className={cn(styles.disabled_billing_section, 'container')}>
      <h3>Introducing our Free Subscription Plan!</h3>

      <div className={styles.billing_disabled_block}>
        <div className={cn(stylesServices.our_services_list, styles.services_list)}>
          {services.length === 0 &&
            _count_services.map((item, index) => {
              return (
                <div key={index} className={cn(stylesServices.our_services_preloader, 'preloader')}>
                  <div className={cn(stylesServices.our_services_preloader_title, 'preloader-text')}></div>
                  <div className={cn(stylesServices.our_services_preloader_description, 'preloader-text')}></div>
                  <div className={cn(stylesServices.our_services_preloader_description, 'preloader-text')}></div>
                  <div className={cn(stylesServices.our_services_preloader_marker, 'preloader-text')}></div>
                </div>
              )
            })}

          {services.length > 0 &&
            services.map((service) => (
              <OurServiceItem
                key={service.order}
                service={service}
                handleOpenService={handleOpenService}
                isOpen={isOpen === service.order}
              />
            ))}
        </div>

        <div className={cn(stylesServices.our_services_subscription_free_plan, styles.services_subscription_free_plan)}>
          <p className={cn(stylesServices.our_services_subscription_free_plan_description, styles.description)}>
            We offer a job search service for seafarers with <br />
            <span>free basic features.</span> You can post job vacancies, browse profiles, and communicate with
            seafarers for free.
          </p>

          <p className={cn(stylesServices.our_services_subscription_free_plan_description, styles.description)}>
            During the promotional period, we are pleased to offer free access to all features for CrewManager.
          </p>

          <div className={stylesServices.our_services_subscription_free_plan_image} />

          <p className={cn(stylesServices.our_services_subscription_free_plan_description, styles.description)}>
            Discover enhanced search filters and find the best maritime professionals effortlessly. These options allow
            you to optimize your crew selection process and access to additional data
          </p>
        </div>
      </div>
    </section>
  )
}

export default DisabledStore
