import { ReactComponent as ChartIcon } from './PublishedVacanciesChart/icons/chart.svg'
import { ReactComponent as ListIcon } from './PublishedVacanciesChart/icons/list.svg'

export const view = {
  chart: 'chart',
  list: 'list',
}

export const viewTabs = {
  chart: { name: view.chart, icon: ChartIcon },
  list: { name: view.list, icon: ListIcon },
}
