import React from 'react'
import styles from '../userCard.module.scss'
import VideosResumeItem from './VideoResumeItem/VideosResumeItem'
import { isEmpty } from 'lodash'
import { ReactComponent as NoInfoImage } from '../../../assets/images/video_resume_stub.svg'

const VideoResume = ({ userCardData }) => {
  const { video_resume } = userCardData

  if (isEmpty(video_resume)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={460} height={260} />
        <p>Video resume has not been uploaded yet.</p>
      </div>
    )
  } else {
    return (
      <div className={styles.videoResumeContainer}>
        <VideosResumeItem videoResume={video_resume} />
      </div>
    )
  }
}

export default VideoResume
