import React, { useEffect } from "react";
import styles from "./Image.module.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";

export default ({ src, alt, className = "", preloader, ...props }) => {
  const classes = `${styles.wrapper} ${className}`;

  const [loadedSrc, setLoadedSrc] = React.useState(null);
  useEffect(() => {
    if (src) {
      const handleLoad = () => {
        setLoadedSrc(src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [src]);
  return (
    <div className={classes} {...props}>
      <SwitchTransition>
        <CSSTransition
          key={loadedSrc === src ? "image" : "preloader"}
          classNames="fadein"
          timeout={0}
        >
          {loadedSrc === src ? (
            <img src={src} alt={alt} className={styles.image} />
          ) : (
            preloader || <div className={styles.preloader} />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
