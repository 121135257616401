import React, { memo } from 'react'

import { useHistory } from 'react-router-dom'

import styles from './styles.module.scss'
import cn from 'classnames'

import { Ellipsis } from '../../../../UI/Icons/Icons'
import noAvatar from '../../../../assets/images/no-avatar.svg'

import Image from '../../../../UI/Image/Image'
import Dropdown from '../../../../UI/Dropdown/Dropdown'
import Menu from '../../../../UI/Menu/Menu'
import MenuItem from '../../../../UI/Menu/MenuItem'

import { isServiceAvailable } from '../../../../helpers/helpers'
import { billingPermission, profilePaths } from '../../../../constants/constants'

export const Header = memo(({ interlocutor, isOnline }) => {
  const isResumeSearchAccess = isServiceAvailable(billingPermission.resume_search_access)

  const history = useHistory()

  const handleMenuClick = () => {
    const { id = null, name = '' } = interlocutor ?? {}

    const page = isResumeSearchAccess ? 'search' : 'requests'

    if (!id || !name) return

    history.push(`${profilePaths.seafarers}/${page}/${id}/?name=${name}`)
  }

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <Image
          className={cn(styles.avatar, { [styles.avatar__online]: isOnline })}
          src={interlocutor.avatar ?? noAvatar}
          alt="user avatar"
        />

        <p className={styles.userInfo}>
          <span>
            Your chat with seafarer <b>{interlocutor.name}</b>
          </span>
        </p>
      </div>

      <Dropdown
        clickEverywhere
        overlay={
          <Menu>
            <MenuItem name="profile" onClick={handleMenuClick}>
              <b>View profile</b>
            </MenuItem>
          </Menu>
        }
      >
        <button className={styles.moreButton}>
          <Ellipsis />
        </button>
      </Dropdown>
    </div>
  )
})
