import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.module.scss'

const SelectItem = ({ name, id, displayName, isActive, withIcon, onClick, refs, ...rest }) => {
  return (
    <li
      ref={refs}
      name={name}
      id={id}
      className={cn(styles.item, { [styles.item_active]: isActive }, { [styles.withIcon]: withIcon })}
      onClick={() => onClick(id, name)}
      {...rest}
    >
      <p>{displayName ? displayName : name}</p>
    </li>
  )
}

SelectItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayName: PropTypes.string,
  isActive: PropTypes.bool,
  withIcon: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

SelectItem.defaultProps = {
  withIcon: false,
}

export default SelectItem
