import PropTypes from 'prop-types'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import SelectItem from '../SelectItem'
import Input from '../../Input/Input'
import MTButtonWithSearch from './MTButtonWithSearch/MTButtonWithSearch'
import mtButton from '../../Select/VesselAutocomplete/MTButton/mtButton.module.scss'
import { ReactComponent as vesselIcon } from '../../../assets/icons/vessel.svg'
import { getCompleteVesselInfo } from '../../../helpers/autocomplete.helper'
import useClickOutside from '../../../hooks/useClickOutside'
import useInput from '../../../hooks/useInput'
import useDebounce from '../../../hooks/useDebounce'

const VesselAutocomplete = ({
  name,
  value,
  label,
  placeholder,
  getTitle,
  items,
  cursor,
  onClick,
  onChange,
  onFocus,
  handleScroll,
  onSuccess,
  onError,
  isOpen,
  setIsOpen,
  isInvalid,
  required,
  errorMessage,
  disabled,
}) => {
  const [selectedId, setSelectedId] = useState(null)
  const [activeIndex, setActiveIndex] = useState(-1)

  const selectEl = useClickOutside(() => setIsOpen(false))

  const { value: inputValue, onChange: handleChange, setValue } = useInput(value)

  const debounceValue = useDebounce(inputValue)

  useEffect(() => {
    if (inputValue !== value) {
      onChange(name, { target: { value: inputValue } })
    }

    // eslint-disable-next-line
  }, [debounceValue])

  useEffect(() => {
    setValue(value)

    // eslint-disable-next-line
  }, [value])

  const handleItemClick = (id, value) => {
    setIsOpen(false)
    setSelectedId(id)

    onClick(name, { id, name: value })
  }

  const getValidVesselName = (name) => {
    const isCharValid = (char) => {
      const forbiddenSymbols = [' ']
      return !forbiddenSymbols.includes(char)
    }

    let canTakeForbiddenSymbols = false

    return name.split('').reduce((acc, char) => {
      if (isCharValid(char)) {
        canTakeForbiddenSymbols = true
      }
      if (canTakeForbiddenSymbols) {
        return acc + char
      } else {
        return acc
      }
    }, '')
  }

  const handleKeyDown = (e) => {
    if (!isOpen) {
      return
    }

    if (e.key === 'ArrowUp' && activeIndex > 0) {
      e.preventDefault()

      setActiveIndex(activeIndex - 1)

      return
    }

    if (e.key === 'ArrowDown' && activeIndex < items.length - 1) {
      e.preventDefault()

      setActiveIndex(activeIndex + 1)

      return
    }

    if (e.key === 'Enter' && activeIndex !== -1) {
      const id = items[activeIndex].id
      const name = items[activeIndex].name

      handleItemClick(id, name)
    }
  }

  return (
    <>
      {label && (
        <label
          className={cn(styles.label, {
            [styles.label_invalid]: isInvalid,
            [styles.label_disabled]: disabled,
          })}
        >
          {label} {required && <span className="required-star">*</span>}
        </label>
      )}

      <div
        ref={selectEl}
        className={cn(styles.wrapper, {
          [styles.wrapper_invalid]: isInvalid,
          [styles.noEvent]: disabled,
        })}
        onFocus={() => setIsOpen(true)}
        onKeyDown={handleKeyDown}
      >
        <Input
          autoComplete="off"
          role="presentation"
          onFocus={(e) => onFocus(name, e)}
          placeholder={placeholder}
          onChange={handleChange}
          value={getValidVesselName(inputValue)}
          name={name}
          disabled={disabled}
        />

        {isInvalid && errorMessage && <div className={styles.selectErrorMessage}>{errorMessage}</div>}

        {items.length && isOpen && value.length ? (
          <ul className={styles.list} onScroll={handleScroll}>
            {items.map((item, index) => {
              const { [getTitle]: name, place_id: id, id: alt_id } = item
              const isActive = index === activeIndex || Number(selectedId) === id || Number(selectedId) === alt_id

              return (
                <SelectItem
                  tabIndex="0"
                  key={id || alt_id}
                  name={name}
                  id={id || alt_id}
                  displayName={getCompleteVesselInfo(item, getTitle)}
                  isActive={isActive}
                  onClick={handleItemClick}
                />
              )
            })}

            {!cursor && value.length > 2 && (
              <div className={mtButton.wrapperButton}>
                <MTButtonWithSearch Icon={vesselIcon} onSuccess={onSuccess} onError={onError} value={value} />
              </div>
            )}
          </ul>
        ) : null}
      </div>
    </>
  )
}

VesselAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getTitle: PropTypes.string,
  items: PropTypes.array.isRequired,
  cursor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  handleScroll: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
}

VesselAutocomplete.defaultProps = {
  items: [],
  dateRange: {},
  getTitle: 'description',
  errorMessage: '',
  isInvalid: false,
  required: false,
  disabled: false,
}

export default VesselAutocomplete
