import { getAuthMe, getSettings } from '../../api/getApi'
import { notify } from '../../helpers/notify'
import { setLocalStorageItem } from '../../helpers/helpers'
import { batch } from 'react-redux'
import { setPermissionsList, updateCompanyInfo } from './company.action'

export const getProfileFetch = (withOutLoading = false) => {
  return async (dispatch) => {
    if (!withOutLoading) dispatch(setLoading(true))

    try {
      const response = await getAuthMe()

      const { permissions, last_login } = response

      const { is_active, picture } = response.company

      batch(() => {
        dispatch(setUserInfo(response))
        dispatch(setPermissionsList({ permissions }))
        dispatch(updateCompanyInfo({ is_active, last_login, picture }))
        dispatch(updateSettings())
      })
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const setToken = (data) => {
  data.expires_date = new Date().getTime() + data.expires_in * 1000

  setLocalStorageItem('user', data)

  return {
    type: 'SET_TOKEN',
    payload: data,
  }
}

export const updateSettings = () => {
  return async (dispatch) => {
    try {
      const response = await getSettings()

      setLocalStorageItem('settings', response)

      dispatch(getUserSettings(response))
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }
}

export const changeProfilePicture = (payload) => ({
  type: 'CHANGE_PROFILE_PICTURE',
  payload,
})

export const setUserInfo = (payload) => ({
  type: 'SET_USER_INFO',
  payload,
})

export const getUserSettings = (payload) => ({
  type: 'GET_USER_SETTINGS',
  payload,
})

export const setLoginEmail = (payload) => ({
  type: 'SET_LOGIN_EMAIL',
  payload,
})

export const setLoading = (payload) => ({
  type: 'SET_LOADING',
  payload,
})
