import { subDays, subMonths, subYears } from 'date-fns'
import { groupName, tab } from '../config'

export const getTabs = () => {
  const currentDate = new Date()

  const tabs = [
    {
      name: tab.day,
      dates: {
        start_date: subDays(currentDate, 7),
        end_date: currentDate,
      },
      group: groupName.day,
    },
    {
      name: tab.week,
      dates: {
        start_date: subMonths(currentDate, 1),
        end_date: currentDate,
      },
      group: groupName.week,
    },
    {
      name: tab.month,
      dates: {
        start_date: subMonths(currentDate, 6),
        end_date: currentDate,
      },
      group: groupName.month,
    },
    {
      name: tab.year,
      dates: {
        start_date: subYears(currentDate, 1),
        end_date: currentDate,
      },
      group: groupName.month,
    },
  ]

  return { tabs }
}
