import React, { useCallback, useEffect, useState } from 'react'

import cn from 'classnames'
import styles from './styles.module.scss'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { batch, useDispatch, useSelector } from 'react-redux'
import {
  clearForm,
  deleteVessel,
  editVessel,
  updateVesselsList,
  restoreDeletedVessel,
  updateDeletedVesselsList,
  clearVesselsList,
  vesselAllCounter,
  vesselDeletedCounter,
  resetVessels,
} from '../../../redux/vesselManager/vesselManager.action'
import VesselManagerListItem from './VesselManagerListItem'
import { confirm } from '../../../UI/Confirm/Confirm'
import useInfiniteScrollCursor from '../../../hooks/useInfiniteScrollCursor'
import { getAuthCompanyVessels, getAuthDeletedVessels } from '../../../api/getApi'
import { vesselDataPrettier } from '../../../helpers/vesselManager.helper'
import { searchQueryConverter } from '../../../helpers/apiHelper'
import VesselManagerListHeader from './VesselManagerListHeader'

export const tabName = {
  all: 'all',
  del: 'del',
}

const VesselManagerList = () => {
  const {
    list,
    deletedList,
    inEditId: currentInEdit,
    vesselCounter: allCount,
    deletedVesselCount: delCount,
    isRestoreLoading,
  } = useSelector(({ vessels }) => vessels)

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState(tabName.all)
  const [openItem, setOpenItem] = useState(null)
  const [value, setValue] = useState('')

  const { handleScroll, dataCount: vesselsCount } = useInfiniteScrollCursor(
    getAuthCompanyVessels,
    updateVesselsList,
    vesselDataPrettier,
    searchQueryConverter({ search: value, type: 'search' }) || `search=${value}`
  )

  const { handleScroll: deletedScroll, dataCount: deletedVesselsCount } = useInfiniteScrollCursor(
    getAuthDeletedVessels,
    updateDeletedVesselsList,
    vesselDataPrettier,
    searchQueryConverter({ search: value, type: 'search' }) || `search=${value}`
  )

  useEffect(() => {
    dispatch(vesselDeletedCounter(deletedVesselsCount))

    //eslint-disable-next-line
  }, [deletedVesselsCount])

  useEffect(() => {
    dispatch(vesselAllCounter(vesselsCount))

    //eslint-disable-next-line
  }, [vesselsCount])

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(clearForm())
        dispatch(resetVessels())
      })
    }

    //eslint-disable-next-line
  }, [])

  const memoizedSearch = useCallback(() => {
    dispatch(clearVesselsList())

    // eslint-disable-next-line
  }, [])

  const handleTabClick = useCallback(({ target }) => {
    const { name } = target

    setActiveTab(name)
  }, [])

  const handleSearchChange = useCallback(
    ({ target }) => {
      setValue(target.value)

      memoizedSearch()
    },
    [memoizedSearch]
  )

  const handleOpenClick = useCallback(
    (id) => {
      setOpenItem(id === openItem ? null : id)
    },
    [openItem]
  )

  const handleEditClick = useCallback(
    (id, isOnEdit) => {
      if (isOnEdit) {
        dispatch(clearForm())

        return
      }

      dispatch(editVessel(id))
    },

    // eslint-disable-next-line
    []
  )

  const handleDeleteClick = useCallback((id) => {
    confirm('Are you sure?', 'You want to delete this vessel?', () => {
      dispatch(deleteVessel(id))
    })

    // eslint-disable-next-line
  }, [])

  const handleRestoreClick = useCallback((id) => {
    dispatch(restoreDeletedVessel(id))

    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.wrapper}>
      <VesselManagerListHeader
        onClick={handleTabClick}
        onChange={handleSearchChange}
        value={value}
        allCount={allCount}
        delCount={delCount}
        activeTab={activeTab}
      />

      <div
        className={cn(styles.list, { [styles.list__disabled]: isRestoreLoading })}
        onScroll={(event) => {
          activeTab === tabName.del ? deletedScroll(event) : handleScroll(event)
        }}
      >
        <TransitionGroup>
          {(activeTab === tabName.del ? deletedList : list).map((item) => (
            <CSSTransition key={item.vessel.id} timeout={500} classNames="vessel">
              <VesselManagerListItem
                key={item.vessel.id}
                data={item}
                onOpenClick={handleOpenClick}
                onDeleteClick={handleDeleteClick}
                onEditClick={handleEditClick}
                onRestoreClick={handleRestoreClick}
                isOpen={openItem === item.vessel.id}
                isOnEdit={item.vessel.id === currentInEdit}
                isDeleted={activeTab === tabName.del}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </div>
  )
}

export default VesselManagerList
