import React from 'react'
const TotalExperienceWriting = ({ children }) => {
  const { years, months, days: remainingDays } = children

  return (
    <span>
      {years > 0 && `${years}y `}

      {months > 0 && `${months}m `}

      {remainingDays > 0 && `${remainingDays}d `}
    </span>
  )
}

export default TotalExperienceWriting
