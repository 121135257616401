export const popupNotifyText = {
  process: 'Your payment is currently being processed... Please note that this may take some time.',
  success: 'Your payment has been successfully processed. You now have access to all the selected services.',
  unSuccess: 'The payment was not successful. Kindly attempt the transaction once more.',
}

export const steps = {
  process: 'process',
  success: 'success',
  unSuccess: 'unSuccess',
}

export const paymentIntentStatus = {
  confirmation: 'requires_confirmation',
  action: 'requires_action',
  succeeded: 'succeeded',
}
