import { useEffect, useRef, useState } from 'react'

const useDistanceToBottom = () => {
  const [distanceToBottom, setDistanceToBottom] = useState(0)

  const ref = useRef(null)

  useEffect(() => {
    const updateDistanceToBottom = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect()
        const distance = window.innerHeight - rect.top

        setDistanceToBottom(distance)
      }
    }

    updateDistanceToBottom()

    window.addEventListener('resize', updateDistanceToBottom)

    return () => {
      window.removeEventListener('resize', updateDistanceToBottom)
    }
  }, [])

  return { ref, distanceToBottom }
}

export default useDistanceToBottom
