export const action = {
  can_edit: 'Can edit',
  can_view: 'Can view',
  no_rights: 'No rights',
}

export const role = {
  owner: 'Owner',
  admin: 'Admin',
  manager: 'Manager',
  operator: 'Operator',
  accountant: 'Accountant',
}

export const section = {
  billing: 'Billing',
  company_profile: 'Company Profile',
  users: 'Users',
  vacancy: 'Vacancy',
  vessel: 'Vessel',
  chat: 'Chat',
  seafarers_favourite: 'Seafarers & Favourite',
  vacancy_request: 'Vacancy Request',
}

export const permission = {
  [section.billing]: {
    [role.owner]: action.can_edit,
    [role.admin]: action.can_edit,
    [role.manager]: action.can_view,
    [role.operator]: action.no_rights,
    [role.accountant]: action.can_edit,
  },
  [section.company_profile]: {
    [role.owner]: action.can_edit,
    [role.admin]: action.can_edit,
    [role.manager]: action.can_edit,
    [role.operator]: action.no_rights,
    [role.accountant]: action.can_view,
  },
  [section.users]: {
    [role.owner]: action.can_edit,
    [role.admin]: action.can_edit,
    [role.manager]: action.can_view,
    [role.operator]: action.no_rights,
    [role.accountant]: action.no_rights,
  },
  [section.vacancy]: {
    [role.owner]: action.can_edit,
    [role.admin]: action.can_edit,
    [role.manager]: action.can_edit,
    [role.operator]: action.can_view,
    [role.accountant]: action.no_rights,
  },
  [section.vessel]: {
    [role.owner]: action.can_edit,
    [role.admin]: action.can_edit,
    [role.manager]: action.can_edit,
    [role.operator]: action.can_view,
    [role.accountant]: action.no_rights,
  },
  [section.chat]: {
    [role.owner]: action.can_edit,
    [role.admin]: action.can_edit,
    [role.manager]: action.can_edit,
    [role.operator]: action.can_edit,
    [role.accountant]: action.can_view,
  },
  [section.seafarers_favourite]: {
    [role.owner]: action.can_edit,
    [role.admin]: action.can_edit,
    [role.manager]: action.can_edit,
    [role.operator]: action.can_view,
    [role.accountant]: action.no_rights,
  },
  [section.vacancy_request]: {
    [role.owner]: action.can_edit,
    [role.admin]: action.can_edit,
    [role.manager]: action.can_edit,
    [role.operator]: action.can_view,
    [role.accountant]: action.no_rights,
  },
}
