import { NavLink, useLocation } from 'react-router-dom'
import styles from './searchTabsPanel.module.scss'
import { ReactComponent as CloseIcon } from '../../assets/icons/delete.svg'
import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import socket from '../../websocket'
import { notify } from '../../helpers/notify'
import Tooltip from 'react-tooltip-lite'
import { getUserIdFromUrl, tooltipProps } from './utils'

const Tab = ({ tab, route, onTabDelete, idDeletedUsers }) => {
  const [isDeleted, setIsDeleted] = useState(false)

  const location = useLocation()

  const userId = getUserIdFromUrl(location.pathname)

  useEffect(() => {
    if (idDeletedUsers.includes(tab.id) && !userId) {
      setIsDeleted(true)

      notify('Info', `User ${tab.title} was deleted account!`, { type: 'info', autoClose: false })
    }

    const onUserCallback = (socketEvent) => {
      const { event, data } = JSON.parse(socketEvent.data)

      if (event === 'seafarer_actions.seafarer.deleted' && userId === data.user_id && tab.id === data.user_id) {
        setIsDeleted(true)
      }
    }

    socket.addEvent(onUserCallback)

    return () => {
      socket.clearEvent(onUserCallback)
    }

    // eslint-disable-next-line
  }, [idDeletedUsers, tab, userId])

  return (
    <>
      {isDeleted && (
        <Tooltip {...tooltipProps}>
          <NavLink
            className={cn(styles.tab, { [styles.tab_inactive]: isDeleted })}
            activeClassName={styles.tab_active}
            to={`${route}/${tab.id}`}
          >
            <span>{tab.title || 'No name'}</span>

            <button onClick={(e) => onTabDelete(e, tab.id)}>
              <CloseIcon />
            </button>
          </NavLink>
        </Tooltip>
      )}

      {!isDeleted && (
        <NavLink className={styles.tab} activeClassName={styles.tab_active} to={`${route}/${tab.id}`}>
          <span>{tab.title || 'No name'}</span>

          <button onClick={(e) => onTabDelete(e, tab.id)}>
            <CloseIcon />
          </button>
        </NavLink>
      )}
    </>
  )
}

export default Tab
