import styles from '../CompanyDataPage.module.scss'
import ContactBlock from './ContactBlock/ContactBlock'
import React from 'react'

const Contacts = ({
  values,
  handleInputChange,
  isInputsDisabled,
  formik,
  isEditMode,
  setValidPhoneContact,
  setValidEmailContact,
}) => {
  const createPhoneContact = () => {
    const newContact = { type: 1, contact: '', comment: null }

    const currentContacts = values.phone_contacts?.results || []

    const updatedContacts = [...currentContacts, newContact]

    formik.setFieldValue('phone_contacts.results', updatedContacts)
  }
  const createEmailContact = () => {
    const newContact = { type: 2, contact: '', comment: null }

    const currentContacts = values.email_contacts?.results || []

    const updatedContacts = [...currentContacts, newContact]

    formik.setFieldValue('email_contacts.results', updatedContacts)
  }

  return (
    <>
      <div className={styles.item}>
        <div className={styles.additional_info}>
          <b>Additional phone</b>
          <button
            onClick={(e) => {
              e.preventDefault()
              if (!isEditMode || isInputsDisabled) return
              createPhoneContact()
            }}
          >
            Add phone
          </button>
        </div>
      </div>
      <div className={styles.contact_item}>
        <div className={styles.item_body}>
          {values?.phone_contacts?.results?.map((item, index) => (
            <ContactBlock
              id={item}
              propsName={`phone`}
              item={item}
              onChange={handleInputChange}
              isInputsDisabled={isInputsDisabled}
              formik={formik}
              index={index}
              isEditMode={isEditMode}
              setValidPhoneContact={setValidPhoneContact}
              setValidEmailContact={setValidEmailContact}
            />
          ))}
        </div>
      </div>
      <br />
      <div className={styles.item}>
        <div className={styles.additional_info}>
          <b>Additional Email</b>
          <button
            onClick={(e) => {
              e.preventDefault()
              if (!isEditMode || isInputsDisabled) return
              createEmailContact()
            }}
          >
            Add Email
          </button>
        </div>
      </div>
      <div className={styles.contact_item}>
        <div className={styles.item_body}>
          {values.email_contacts?.results?.map((item, index) => (
            <ContactBlock
              id={item}
              propsName={'email'}
              item={item}
              onChange={handleInputChange}
              isInputsDisabled={isInputsDisabled}
              formik={formik}
              index={index}
              isEditMode={isEditMode}
              setValidPhoneContact={setValidPhoneContact}
              setValidEmailContact={setValidEmailContact}
            />
          ))}
        </div>
      </div>
    </>
  )
}
export default Contacts
