import React, { useEffect, useState } from 'react'
import Spinner from '../../../UI/Spinner/Spinner'
import AvatarCropper from '../AvatarCropper/AvatarCropper'
import styles from './styles.module.scss'
import { useNotify } from '../../../hooks/useNotify'
import Avatar from './Avatar'
import QualityStatus from './QualityStatus'
import Controls from './Controls'
import cn from 'classnames'
import useCanEdit from '../../../hooks/useCanEdit'

const UserAvatarEditor = ({ picture, id, onChange, onDelete, setIsUploadingAvatar }) => {
  const [loading, setLoading] = useState(true)
  const [showCropper, setShowCropper] = useState(false)
  const [userAvatar, setUserAvatar] = useState('')

  const { notify } = useNotify()

  const isCanEdit = useCanEdit()

  useEffect(() => {
    setIsUploadingAvatar(showCropper && userAvatar)

    //eslint-disable-next-line
  }, [showCropper, userAvatar])

  const loaded = () => {
    setLoading(false)
  }

  const handleHideCropper = () => {
    setShowCropper(false)
  }

  const handleNewImage = (e) => {
    if (['image/png', 'image/jpeg', 'image/jpg'].includes(e.target.files[0].type)) {
      if (e.target.files[0]) {
        setUserAvatar(e.target.files[0])
        setShowCropper(true)
      }
    } else {
      notify.error('Error', 'Type of your image not supported. Supported: jpg, png, jpeg')
    }
  }

  const handleDelete = () => {
    onDelete()
  }

  return (
    <form className={cn(styles.form, { [styles.form_inactive]: !isCanEdit })}>
      {picture &&
        (showCropper && userAvatar ? (
          <AvatarCropper
            picture={userAvatar}
            id={id}
            handleHideCropper={handleHideCropper}
            setEditorLoading={setLoading}
            onChange={onChange}
            setUserAvatar={setUserAvatar}
          />
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>
              {loading && <Spinner />}

              <div
                className={styles.logoContainer}
                style={loading ? { backgroundColor: '#E0E0E0', filter: 'blur(3px)' } : {}}
              >
                <input
                  className={styles.fileInput}
                  onChange={handleNewImage}
                  accept="image/png, .jpeg, .jpg"
                  type="file"
                  id="avatar-editor"
                />

                <QualityStatus picture={picture} />

                <Avatar picture={picture} loading={loading} loaded={loaded} />
              </div>
            </div>

            <div className={styles.body}>
              <Controls picture={picture} loading={loading} handleDelete={handleDelete} />
            </div>

            <div className={styles.footer}>
              <span>Please use formats:</span>

              <b>jpg, jpeg, png</b>
            </div>
          </div>
        ))}
    </form>
  )
}

export default UserAvatarEditor
