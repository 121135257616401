import React, { useRef } from 'react'
import styles from './Comments.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNewComment,
  clearCommentsData,
  clearCommentsList,
  setCommentsList,
} from '../../redux/actions/seafarersSearch'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import ResizableTextarea from '../../UI/ResizableTextarea/ResizableTextarea'
import errorLengthTextMessage from '../../assets/icons/error-length-text-message.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { commentRequest } from '../../api/postApi'
import { getSeafarerComments } from '../../api/getApi'
import useInfiniteScrollCursor from '../../hooks/useInfiniteScrollCursor'
import { commentsDataPrettier } from '../../helpers/comments.helper'
import { CommentItem } from './CommentItem/CommentItem'
import useInput from '../../hooks/useInput'
import useDebounce from '../../hooks/useDebounce'
import useClickOutside from '../../hooks/useClickOutside'
import { notify } from '../../helpers/notify'

export const Comments = () => {
  const dispatch = useDispatch()
  const commentRef = useClickOutside(() => dispatch(clearCommentsData()))
  const { user_name, user_id, comments_list } = useSelector(({ seafarersSearch }) => seafarersSearch.comments)
  const initialValues = {
    comment: '',
    search: '',
  }
  const scrollRef = useRef()

  const validationSchema = Yup.object().shape({
    comment: Yup.string()
      .test('Comment check', 'Уou entered the maximum number of characters!', function (value = '') {
        const volume = Buffer.from(value).length
        if (volume >= 4096) {
          return false
        } else return true
      })
      .required('Field is required'),
  })

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      if (values.comment) {
        commentRequest({ message: values.comment }, user_id)
          .then((data) => {
            dispatch(addNewComment(data))
            scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' })
            formik.resetForm()
          })
          .catch((error) => notify.errorsList(error.errors))
      }
    },
  })

  const { values, errors } = formik

  const { value: inputValue, onChange: handleChange } = useInput(values.search)
  const debounceValue = useDebounce(inputValue)

  const { handleScroll } = useInfiniteScrollCursor(
    getSeafarerComments,
    setCommentsList,
    commentsDataPrettier,
    `${user_id}/?&search=${debounceValue}`,
    clearCommentsList
  )

  return (
    <div ref={commentRef} className={styles.comments}>
      <div className={styles.header}>
        <p className={styles.user_name}>
          Comments about <span>{user_name}</span>
        </p>
        <form className={styles.controls}>
          <Input className={styles.search_comments} placeholder="Search" value={inputValue} onChange={handleChange} />
          <Button className={styles.close_comments} theme="text" onClick={() => dispatch(clearCommentsData())}>
            Close
          </Button>
        </form>
      </div>
      <div className={styles.comments_list_block}>
        <div ref={scrollRef} onScroll={handleScroll} className={styles.comments_list}>
          {comments_list.length ? (
            comments_list.map((comment) => {
              return <CommentItem key={comment.id} comment={comment} search={debounceValue} />
            })
          ) : (
            <div className={styles.noInfo}>No information</div>
          )}
        </div>
        <form onSubmit={formik.handleSubmit} className={styles.comments_sender}>
          <ResizableTextarea
            name={'comment'}
            className={styles.comment_textarea}
            onChange={(e) => {
              formik.setValues(() => {
                return {
                  ...values,
                  comment: e.target.value,
                }
              })
            }}
            value={values.comment}
            lineHeight={18}
            placeholder="Add your comment..."
          />
          <Button type="submit" className={styles.send}>
            Send
          </Button>
        </form>
        {errors.comment && (
          <div className={styles.maxLengthMessage}>
            <img src={errorLengthTextMessage} alt="error" />
            {errors.comment}
          </div>
        )}
      </div>
    </div>
  )
}
