import { getUserTimeZone } from '../helpers/time-helper/time-helper'

const setAPIDomain = () => {
  if (window.location.href.includes('dev') || window.location.href.includes('localhost')) {
    return `https://devapi.nextship.net/`
  } else if (window.location.href.includes('stage') || window.location.href.includes('test')) {
    return `https://stageapi.nextship.net/`
  } else {
    return `https://api.nextship.net/`
  }
}

const setSocketApiBase = () => {
  if (window.location.href.includes('dev') || window.location.href.includes('localhost')) {
    return `wss://devapi.nextship.net/ws/`
  } else if (window.location.href.includes('stage') || window.location.href.includes('test')) {
    return `wss://stageapi.nextship.net/ws/`
  } else {
    return `wss://api.nextship.net/ws/`
  }
}

const API_DOMAIN = setAPIDomain()
const API_VERSION = `api/v1/`
const API_VERSION2 = `api/v2/`

export const API_BASE = `${API_DOMAIN}${API_VERSION}`
export const API_BASE_V2 = `${API_DOMAIN}${API_VERSION2}`

export const CLIENT_SECRET =
  `kq1UhqHYYkcNWMUoIpNlU8On0Hg6ZVcsUG1fDPSccFcABRAzdpdDKW7xjv` +
  `DasC77E9opMJ8TAKaKUopMZ29lhCbvmau0qR8dgtJz6WfjsSFX8wL8SOK5aAC8SoEIhkNA`
export const CLIENT_ID = '4bRXe6dcHE71xekoMj3XV6MDFA9TfNpqKfSdOJJn'

export const WS_API_BASE = setSocketApiBase()

export const initHeaders = new Headers({
  'Content-Type': 'application/json',
  'X-User-Time-Zone': getUserTimeZone(),
  Accept: 'application/json',
})

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}
