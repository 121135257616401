import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import PropTypes from 'prop-types'
import { ReactComponent as CrossSvg } from '../../assets/icons/circle_cross_icon.svg'
import './fileUploader.scss'
import Spinner from '../../UI/Spinner/Spinner'
import { ReactComponent as AboutIcon } from '../../assets/icons/about_icon.svg'

export default function FileUploader({
  name = 'doc',
  text,
  title,
  theme = 'default',
  icon,
  files,
  onChange,
  onDelete,
  isUpload,
  disabled = false,
}) {
  return (
    <div>
      <div className={styles.body}>
        <span
          className={cn(styles.inputFile_label, { [styles.inputFile_label_withFiles]: files?.length })}
          htmlFor={`${name}`}
        >
          <div
            className={cn(`input_${theme}`, {
              [`input_${theme}_disabled`]: disabled,
            })}
          >
            <input
              id={`${name}`}
              type="file"
              name={name}
              value={''}
              onChange={onChange}
              className={styles.inputFile_input}
              accept="image/png, .jpeg, .jpg, .pdf"
              disabled={disabled}
            />

            <label className={styles.inputFile_label} htmlFor={`${name}`}>
              {icon}
              <h3>{title}</h3>
              <span className={cn(styles.inputFile_label_text, { [styles.inputFile_label_text_disabled]: disabled })}>
                {isUpload ? <Spinner /> : text}
              </span>
            </label>
          </div>
          <AboutIcon />
          <div className={styles.item_info}>
            You can add additional documents about your company in <b>PDF, JPG, JPEG, PNG</b> formats
          </div>
        </span>
      </div>

      <div className={cn(styles.input_active_files, { [styles.input_active_files_cv]: theme === 'cv' })}>
        {files?.map((file, index) => (
          <div key={index} className={cn(styles.inputFile_active, { [styles.inputFile_active_cv]: theme === 'cv' })}>
            <span className={cn(styles.inputFile_file_text, 'input_active')}>
              Attached{' '}
              <a target="_blank" href={file.file} rel="noopener noreferrer">
                <b>{file.name}</b> {/*<span>{(file.size / 1048576).toFixed(2)}MB</span>*/}
              </a>
            </span>
            {!disabled && (
              <button onClick={() => onDelete(file.name)} type="button">
                <CrossSvg />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

FileUploader.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.string,
}
