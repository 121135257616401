import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import ItemsGroupItem from './ItemsGroupItem'
import Spinner from '../../UI/Spinner/Spinner'

const ItemsGroup = ({
  title,
  items,
  onDelete,
  ChildComponent = ItemsGroupItem,
  onEdit,
  currentId,
  lastElemId,
  setMain,
}) => {
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)

  const handleAttachHover = (files, subtype) => {
    setImages(
      files.map(({ file_mini, type, file }) => ({
        link: file_mini || file,
        title: `${subtype} ${type}`,
      }))
    )
  }

  const handleImageLoad = () => {
    setLoading(false)
  }

  const handleAttachBlur = () => {
    setImages([])
  }

  return (
    <div className={styles.group} style={{ maxWidth: 630 }}>
      {images.length ? (
        <div className={styles.imageTooltip}>
          {images.map((img, idx) => {
            return (
              <div key={idx} className={styles.imageTooltipItem}>
                <div className={styles.imageTooltipItem_title}>{img.title}</div>
                {loading && <Spinner />}

                <img src={img.link} alt="document" onLoad={handleImageLoad} />
              </div>
            )
          })}
        </div>
      ) : null}

      <div className={styles.title}>{title}</div>

      <div className={styles.wrapper}>
        {items.map((item) => (
          <ChildComponent
            key={item.id}
            handleAttachHover={handleAttachHover}
            handleAttachBlur={handleAttachBlur}
            data={item}
            setMain={setMain}
            isActive={currentId === item.id}
            isAnim={lastElemId === item.id}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}
      </div>
    </div>
  )
}

function areEqual(prevProps, nextProps) {
  return prevProps.items === nextProps.items && prevProps.currentId === nextProps.currentId
}

ItemsGroup.propTypes = {
  title: PropTypes.node.isRequired,
  items: PropTypes.array,
  lastItemId: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

ItemsGroup.defaultProps = {
  title: 'title',
  items: [],
}

export default memo(ItemsGroup, areEqual)
