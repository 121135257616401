import React from 'react'

import styles from './noInfo.module.scss'
import cn from 'classnames'

const NoInfo = ({ message = 'no info', className }) => {
  return <div className={cn(styles.noInfo, className)}>{message}</div>
}

export default NoInfo
