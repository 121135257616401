import React from "react";
import PropTypes from "prop-types";

function BottomArrowSvg({ width = 14, height = 7, fill = "#666", classNames }) {
  return (
    <svg
      className={classNames}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 14 7"
    >
      <path
        fill={fill}
        d="M6.989 6.998a.997.997 0 01-.639-.23L.36 1.77A1.001 1.001 0
            011.639.231L6.99 4.71 12.339.39a.998.998 0 011.408.15 1 1 0 
            01-.14 1.46l-5.99 4.827a.997.997 0 01-.628.17z"
      />
    </svg>
  );
}

BottomArrowSvg.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

export default BottomArrowSvg;
