import React from 'react'

import styles from './styles.module.scss'

import { ReactComponent as XIcon } from './icons/x.svg'

const SearchLabel = ({ label = '', value, onDeleteClick }) => {
  return (
    <label className={styles.label}>
      {label && `${label}:`} {value}
      <button onClick={onDeleteClick} className={styles.button}>
        <XIcon />
      </button>
    </label>
  )
}

export default SearchLabel
