import React, { useMemo, useState } from 'react'

import styles from './publishedVacanciesChart.module.scss'
import mainStyles from '../publishedVacancies.module.scss'
import cn from 'classnames'

import { Bar, BarChart, XAxis, Tooltip } from 'recharts'
import { getDate, getTick, getWidth } from './utils'
import NoInfo from '../../NoInfo/NoInfo'

const PublishedVacanciesChart = ({ data, activeTab }) => {
  const [bar, setBar] = useState(null)

  const newData = useMemo(() => {
    return data.reduce((acc, cur, index) => {
      const date = getDate(activeTab, cur.date)
      const tick = getTick(activeTab, index + 1)

      return [...acc, { ...cur, date, tick }]
    }, [])
  }, [activeTab, data])

  const isDataEmpty = newData.length === 0

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length && Boolean(bar)) {
      const { date, vacancy_count } = payload[0].payload

      return (
        <div className={cn('custom-tooltip', styles.tooltip)}>
          <p className="label">{`${vacancy_count} (${date})`}</p>
        </div>
      )
    }

    return null
  }

  const CustomTick = ({ x, y, payload }) => {
    return (
      <text
        className={cn(styles.tick, { [styles.activeTick]: bar?.tick === payload.value })}
        x={x}
        y={y + 16}
        textAnchor="middle"
      >
        {payload.value}
      </text>
    )
  }

  const handleMouseEnter = ({ x, y, payload }) => {
    const { tick } = payload

    setBar({ x, y, tick })
  }

  const handleMouseLeave = () => {
    setBar(null)
  }

  const renderGradients = () => {
    return (
      <>
        <defs>
          <linearGradient id="mainGradient" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
            <stop offset="0" stopColor="#FCE59E" />
            <stop offset="1" stopColor="#FFFBDC" />
          </linearGradient>
        </defs>

        <defs>
          <linearGradient id="hoverGradient" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
            <stop offset="0" stopColor="#E6B92A" />
            <stop offset="1" stopColor="#FEF49E" />
          </linearGradient>
        </defs>
      </>
    )
  }

  if (isDataEmpty) return <NoInfo className={mainStyles.noInfo} />

  return (
    <BarChart width={getWidth(newData)} height={200} data={newData}>
      <Bar
        dataKey="vacancy_count"
        className={styles.bar}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        radius={[8, 8, 0, 0]}
        maxBarSize={32}
        fill="url(#mainGradient)"
      />

      {renderGradients()}

      <XAxis tick={<CustomTick />} dataKey="tick" axisLine={false} tickLine={false} />
      <Tooltip content={<CustomTooltip />} position={{ x: bar?.x, y: bar?.y - 30 }} cursor={false} />
    </BarChart>
  )
}

export default PublishedVacanciesChart
