export const getPrettierVessels = (list) => {
  return list.map(({ owner = null, name, vessel_type, engine, ...rest }) => {
    const shipowner = (() => {
      if (owner) {
        const { id, name } = owner

        return {
          id,
          name,
        }
      }

      return { id: null, name: '' }
    })()

    return {
      ...rest,

      name: `${name} / ${vessel_type.name}`,
      type: { id: vessel_type.id, name: vessel_type.name },
      engine: engine ?? { id: null, name: '' },

      shipowner,
    }
  })
}

export const setRequiredField = (fields, name, remove) => {
  if (remove) {
    const removeIndex = fields.indexOf(name)

    if (removeIndex !== -1) fields.splice(removeIndex, 1)

    return
  }

  if (fields.includes(name)) {
    return
  }

  fields.push(name)
}

export const getPrettierCities = (cities) => {
  return cities.map((citi) => ({ id: citi.place_id, name: citi.description }))
}
export const getPrettierAddress = (address) => {
  return address.map((street) => ({ id: street.id, name: street.description }))
}
