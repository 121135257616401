import React, { useState } from 'react'
import widgetMT from './mtWidget.module.scss'
import PropTypes from 'prop-types'
import MTButton from '../../UI/Select/VesselAutocomplete/MTButton/MTButton'
import { ReactComponent as VesselIcon } from '../../assets/icons/vessel.svg'
import { getMtVessels } from '../../api/getApi'
import useClickOutside from '../../hooks/useClickOutside'
import { useNotify } from '../../hooks/useNotify'

const STEP = {
  INIT: 'INIT',
  REQUEST: 'REQUEST',
  NOT_FOUND: 'NOT_FOUND',
}

function WidgetMT({ value, onSuccess, onError, stepErr, setMTWidget }) {
  const { notify } = useNotify()

  const [step, setStep] = useState(stepErr ?? STEP.INIT)

  const MTWidgetRef = useClickOutside(() => {
    setMTWidget({ isOpened: false, initialStep: null })
  })

  function runNextStep() {
    setStep(STEP.REQUEST)
    getMtVessels(value)
      .then((data) => {
        if (data.results?.length) {
          onSuccess(data)
        } else {
          setStep(STEP.NOT_FOUND)
        }
      })
      .catch(({ errors }) => {
        if (errors) notify.info('Info', errors[0].message)
        setStep(STEP.INIT)
      })
  }

  function handleNotFoundClick() {
    onError()
  }

  return (
    <div className={widgetMT.widget} ref={MTWidgetRef}>
      {step === STEP.INIT && (
        <div className={widgetMT.wrapper}>
          <span className={widgetMT.message}>This vessel not found</span>
          <MTButton onClick={runNextStep} Icon={VesselIcon}>
            Search in
          </MTButton>
        </div>
      )}

      {step === STEP.REQUEST && (
        <div className={widgetMT.wrapper}>
          <span className={widgetMT.message}>Search in progress ...</span>
          <MTButton disabled={true} Icon={VesselIcon}>
            Search in
          </MTButton>
        </div>
      )}

      {step === STEP.NOT_FOUND && (
        <div className={widgetMT.wrapper}>
          <span className={widgetMT.message}>This vessel not found</span>
          <p>
            Please inform Customer Support Service about this problem. After your confirmation, chat with Customer
            Support will be started
          </p>
          <MTButton onClick={handleNotFoundClick}>I confirm</MTButton>
        </div>
      )}
    </div>
  )
}

WidgetMT.propTypes = {
  value: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  stepErr: PropTypes.string,
}

export default WidgetMT
