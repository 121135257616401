import { API_BASE_V2, METHOD } from './constans'
import { request } from './auth.service'

export const deleteCompanyPicture = async (id) => {
  return await request(`company/uploads/${id}/`, { method: METHOD.DELETE })
}

export const deleteCompanyUserById = async (id) => {
  return await request(`company/users/${id}/`, { method: METHOD.DELETE })
}
export const deleteAdditionalFile = async (id) => {
  return await request(`company/uploads/${id}/`, { method: METHOD.DELETE })
}
export const deleteAdditionalContact = async (id) => {
  return await request(`company/profile_contact/${id}/`, { method: METHOD.DELETE })
}

export const deleteCompanyUserPictureById = async (id) => {
  return await request(`company/uploads/${id}/`, { method: METHOD.DELETE })
}

export const deleteCompanyVessel = async (id) => {
  return await request(`company/vessels/${id}/`, { api: API_BASE_V2, method: METHOD.DELETE })
}

export const deleteCompanyVacancy = async (id) => {
  return await request(`company/vacancies/${id}/`, { method: METHOD.DELETE })
}

export const hardDeleteCompanyVacancy = async (id) => {
  return await request(`company/vacancies/${id}/?force=true`, { method: METHOD.DELETE })
}

export const deleteSeafarerFromFavourites = async (id) => {
  return await request(`company/favorite/${id}/`, { method: METHOD.DELETE })
}

export const deleteSeafarerFromRequests = async (id) => {
  return await request(`company/vacancy-requests/${id}/`, { method: METHOD.DELETE })
}

//================= Billing start ====================

export const deleteBillingPaymentIntents = async (id) => {
  return await request(`billing/payment-intents/${id}/`, {
    api: API_BASE_V2,
    method: METHOD.DELETE,
  })
}

export const deleteEmailSubscription = async (id) => {
  return await request(`billing/email-subscription/${id}/`, {
    api: API_BASE_V2,
    method: METHOD.DELETE,
  })
}

//================= Billing end ====================
