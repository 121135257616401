import React from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { userPermission, profilePaths, billingPermission } from '../../constants/constants'
import { useSelector } from 'react-redux'

import { BillsIcon, CellsIcon, SearchSeafarersIcon, StatisticIcon, UsersIcon } from '../../UI/Icons/Icons'
import MessagesIcon from '../../UI/Icons/MessagesIcon'
import UserIcon from '../../UI/Icons/UserIcon'
import SettingsIcon from '../../UI/Icons/SettingsIcon'
import {
  isPathAvailable as isExistUserPermission,
  isServiceAvailable as isExistBillingPermission,
} from '../../helpers/helpers'
import useActiveCompany from '../../hooks/useActiveCompany'

const useNavigationProfile = () => {
  const isActiveCompany = useActiveCompany()

  const messagesCount = useSelector(({ chat }) => chat.global_undelivered)

  const navigation = {
    [profilePaths.seafarers_search]: {
      icon: <SearchSeafarersIcon />,
      title: 'Seafarers',
      paths: [profilePaths.seafarers_search, profilePaths.seafarers_favorites, profilePaths.seafarers_request],
      disabled:
        (!isExistUserPermission(userPermission.seafarers_favourites) &&
          !isExistUserPermission(userPermission.vacancy_requests)) ||
        !isActiveCompany,
    },
    [profilePaths.chat]: {
      icon: (
        <span className={cn({ [styles.notSeen]: messagesCount })}>
          <MessagesIcon />
        </span>
      ),
      title: 'Chats',
      disabled:
        !Boolean(
          isExistUserPermission(userPermission.seafarer_chat) &&
            isExistBillingPermission(billingPermission.instant_messaging)
        ) || !isActiveCompany,
    },
    [profilePaths.create_vacancy]: {
      icon: <CellsIcon />,
      title: 'Vacancies',
      paths: [profilePaths.create_vessel, profilePaths.create_vacancy, profilePaths.vacancy_manager],
      disabled:
        (!isExistUserPermission(userPermission.vacancies) && !isExistUserPermission(userPermission.vessels)) ||
        !isActiveCompany,
    },
    [profilePaths.users_create]: {
      icon: <UsersIcon />,
      title: 'Users',
      paths: [profilePaths.users_create, profilePaths.role_permissions],
      disabled: !isExistUserPermission(userPermission.users) || !isActiveCompany,
    },
    [profilePaths.company_data]: {
      icon: <UserIcon />,
      title: 'Company',
      disabled: !isExistUserPermission(userPermission.company_profile),
    },
    [profilePaths.billing_payment]: {
      icon: <BillsIcon />,
      title: 'Billing',
      paths: [profilePaths.billing_payment, profilePaths.billing_history],
      disabled: !isExistUserPermission(userPermission.billing) || !isActiveCompany,
    },
    [profilePaths.statistic]: {
      icon: <StatisticIcon />,
      title: 'Statistic',
      disabled: !isActiveCompany,
    },
    [profilePaths.settings]: {
      icon: <SettingsIcon />,
      title: 'Settings',
      disabled: false,
    },
  }

  return { navigation }
}

export default useNavigationProfile
