import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import closeSvg from '../../assets/icons/close.svg'
import './styles.scss'
import { ReactComponent as LoupeIcon } from '../../assets/icons/loupe.svg'

const Input = ({
  field,
  textarea,
  autoHeight,
  focus,
  clear,
  isInvalid,
  disabled,
  form,
  theme = 'default',
  label,
  required,
  errorMessage = '',
  className,
  loupe = false,
  ...rest
}) => {
  const Tag = textarea ? 'textarea' : 'input'

  const inputRef = useRef(null)

  useEffect(() => {
    focus && inputRef.current.focus()
  }, [focus])

  return (
    <div className={cn('field-wrapper', `field-wrapper-${theme}`)}>
      {label && (
        <label
          className={cn(`input-label input-label-${theme}`, {
            'input-label_invalid': isInvalid,
            'input-label_disabled': disabled,
          })}
        >
          {label} {required && <span className="required-star">*</span>}
        </label>
      )}

      <div className={cn(`wrapper wrapper-${theme}`)}>
        <Tag
          ref={inputRef}
          disabled={disabled}
          className={cn(`input input-${theme}`, className, { input_invalid: isInvalid })}
          {...field}
          {...rest}
          value={rest.value ?? ''}
          style={loupe ? { paddingLeft: 36 } : {}}
          autoComplete="new-password"
        />

        {loupe && (
          <div className={cn(`input-${theme}_icon`)}>
            <LoupeIcon />
          </div>
        )}
      </div>

      {isInvalid && errorMessage && (
        <div className={cn('input-error-message', { 'input-error-message--small': errorMessage.length > 37 })}>
          {errorMessage}
        </div>
      )}

      {clear && (
        <button type="button" className="input__close-button" onClick={() => clear({ target: { value: '' } })}>
          <img src={closeSvg} alt="clear input value" />
        </button>
      )}
    </div>
  )
}

Input.propTypes = {
  placeholder: PropTypes.string,
  theme: PropTypes.string,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
}

export default Input
