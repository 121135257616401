import React from 'react'

import styles from './styles.module.scss'

import scrollPreloader from '../../assets/icons/scroll-preloader.svg'
import cn from 'classnames'

const spinnerType = {
  magnifier: 'magnifier',
}

export default function Spinner({ size = 40, type, color = '#43927c', strokeWidth = 6, withoutPosition = false }) {
  let spinner

  const classes = {
    spinner: {
      width: size,
      height: size,
      border: `${strokeWidth}px solid #f3f3f377`,
      borderTop: `${strokeWidth}px solid ${color}`,
    },
  }

  switch (type) {
    case spinnerType.magnifier:
      spinner = (
        <div style={{ width: size, height: size }} className="scroll-preloader">
          <img src={scrollPreloader} alt="preloader" />
        </div>
      )

      break

    default:
      spinner = <div style={classes.spinner} className={styles.spinner} />
  }

  return <div className={cn(styles.wrapper, { [styles.withoutPosition]: withoutPosition })}>{spinner}</div>
}
