import React from 'react'
import styles from './ForSeafarers.module.scss'
// import ScrollAnimation from 'react-animate-on-scroll'

const items = [
  'Can communicate with registered seafarers, offer them a job or clarify any details',
  'Mobile application for searching vacancies and communication with the shipowner',
  'Provides the search for seafarers with the same contract in the past',
  'Offers the seafarer options for improving his resume',
  'Helps sailor’s relatives to be aware of vessel’s location',
]

function ForSeafarers() {
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <p className={styles.title}>
          <span className="accent">NextShip</span> services offered <br className={styles.br} /> to seafarers
        </p>
        <div className={styles.items}>
          {items.map((text, index) => (
            <div key={index} className={styles.item}>
              {text}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ForSeafarers
