import React from 'react'
import styles from '../../userCard.module.scss'

const VideosResumeItem = ({ videoResume }) => {
  return (
    <video controls className={styles.videoResume}>
      {videoResume?.length
        ? videoResume?.map(({ id, file, file_name }) => (
            <source key={id} src={file} type={`video/${file_name.split('.')[1]}`} />
          ))
        : videoResume && (
            <source key={videoResume.id} src={videoResume.file} type={`video/${videoResume.file_name.split('.')[1]}`} />
          )}
      Your browser does not support the video tag.
    </video>
  )
}

export default VideosResumeItem
