import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import Tooltip from 'react-tooltip-lite'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ExperienceTooltipContent } from './TooltipContent/ExperienceTooltipContent'
import VacancyRequest from './VacancyRequest/VacancyRequest'
import Controls from './Controls/Controls'
import Avatar from './Avatar/Avatar'
import PersonalStatus from '../PersonalStatus/PersonalStatus'
import Documents from './Documents'
import Visas from './Visas'

import { getDocuments } from './helpers'
import TotalExperienceWriting from '../../helpers/TotalExperienceWriting'
import { addTab } from '../../redux/actions/seafarersSearch'

import { ReactComponent as VipIcon } from '../../assets/icons/vip.svg'

const SeafarersItem = ({ data, setRemoving }) => {
  const tabs = useSelector(({ seafarersSearch }) => seafarersSearch.tabs)

  const dispatch = useDispatch()

  const {
    user_id,
    first_name,
    middle_name,
    last_name,

    is_favourite,
    is_viewed,
    in_vacancy_request,

    expired_documents,
    first_to_expire,
    availability_status,
    request_date,
    vacancies,
    vacancy,
    personal_status,
    enhanced_visibility,
    experience_current,
    experience_total_dict,
    video_resume,
    years_old,
    rank,
    nationality,
    available_from,
    registration_date,
    visas,
    chat_info,
  } = data

  const documents = getDocuments(expired_documents, first_to_expire)

  const isExperienceTotal = Boolean(
    experience_total_dict.years || experience_total_dict.months || experience_total_dict.days
  )

  const experienceTotal = (() => {
    if (isExperienceTotal) return <TotalExperienceWriting>{experience_total_dict}</TotalExperienceWriting>

    if (experience_current) return 'Current'

    return 'Experience not yet added'
  })()

  const handleItemClick = () => {
    const tabIds = tabs.map((tab) => tab.id)

    if (!tabIds.includes(user_id)) {
      dispatch(addTab(user_id, last_name || first_name))
    }
  }

  return (
    <div className={styles.wrapper}>
      {enhanced_visibility && <VipIcon className={styles.vip} />}

      <div className={styles.itemWrapper}>
        <div
          className={cn(styles.item, {
            [styles.vacancy_info_item]: in_vacancy_request,
          })}
          onClick={handleItemClick}
        >
          <div className={styles.section}>
            <Avatar src={data.picture} videoResume={video_resume} />

            <div className={styles.column}>
              <div className={cn(styles.field, styles.general_info)}>
                <b className={styles.fullName}>
                  {first_name} {middle_name} {last_name}
                </b>
              </div>

              <div className={styles.field}>
                <span className={styles.light_bold}>{rank}</span>
              </div>

              {years_old && nationality && (
                <div className={styles.field}>
                  {years_old} y.o. {nationality}
                </div>
              )}
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.column}>
              <div className={styles.field}>
                <span>Total experience –</span>

                <Tooltip
                  direction="down"
                  padding="0"
                  arrow={false}
                  className={styles.field}
                  content={<ExperienceTooltipContent data={data} />}
                >
                  <div className={styles.semi_bold}>{experienceTotal}</div>
                </Tooltip>
              </div>

              <Documents data={documents} />

              <Visas visas={visas} />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.date}>
              <div className={styles.date_item}>
                <p className={styles.field}>Date Available:</p>

                <p className={styles.field}>
                  {available_from ? <b>{available_from}</b> : <b style={{ display: 'block', margin: 'auto' }}>—</b>}
                </p>
              </div>

              <div className={styles.date_item}>
                <p className={styles.field}>Date of registration:</p>

                <p className={styles.field}>
                  <b>{registration_date}</b>
                </p>
              </div>
            </div>
          </div>

          <Controls
            status={availability_status.name}
            isFavourite={is_favourite}
            setRemoving={setRemoving}
            chatId={data.chat_id}
            userId={user_id}
            chatInfo={chat_info}
          />
        </div>

        {personal_status.has_right && personal_status.status.length !== 0 && (
          <PersonalStatus status={personal_status.status} id={user_id} />
        )}

        {in_vacancy_request && vacancy && (
          <VacancyRequest
            requestId={data.id}
            vacancies={[vacancy]}
            requestDate={request_date}
            isVacancyRequest={in_vacancy_request}
            isViewed={is_viewed}
            newRequest
          />
        )}

        {in_vacancy_request && vacancies && <VacancyRequest requestId={data.id} vacancies={vacancies} />}
      </div>
    </div>
  )
}

export default SeafarersItem
