import React from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import styles from '../userCard.module.scss'
import LicenseItem from './LicenseItem/LIcenseItem'
import { ReactComponent as NoInfoImage } from '../../../assets/images/license_stub.svg'

const Licenses = ({ userCardData, userName }) => {
  const { licenses } = userCardData

  if (isEmpty(licenses)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={415} height={265} />
        <p>
          <b>{userName}</b> doesn’t have any licenses yet.{' '}
        </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={1} gutter="16px">
        {licenses?.map((license, index) => {
          return (
            <LicenseItem
              key={index}
              title={license.name}
              licenseFiles={license.licenseFiles}
              endorsementFiles={license.endorsementFiles}
              items={license.values}
            />
          )
        })}
      </Masonry>
    )
  }
}

export default Licenses
