import React from 'react'

import styles from './viewTab.module.scss'
import cn from 'classnames'

import Button from '../../../../../../UI/Button/Button'
import { viewTabs } from '../../config'

const ViewTab = ({ activeTab, onClick }) => {
  return (
    <div className={styles.wrapper}>
      {Object.values(viewTabs).map(({ name, icon: Icon }) => (
        <Button
          key={name}
          className={cn(styles.tab, { [styles.activeTab]: activeTab === name })}
          size="small"
          theme="transparent"
          onClick={() => onClick(name)}
          disabled={activeTab === name}
        >
          <Icon />
        </Button>
      ))}
    </div>
  )
}

export default ViewTab
