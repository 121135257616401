import React, { useEffect, useState } from 'react'

import Tooltip from 'react-tooltip-lite'

import styles from './styles.module.scss'
import mainStyles from '../styles.module.scss'
import cn from 'classnames'

import VacancyTooltipContent from '../TooltipContent/VacancyTooltipContent'

import { getDateByFormat, parsedByFormatWithTime } from '../../../helpers/time-helper/time-helper'

import { ReactComponent as EyeIcon } from './icons/eye.svg'
import { ReactComponent as CloseIcon } from './icons/close.svg'

const DATE_FORMAT = 'dd.MM.yyyy HH:mm'

const VacancyRequestItem = ({
  id,
  vacancy,
  date,
  isVacancyRequest,
  isViewed,
  newRequest,
  loading,
  isActiveControls,
  handleMouseEnter,
  handleMouseLeave,
  onViewClick,
  onRejectClick,
}) => {
  const [isSeaVacancy, setIsSeaVacancy] = useState(false)

  const parsedDate = parsedByFormatWithTime(date ?? vacancy.request_sent_date)

  useEffect(() => {
    vacancy.vacancy_type === 'sea' ? setIsSeaVacancy(true) : setIsSeaVacancy(false)
  }, [vacancy])

  return (
    <div
      className={cn(
        styles.vacancyItem,
        { [styles.activeVacancyItem]: newRequest && isActiveControls },
        { [styles.controls_inactive]: !isActiveControls }
      )}
      onMouseEnter={() => handleMouseEnter(vacancy.id)}
      onMouseLeave={() => handleMouseLeave(null)}
    >
      <Tooltip padding={0} className={mainStyles.field} content={<VacancyTooltipContent vacancyDetails={vacancy} />}>
        <span className={mainStyles.rank_name}>{vacancy.rank?.name || vacancy?.rank_name}</span>

        {isSeaVacancy ? (
          <span>{`(${vacancy.vessel?.name ?? vacancy.vessel?.vessel_type?.name})`}</span>
        ) : (
          <span>(Shore-Based Job)</span>
        )}

        <span>{getDateByFormat(parsedDate, DATE_FORMAT)}</span>

        {isVacancyRequest && !isViewed && <div className={styles.labelNewRequest}>new</div>}
      </Tooltip>

      {newRequest && id === vacancy.id && (
        <div className={styles.controls}>
          {isVacancyRequest && !isViewed && (
            <button onClick={onViewClick} className={styles.button} disabled={loading}>
              <span>View</span>

              <EyeIcon />
            </button>
          )}

          <button onClick={onRejectClick} className={styles.button} disabled={loading}>
            <span>Reject request</span>

            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  )
}

export default VacancyRequestItem
