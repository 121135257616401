export const vesselSubmitHelper = ({
  vessel,
  vessel_type,
  owner,
  country,
  crew_nationality,
  engine,
  vessel_flag,
  year_of_build,
  teu,

  ...rest
}) => {
  return {
    ...rest,

    owner: {
      id: owner.id,
      country: country.id,
    },

    name: vessel.name,
    vessel_type: vessel_type.id,
    engine_type: engine.id,
    flag: vessel_flag.id,
    year_of_build: Number(year_of_build),
    crew_nationality: crew_nationality.id === 0 ? 'mix' : crew_nationality.id,
    teu: !teu ? null : Number(teu),
  }
}

export const getPrettierShipowners = (data) => {
  if (Array.isArray(data)) {
    return data.map(({ id, name, company_id, country }) => {
      return {
        id,
        name,
        company_id,
        country,
      }
    })
  }

  const { id, name, company_id, country } = data

  return {
    id,
    name,
    company_id,
    country,
  }
}

export const vesselDataPrettier = (data) => {
  if (Array.isArray(data)) {
    return data.map(({ id, name, flag, crew_nationality, engine_type, ...vessel }) => {
      const vessel_flag = (() => {
        if (!flag) return { id: null, name: '' }

        const { id, name } = flag

        return { id, name }
      })()

      return {
        ...vessel,

        vessel: { id, name },
        crew_nationality: !crew_nationality ? { id: 'mix', name: 'Mix' } : crew_nationality,
        engine: engine_type ?? { id: null, name: '' },

        vessel_flag,
      }
    })
  }

  const { id, name, flag, crew_nationality, engine_type, ...restData } = data

  const vessel_flag = (() => {
    if (!flag) return { id: null, name: '' }

    const { id, name } = flag

    return { id, name }
  })()

  return {
    ...restData,

    vessel: { id, name },
    crew_nationality: !crew_nationality ? { id: 'mix', name: 'Mix' } : crew_nationality,
    engine: engine_type ?? { id: null, name: '' },

    vessel_flag,
  }
}

export const helperEditDataVessel = ({ vessel, crew_nationality, engine, owner, vessel_flag, ...data }) => {
  const { id: vesselId, name: vesselName } = vessel
  const { id: crewNationalityId, name: crewNationalityName } = crew_nationality
  const { id: ownerId = null, name: ownerName = '' } = owner ?? {}
  const { id: engineId, name: engineName } = engine
  const { id: flagId = null, name: flagName = '' } = vessel_flag ?? {}
  const { id: countryId = null, name: countryName = '' } = owner?.country ?? {}

  return {
    ...data,

    crew_nationality: crew_nationality
      ? { id: crewNationalityId, name: crewNationalityName }
      : { id: 'mix', name: 'Mix' },

    vessel: { id: vesselId, name: vesselName },
    vessel_flag: { id: flagId, name: flagName },
    country: { id: countryId, name: countryName },
    owner: { id: ownerId, name: ownerName },
    engine: { id: engineId, name: engineName },

    teu: !data.teu ? '' : String(data.teu),

    hp: String(data.hp),
    kw: String(data.kw),
    year_of_build: String(data.year_of_build),
  }
}

export const searchQueryConverter = (obj) => {
  const { search, type } = obj

  if (search.length > 2) {
    const clearValue = search.trim()

    return new URLSearchParams({ [type]: clearValue }).toString()
  }
}
