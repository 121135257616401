import React, { useState, memo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ReactComponent as CheckIcon } from '../../../../assets/icons/checkbox-check.svg'
import errorLengthTextMessage from '../../../../assets/icons/error-length-text-message.svg'

import Button from '../../../../UI/Button/Button'
import ResizableTextarea from '../../../../UI/ResizableTextarea/ResizableTextarea'
import MessageAction from '../../../MessageAction/MessageAction'

import { useNotify } from '../../../../hooks/useNotify'
import { actionMessageClose, changeMessageValue } from '../../../../redux/actions/chat.action'
import socket from '../../../../websocket'
import { events, operationType } from '../../../../constants/constants'

export const Footer = memo(({ chat_id }) => {
  const [errorLengthText, setErrorLengthText] = useState(false)
  const [isButtonEnable, setIsButtonEnable] = useState(true)
  const [isCurrentRows, setIsCurrentRows] = useState(1)
  const { message_action, message } = useSelector(({ chat }) => chat)

  const dispatch = useDispatch()

  const { notify } = useNotify()

  const { chat } = events

  const isEdit = message_action.operation === operationType.edit
  const isReply = message_action.operation === operationType.reply

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!message.trim()) return

    const volume = Buffer.from(message).length

    if (volume <= 4096) {
      const isInactiveAction = !isReply && !isEdit

      if (isInactiveAction || message_action.isMessageDelete) socket.emit(chat.sendMessage, { message, chat_id })

      isReply && socket.emit(chat.sendMessage, { reply: message_action.id, chat_id, message })

      isEdit &&
        socket.emit(chat.editMessage, {
          message,
          message_id: message_action.id,
          chat_id,
        })

      dispatch(actionMessageClose())
      dispatch(changeMessageValue(''))
    } else {
      notify.error('Error', 'This message is out of size')
    }
  }

  const handleClose = () => {
    dispatch(actionMessageClose())
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && !e.ctrlKey && isButtonEnable) handleSubmit(e)
  }

  const handleChange = ({ target }) => {
    dispatch(changeMessageValue(target.value))

    const volume = Buffer.from(target.value).length

    if (volume >= 4096) {
      setErrorLengthText(() => true)
      setIsButtonEnable(() => false)
    } else {
      setIsButtonEnable(() => true)
      setErrorLengthText(() => false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className={cn(styles.footer, { [styles.footer__textareaActive]: isCurrentRows > 2 })}>
      {message_action.isActive && <MessageAction messageAction={message_action} onClick={handleClose} />}

      <div className={styles.wrapper}>
        <div className={styles.input}>
          <ResizableTextarea
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            value={message}
            placeholder="Write a message..."
            lineHeight={17}
            setIsCurrentRows={setIsCurrentRows}
          />
        </div>

        <Button
          className={cn(styles.submit, {
            [styles.submitToLongText]: errorLengthText,
            [styles.submitIsEdit]: isEdit,
          })}
          disabled={!message.length || !isButtonEnable}
          size="small"
          type="submit"
        >
          {isEdit ? <CheckIcon /> : 'Send'}
        </Button>
      </div>

      {errorLengthText && (
        <div className={styles.maxLengthMessage}>
          <img src={errorLengthTextMessage} alt="error" />
          Уou entered the maximum number of characters!
        </div>
      )}
    </form>
  )
})
