import * as Yup from 'yup'
import format from 'date-fns/format'

const IMO_CHAR_LENGTH = 7
const regExFraction = /^(0*[1-9][0-9]*(\.[0-9]*)?(,[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/gm

const isExact = (value, length) => {
  if (!value) return false

  return value.toString().length === length
}

const imo_validation_rules = Yup.number()
  .required('IMO is required')
  .test('len', `IMO must be exactly ${IMO_CHAR_LENGTH} characters`, (val) => isExact(val, IMO_CHAR_LENGTH))
  .positive('IMO must be positive')
  .integer('IMO must be a positive integer')
  .typeError(`IMO must be exactly ${IMO_CHAR_LENGTH} characters`)

export const validationSchema = Yup.object().shape({
  vessel: Yup.object().shape({
    name: Yup.string().required('Field is required').max(60, 'Too long'),
  }),

  owner: Yup.object().shape({
    id: Yup.mixed()
      .test('Name check', 'Select Shipowner name from list', function (value) {
        const { name } = this.parent

        return name ? Boolean(value) : true
      })
      .required('Field is required'),
  }),

  vessel_type: Yup.object().shape({
    id: Yup.number().required('Field is required').nullable(),
  }),

  year_of_build: Yup.number()
    .min(format(new Date('01/01/1900'), 'yyyy'), "Build year can't be less than 1900 year")
    .max(Number(format(new Date(), 'yyyy')), "Build year can't be more than current year")
    .required('Field is required')
    .typeError('Only numbers'),

  crew_nationality: Yup.object().shape({
    name: Yup.string().required('Field is required'),
  }),

  engine: Yup.object().shape({
    id: Yup.number().required('Field is required').nullable(),
  }),

  vessel_flag: Yup.object().shape({
    id: Yup.number().required('Field is required').nullable(),
  }),

  dwt: Yup.number()
    .required('Field is required')
    .positive('Must be positive')
    .test('Length check', 'Too long', (value) => value <= 2147483647)
    .typeError(`Must be positive`),

  grt: Yup.number()
    .required('Field is required')
    .positive('Must be positive')
    .test('Length check', 'Too long', (value) => value <= 2147483647)
    .typeError(`Must be positive`),

  teu: Yup.number()
    .positive('Must be positive')
    .integer('A valid integer is required')
    .test('Length check', 'Too long', (value) => value <= 2147483647 || !value)
    .typeError('Only numbers')
    .nullable(),

  imo: imo_validation_rules,
  hp: Yup.string().matches(regExFraction, 'Only numbers').max(10, 'Too long').required('Field is required'),
  kw: Yup.string().matches(regExFraction, 'Only numbers').max(10, 'Too long').required('Field is required'),
})
