import React, { useEffect } from 'react'

import cn from 'classnames'
import styles from './styles.module.scss'

import { useDispatch, useSelector } from 'react-redux'
import {
  updateFilters,
  updateVacanciesList,
  clearVacancyList,
} from '../../../redux/vacancyManager/vacancyManager.action'
import Input from '../../../UI/Input/Input'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import { getAuthCompanyVacancies } from '../../../api/getApi'
import { vacancyDataHelper } from '../../../helpers/vacancyManager.helper'
import { objToQueryConverter } from '../../../helpers/apiHelper'
import WithPreloader from '../../../HOC/WithPreloader'
import VacancyManagerList from './VacancyManagerList'
import useInput from '../../../hooks/useInput'
import useDebounce from '../../../hooks/useDebounce'

const tabName = {
  archive: 'archive',
  all: 'all',
}

const VacancyManagerListHeader = () => {
  const { value, onChange } = useInput('')

  const { filters, count_active, count_deleted } = useSelector(({ vacancies }) => vacancies)

  const dispatch = useDispatch()

  const debounceValue = useDebounce(value, 500)

  useEffect(() => {
    return () => {
      dispatch(clearVacancyList())
      dispatch(updateFilters({ search: '' }))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const search = debounceValue.trim()

    dispatch(updateFilters({ search }))

    // eslint-disable-next-line
  }, [debounceValue])

  const { loading, nextPage, handleScroll } = useInfiniteScroll(
    getAuthCompanyVacancies,
    updateVacanciesList,
    vacancyDataHelper,
    objToQueryConverter(filters)
  )

  const handleTabClick = ({ target }) => {
    const { name } = target

    if (name === tabName.archive && filters.deleted) return
    if (name === tabName.all && !filters.deleted) return

    dispatch(updateFilters({ deleted: name === tabName.archive }))
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.header_nav}>
          <button
            className={cn(styles.tab, { [styles.tab__active]: !filters.deleted })}
            onClick={handleTabClick}
            name={tabName.all}
          >
            All vacancies {count_active ? `(${count_active})` : null}
          </button>

          <button
            className={cn(styles.tab, { [styles.tab__active]: filters.deleted })}
            onClick={handleTabClick}
            name={tabName.archive}
          >
            Archive {count_deleted ? `(${count_deleted})` : null}
          </button>
        </div>

        <div className={styles.input}>
          <Input theme="search" value={value} onChange={onChange} />
        </div>
      </div>

      <WithPreloader
        size={150}
        component={VacancyManagerList}
        type="magnifier"
        loading={nextPage ? loading : null}
        isActive={nextPage === 1 && loading}
        handleScroll={handleScroll}
      />
    </div>
  )
}

export default VacancyManagerListHeader
