import React from 'react'

import { useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import styles from './styles.module.scss'
import cn from 'classnames'

import UserProfile from '../../UserProfile/UserProfile'
import Button from '../../../UI/Button/Button'

import { getCurrentDomain } from '../../../helpers/apiHelper'
import { isMobileDevice } from '../../../helpers/mobileDevice.helper'
import { profilePaths } from '../../../constants/constants'

const HeaderNav = ({ onMobilePopup }) => {
  const { location } = useHistory()

  const { isAuth } = useSelector(({ authentication }) => authentication)

  return (
    <nav className={styles.navigation}>
      {isAuth && (
        <div className={cn(styles.list, { [styles.listLogin]: isAuth })}>
          <div className={cn(styles.item, { [styles.itemLogin]: isAuth })}>
            <UserProfile />
          </div>
        </div>
      )}

      {!isAuth && (
        <div
          className={cn(
            styles.list,
            { [styles.listLogin]: location.pathname !== '/' },
            { [styles.listMobile]: isMobileDevice() }
          )}
        >
          <a
            className={cn(styles.forEmployersLink, { [styles.disabled]: location.pathname !== '/' })}
            href={`${getCurrentDomain()}nextship.net/`}
          >
            For seafarers
          </a>

          {isMobileDevice() && (
            <Button theme="transparent" className={styles.button} onClick={onMobilePopup}>
              Log in
            </Button>
          )}

          {!isMobileDevice() && (
            <NavLink className={styles.item} activeClassName={styles.active} to={profilePaths.auth}>
              Log in
            </NavLink>
          )}

          {!isMobileDevice() && (
            <NavLink className={cn(styles.item, styles.itemFilled)} activeClassName={styles.active} to="/auth/signup">
              Sign up
            </NavLink>
          )}
        </div>
      )}
    </nav>
  )
}

export default HeaderNav
