import React, { useEffect, useState } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import cn from 'classnames'
import { getSeafarerCv, getUserInfoForCompany } from '../../api/getApi'
import { createChatRoom, setSeafarerFavorite, setViewedVacancy } from '../../api/postApi'
import { deleteSeafarerFromFavourites } from '../../api/deleteApi'
import UserCardHelper from '../../helpers/userCard.helper'
import Spinner from '../../UI/Spinner/Spinner'
import UserCard from '../../components/UserCard/UserCard'
import styles from '../../components/UserCard/CardHeader/CardHeader.module.scss'
import { ReactComponent as FavouriteIcon } from '../../assets/icons/favouriteIcon.svg'
import { ReactComponent as ChatIcon } from '../../assets/icons/startChatIcon.svg'
import ReactTooltip from 'react-tooltip'
import { useNotify } from '../../hooks/useNotify'
import { downloadFile } from '../../helpers/downloadFile'
import Button from '../../UI/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTab, setCommentsGeneral } from '../../redux/actions/seafarersSearch'
import { billingPermission, profilePaths } from '../../constants/constants'
import { isServiceAvailable } from '../../helpers/helpers'
import useCanEdit from '../../hooks/useCanEdit'
import LastChatDateInfo from '../../components/LastChatDateInfo/LastChatDateInfo'

const SeafarerCard = () => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [isFavourite, setIsFavourite] = useState(null)
  const [cvLoading, setCvLoading] = useState(false)
  const [fullNameSeafarer, setFullNameSeafarer] = useState(null)
  const [rankSeafarer, setRankSeafarer] = useState(null)
  const [otherInfo, setOtherInfo] = useState({})
  // const [isActive, setIsActive] = React.useState(null)
  const { vacancy_requests, tabs } = useSelector(({ seafarersSearch }) => seafarersSearch)

  const { id } = useParams()
  const { notify } = useNotify()
  const dispatch = useDispatch()

  const isChatActive = isServiceAvailable(billingPermission.instant_messaging)
  const isCandidateManagementActive = isServiceAvailable(billingPermission.candidate_management)
  const isCanEdit = useCanEdit()

  useEffect(() => {
    setLoading(true)

    getUserInfoForCompany(id, 'general')
      .then(({ other_info, ...data }) => {
        setData(UserCardHelper.getUserCard(data.general))

        setOtherInfo(other_info)
        setIsFavourite(other_info.is_favourite)
        // getUkrainianLocationInfo(id).then((response) => setIsActive(response.out_of_residence))
        setLoading(false)
      })
      .catch((error) => {
        dispatch(deleteTab(id))
        setLoading(false)
        notify.errorsList(error.errors)
      })

    //eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (data?.general?.mainInfo) {
      const mainInfo = data.general.mainInfo

      mainInfo.forEach((item) => {
        if (item.title === 'Full name') {
          setFullNameSeafarer(item.value)
        } else if (item.title === 'Rank') {
          setRankSeafarer(item.value)
        }
      })
    }
  }, [data])

  useEffect(() => {
    return () => {
      setData({})
      setOtherInfo({})
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const currentUser = vacancy_requests.find((vacancy) => vacancy.user_id === Number(id))

    if (!currentUser) return

    const setViewed = async () => {
      await setViewedVacancy(currentUser.id)
    }

    const isViewed = currentUser.in_vacancy_request && currentUser.is_viewed
    const userTabExist = tabs.some((tab) => tab.id === currentUser.id)

    if (!isViewed && !userTabExist) setViewed()

    // eslint-disable-next-line
  }, [tabs, id])

  const link = (() => {
    if (isChatActive) return `${profilePaths.chat}/${otherInfo.chat_id}`

    return ''
  })()

  function handleFavouriteClick() {
    if (!isFavourite) {
      setSeafarerFavorite(id)
        .then(() => {
          setIsFavourite(true)
        })
        .catch(({ errors }) => notify.errorsList(errors))
    } else {
      deleteSeafarerFromFavourites(id)
        .then(() => {
          setIsFavourite(false)
        })
        .catch(({ errors }) => notify.errorsList(errors))
    }
  }

  const [createdChat, setCreatedChat] = useState(null)

  if (createdChat) {
    return <Redirect to={`${profilePaths.chat}/${createdChat}`} />
  }

  const handleCreateChatClick = () => {
    setCreatedChat(false)
    createChatRoom(id)
      .then(({ chat_id }) => {
        setCreatedChat(chat_id)
      })
      .catch((error) => notify.errorsList(error.errors))
  }

  async function handleToPdfClick() {
    setCvLoading(true)

    try {
      const response = await getSeafarerCv(id)

      setCvLoading(false)

      downloadFile(response, `CV of ${rankSeafarer} ${fullNameSeafarer}`)
    } catch (error) {
      setCvLoading(false)
      notify.errorsList(error.errors)
    }
  }

  const handleToComments = () => {
    // if(){

    const full_name = 'Full name'
    const user_name = data.general.mainInfo.find((item) => item.title === full_name).value

    dispatch(setCommentsGeneral({ user_name, user_id: id }))
    // }
  }

  // const toggleCheckbox = () => {
  //   if (isActive === null) {
  //     putUkrainianLocationInfo(id, { out_of_residence: true }).then(
  //       (response) => {
  //         setIsActive(response.out_of_residence);
  //       }
  //     );
  //   } else {
  //     putUkrainianLocationInfo(id, { out_of_residence: null }).then(
  //       (response) => {
  //         setIsActive(response.out_of_residence);
  //       }
  //     );
  //   }
  // };

  // const toggleSwitcher = (state) => {
  //   if (state !== undefined) {
  //     if (state !== isActive) {
  //       putUkrainianLocationInfo(id, {
  //         out_of_residence: state,
  //       }).then((response) => {
  //         setIsActive(response.out_of_residence);
  //       });
  //     }
  //   } else {
  //     putUkrainianLocationInfo(id, { out_of_residence: !isActive }).then(
  //       (response) => {
  //         setIsActive(response.out_of_residence);
  //       }
  //     );
  //   }
  // };

  // const switcher = () => {
  //   return (
  //     <div className={styles.outOfUkraine}>
  //       <CheckBox theme="filled" name="citizenUA" id="citizenUA" checked={isActive !== null} onChange={toggleCheckbox}>
  //         Citizen UA
  //       </CheckBox>
  //
  //       <div
  //         className={cn(styles.switcher, {
  //           [styles.disable]: isActive === null,
  //         })}
  //       >
  //         <span
  //           className={cn(styles.switcherValue, {
  //             [styles.disable]: isActive === null ? isActive : !isActive,
  //           })}
  //           onClick={() => toggleSwitcher(true)}
  //         >
  //           Out
  //         </span>
  //         <Switcher theme="ukraine" isActive={isActive === null ? isActive : !isActive} onClick={toggleSwitcher} />
  //         <span
  //           className={cn(styles.switcherValue, {
  //             [styles.disable]: isActive === null ? !isActive : isActive,
  //           })}
  //           onClick={() => toggleSwitcher(false)}
  //         >
  //           In
  //         </span>
  //       </div>
  //     </div>
  //   )
  // }

  const controls = () => {
    const dataTipFavoriteBtn = (() => {
      if (isCandidateManagementActive) {
        if (isFavourite) return 'Remove from favorite'

        return 'Add to favorite'
      }

      return 'Service not paid'
    })()

    return (
      <>
        {/*{switcher()}*/}

        <div className={styles.cv_and_remove}>
          <Button disabled={cvLoading} onClick={handleToPdfClick} className={styles.pdfBtn}>
            {cvLoading ? <Spinner strokeWidth={4} color="#E7B92A" size={26} /> : 'To PDF'}
          </Button>
        </div>

        <button
          data-tip={isCandidateManagementActive ? 'To comment' : 'Service not paid'}
          data-for="commentBtn"
          className={cn(styles.comments, { [styles.comments__inactive]: !isCandidateManagementActive })}
          onClick={handleToComments}
          disabled={!isCandidateManagementActive}
        >
          Comments
        </button>

        <div className={cn(styles.controlGroup, { [styles.controlGroup__inactive]: !isCanEdit })}>
          <button
            data-tip={dataTipFavoriteBtn}
            data-for="favoriteBtn"
            onClick={handleFavouriteClick}
            className={cn(styles.favoriteBtn, {
              [styles.favoriteBtn__active]: isFavourite,
              [styles.favoriteBtn__inactive]: !isCandidateManagementActive,
            })}
            disabled={!isCandidateManagementActive}
          >
            <FavouriteIcon />
          </button>

          {otherInfo.chat_id && (
            <div className={styles.chatBlock}>
              <Link
                data-tip={isChatActive ? 'To chat' : 'Service not paid'}
                data-for="chatBtn"
                to={link}
                className={cn(styles.chatBtn, {
                  [styles.chatBtnDisabled]: !isCanEdit || !isChatActive,
                })}
              >
                <ChatIcon />
              </Link>

              {otherInfo.last_message_date && <LastChatDateInfo date={otherInfo.last_message_date} to={link} />}
            </div>
          )}

          {!otherInfo.chat_id && (
            <button data-tip="Start chat" data-for="chatBtn" onClick={handleCreateChatClick} className={styles.chatBtn}>
              {createdChat === false ? <Spinner size={20} strokeWidth={3} /> : <ChatIcon />}
            </button>
          )}
        </div>

        <ReactTooltip backgroundColor="var(--color-accent-light)" id="chatBtn" />
        <ReactTooltip backgroundColor="var(--color-accent-light)" id="favoriteBtn" />
        <ReactTooltip backgroundColor="var(--color-accent-light)" id="commentBtn" />
      </>
    )
  }

  return <UserCard data={data} loadingGeneral={loading} controls={controls()} userId={id} />
}

export default SeafarerCard
