import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileFetch } from '../redux/actions/user.action'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Main from './Main'
import ForgotPassPage from './ForgotPassPage'
import TermsPage from './TermsPage'
import PrivacyPolicyPage from './PrivacyPolicyPage'
import SignupVerifyPage from './SignupVerifyPage'
import ChangePassword from './ChangePassword/ChangePassword'
import { AuthPage } from './AuthPage'
import { Profile } from './Profile'
import useAuthObserver from '../hooks/useAuthObserver'
import NotFoundPage from './NotFoundPage'
import TutorialPage from './TutorialPage'
import { getHealth } from '../api/getApi'
import TechnicalWorks from '../components/TechnicalWorks/TechnicalWorks'
import useOnline from '../hooks/useOnline'
import Offline from '../components/Offline/Offline'
import { profilePaths } from '../constants/constants'
import Spinner from '../UI/Spinner/Spinner'
import Unsubscribe from './Unsubscribe'
import useFCMToken from '../hooks/useFCMToken'
import MobStoreRedirect from './MobStoreRedirect'
import UnsubscribeAnonymous from './UnsubscribeAnonymous'

function Root() {
  const [healthcheck, setHealthcheck] = useState({
    status: null,
    message: '',
    downtime: '',
  })

  const { isAuth } = useSelector(({ authentication }) => authentication)
  const { loading } = useSelector(({ user }) => user.profile)

  const dispatch = useDispatch()

  useAuthObserver()
  useFCMToken()

  const isOnline = useOnline()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const callbackUrl = params.get('callback_url')

  useEffect(() => {
    const getHealthCheck = async () => {
      setHealthcheck({ ...healthcheck, status: true })

      try {
        const response = await getHealth()

        setHealthcheck({
          ...healthcheck,
          status: true,
          message: response.message,
          downtime: '10 min',
        })
      } catch (error) {
        setHealthcheck({
          ...healthcheck,
          status: false,
          message: error?.response?.data?.message || 'Technical works',
          downtime: error?.response?.data?.downtime || '10 min',
        })
      }
    }

    if (isOnline) getHealthCheck()

    // eslint-disable-next-line
  }, [isOnline])

  useEffect(() => {
    if (isAuth) dispatch(getProfileFetch())

    // eslint-disable-next-line
  }, [isAuth])

  if (!isOnline) return <Offline />
  if (loading) return <Spinner size={90} />

  return (
    <>
      {healthcheck.status === null ? null : healthcheck.status ? (
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/password-reset" component={ForgotPassPage} />
          <Route exact path="/tutorial" component={TutorialPage} />
          <Route exact path="/terms" component={TermsPage} />
          <Route exact path="/privacy" component={PrivacyPolicyPage} />
          <Route exact path="/signup-verify" component={SignupVerifyPage} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/company-unsubscribe" component={Unsubscribe} />
          <Route exact path="/unsubscribe" component={UnsubscribeAnonymous} />

          {/* employers pages */}
          <Route path="/auth/">
            {isAuth ? <Redirect to={!callbackUrl ? profilePaths.seafarers_search : callbackUrl} /> : <AuthPage />}
          </Route>
          <Route exact path="/mobile-store-redirect" component={MobStoreRedirect} />
          <Route path="/profile/" component={Profile} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      ) : (
        <TechnicalWorks data={healthcheck} />
      )}
    </>
  )
}

export default Root
