import { API_BASE_V2, METHOD } from './constans'
import { download, request } from './auth.service'
import { notify } from '../helpers/notify'
import { userLogout } from '../redux/actions/auth'
import { searchQueryConverter } from '../helpers/vesselManager.helper'
import { categoryPaths } from '../components/Statistic/Charts/config'
import { getQueryParams } from '../helpers/apiHelper'
import { CardElement } from '@stripe/react-stripe-js'

export const getAuthProfileFilters = async (...args) => {
  return await request(`alpha/filters/?models=${args.join(',')}`)
}

export const getAuthMe = async () => {
  try {
    return await request(`company/me/`)
  } catch (error) {
    userLogout()

    notify.errorsList(error.errors)
  }
}

export const getCompanyInfo = async () => {
  return await request(`company/profile/`)
}

export const getCompanyAdditionalInfo = async () => {
  return await request(`company/profile_additional_data/`)
}

export const getCompanyContactTypes = async () => {
  return await request(`alpha/filters/?models=company_contact_type`)
}

export const getCompanyContactsList = async (type) => {
  const url = type
    ? `company/profile_contact/?${typeof type === 'string' ? 'type__name' : 'type'}=${type}`
    : 'company/profile_contact/'
  return await request(url)
}

export const getCompanyPicture = async (id) => {
  return await request(`company/uploads/${id}/`)
}

export const getGooglePlacesCities = async (city, countryCode) => {
  return await request(
    `services/places-autocomplete/?input_text=${city}&components={"country":"${countryCode}"}&types=(cities)`
  )
}

export const getAutocompleteVessels = async (page, query = '') => {
  const searchByName = searchQueryConverter({ search: query, type: 'search' })
  return await request(`vessels/elastic-search/?${searchByName}&page=${page}&same_ordering=True&page_size=15`, {
    api: API_BASE_V2,
  })
}

export const getGooglePlacesAddress = async (address, place_id) => {
  return await request(
    `services/places-autocomplete-by-place-id/?input_text=${address}&place_id=${place_id}&types=address`
  )
}

export const getCompaniesUI = async () => {
  return await request(`company/ui/`)
}

// company requests
export const getAuthCompanyUsers = async (id = '') => {
  if (id !== '') id += '/'

  return await request(`company/users/${id}`)
}

export const getShipowners = async (page, query = '') => {
  const searchByName = searchQueryConverter({ search: query, type: 'search' }) || 'search='
  return await request(`shipowner/elastic-search/?&page=${page}&same_ordering=True&page_size=15&${searchByName}`, {
    api: API_BASE_V2,
  })
}

export const getAuthCompanyVessels = async (cursor = '', query) => {
  return await request(`company/vessels/elastic/search/?cursor=${cursor}&page_size=15&${query}`, { api: API_BASE_V2 })
}

export const getSearchCompanyVessels = async (cursor, query = '') => {
  const searchByName = searchQueryConverter({ search: query, type: 'search' }) || 'search='
  return await request(
    `company/vessels/elastic/search/?cursor=${cursor}&same_ordering=True&page_size=15&${searchByName}`,
    {
      api: API_BASE_V2,
    }
  )
}

export const getAuthDeletedVessels = async (cursor = '', query) => {
  return await request(`company/vessels/elastic/search/?deleted=true&cursor=${cursor}&page_size=15&${query}`, {
    api: API_BASE_V2,
  })
}

export const getAuthCompanyVacancies = async (page, query = '') => {
  const searchParams = page && query ? `&${query}` : ''

  const url = page
    ? `company/vacancies/?page=${page}${searchParams}&page_size=5`
    : `company/vacancies/?${query}&page_size=5`

  return await request(url)
}

export const getAuthDeletedCompanyUsers = async () => {
  return await request(`company/users/?deleted=true`)
}

export const getUserInfoForCompany = async (id, section = '') => {
  const SECTION_COLLEAGUES = 'colleagues'

  if (section === SECTION_COLLEAGUES) {
    return await request(`company/seafarer-colleagues-info/${id}`)
  }

  return await request(`company/seafarer-info/${id}/?section=${section}`)
}

export const getSettings = async () => {
  return await request(`company/settings/`)
}

export const getNotificationSettings = async () => {
  return await request(`company/notifications/`, {
    api: API_BASE_V2,
  })
}

export const fetchSeafarersList = async (page = 1, query = '') => {
  return await request(`company/seafarers/?page_size=15&page=${page}&${query}`)
}

export const fetchSeafarersRequests = async (page = 1, query = '') => {
  return await request(`company/vacancy-requests/?page_size=15&page=${page}&${query}`)
}

export const fetchSeafarersFavouritesList = async (page = 1, query = '') => {
  return await request(`company/seafarers/?page_size=15&page=${page}&favourite=true&${query}`)
}

export const getSeafarerCv = async (id) => {
  return await download(`company/seafarer_pdf/${id}`)
}

export const getSeafarerComments = async (cursor = '', query) => {
  return await request(`company/seafarer_comments/${query}&cursor=${cursor}&page_size=15`)
}

// Billing prices
export const getBillingPrices = async () => {
  return await request(`company/product-prices/`)
}

// Billing history
export const getBillingHistory = async () => {
  return await request(`company/billing-history/`)
}

// check if api is reachable
export const getHealth = async () => {
  return await request('healthcheck', { api: API_BASE_V2 })
}

export const getMtVessels = async (value) => {
  const clearValue = value.trim()
  // const searchBy = isImo(clearValue) ? 'imo' : 'name'
  const searchBy = 'name'

  return await request(`vessels/mt-name-search/?${searchBy}=${clearValue}&page_size=15`, {
    api: API_BASE_V2,
  })
}

export const getVesselNameHistory = async (query = '', vesselId) => {
  const params = query ? `?cursor=${query}` : ''

  return await request(`vessels/name-history/${vesselId}/${params}`, { api: API_BASE_V2 })
}

export const getPopular = async (category, params) => {
  const path = categoryPaths[category]
  const query = getQueryParams(params)

  return await request(`company/statistic/${path}/?${query}`)
}

export const getPublishedVacanciesList = async (page = 1, params) => {
  const query = getQueryParams(params)

  return await request(`company/statistic/vacancies-statistic/extended-count/?page=${page}&${query}`)
}

export const getLoginHistoryList = async (page = 1, params) => {
  const query = getQueryParams(params)

  return await request(`company/statistic/company-statistic/history/?page=${page}&${query}`)
}

//================= Billing start ====================

export const getBillingSettingsGlobal = async () => {
  return await request('billing/settings/global/?type=company', { api: API_BASE_V2 })
}

export const getBillingSubscriptionServices = async (page = 1, params) => {
  return await request(`billing/product/?page=${page}&page_size=15&user_type=company&${params}`, { api: API_BASE_V2 })
}
export const getBillingCommercialServices = async () => {
  return await request('billing/product/commercial/?user_type=company', {
    api: API_BASE_V2,
  })
}

export const getBillingSubscription = async () => {
  return await request('billing/subscription/', {
    api: API_BASE_V2,
  })
}

export const getBillingCard = async () => {
  return await request(`billing/card/`, {
    api: API_BASE_V2,
  })
}

export const getBillingSubscriptionPlanPrice = async (ids) => {
  return await request('billing/subscription-plan/price-preview/', {
    api: API_BASE_V2,
    method: METHOD.POST,
    body: JSON.stringify({ products: ids }),
  })
}

export const getBillingInvoiceHistory = async (page = 1, params) => {
  return await request(`billing/invoice-history/?page=${page}&page_size=15&${params}`, {
    api: API_BASE_V2,
  })
}

export const getBillingSubscriptionPlans = async () => {
  return await request('billing/subscription-plan/', {
    api: API_BASE_V2,
  })
}

export const getLastPaymentIntent = async () => {
  return await request('billing/payment-intents/', {
    api: API_BASE_V2,
  })
}

export const getPaymentMethodStripe = async (stripe, elements, data) => {
  const config =
    data.elementType === 'expressCheckout'
      ? { elements }
      : {
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            address: {
              city: data.city.name,
              country: data.country.iso2,
              line1: data.street.name,
              postal_code: data.zipCode,
            },
            name: data.name,
            phone: data.phone,
          },
        }
  const { paymentMethod, errorPaymentMethod } = await stripe.createPaymentMethod(config)

  return { paymentMethod, errorPaymentMethod }
}

export const getPublicStripeKey = async () => {
  return await request('billing/stripe-client-key/', {
    api: API_BASE_V2,
  })
}

//================= Billing end ====================
