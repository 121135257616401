import React, { useCallback, useEffect, useMemo } from 'react'

import { Route, Switch } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'

import SeafarersList from '../../components/SeafarersList/SeafarersList'
import SearchTabsPanel from '../../components/SearchTabsPanel/SearchTabsPanel'
import SeafarersSearchSort from '../../components/SeafarersSearchSort/SeafarersSearchSort'
import SeafarerCard from './SeafarerCard'
import SeafarersSearchPanel from '../../components/SeafarersSearchPanel/SeafarersSearchPanel'
import SearchFilterForm from '../../components/SeafarersSearchPanel/FilterForm/SearchFilterForm/SearchFilterForm'
import WithPreloader from '../../HOC/WithPreloader'

import {
  clearCommentsData,
  clearSeafarersFavouritesList,
  setSeafarersFavouriteList,
  updateFavoriteFilter,
} from '../../redux/actions/seafarersSearch'
import { fetchSeafarersFavouritesList } from '../../api/getApi'
import { fetchSeafarersHelper, objToQueryConverter } from '../../helpers/apiHelper'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import { searchHelper } from '../../components/SeafarersSearchPanel/utils'
import { profilePaths } from '../../constants/constants'

export default function Favourites() {
  // const [isFilterOpen, setIsFilterOpen] = useState(false)
  // const [isMouseUp, setIsMouseUp] = useState(false)

  const { favourites, favouritesFilter } = useSelector(({ seafarersSearch }) => seafarersSearch)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(clearCommentsData())
        dispatch(clearSeafarersFavouritesList())
      })
    }

    // eslint-disable-next-line
  }, [])

  const values = useMemo(() => {
    return searchHelper(favouritesFilter)
  }, [favouritesFilter])

  const { loading, nextPage, handleScroll } = useInfiniteScroll(
    fetchSeafarersFavouritesList,
    setSeafarersFavouriteList,
    fetchSeafarersHelper,
    objToQueryConverter(values),
    clearSeafarersFavouritesList
  )

  const handleListScroll = (e) => {
    handleScroll(e)

    // if (isMouseUp && event.target.scrollTop <= 90) {
    //   setIsFilterOpen(true)
    // } else {
    //   setIsFilterOpen(false)
    // }
  }

  const handleSortChange = useCallback((value, status) => {
    if (!value) return

    const separator = (() => {
      if (status) return ''

      return '-'
    })()

    const ordering = `${separator}${value}`

    dispatch(updateFavoriteFilter({ ordering }))

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <SeafarersSearchPanel
        Form={SearchFilterForm}
        searchValues={favouritesFilter}
        updateAction={updateFavoriteFilter}
      />

      <div className="space-between min-height-50">
        <SearchTabsPanel route={profilePaths.seafarers_favorites} />

        <div style={{ padding: '0', width: '100%', maxWidth: '260px', alignSelf: 'flex-end' }}>
          <SeafarersSearchSort onChange={handleSortChange} />
        </div>
      </div>

      <Switch>
        <Route exact path={profilePaths.seafarers_favorites}>
          <WithPreloader
            size={150}
            type="magnifier"
            noContentMessage="You don't have any seafarers in favourites"
            tab="favorites"
            list={favourites}
            component={SeafarersList}
            isLoading={nextPage ? loading : null}
            isActive={nextPage === 1 && loading}
            onScroll={handleListScroll}
            // onWheel={(e) => (e.deltaY > 0 ? setIsMouseUp(false) : setIsMouseUp(true))}
          />
        </Route>

        <Route exact path={`${profilePaths.seafarers_favorites}/:id`} component={SeafarerCard} />
      </Switch>
    </>
  )
}
