import {
  chatLastMessageUpdate,
  clearChatRoomTotalUndelivered,
  deleteMessage,
  restoreChatRoom,
  searchChatMessage,
  setDeleteMessageError,
  setDialogs,
  setEditMessageError,
  setGlobalUndelivered,
  setMessages,
  setNewMessage,
  setNextPageMessages,
  updateMessage,
} from '../redux/actions/chat.action'
import {
  newSupportMessageAction,
  setSupportUndelivered,
  updateSupportMessages,
  resetTotalUndelivered,
  setSupportStatus,
  setNextPageMessagesSupport,
  deleteSupportMessage,
  updateSupportMessage,
  setSupportEditMessageError,
  setSupportDeleteMessageError,
  searchSupportMessage,
  setAnonymousId,
} from '../redux/support/support.action'
import { notify } from '../helpers/notify'
import {
  deleteVacancyRequest,
  setIdDeletedUser,
  setNewVacancyRequest,
  setVacancyRequestCount,
  updateVacancyRequest,
} from '../redux/actions/seafarersSearch'
import { setBillingPermissions, updateCompanyInfo } from '../redux/actions/company.action'
import { companyInformationHelper } from '../helpers/companyInformation.helper'
import { profilePaths, events } from '../constants/constants'
import { getProfileFetch } from '../redux/actions/user.action'
import { userLogout } from '../redux/actions/auth'
import { popupNotifyText } from '../components/Store/Services/constants'

export const socketEvent = (event, payload) => (dispatch, useSelector) => {
  if (
    window.location.href.includes('dev') ||
    window.location.href.includes('localhost') ||
    window.location.href.includes('stage')
  ) {
    console.log('%c%s', 'color: #43927C;', event, payload)
  }

  switch (event) {
    // information about the company
    case 'company.moderation_state.registered.remove':
      dispatch(updateCompanyInfo(companyInformationHelper(payload)))
      break

    case 'company.moderation_state.registered.add':
      notify.info('Info', 'Your company has been sent for remoderation', {})
      dispatch(getProfileFetch())
      break

    case 'company.moderation_state.update':
      dispatch(updateCompanyInfo(companyInformationHelper(payload)))
      dispatch(getProfileFetch())
      break

    case 'company.moderation_state.accepted.add':
      dispatch(updateCompanyInfo(companyInformationHelper(payload)))
      break

    case 'company.moderation_state.moderation':
      dispatch(updateCompanyInfo(companyInformationHelper(payload)))
      dispatch(getProfileFetch())
      notify.info('Info', 'Your company has successfully passed moderation')

      break

    case 'company.moderation_state.accepted.remove':
      dispatch(updateCompanyInfo(companyInformationHelper(payload)))
      break

    case 'company.moderation_state.rejected.add':
      dispatch(updateCompanyInfo(companyInformationHelper(payload)))
      notify.info('Info', 'Your company was rejected')
      dispatch(userLogout())
      break

    // vacancy request cases
    case 'company.vacancy_request.count':
      dispatch(setVacancyRequestCount(payload))
      break

    case 'company.vacancy_request.created':
      const selector = useSelector()
      const userId = selector.user.profile.id
      const candidate = selector.seafarersSearch.requestFilter.candidates

      if (candidate !== 'my') {
        dispatch(setNewVacancyRequest(payload))
        dispatch(setVacancyRequestCount(payload))
      } else if (
        payload.vacancy.vacancy.created_by_company_user === userId ||
        payload.vacancy.vacancy.created_by_company_user === null
      ) {
        dispatch(setNewVacancyRequest(payload))
        dispatch(setVacancyRequestCount(payload))
      }

      break

    case 'company.vacancy_request.deleted':
      dispatch(deleteVacancyRequest(payload))
      break

    case 'company.vacancy_request.viewed':
      dispatch(updateVacancyRequest(payload))

      break

    // seafarer cases
    case 'seafarer_actions.seafarer.deleted':
      dispatch(setIdDeletedUser(payload.user_id))
      break

    // main chat cases
    case 'chat.rooms':
      dispatch(setDialogs(payload))
      break

    case 'chat.messages':
      // if payload has last_message_id, call setNextPageMessages
      if (payload.last_message_id) {
        dispatch(setNextPageMessages(payload))
      } else {
        dispatch(setMessages(payload))
      }

      break

    case 'new.chat.message':
      dispatch(setNewMessage(payload))
      break

    case 'chat.message.deleted':
      dispatch(deleteMessage(payload.chat_id, payload.id, payload.total_undelivered))
      break

    case 'chat.message.updated':
      dispatch(updateMessage(payload))
      break

    case 'chat.total.undelivered':
      dispatch(setGlobalUndelivered(payload))
      dispatch(setSupportUndelivered(payload.support))
      break

    case 'chat.room.auto.restore':
      dispatch(restoreChatRoom(payload))
      break

    case 'no.chat.messages':
      window.location.href = `${profilePaths.chat}`
      break

    case 'chat.message.was.delivered':
      dispatch(clearChatRoomTotalUndelivered(payload))
      break

    case 'chat.last.message.update':
      dispatch(chatLastMessageUpdate(payload))
      break

    case 'error.message':
      notify.error('Error', payload.message)
      break

    case 'error.chat.edit.message':
      dispatch(setEditMessageError(payload.message))
      break

    case 'error.chat.delete.message':
      dispatch(setDeleteMessageError(payload.message))
      break

    case 'search.chat.message':
      dispatch(searchChatMessage(payload))
      break

    //  support chat cases
    case 'support.messages':
      if (payload.last_message_id) {
        dispatch(setNextPageMessagesSupport(payload))
      } else {
        dispatch(updateSupportMessages(payload))
      }

      break

    case 'support.new.chat.message':
      dispatch(newSupportMessageAction(payload))
      break

    case 'support.chat.message.was.delivered':
      dispatch(resetTotalUndelivered(payload))
      break

    case 'support.chat.status':
      dispatch(setSupportStatus(payload))
      break

    case 'support.chat.message.deleted':
      dispatch(deleteSupportMessage(payload.id))
      break

    case 'support.chat.message.updated':
      dispatch(updateSupportMessage(payload))
      break

    case 'error.support.chat.edit.message':
      dispatch(setSupportEditMessageError(payload.message))
      break

    case 'error.support.chat.delete.message':
      dispatch(setSupportDeleteMessageError(payload.message))
      break

    case 'search.support.message':
      dispatch(searchSupportMessage(payload))
      break

    case 'anonymous.uuid':
      dispatch(setAnonymousId(payload.uuid))
      break

    // billing cases
    case events.billing.permissionsCurrent:
      dispatch(setBillingPermissions(payload))
      break
    case events.billing.permissionsUpdated:
      dispatch(setBillingPermissions(payload))
      break
    case events.billing.subscriptionPaid:
      if (window.location.href.indexOf('profile/billing') === -1) {
        notify.info('Info', popupNotifyText.success)
      }
      break
    case events.billing.subscriptionUnPaid:
      if (window.location.href.indexOf('profile/billing') === -1) {
        notify.info('Info', popupNotifyText.unSuccess)
      }
      break
    case events.billing.subscriptionCanceled:
      notify.info('Info', 'Your subscription has been canceled!')
      break
    // error case
    case 'error': {
      notify.error('Error', payload.message)
      break
    }

    default:
      return
  }
}
