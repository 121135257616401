import React, { memo } from 'react'

import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import cn from 'classnames'

import ReactTooltip from 'react-tooltip'
import AnimateHeight from 'react-animate-height'

import { ReactComponent as HistoryIcon } from '../../../assets/icons/revers.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import CrossIcon from '../../../UI/Icons/CrossIcon'
import { RestoreIcon, TrashIcon } from '../../../UI/Icons/Icons'

import useVesselNameHistory from './VesselNameHistory/useVesselNameHistory'
import VesselNameHistory from './VesselNameHistory/VesselNameHistory'
import Image from '../../../UI/Image/Image'

import useCanEdit from '../../../hooks/useCanEdit'
import { isDefaultVesselImg } from '../../../helpers/helpers'

const VesselManagerListItem = memo(
  ({ data, onOpenClick, onDeleteClick, onRestoreClick, onEditClick, isOpen, isOnEdit, isDeleted }) => {
    const { props, closeHistory, getRenameHistory, isOpenHistory } = useVesselNameHistory(data.vessel.id)

    const isCanEdit = useCanEdit()

    return (
      <div className={cn(styles.vessel, { [styles.vessel__open]: isOpen })} onMouseLeave={closeHistory}>
        <ReactTooltip backgroundColor="#43927C" id="default" />

        <div className={cn(styles.vesselHeader, { [styles.vesselHeaderOpen]: isOpen })}>
          <div className={styles.vesselName}>
            <b>Vessel name: </b>

            <span data-tip={true} data-for={String(data.vessel.id)} className={styles.name}>
              {data.vessel.name || 'NO NAME'}
            </span>

            <ReactTooltip id={String(data.vessel.id)} backgroundColor="transparent" className={styles.tooltip}>
              <Image
                src={data.mt_photo_mini_url}
                alt="vassel"
                className={cn({ [styles.vesselImg]: isDefaultVesselImg(data.mt_photo_mini_url) })}
              />
            </ReactTooltip>

            <span className={styles.historyIcon}>
              <HistoryIcon onClick={() => getRenameHistory(data.vessel.id)} />
            </span>

            <div className={styles.renameHistoryWrapper}>{isOpenHistory && <VesselNameHistory {...props} />}</div>

            {!isOpen && (
              <div className={styles.vesselHeaderMore}>
                <span>{data.vessel_type.name ? `${data.vessel_type.name},` : null} </span>
                <span>{data.engine.name ? `${data.engine.name},` : null} </span>
                <span>{data.dwt ? `DWT - ${data.dwt},` : null} </span>
                <span>{data.owner?.country?.iso3 ? `${data.owner.country.iso3}` : null}</span>
              </div>
            )}
          </div>

          <div className={cn(styles.controls, { [styles.controls__inactive]: !isCanEdit })}>
            {isDeleted && (
              <button onClick={() => onRestoreClick(data.vessel.id)} data-tip="restore" data-for="default">
                <RestoreIcon width={25.42} height={28.74} strokeWidth={1} strokeColor="#43927C" />
              </button>
            )}

            {!isDeleted && (
              <>
                <button data-tip="delete" data-for="default" onClick={() => onDeleteClick(data.vessel.id)}>
                  <TrashIcon width={15.42} height={18.74} strokeWidth={1} strokeColor="#C20000" />
                </button>

                <button
                  data-tip={isOnEdit ? 'cancel' : 'edit'}
                  data-for="default"
                  onClick={() => onEditClick(data.vessel.id, isOnEdit)}
                  style={{ marginLeft: '10px' }}
                >
                  {isOnEdit && <CrossIcon width={14} height={14} strokeWidth={1} stroke="#43927C" />}
                  {!isOnEdit && <EditIcon />}
                </button>
              </>
            )}
          </div>
        </div>

        <AnimateHeight height={isOpen ? 'auto' : 0}>
          <div className={styles.vesselMore}>
            <div className={styles.vesselRow}>
              <div className={styles.vesselRowItem}>
                <b>IMO: </b> {data.imo || '–'}
              </div>

              <div className={styles.vesselRowItem}>
                <b>Build year: </b> {data.year_of_build || '–'}
              </div>
            </div>

            <div className={styles.vesselRow}>
              <div className={styles.vesselRowItem}>
                <b>Vessel type: </b> {data.vessel_type.name || '–'}
              </div>

              <div className={styles.vesselRowItem}>
                <b>DWT: </b> {data.dwt || '–'}
              </div>
            </div>

            <div className={styles.vesselRow}>
              <div className={styles.vesselRowItem}>
                <b>Engine type: </b> {data.engine.name || '–'}
              </div>

              <div className={styles.vesselRowItem}>
                <b>GRT: </b> {data.grt || '–'}
              </div>
            </div>

            <div className={styles.vesselRow}>
              <div className={styles.vesselRowItem}>
                <b>Vessel flag: </b> {data.vessel_flag.name || '–'}
              </div>

              <div className={styles.vesselRowItem}>
                <b>TEU: </b> {data.teu || '–'}
              </div>
            </div>

            <div className={styles.vesselRow}>
              <div className={styles.vesselRowItem}>
                <b>Crew: </b> {data.crew_nationality.name || '–'}
              </div>

              <div className={styles.vesselRowItem}>
                <b>Power, Hp: </b> {data.hp || '–'}
              </div>
            </div>

            <div className={styles.vesselRow}>
              <div className={styles.vesselRowItem}>
                <b>Shipowner country: </b> {data.owner?.country?.iso3 || '–'}
              </div>

              <div className={styles.vesselRowItem}>
                <b>Power, Kw: </b> {data.kw || '–'}
              </div>
            </div>

            <div className={styles.vesselRow}>
              <div className={styles.vesselRowItem}>
                <b>Shipowner name: </b> {data.owner?.name || '–'}
              </div>
            </div>
          </div>
        </AnimateHeight>

        <button className={styles.footerButton} onClick={() => onOpenClick(data.vessel.id)}>
          <ArrowDownIcon />
        </button>
      </div>
    )
  }
)

VesselManagerListItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default VesselManagerListItem
