import React from 'react'
import styles from './checkBox.module.scss'
import { ReactComponent as CheckIcon } from './icons/check-icon.svg'
import cn from 'classnames'

const CheckBox = ({
  checked = false,
  onChange,
  children = undefined,
  name,
  color = 'var(--color-accent-light)',
  id,
  disabled,
  theme,
  className,
  ...rest
}) => {
  return (
    <div
      className={cn(styles.wrapper, className, {
        [styles.disabled]: disabled,
        [styles[theme]]: theme === 'filled',
      })}
      style={{ '--color': color }}
    >
      <input
        name={name}
        disabled={disabled}
        onChange={() => onChange(id)}
        checked={checked}
        type="checkbox"
        id={id}
        {...rest}
      />

      <label className={styles.indicator} htmlFor={id}>
        {checked && <CheckIcon />}
      </label>

      {children && (
        <label className={styles.label} htmlFor={id}>
          {children}
        </label>
      )}
    </div>
  )
}

export default CheckBox
