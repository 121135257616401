import { postCompanyVacancy } from '../../api/postApi'
import { vacancyDataHelperUpdate, vacancySubmitHelper } from '../../helpers/vacancyManager.helper'
import { putCompanyVacancy } from '../../api/putApi'
import { setIsVacancyEnable, pathRestoreDeletedVacancy } from '../../api/patchApi'
import { deleteCompanyVacancy, hardDeleteCompanyVacancy } from '../../api/deleteApi'
import { notify } from '../../helpers/notify'
import { batch } from 'react-redux'
import { VACANCY } from './vacancyManager.types'

export const postVacancy = (data, archive, clearForm, type) => (dispatch) => {
  dispatch(setIsButtonEnable(false))

  postCompanyVacancy(vacancySubmitHelper(data, type))
    .then((response) => {
      dispatch(setIsButtonEnable(true))

      notify('Success', 'Vacancy was successfully created')

      clearForm()

      if (!archive) {
        dispatch(createVacancy(vacancyDataHelperUpdate(response, type)))
      } else {
        dispatch(incrementActiveVacancy())
      }
    })
    .catch((error) => {
      dispatch(setIsButtonEnable(true))
      notify.errorsList(error.errors)
    })
}
export const setIsButtonEnable = (payload) => ({
  type: VACANCY.SET_IS_BUTTON_ENABLE,
  payload,
})

export const updateVacancy = (data, id, type) => (dispatch) => {
  dispatch(setIsButtonEnable(false))

  putCompanyVacancy(vacancySubmitHelper(data, type), id)
    .then((response) => {
      dispatch(updateVacancyInfo(vacancyDataHelperUpdate(response, type)))
      dispatch(clearVacancyForm())
      dispatch(setIsButtonEnable(true))
      notify('Success', 'Vacancy was successfully update')
    })
    .catch((error) => {
      dispatch(setIsButtonEnable(true))
      notify.errorsList(error.errors)
    })
}

export const deleteVacancy = (id) => {
  return (dispatch) => {
    deleteCompanyVacancy(id)
      .then(() => {
        batch(() => {
          dispatch(removeVacancy(id))
          dispatch(clearVacancyForm())
          dispatch(incrementArchiveVacancy())
          dispatch(decrementActiveVacancy())
          dispatch(updateFilters({ deleted: true }))
        })

        notify('Success', 'Vacancy was successfully archived')
      })
      .catch((error) => notify.errorsList(error.errors))
  }
}

export const hardDeleteVacancy = (id) => {
  return (dispatch) => {
    hardDeleteCompanyVacancy(id)
      .then(() => {
        batch(() => {
          dispatch(removeVacancy(id))
          dispatch(decrementActiveVacancy())
        })

        notify('Success', 'Vacancy was successfully deleted')
      })
      .catch((error) => notify.errorsList(error.errors))
  }
}

export const removeVacancy = (payload) => ({
  type: VACANCY.REMOVE_FROM_VACANCIES_LIST,
  payload,
})

export const restoreDeletedVacancy = (id) => {
  return (dispatch) => {
    pathRestoreDeletedVacancy(id)
      .then(() => {
        batch(() => {
          dispatch(removeVacancy(id))
          dispatch(decrementArchiveVacancy())
          dispatch(incrementActiveVacancy())
          dispatch(updateFilters({ deleted: false }))
        })
      })
      .catch((error) => notify.errorsList(error.errors))
  }
}

export const updateFilters = (value) => (dispatch) => {
  dispatch(updateVacanciesFilter(value))
}

export const setVacancyStatus = (id, status) => {
  return (dispatch) => {
    setIsVacancyEnable(id, status)
      .then(() => {
        dispatch(setIsVacancyEnabled({ id, status }))
      })
      .catch((error) => notify.errorsList(error.errors))
  }
}

export const updateVacanciesFilter = (payload) => ({
  type: VACANCY.UPDATE_VACANCIES_FILTER,
  payload,
})

export const updateVacancyInfo = (payload) => ({
  type: VACANCY.UPDATE_VACANCY,
  payload,
})

export const createVacancy = (payload) => ({
  type: VACANCY.CREATE_NEW_VACANCY,
  payload: {
    ...payload,
  },
})

export const setIsVacancyEnabled = (payload) => ({
  type: VACANCY.SET_IS_VACANCY_ENABLED,
  payload,
})

export const updateVacanciesList = (payload) => ({
  type: VACANCY.UPDATE_VACANCIES_LIST,
  payload,
})

export const clearVacancyList = () => ({
  type: VACANCY.CLEAR_VACANCY_LIST,
})

export const editVacancy = (inEditId, vacancyType) => ({
  type: VACANCY.EDIT_VACANCY,
  payload: { inEditId, vacancyType },
})

export const incrementArchiveVacancy = () => ({
  type: VACANCY.INCREMENT_ARCHIVE_VACANCY,
})

export const decrementArchiveVacancy = () => ({
  type: VACANCY.DECREMENT_ARCHIVE_VACANCY,
})

export const incrementActiveVacancy = () => ({
  type: VACANCY.INCREMENT_ACTIVE_VACANCY,
})

export const decrementActiveVacancy = () => ({
  type: VACANCY.DECREMENT_ACTIVE_VACANCY,
})

export const clearVacancyForm = () => ({ type: VACANCY.CLEAR_VACANCY_FORM })

export const resetVacancies = () => ({
  type: VACANCY.RESET_VACANCIES,
})

export const setVacancyType = (payload) => ({
  type: VACANCY.SET_VACANCY_TYPE,
  payload,
})
