import React, { useEffect, useRef, useState } from 'react'
import styles from './resizableTextarea.module.scss'
import cn from 'classnames'

const ResizableTextarea = ({
  minRows = 1,
  maxRows = 10,
  lineHeight = 15,
  onChange,
  value,
  autoFocus,
  className,
  setIsCurrentRows,
  ...rest
}) => {
  const [rows, setRows] = useState(1)
  const ref = useRef()

  useEffect(() => {
    if (autoFocus) ref.current.focus()
  }, [autoFocus])

  useEffect(() => {
    const elem = ref.current
    const previousRows = elem.rows
    elem.rows = minRows // reset number of rows in textarea
    const currentRows = ~~(elem.scrollHeight / lineHeight)
    if (currentRows === previousRows) elem.rows = currentRows

    if (currentRows >= maxRows) {
      elem.rows = maxRows
      elem.scrollTop = elem.scrollHeight
    }

    setRows(currentRows < maxRows ? currentRows : maxRows)
    if (setIsCurrentRows) setIsCurrentRows(currentRows < maxRows ? currentRows : maxRows)
    //eslint-disable-next-line
  }, [setRows, maxRows, minRows, value, lineHeight])

  const lineHeightStyles = {
    lineHeight: `${lineHeight}px`,
    '--line-height': `${lineHeight}px`,
  }

  return (
    <textarea
      ref={ref}
      rows={rows}
      value={value}
      placeholder={'Enter your...'}
      className={cn(styles.textarea, className)}
      onChange={onChange}
      style={lineHeightStyles}
      {...rest}
    />
  )
}

export default ResizableTextarea
