import { setSeafarerFavorite } from '../../api/postApi'
import { deleteSeafarerFromFavourites } from '../../api/deleteApi'
import { fetchSeafarersHelper } from '../../helpers/apiHelper'
import { notify } from '../../helpers/notify'

export const setIsSeafarerFavorite = (id, status) => (dispatch) => {
  if (!status) {
    setSeafarerFavorite(id)
      .then(() => {
        dispatch(setFavouriteStatus(id, status))
      })
      .catch((error) => notify.errorsList(error.errors))
  } else {
    deleteSeafarerFromFavourites(id)
      .then(() => {
        dispatch(removeSeafarerFromFavourites(id))
      })
      .catch((error) => notify.errorsList(error.errors))
  }
}

export const updateSearchFilterOptions = (data) => (dispatch) => {
  dispatch(setSearchFilterOptions(data))
}

export const updateFavoriteFilter = (value) => (dispatch) => {
  dispatch(setFavouriteFilter(value))
}

export const updateRequestsFilter = (value) => async (dispatch) => {
  dispatch(setRequestsFilter(value))

  // if (!value.vacancy_id && !value.search) {
  //   try {
  //     const response = await fetchSeafarersRequests(1)
  //
  //     dispatch(setSeafarersRequestsList(fetchSeafarersHelper(response.results, response.count, response)))
  //   } catch (error) {
  //     notify.errorsList(error.errors)
  //   }
  // }
}

const setRequestsFilter = (payload) => ({
  type: 'SET_REQUESTS_FILTER',
  payload,
})

export const setLoading = (peyload) => ({
  type: 'SET_LOADING',
  peyload,
})

const setFavouriteFilter = (payload) => ({
  type: 'SET_FAVOURITE_FILTER',
  payload,
})

export const setSearchFilterOptions = (data) => ({
  type: 'SET_SEARCH_FILTER_OPTIONS',
  payload: data,
})

const setFavouriteStatus = (id, status) => {
  return {
    type: 'SET_FAVOURITE_STATUS',
    payload: { id, status },
  }
}

const removeSeafarerFromFavourites = (id) => {
  return {
    type: 'REMOVE_SEAFARER_FROM_FAVOURITES',
    payload: id,
  }
}

export const setSeafarersList = (data) => ({
  type: 'SET_SEAFARERS_LIST',
  payload: data,
})

export const setSeafarersFavouriteList = (data) => ({
  type: 'SET_SEAFARERS_FAVOURITE_LIST',
  payload: data,
})

export const clearSeafarersList = () => ({
  type: 'CLEAR_SEAFARERS_LIST',
})

export const clearSeafarersFavouritesList = () => ({
  type: 'CLEAR_SEAFARERS_FAVOURITES_LIST',
})

export const clearSeafarersRequestsList = () => ({
  type: 'CLEAR_SEAFARERS_REQUESTS_LIST',
})

export const addTab = (id, title) => ({
  type: 'ADD_TABS_PANEL_ITEM',
  payload: {
    id,
    title,
  },
})

export const deleteTab = (id) => ({
  type: 'DELETE_TABS_PANEL_ITEM',
  payload: id,
})

export const deleteTabs = () => ({
  type: 'DELETE_ALL_TABS',
})

export const setIdDeletedUser = (user_id) => ({
  type: 'SET_ID_DELETED_USER',
  payload: user_id,
})

export const removeSeafarerFromList = (id) => ({
  type: 'REMOVE_SEAFARER_FROM_LIST',
  payload: id,
})

// SEAFARERS REQUESTS ACTIONS
export const setSeafarersRequestsList = (data) => ({
  type: 'SET_SEAFARERS_REQUESTS_LIST',
  payload: data,
})

export const removeRequest = (id) => ({
  type: 'REMOVE_REQUEST',
  payload: id,
})

// SEAFARERS SCROLL
export const changeScroll = (value) => ({
  type: 'CHANGE_SCROLL',
  payload: value,
})

// RESET FILTER PARAMS
export const resetFilterParams = () => ({
  type: 'RESET_FILTER_PARAMS',
})

// UPDATE VACANCY REQUEST
export const setNewVacancyRequest = (data) => {
  const vacancies = fetchSeafarersHelper([data.vacancy])
  return {
    type: 'SET_NEW_VACANCY_REQUEST',
    payload: { vacancies },
  }
}

// SET VACANCY TEMPLATE
export const setVacancyTemplatesRequestsList = (data) => ({
  type: 'SET_VACANCY_TEMPLATES_REQUESTS_LIST',
  payload: data,
})

export const setCommentsGeneral = (payload) => {
  return {
    type: 'SET_COMMENTS_GENERAL',
    payload,
  }
}

export const setCommentsList = (payload) => {
  return {
    type: 'SET_COMMENTS_LIST',
    payload,
  }
}

export const clearCommentsList = () => {
  return {
    type: 'CLEAR_COMMENTS_LIST',
  }
}

export const addNewComment = (payload) => {
  return {
    type: 'ADD_NEW_COMMENT',
    payload,
  }
}

export const clearCommentsData = () => {
  return {
    type: 'CLEAR_COMMENTS_DATA',
  }
}

export const setVacancyRequestCount = (payload) => ({
  type: 'SET_VACANCY_REQUEST_COUNT',
  payload,
})

export const deleteVacancyRequest = (payload) => ({
  type: 'DELETE_VACANCY_REQUEST',
  payload,
})

export const updateVacancyRequest = (payload) => ({
  type: 'UPDATE_VACANCY_REQUEST',
  payload,
})

export const clearVacancyTemplatesRequestsList = () => ({ type: 'CLEAR_VACANCY_TEMPLATES_REQUESTS_LIST' })
