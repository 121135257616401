import { useNotify } from '../../../hooks/useNotify'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useCallback, useEffect, useState } from 'react'
import {
  getAuthProfileFilters,
  getBillingCard,
  getBillingSubscription,
  getBillingSubscriptionPlans,
  getPaymentMethodStripe,
  getBillingSubscriptionPlanPrice,
  getBillingSubscriptionServices,
} from '../../../api/getApi'
import {
  centToDollar,
  transformBillingDetails,
  updatePaidIds,
  updatePendingPaymentData,
  updateUnpaidIds,
  updateUnProlongedProducts,
} from './utils'
import {
  detachBillingCard,
  setBillingCard,
  setBillingSubscription,
  setBillingSubscriptionPlans,
} from '../../../api/postApi'
import useListenEvent from '../../../hooks/useListenEvent'

import { confirm } from '../../../UI/Confirm/Confirm'

import { popupNotifyText, steps } from './constants'
import { events } from '../../../constants/constants'

const useServicesStore = () => {
  const [card, setCard] = useState({})
  const [billingDetails, setBillingDetails] = useState({})
  const [products, setProducts] = useState([])

  const [markedUnpaidProductsId, setMarkedUnpaidProductsId] = useState([])
  const [markedPaidProductsId, setMarkedPaidProductsId] = useState([])
  const [unProlongedProducts, setUnProlongedProducts] = useState([])
  const [unsubscribeProduct, setUnsubscribeProduct] = useState({})

  const [popup, setPopup] = useState({ isActive: false, message: popupNotifyText.process, step: steps.process })

  const [filters, setFilters] = useState({ countries: [], reasons: [] })
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalPriceForButton, setTotalPriceForButton] = useState(0)

  const [loading, setLoading] = useState(true)
  const [isPriceLoading, setIsPriceLoading] = useState(false)
  const [isActiveUnsubscribeForm, setIsActiveUnsubscribeForm] = useState(false)
  const [pendingPaymentData, setPendingPaymentData] = useState(null)
  const [currentProductId, setCurrentProductId] = useState(null)
  const [paddingBottom, setPaddingBottom] = useState('54px')
  const [unPaid, setUnPaid] = useState(null)
  const { notify } = useNotify()

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [billingSubscription, billingSubscriptionPlan, billingCard, _filters, products] = await Promise.all([
          getBillingSubscription(),
          getBillingSubscriptionPlans(),
          getBillingCard(),
          getAuthProfileFilters('country', 'billing_unsubscription_reasons'),
          getBillingSubscriptionServices(),
        ])

        setFilters({ countries: _filters.country, reasons: _filters.billing_unsubscription_reasons })
        setProducts(products.results)

        if (!billingSubscription.last_payment_intent) {
          setLoading(false)

          return
        }

        updatePendingPaymentData(billingSubscription, setPendingPaymentData)

        const paidResult = updatePaidIds(billingSubscription.subscription)
        const unProlongedResult = updateUnProlongedProducts(
          billingSubscription.subscription,
          billingSubscriptionPlan.results
        )

        setMarkedPaidProductsId(paidResult)
        setUnProlongedProducts(unProlongedResult)

        setCard(billingCard.results[0] ?? {})
        setBillingDetails(transformBillingDetails(billingCard.results[0] ?? {}, _filters.country))
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    fetchData()

    // eslint-disable-next-line
  }, [unPaid])

  useEffect(() => {
    const getSubscriptionPrice = async () => {
      setIsPriceLoading(true)

      try {
        const { price } = await getBillingSubscriptionPlanPrice(markedUnpaidProductsId)

        setTotalPrice(centToDollar(price))
        setTotalPriceForButton(price)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setIsPriceLoading(false)
      }
    }

    if (markedUnpaidProductsId.length) getSubscriptionPrice()
    if (!markedUnpaidProductsId.length) setTotalPrice(0)
    if (!markedUnpaidProductsId.length) setTotalPriceForButton(0)

    // eslint-disable-next-line
  }, [markedUnpaidProductsId])

  const successProcessPayment = useCallback((message = popupNotifyText.success) => {
    setPopup({ isActive: true, message, step: steps.success })
  }, [])

  const unSuccessProcessPayment = useCallback((message = popupNotifyText.unSuccess) => {
    setPopup({ isActive: true, message, step: steps.unSuccess })
  }, [])

  const handlePopupClose = useCallback(() => {
    setPopup({ isActive: false, message: popupNotifyText.process, step: steps.process })
  }, [])

  const handleReadyButtons = useCallback(({ availablePaymentMethods }) => {
    if (availablePaymentMethods) {
      // Optional: Animate in the Element
      setPaddingBottom('86px')
    }
  }, [])

  const serviceEmailSubscription = useCallback((action, name) => {
    setPopup({
      isActive: true,
      message: `Success, We will ${action} you an email once the ${name} service is launched.`,
      step: steps.success,
    })
  }, [])

  const updateProductsByEmailSubscription = useCallback(
    (id) => {
      const result = products.map((product) => {
        if (product.id === id) {
          return { ...product, email_subscription: !product.email_subscription }
        }

        return product
      })

      setProducts(result)
    },
    [products]
  )

  const handleCheckBoxClick = useCallback(
    (id) => {
      setCurrentProductId(id)

      if (!markedPaidProductsId.includes(id)) {
        const unpaidResult = updateUnpaidIds(markedUnpaidProductsId, id)

        setMarkedUnpaidProductsId(unpaidResult)

        return
      }

      const product = products.find((product) => product.id === id)

      setUnsubscribeProduct(product)
      setIsActiveUnsubscribeForm(true)
    },
    [markedPaidProductsId, markedUnpaidProductsId, products]
  )

  const handleUnsubscribeFormClose = useCallback(() => {
    setIsActiveUnsubscribeForm(false)
  }, [])

  // REQUESTS
  const setSubscription = useCallback(
    async (plan_id, card_id) => {
      try {
        const { last_payment_intent } = await setBillingSubscription({ plan_id, card_id })

        const { client_secret } = last_payment_intent

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          client_secret,
          {
            return_url: window.location.href,
          },
          {
            handleActions: false,
          }
        )

        if (paymentIntent) {
          const { url = '' } = paymentIntent?.next_action?.redirect_to_url

          window.open(url, '_parent')

          return
        }

        if (error && !error.payment_intent) {
          unSuccessProcessPayment()

          return
        }

        successProcessPayment()
        setMarkedUnpaidProductsId([])
      } catch (error) {
        unSuccessProcessPayment(error.errors)
      }
    },
    [stripe, successProcessPayment, unSuccessProcessPayment]
  )

  const setSubscriptionPlans = useCallback(
    async (cardId) => {
      try {
        const { id } = await setBillingSubscriptionPlans([...markedPaidProductsId, ...markedUnpaidProductsId])

        await setSubscription(id, cardId)
      } catch (error) {
        unSuccessProcessPayment(error.errors)
      }
    },
    [markedPaidProductsId, markedUnpaidProductsId, setSubscription, unSuccessProcessPayment]
  )

  const postCard = useCallback(
    async (id) => {
      try {
        const { id: cardId } = await setBillingCard(id)

        // if we have purchased goods and there are no new ones to purchase, we do not call this function
        // card replacement logic is executed
        if (markedUnpaidProductsId.length) {
          await setSubscriptionPlans(cardId)

          return
        }

        handlePopupClose()
      } catch (error) {
        unSuccessProcessPayment(error.errors)
      }
    },
    [handlePopupClose, markedUnpaidProductsId.length, setSubscriptionPlans, unSuccessProcessPayment]
  )

  const getPaymentMethod = useCallback(
    async (data) => {
      try {
        const { paymentMethod, errorPaymentMethod } = await getPaymentMethodStripe(stripe, elements, data)

        if (errorPaymentMethod) {
          const message = `Error creating payment method: ${errorPaymentMethod}`

          unSuccessProcessPayment(message)

          return
        }

        await postCard(paymentMethod.id)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    },

    // eslint-disable-next-line
    [elements, postCard, stripe, unSuccessProcessPayment]
  )

  const handleCardDetach = useCallback(() => {
    confirm('Are you sure?', 'Do you want to remove this payment information?', async () => {
      try {
        await detachBillingCard(card.id)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    })

    // eslint-disable-next-line
  }, [card.id])

  const handleSubmit = useCallback(
    async (data) => {
      const newPopup = (() => {
        // if we have purchased goods and there are no new ones to purchase, we do not call this function
        // card replacement logic is executed
        if (!markedUnpaidProductsId.length) {
          const message = 'Your card is being saved for future payments.'

          return { ...popup, isActive: true, message }
        }

        return { ...popup, isActive: true }
      })()

      setPopup(newPopup)

      try {
        if (!card.id || data.elementType === 'expressCheckout') {
          await getPaymentMethod(data)

          return
        }

        await setSubscriptionPlans(card.id)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    },

    // eslint-disable-next-line
    [card.id, getPaymentMethod, popup, setSubscriptionPlans]
  )
  const handleCancelSubscription = useCallback((data) => {
    if (!data.subscription && !data.last_payment_intent) {
      setMarkedUnpaidProductsId([])
      setMarkedPaidProductsId([])
      setUnsubscribeProduct([])
    }
  }, [])
  // REQUESTS

  // EVENTS
  const { billing } = events

  const handlePaidUpdateAction = (data) => {
    updatePendingPaymentData(data, setPendingPaymentData)

    const { subscription } = data

    const paidResult = updatePaidIds(subscription)
    const unProlongedResult = updateUnProlongedProducts(subscription, subscription.subscription_plan)

    setMarkedPaidProductsId(paidResult)
    setUnProlongedProducts(unProlongedResult)
  }

  const onRequiresConfirmationCallback = (data) => {
    updatePendingPaymentData(data, setPendingPaymentData)
  }

  useListenEvent(billing.paymentRequiresConfirmation, onRequiresConfirmationCallback)

  const onCardDetachCallback = () => {
    setCard({})
    setBillingDetails({})
  }

  useListenEvent(billing.cardDetach, onCardDetachCallback)

  const onCardAddedCallback = useCallback(
    (data) => {
      if (!filters.countries.length) return

      setCard(data)
      setBillingDetails(transformBillingDetails(data, filters.countries))
    },
    [filters.countries]
  )

  useListenEvent(billing.cardAdded, onCardAddedCallback, [onCardAddedCallback])

  const onSubscriptionPaidCallback = (data) => {
    handlePaidUpdateAction(data)
    successProcessPayment()
  }

  useListenEvent(billing.subscriptionPaid, onSubscriptionPaidCallback)

  const onSubscriptionUpdatedCallback = (data) => {
    handlePaidUpdateAction(data)
  }

  useListenEvent(billing.subscriptionUpdated, onSubscriptionUpdatedCallback)

  const unSuccessProcessUpdateCallback = (data) => {
    unSuccessProcessPayment()
    setUnPaid(true)
    setPendingPaymentData(null)
  }

  useListenEvent(billing.subscriptionCanceled, handleCancelSubscription)

  useListenEvent(billing.subscriptionUnPaid, unSuccessProcessUpdateCallback)

  // EVENTS

  return {
    stripe,
    elements,

    card,
    billingDetails,
    products,

    markedUnpaidProductsId,
    markedPaidProductsId,
    unProlongedProducts,
    unsubscribeProduct,
    setMarkedPaidProductsId,
    setUnProlongedProducts,
    updateProductsByEmailSubscription,

    popup,
    successProcessPayment,
    unSuccessProcessPayment,
    serviceEmailSubscription,

    pendingPaymentData,
    setPendingPaymentData,

    filters,
    totalPrice,
    totalPriceForButton,

    loading,
    isPriceLoading,
    isActiveUnsubscribeForm,

    handleSubmit,
    handleCheckBoxClick,
    handleCardDetach,
    handleReadyButtons,

    handlePopupClose,
    handleUnsubscribeFormClose,
    currentProductId,
    setPaddingBottom,
    paddingBottom,
  }
}

export default useServicesStore
