import React, { useEffect, useRef } from 'react'

import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import TabNav from '../components/TabNav/TabNav'
import Services from '../components/Store/Services/Services'
import History from '../components/Store/History/History'

import { setProfileTitle } from '../redux/actions/ui.action'
import { profilePaths } from '../constants/constants'

const links = [
  {
    url: profilePaths.billing_payment,
    title: 'Payment',
  },
  {
    url: profilePaths.billing_history,
    title: 'History',
  },
]

const BillingPage = () => {
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  useEffect(() => {
    dispatch(setProfileTitle('Billing'))

    //eslint-disable-next-line
  }, [])

  return (
    <>
      <TabNav links={links} />

      <main className="container" ref={containerRef}>
        <Switch>
          <Route path={profilePaths.billing_payment} component={() => <Services containerRef={containerRef} />} />
          <Route path={profilePaths.billing_history} component={History} />

          <Redirect to={profilePaths.billing_payment} />
        </Switch>
      </main>
    </>
  )
}

export default BillingPage
