import React, { useCallback, useEffect, useState } from 'react'

import styles from './styles.module.scss'

import VacancyManagerListItem from './VacancyManagerListItem'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearVacancyForm,
  deleteVacancy,
  editVacancy,
  hardDeleteVacancy,
  restoreDeletedVacancy,
  setVacancyStatus,
  setVacancyType,
} from '../../../redux/vacancyManager/vacancyManager.action'
import { confirm } from '../../../UI/Confirm/Confirm'
import Spinner from '../../../UI/Spinner/Spinner'
import { setIsButtonEnable } from '../../../redux/vesselManager/vesselManager.action'

const VacancyManagerList = ({ handleScroll, loading }) => {
  const [openItem, setOpenItem] = useState(null)

  const { inEditId: currentInEdit, list, filters } = useSelector(({ vacancies }) => vacancies)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(clearVacancyForm())

    // eslint-disable-next-line
  }, [])

  const handleOpenClick = useCallback(
    (id) => {
      setOpenItem(id === openItem ? null : id)
    },
    [openItem]
  )

  const handleSwitcherClick = useCallback((id, status) => {
    dispatch(setVacancyStatus(id, !status))

    // eslint-disable-next-line
  }, [])

  const handleDeleteClick = useCallback((id) => {
    confirm('Are you sure?', 'You want to archivate this vacancy?', () => {
      dispatch(deleteVacancy(id))
    })

    // eslint-disable-next-line
  }, [])

  const handleHardDeleteClick = useCallback((id) => {
    confirm('Are you sure?', 'You want to delete this vacancy? (forever)', () => {
      dispatch(hardDeleteVacancy(id))
      dispatch(clearVacancyForm())
    })

    // eslint-disable-next-line
  }, [])

  const handleRestoreClick = useCallback((id) => {
    dispatch(restoreDeletedVacancy(id))

    // eslint-disable-next-line
  }, [])

  const handleEditClick = useCallback(
    (id, vacancyType, isOnEdit) => {
      dispatch(setVacancyType(vacancyType))

      if (isOnEdit) {
        dispatch(clearVacancyForm())

        return
      }

      dispatch(editVacancy(id, vacancyType))
      dispatch(setIsButtonEnable(false))
    },

    // eslint-disable-next-line
    []
  )

  return (
    <div className={styles.list} onScroll={handleScroll}>
      {list.length ? (
        <>
          {list.map((item) => (
            <VacancyManagerListItem
              key={item.id}
              data={item}
              onOpenClick={handleOpenClick}
              onDeleteClick={handleDeleteClick}
              onHardDeleteClick={handleHardDeleteClick}
              onSwitcherClick={handleSwitcherClick}
              onEditClick={handleEditClick}
              onRestoreClick={handleRestoreClick}
              isOpen={openItem === item.id}
              isArchived={filters.deleted}
              isOnEdit={item.id === currentInEdit}
            />
          ))}

          {loading === null && list.length > 7 ? (
            <div className={styles.noContent}>You've seen everything</div>
          ) : (
            loading && <Spinner type="magnifier" size={60} withoutPosition />
          )}
        </>
      ) : null}
    </div>
  )
}

export default VacancyManagerList
