import React from 'react'
import cn from 'classnames'

const Tabs = ({ unsetLastElemId, isDeleted, onClick }) => {
  const handleClick = (value) => {
    onClick(value !== 'enabled')
  }

  return (
    <div className="user-create-tabs">
      <div
        className={cn('user-create-tabs__item', {
          'user-create-tabs__item_active': !isDeleted,
        })}
        onClick={() => handleClick('enabled')}
      >
        Enabled/Disabled
      </div>

      <div
        className={cn('user-create-tabs__item', {
          'user-create-tabs__item_active': isDeleted,
        })}
        onClick={() => {
          unsetLastElemId()
          handleClick('deleted')
        }}
      >
        Deleted
      </div>
    </div>
  )
}

export default Tabs
