import React from 'react'

import styles from '../styles.module.scss'
import cn from 'classnames'

// import { getFullYear } from '../../../helpers/time-helper/time-helper'

const VacancyTooltipContent = ({ vacancyDetails }) => {
  const { file_mini: companyLogo } = vacancyDetails.company.picture

  return (
    <div className={styles.vacancyDetailsWrap}>
      <div className={styles.row_more}>
        {vacancyDetails.vessel.name && (
          <>
            <div className={styles.column}>
              <div className={styles.row_more_avatar}>
                <img className={styles.avatar} src={companyLogo} alt="company logo" />
                <p className={styles.vacancy_details}>{vacancyDetails.title}</p>
              </div>

              <div className={styles.row_more_field}>
                <div className={styles.bolder}>Vessel Name:</div>
                <p>{vacancyDetails.vessel.name || 'No name'}</p>
              </div>

              <div className={styles.row_more_field}>
                <div className={styles.bolder}>Shipowner:</div>

                {vacancyDetails?.shipowner?.name || 'No name'}
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.row_more_field}>
                <div className={styles.bolder}>Crew:</div>

                {vacancyDetails.vessel.crew_mixed ? 'Mix' : vacancyDetails.vessel.crew_nationality?.name || '—'}
              </div>

              <div className={styles.row_more_field}>
                <div className={styles.bolder}>Engine type:</div>

                {vacancyDetails.vessel?.engine_type?.name || '—'}
              </div>

              <div className={styles.row_more_field}>
                <div className={styles.bolder}>Built Year:</div>

                {vacancyDetails?.vessel?.year_of_build || '—'}
              </div>

              <div className={styles.row_more_field}>
                <div className={styles.bolder}>Vessel Flag:</div>

                {vacancyDetails.vessel.flag?.name || '—'}
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.row_more_field}>
                <div className={styles.bolder_sm}>DWT:</div>

                {vacancyDetails.vessel.dwt || '—'}
              </div>

              <div className={styles.row_more_field}>
                <div className={styles.bolder_sm}>GRT:</div>

                {vacancyDetails.vessel.grt || '—'}
              </div>

              <div className={styles.row_more_field}>
                <div className={styles.bolder_sm}>TEU:</div>

                {vacancyDetails.vessel.teu || '—'}
              </div>

              <div className={styles.row_more_field}>
                <div className={styles.bolder_sm}>Power:</div>

                {`${vacancyDetails.vessel.hp}HP/${vacancyDetails.vessel.kw}KW`}
              </div>
            </div>
          </>
        )}

        {!vacancyDetails.vessel.name && (
          <div className={styles.column}>
            <div className={styles.row_more_field}>
              <img className={styles.avatar} src={companyLogo} alt="avatar" />

              <p className={styles.vacancy_details}>{vacancyDetails.title}</p>
            </div>

            <div className={styles.row_more_field}>
              <div className={styles.bolder}>Shipowner:</div>

              {vacancyDetails?.shipowner?.name || 'No name'}
            </div>
          </div>
        )}
      </div>

      <div className={styles.row_more_footer}>
        <div className={styles.row_more_field}>
          <div className={styles.bolder}>Salary:</div>

          <div className={styles.salary}>
            {vacancyDetails.salary_currency.symbol}

            {`${vacancyDetails.salary}/${vacancyDetails.salary_rate}`}
          </div>

          <span className={cn(styles.salary_description, 'nowrap_string')}>{vacancyDetails.salary_description}</span>
        </div>

        {vacancyDetails.description && (
          <div className={styles.row_more_field}>
            <div className={styles.bolder}>Description:</div>
            <span className={styles.description}>{vacancyDetails.description}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default VacancyTooltipContent
