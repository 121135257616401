import React from 'react'
import cn from 'classnames'
import Masonry from 'react-responsive-masonry'
import UserCardSmartTooltip from '../../UserCardSmartTooltip/UserCardSmartTooltip'
import FileList from '../../FileList/FileList'
import styles from '../../userCard.module.scss'
import { ReactComponent as CalcOfFiles } from '../../../../assets/icons/calcOfFiles.svg'
import { ReactComponent as CloseSvg } from '../../../../assets/icons/closeIcon.svg'
import vessel_default from '../../../../assets/images/vessel_default.webp'
import { uniqueId } from 'lodash'

const ExperienceItem = ({ experience_type, title, period, files, items }) => {
  const [showFileList, setShowFileList] = React.useState(false)
  const [viewVesselPreview, setViewVesselPreview] = React.useState(false)
  const [image, ...rest] = items

  const onMouseEnter = () => {
    if (experience_type === 'sea') setViewVesselPreview(() => true)
  }

  const onMouseLeave = () => {
    if (experience_type === 'sea') setViewVesselPreview(() => false)
  }

  return (
    <div className={styles.contentItem}>
      <div className={cn(styles.header, { [styles.experience]: true })}>
        <div
          className={cn(styles.vessel_preview, {
            [styles.view]: viewVesselPreview,
          })}
        >
          <img src={image.value || vessel_default} alt="vessel" />
        </div>
        <UserCardSmartTooltip
          type={'simple'}
          title={experience_type === 'sea' ? `Vessel name: ${title}` : `Company name: ${title} (for location on land)`}
          titleClass={cn(styles.contentItemTitle, {
            [styles.contentItemTitleVessel]: experience_type === 'sea',
          })}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          id={uniqueId('experience-item-tooltip-')}
        />

        <p className={styles.headerPeriod}>{period}</p>

        {!showFileList && (
          <div
            className={styles.calcOfFilesContainer}
            onClick={() => {
              files.length && setShowFileList(true)
            }}
          >
            <label className={styles.calcOfFilesContainerLabel}>Photo</label>
            <div className={styles.calcOfFiles}>
              <CalcOfFiles />
              <label className={styles.calcOfFilesLabel}>{files.length}</label>
            </div>
          </div>
        )}

        {showFileList && (
          <button
            className={styles.closeBtn}
            onClick={() => {
              files.length && setShowFileList(false)
            }}
          >
            Close
            <CloseSvg />
          </button>
        )}
      </div>

      <div className={cn(styles.body, { [styles.experience]: true })}>
        <Masonry columnsCount={3} gutter="10px">
          {rest?.map((item, index) => (
            <UserCardSmartTooltip
              key={index}
              title={item.title}
              value={item.value}
              id={uniqueId('experience-item-tooltip-')}
              containerClass={styles.item}
              titleClass={styles.itemTitle}
              valueClass={cn(styles.itemValue, {
                [styles.info]: item.title === 'Info',
              })}
              tooltipClass={styles.tooltip}
            />
          ))}
        </Masonry>

        {files.length ? <FileList files={files} showFiles={showFileList} type={'experience'} /> : ''}
      </div>
    </div>
  )
}

export default ExperienceItem
