import React from 'react'

import styles from './Header.module.scss'
import cn from 'classnames'

import Logo from '../../UI/Logo/Logo'
import HeaderNav from './HeaderNav/HeaderNav'
// import { getBillingSettingsGlobal } from '../../api/getApi'
// import { notify } from '../../helpers/notify'

const Header = ({ onMobilePopup }) => {
  // const [isActive, setIsActive] = useState(false)
  //
  // useEffect(() => {
  //   getBillingSettingsGlobal()
  //     .then(({ is_active }) => setIsActive(is_active))
  //     .catch(({ errors }) => notify.errorsList(errors))
  // }, [])

  return (
    <header className={styles.header}>
      <div className={cn('container-landing', styles.wrapper)}>
        <Logo theme="dark" width={216} />
        <HeaderNav onMobilePopup={onMobilePopup} />
      </div>
    </header>
  )
}

Header.propTypes = {}

export default Header
