import format from 'date-fns/format'
import { formatISO, isValid, lastDayOfMonth, parse, parseISO, setDate } from 'date-fns'

import { getDateRangeMap } from '../vacancyManager.helper'
import { getLocalStorageItem } from '../helpers'
import store from '../../redux/store'

export const getDateFnsFormat = (format) => {
  return format.replaceAll('Y', 'y').replaceAll('D', 'd')
}

export const diffDays = (date1, date2) => {
  return Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24))
}

export const stringToISO = (value) => {
  const userFormat = JSON.parse(localStorage.getItem('settings')).date_format

  if (!value) return null

  return formatISO(parse(value, userFormat.replace('YYYY', 'yyyy').replace('DD', 'dd'), new Date()), {
    representation: 'date',
  })
}

export const getFullYear = (str) => {
  if (!str) {
    return null
  }

  return str.split('/').reduce((acc, cur) => (acc.length > cur.length ? acc : cur))
}

export const getUserTimeZone = () => new Date().getTimezoneOffset() * -1

export const getUserTimezone = () => {
  const userSettings = JSON.parse(localStorage.getItem('settings') || '{}')

  if (userSettings?.date_format) {
    return userSettings.date_format.toLowerCase().replaceAll('m', 'M')
  } else return 'yyyy/MM/dd'
}

export const formatWithoutDay = (date, userFormat) => {
  const fnsFormat = getDateFnsFormat(userFormat)

  return format(
    date,
    fnsFormat
      .split('/')
      .filter((el) => el !== 'dd')
      .join('/')
  )
}

export const formatForTimeTag = (dataString) => {
  if (typeof dataString === 'string') {
    return dataString.split('/').reverse().join('-')
  }

  return null
}

export const UTCToLocalDate = (utcDate) => {
  const date = new Date(utcDate)

  date.setMinutes(date.getMinutes() - date.getTimezoneOffset())

  return date.toString()
}

export const dateToTwelveHourFormat = (utcDate) => {
  return format(new Date(UTCToLocalDate(utcDate)), "hh':'mm aaa")
}

export const getAllDates = (arr) => {
  if (!arr) return null

  return arr.reverse().reduce((acc, cur) => {
    const newFormatDate = getDateByFormat(cur.date, 'MMMM dd')
    const publicationYear = new Date(cur.date).getFullYear()
    const currentYear = new Date().getFullYear()
    const displayDate = publicationYear < currentYear ? `${newFormatDate}, ${publicationYear}` : `${newFormatDate}`

    if (!acc.some((el) => el.value === newFormatDate)) {
      return [...acc, { value: displayDate, id: cur.id }]
    }

    return acc
  }, [])
}

export const formatPaymentDate = (strFrom, strTo) => {
  const dateFrom = new Date(strFrom)
  const dateTo = new Date(strTo)
  const FULL_DATE_FORMAT = 'MMMM dd, yyyy'
  const resultDateTo = format(dateTo, FULL_DATE_FORMAT)
  // if both years are equal - will show 2 different years
  const isYearsEqual = format(dateFrom, 'yyyy') === format(dateTo, 'yyyy')
  const resultDateFrom = format(dateFrom, isYearsEqual ? 'MMMM dd' : FULL_DATE_FORMAT)

  return `${resultDateFrom} - ${resultDateTo}`
}

export const maxDate = (date) => {
  if (date) {
    const dayMilliseconds = 24 * 60 * 60 * 1000
    const currentDate = new Date(date)

    return currentDate.setTime(currentDate.getTime() - dayMilliseconds)
  }
}

export const convertDateToSubmit = (date) => {
  if (date) {
    return format(new Date(date), 'yyyy-MM-dd')
  }

  return null
}

export const getPrettierDate = (date) => {
  if (date) {
    return date[0] === '0' ? '' + date.slice(1) : date
  } else {
    return ''
  }
}

export const getParsedDate = (dateString) => {
  const { date_format: userFormat } = store.getState().user.settings

  const fnsFormat = getDateFnsFormat(userFormat)

  return parse(dateString, fnsFormat, new Date())
}

export const getPeriodInYears = (dateFrom, dateTo) => {
  const incompleteDate = !getFullYear(dateFrom) && !getFullYear(dateTo)
  const yearFrom = getFullYear(dateFrom) || 'no date'
  const yearTo = getFullYear(dateTo) || 'current'

  return incompleteDate ? 'current' : `${yearFrom}-${yearTo}`
}

export const getLastDayOfMonth = (date) => {
  const newDate = new Date(date)

  return lastDayOfMonth(newDate).getDate()
}

export const setMaxRangeDate = (date, range) => {
  return setDate(date, getDateRangeMap(date)[range])
}

export const getDatePickerFormat = (isExactDate) => {
  return isExactDate
    ? getUserTimezone().replace('yyyy', 'yyyy')
    : getUserTimezone()
        .replace('yyyy', 'yyyy')
        .split('/')
        .filter((el) => el !== 'dd')
        .join('/')
}

export const daysToYearsMonthsDays = (days = 0) => {
  const years = Math.floor(days / 365)
  const months = Math.floor((days % 365) / 30)
  const remainingDays = days % 30

  return { years, months, remainingDays }
}

export const getDateByFormat = (date, newFormat) => {
  const fnsFormat = getDateFnsFormat(newFormat)

  return format(new Date(date), fnsFormat)
}

export const parsedByFormatWithTime = (date, format = 'dd.MM.yyyy') => {
  const userFormat = getLocalStorageItem('settings')?.date_format ?? format
  const newFormat = `${getDateFnsFormat(userFormat)} HH:mm:ss`
  const backendFormat = 'yyyy/MM/dd HH:mm:ss'

  const parsedDate = parse(date, newFormat, new Date())

  if (isValid(parsedDate)) {
    return parsedDate
  }

  return parse(date, backendFormat, new Date())
}

export const getDateByUserFormat = (dateString) => {
  const { date_format: userFormat } = store.getState().user.settings

  return getDateByFormat(dateString, userFormat)
}

export const getParsedDateToISO = (dateString) => {
  return parseISO(dateString)
}
