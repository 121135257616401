import React, { memo, useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import CheckBox from '../../../../../UI/CheckBox/CheckBox'

import { ReactComponent as FlagIcon } from './icons/flag.svg'
import { ReactComponent as BellIcon } from './icons/bell.svg'
import { ReactComponent as BellFillIcon } from './icons/bellFill.svg'

import Tooltip from 'react-tooltip-lite'
import Button from '../../../../../UI/Button/Button'

import { setEmailSubscription } from '../../../../../api/postApi'
import { deleteEmailSubscription } from '../../../../../api/deleteApi'
import { useNotify } from '../../../../../hooks/useNotify'
import { getDateByUserFormat } from '../../../../../helpers/time-helper/time-helper'
import { centToDollar } from '../../utils'

const ACTION = {
  SEND: 'send',
  NOT_SEND: 'not send',
}

const ProductItem = memo(
  ({
    data,
    onCheckBoxClick,
    serviceEmailSubscription,
    updateProductsByEmailSubscription,
    markedPaidIds,
    markedUnpaidIds,
    unProlongedProducts,
    currentProductId,
    isPriceLoading,
  }) => {
    const [loading, setLoading] = useState(false)

    const { notify } = useNotify()

    const { id, picture, name, prices, description, is_soon, is_free, is_testing, email_subscription } = data

    const handleEmailSubscriptionClick = async () => {
      setLoading(true)

      try {
        if (email_subscription) {
          await deleteEmailSubscription(id)

          updateProductsByEmailSubscription(id)
          serviceEmailSubscription(ACTION.NOT_SEND, name)

          return
        }

        await setEmailSubscription(id)

        updateProductsByEmailSubscription(id)
        serviceEmailSubscription(ACTION.SEND, name)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    const unProlongedProduct = unProlongedProducts.find(({ product }) => product.id === id)
    const expDateByUserFormat = (() => {
      if (!unProlongedProduct?.expire_datetime) return null

      return getDateByUserFormat(unProlongedProduct?.expire_datetime).replaceAll('/', '.')
    })()

    const isProductPurchased = markedPaidIds.includes(id) || is_free
    const isChecked = [...markedPaidIds, ...markedUnpaidIds].includes(id) || is_free
    const isDisabled = unProlongedProduct || is_free

    const tooltipContent = (
      <p className={styles.tooltip}>
        <span>We will send you an email once the</span> <br />
        <b>{name}</b> <span>is launched</span>
      </p>
    )

    return (
      <article className={cn(styles.productItem, { [styles.productItem__purchased]: isProductPurchased })}>
        <div className={styles.head}>
          <div className={styles.iconWrapper}>
            <img src={picture.file_mini} alt="product icon" />
          </div>

          <div className={styles.mainInfo}>
            <h4>{name}</h4>

            <div className={styles.labels}>
              {unProlongedProduct && (
                <label className={cn(styles.label, styles.label__subscriptionValidDate)}>
                  <FlagIcon />

                  <span>{expDateByUserFormat}</span>
                </label>
              )}

              {is_soon && <label className={cn(styles.label, styles.label__soon)}>soon</label>}

              {is_free && <label className={cn(styles.label, styles.label__priceFree)}>free</label>}

              {is_testing && !is_soon && (
                <label className={cn(styles.label, styles.label__testing)}>testing in progress</label>
              )}

              {!is_free && (
                <label className={cn(styles.label, styles.label__price)}>{`${centToDollar(prices.price)}${
                  prices.currency.symbol
                }/mth`}</label>
              )}
            </div>
          </div>

          {!is_soon && (
            <CheckBox
              id={id}
              checked={isChecked}
              onChange={onCheckBoxClick}
              className={cn(
                styles.checkBox,
                { [styles.checkBox__checked]: isChecked },
                { [styles.checkBox__disabled]: isDisabled }
              )}
              disabled={(isPriceLoading && currentProductId === id) || isDisabled}
            />
          )}

          {is_soon && (
            <Button
              theme="text"
              type="button"
              className={styles.bell}
              onClick={handleEmailSubscriptionClick}
              disabled={isDisabled || loading}
            >
              <Tooltip arrow={false} content={tooltipContent} padding="0">
                {Boolean(email_subscription) ? <BellFillIcon /> : <BellIcon />}
              </Tooltip>
            </Button>
          )}
        </div>

        <div className={styles.description}>{description}</div>
      </article>
    )
  }
)

export default ProductItem
