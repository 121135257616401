import React, { memo, useCallback, useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'

import styles from './styles.module.scss'

import FilterForm from './FilterForm/FilterForm'

import { getSearchLabels } from './utils'

const SeafarersSearchPanel = memo(({ Form, searchValues, updateAction }) => {
  const [values, setValues] = useState(searchValues)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const dispatch = useDispatch()

  const labels = useMemo(() => {
    return getSearchLabels(values)
  }, [values])

  const handleSubmitEvent = useCallback(
    (_values) => {
      setIsOpenForm(false)
      setIsSubmitted(true)

      dispatch(updateAction(_values))
    },

    // eslint-disable-next-line
    [updateAction]
  )

  const handleClearEvent = useCallback(
    (initialValues) => {
      setValues(initialValues)

      dispatch(updateAction(initialValues))
    },

    // eslint-disable-next-line
    [updateAction]
  )

  const handleFilterClick = useCallback(() => {
    setIsOpenForm((prev) => !prev)
    setIsSubmitted(false)
  }, [])

  const updateSearch = useCallback(
    (_values) => {
      if (isSubmitted) dispatch(updateAction(_values))
    },

    // eslint-disable-next-line
    [updateAction, isSubmitted]
  )

  return (
    <div className={styles.wrapper}>
      {!!labels.length && (
        <div className={styles.labels}>
          {labels.map(({ component: Label, ...props }) => (
            <Label key={props.name} updateSearchValues={setValues} updateSearch={updateSearch} {...props} />
          ))}
        </div>
      )}

      <FilterForm isOpen={isOpenForm} onButtonClick={handleFilterClick}>
        <Form values={values} setValues={setValues} onSubmitEvent={handleSubmitEvent} onClearEvent={handleClearEvent} />
      </FilterForm>
    </div>
  )
})

export default SeafarersSearchPanel
