import React from 'react'
import styles from '../../userCard.module.scss'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { daysToYearsMonthsDays } from '../../../../helpers/time-helper/time-helper'
import TotalExperienceWriting from '../../../../helpers/TotalExperienceWriting'

const ColleaguesItem = ({ colleague }) => {
  const { colleagues, vessel_name, date_from, date_to } = colleague

  return (
    <article>
      <div className={cn(styles.colleague, { [styles.lastEmptyItem]: isEmpty(colleagues) })}>
        <p>
          <span className={styles.colleague_vessel_name}>{vessel_name}</span> (Period{' '}
          <span className={styles.colleague_vessel_name}>
            {date_from} - {date_to || 'currently'}
          </span>
          )
        </p>
      </div>

      <ul className={styles.colleague_list}>
        {colleagues &&
          colleagues.map((item, index) => {
            const { date_from, date_to, duration, first_name, last_name, middle_name, rank } = item
            return (
              <li className={styles.colleague_list_item} key={index}>
                <span>
                  {first_name || last_name || middle_name ? `${first_name} ${last_name} ${middle_name}` : 'No name'}
                </span>

                <span>{rank}</span>

                <span>
                  <TotalExperienceWriting>{daysToYearsMonthsDays(duration)}</TotalExperienceWriting>
                  {`(${date_from} - ${date_to || 'currently'})`}
                </span>
              </li>
            )
          })}
      </ul>
    </article>
  )
}

export default ColleaguesItem
