import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import styles from './styles.module.scss'
import cn from 'classnames'

import { ReactComponent as ClockIcon } from '../../assets/icons/clock-frame.svg'

import { getDateByUserFormat } from '../../helpers/time-helper/time-helper'

const LastChatDateInfo = ({ date, to, className = '' }) => {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const newDate = getDateByUserFormat(date).replaceAll('/', '.')

  return (
    <>
      <Link to={to}>
        <ClockIcon
          className={styles.clockIcon}
          onMouseEnter={() => setIsOpenTooltip(true)}
          onMouseLeave={() => setIsOpenTooltip(false)}
        />
      </Link>

      {isOpenTooltip && (
        <div className={cn(styles.lastChatDateInfo, className)}>
          <span>last chat</span>
          <b>{newDate}</b>
        </div>
      )}
    </>
  )
}

export default LastChatDateInfo
