import React from 'react'

import ScrollAnimation from 'react-animate-on-scroll'
import { useSelector } from 'react-redux'

import styles from './Intro.module.scss'

import Button from '../../../UI/Button/Button'

import { profilePaths } from '../../../constants/constants'

const Intro = ({ onMobilePopup }) => {
  const isAuth = useSelector(({ authentication }) => authentication.isAuth)
  const isActive = useSelector(({ company }) => company.is_active)

  return (
    <section className={styles.section}>
      <div className={`container-landing ${styles.wrapper}`}>
        <ScrollAnimation duration={1} animateIn="fadeInUp">
          <h1 className={styles.title}>An innovative service for finding the right sea crew for shipowners</h1>
        </ScrollAnimation>

        <ScrollAnimation duration={2} animateIn="fadeInUp">
          <p className={styles.description}>
            Our service will help each shipowner to assemble the RIGHT crew, which will satisfy all desires and
            requirements.
          </p>
        </ScrollAnimation>

        <ScrollAnimation duration={3} animateIn="fadeInUp">
          <Button
            size="normal"
            theme="white"
            link
            to={
              isActive
                ? profilePaths.seafarers_search
                : isAuth && !isActive
                ? profilePaths.company_data
                : '/auth/signup'
            }
            onClick={onMobilePopup}
          >
            {isAuth ? 'Go to profile' : 'Create account'}
          </Button>
        </ScrollAnimation>
      </div>
    </section>
  )
}

export default Intro
