export const VESSEL = {
  SET_IS_BUTTON_ENABLE: 'SET_IS_BUTTON_ENABLE',
  ADD_EDITED_DATA: 'ADD_EDITED_DATA',
  ADD_NEW_VESSEL: 'ADD_NEW_VESSEL',
  DELETE_VESSEL_UPDATE: 'DELETE_VESSEL_UPDATE',
  RESTORE_VESSEL: 'RESTORE_VESSEL',
  SET_IS_RESTORE_LOADING: 'SET_IS_RESTORE_LOADING',
  EDIT_VESSEL: 'EDIT_VESSEL',
  VESSEL_ALL_COUNTER: 'VESSEL_ALL_COUNTER',
  VESSEL_DELETED_COUNTER: 'VESSEL_DELETED_COUNTER',
  UPDATE_VESSELS_LIST: 'UPDATE_VESSELS_LIST',
  UPDATE_DELETED_VESSELS_LIST: 'UPDATE_DELETED_VESSELS_LIST',
  CLEAR_VESSELS_LIST: 'CLEAR_VESSELS_LIST',
  RESET_VESSELS: 'RESET_VESSELS',
  CLEAR_VESSEL_FORM: 'CLEAR_VESSEL_FORM',
}
