import React, { useCallback, useEffect, useState } from 'react'

import styles from './styles.module.scss'

import Checkbox from '../../../UI/CheckBox/CheckBox'
import Spinner from '../../../UI/Spinner/Spinner'
import Button from '../../../UI/Button/Button'

import { getNotificationSettings } from '../../../api/getApi'
import { postNotificationSettings } from '../../../api/postApi'
import { useNotify } from '../../../hooks/useNotify'

const checkBoxTextStyles = {
  fontWeight: 400,
  color: 'var(--second-color)',
}

const Notifications = () => {
  const [notifications, setNotifications] = useState([])
  const [initNotifications, setInitNotifications] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isButtonEnable, setIsButtonEnable] = useState(false)
  const [loading, setLoading] = useState(false)

  const { notify } = useNotify()

  useEffect(() => {
    const getNotifications = async () => {
      setLoading(true)

      try {
        const { results } = await getNotificationSettings()

        setNotifications(results)
        setInitNotifications(results)
      } catch (error) {
        notify.errorsList(error.errors)
      } finally {
        setLoading(false)
      }
    }

    getNotifications()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const hasChanged = JSON.stringify(initNotifications) !== JSON.stringify(notifications)

    if (hasChanged) {
      setIsButtonEnable(true)
    } else {
      setIsButtonEnable(false)
    }
  }, [initNotifications, notifications])

  const handleNotificationsUpdate = useCallback(async () => {
    setLoading(true)

    try {
      const inactiveNotificationIds = notifications.reduce((acc, cur) => {
        if (!cur.is_active) {
          return [...acc, cur.id]
        }

        return acc
      }, [])

      const { results } = await postNotificationSettings({
        notification: inactiveNotificationIds,
      })

      setNotifications(results)
      setInitNotifications(results)
      setIsEdit(false)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [notifications])

  const handleCheckBoxClick = useCallback(
    (id) => {
      const updatedNotifications = notifications.map((item) => {
        if (item.id === id) {
          return { ...item, is_active: !item.is_active }
        }

        return item
      })

      setNotifications(updatedNotifications)
    },
    [notifications]
  )

  const handleEditClose = useCallback(() => {
    setIsEdit(false)
    setNotifications(initNotifications)
  }, [initNotifications])

  return (
    <>
      <div className={styles.topBlock}>
        {isEdit && (
          <div className={styles.buttons}>
            <Button className={styles.buttonClose} onClick={handleEditClose} type="close" size="small">
              Close
            </Button>

            <Button
              className={styles.buttonSave}
              onClick={handleNotificationsUpdate}
              type="submit"
              size="small"
              disabled={!isButtonEnable}
            >
              Save
            </Button>
          </div>
        )}

        {!isEdit && (
          <button className={styles.buttonEdit} onClick={() => setIsEdit(true)}>
            Edit notification list
          </button>
        )}
      </div>

      <div className={styles.wrapper}>
        <div className={styles.notifications}>
          {loading && <Spinner />}

          {!loading &&
            notifications.map(({ id, is_active, short_name }) => (
              <div className={styles.item} key={id}>
                <Checkbox
                  key={id}
                  id={id}
                  theme="bordered"
                  name="notification"
                  onChange={handleCheckBoxClick}
                  checked={is_active}
                  textStyles={checkBoxTextStyles}
                  disabled={!isEdit}
                >
                  {short_name}
                </Checkbox>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default Notifications
