const initialState = {
  profileTitle: null,
  company: {
    moderationNotification: {
      isVisible: false,
    },
  },
  isSocketConnected: false,
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case "MOD_UI_IS_VISIBLE":
      return {
        ...state,
        company: {
          ...state.company,
          moderationNotification: {
            ...state.company.moderationNotification,
            isVisible: action.payload,
          },
        },
      };
    case "SET_PROFILE_TITLE":
      return {
        ...state,
        profileTitle: action.payload,
      };

    case "SET_IS_SOCKET_CONNECTED": {
      const { status } = action.payload;
      return {
        ...state,
        isSocketConnected: status,
      };
    }

    default:
      return state;
  }
}
