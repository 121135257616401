import React from 'react'
import styles from './UserCardPreloader.module.scss'
import cn from 'classnames'
export const UserCardPreloader = ({ column = false, items }) => {
  const length = [...Array(7 + 1).keys()].slice(1)
  if (!items) items = [...Array(4 + 1).keys()].slice(1)
  return (
    <div className={cn(styles.content_item_wrapper, { [styles.content_item_wrapper_column]: column })}>
      {items.map((item) => {
        return (
          <div key={item} className={styles.contentItem}>
            <div className={styles.header}></div>
            <div className={styles.body}>
              <div className={styles.info}>
                {length.map((item, index) => (
                  <div key={index} className={styles.infoItem}></div>
                ))}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
