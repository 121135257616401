import { removeLastSlashAndValue } from '../helpers/helpers'
import { permissionsByPathMap } from '../constants/constants'
import { getUserIdFromUrl } from '../components/SearchTabsPanel/utils'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const useCanEdit = () => {
  const { permissions } = useSelector(({ company }) => company)

  const { pathname } = useLocation()

  const cardId = getUserIdFromUrl(pathname)
  const newPath = removeLastSlashAndValue(pathname)
  const clearPath = cardId ? newPath : pathname
  const permissionByPage = permissionsByPathMap[clearPath]

  return permissions.find((permission) => permission.name === permissionByPage)?.access_all
}

export default useCanEdit
