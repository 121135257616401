import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import './styles.scss'
import { CalendarIcon } from '../Icons/Icons'
import format from 'date-fns/format'
import isDate from 'date-fns/isDate'
import parse from 'date-fns/parse'
import useUserDateFormat from '../../hooks/useUserDateFormat'

const KeyBoardDatePicker = ({
  name,
  value,
  onChange,
  isInvalid,
  keyBoardLabel,
  required,
  disabled,
  errorMessage = '',
  ...rest
}) => {
  const userFormat = useUserDateFormat()

  if (!userFormat) return null

  if (value === '') {
    value = format(new Date(), userFormat.replace('YYYY', 'yyyy').replace('DD', 'dd'))
  }

  const pickerFormat = userFormat.replaceAll('YYYY', 'yyyy').replaceAll('DD', 'dd')

  if (value !== null && !isDate(value)) {
    value = parse(value, pickerFormat, new Date())
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <>
        <div className="keyBoardDatePicker">
          <p className="keyBoardDatePickerLabel">
            {keyBoardLabel} {required ? <span>*</span> : ''}
          </p>

          <div className={disabled ? 'keyBoardDatePicker-disabled' : isInvalid ? 'Mui-custom-invalid' : 'Mui-custom'}>
            <KeyboardDatePicker
              keyboardIcon={<CalendarIcon />}
              value={value}
              onChange={(value) => onChange(name, value)}
              disabled={disabled}
              format={pickerFormat}
              placeholder={pickerFormat}
              {...rest}
            />
          </div>

          {isInvalid && errorMessage && <div className="datePickerErrorMessage">{errorMessage}</div>}
        </div>
      </>
    </MuiPickersUtilsProvider>
  )
}

export default KeyBoardDatePicker
