import { useEffect } from "react";
import { useSelector } from "react-redux";
import socket from "../websocket";

export default function useAuthObserver() {
  const is_auth = useSelector(({ authentication }) => authentication.isAuth);
  useEffect(() => {
    socket.reconnect();
  }, [is_auth]);
}
