import React from 'react'
import cn from 'classnames'
import UserCardSmartTooltip from '../../UserCardSmartTooltip/UserCardSmartTooltip'
import styles from '../../userCard.module.scss'
import { uniqueId } from 'lodash'
import { ReactComponent as CalcOfFiles } from '../../../../assets/icons/calcOfFiles.svg'
import { ReactComponent as CloseSvg } from '../../../../assets/icons/closeIcon.svg'
import FileList from '../../FileList/FileList'

const EducationItem = ({ title, period, files, items }) => {
  const [showFileList, setShowFileList] = React.useState(false)
  return (
    <div className={styles.contentItem}>
      <div className={styles.header}>
        <UserCardSmartTooltip
          type={'simple'}
          title={title}
          titleClass={styles.contentItemTitle}
          id={uniqueId('education-item-tooltip-')}
        />
        <p className={styles.headerPeriod}>{period}</p>

        {!showFileList && (
          <div
            className={styles.calcOfFilesContainer}
            onClick={() => {
              files.length && setShowFileList(true)
            }}
          >
            <label className={styles.calcOfFilesContainerLabel}>Photo</label>
            <div className={styles.calcOfFiles}>
              <CalcOfFiles />
              <label className={styles.calcOfFilesLabel}>{files.length}</label>
            </div>
          </div>
        )}

        {showFileList && (
          <button
            className={styles.closeBtn}
            onClick={() => {
              files.length && setShowFileList(false)
            }}
          >
            Close
            <CloseSvg />
          </button>
        )}
      </div>

      <div className={styles.body}>
        {items?.map((item, index) => (
          <UserCardSmartTooltip
            key={index}
            title={item.title}
            value={item.value}
            id={uniqueId('education-item-tooltip-')}
            containerClass={styles.item}
            titleClass={styles.itemTitle}
            valueClass={cn(styles.itemValue, {
              [styles.info]: item.title === 'Additional info',
            })}
            tooltipClass={styles.tooltip}
          />
        ))}
        {files.length ? <FileList files={files} showFiles={showFileList} type={'passports'} /> : ''}
      </div>
    </div>
  )
}

export default EducationItem
