import { getDateByFormat } from '../../../helpers/time-helper/time-helper'

export const getDateBadge = (fullDate, date) => {
  const formatDate = getDateByFormat(fullDate, 'MMMM dd')
  const currentFormatDate = getDateByFormat(new Date(), 'yyyy/MM/dd')
  const sendYear = new Date(fullDate).getFullYear()
  const currentYear = new Date().getFullYear()

  if (date === currentFormatDate) return 'Today'

  return sendYear < currentYear ? `${formatDate}, ${sendYear}` : `${formatDate}`
}
