import React from 'react'
import PropTypes from 'prop-types'

const CrossIcon = ({ width = 14, height = 13.79, stroke, strokeWidth }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        fill={stroke}
        strokeWidth={strokeWidth}
        d="M.703 13.983a.7.7 0 01-.496-1.195L12.789.206a.702.702 0 01.993.992L1.199 13.78a.7.7 0 01-.496.203z"
      />
      <path
        fill={stroke}
        strokeWidth={strokeWidth}
        d="M13.284 13.983a.7.7 0 01-.496-.203L.206 1.198a.702.702 0 01.992-.992L13.78 12.788a.698.698 0 01-.496 1.195z"
      />
    </svg>
  )
}

CrossIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  stroke: PropTypes.string,
}

export default CrossIcon
