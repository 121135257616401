import React from 'react'
import { useEffect } from 'react'
import { confirmVerify, signupVerify } from '../api/postApi'
import { useState } from 'react'
import ModalAlert from '../components/ModalAlert/ModalAlert'
import { getTokenFromUrl } from '../helpers/apiHelper'
import WithPreloader from '../HOC/WithPreloader'
import { useNotify } from '../hooks/useNotify'

const SignupVerifyPage = () => {
  const [isVerifySuccess, setIsVerifySuccess] = useState(null)

  const { notify } = useNotify()

  const scope = new URL(window.location).searchParams.get('scope')
  const isConfirm = new URL(window.location).searchParams.get('email_confirm')

  const requestFunction = isConfirm === 'True' ? confirmVerify : signupVerify

  useEffect(() => {
    const request = async () => {
      try {
        await requestFunction(getTokenFromUrl())

        setIsVerifySuccess(true)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    request()

    // eslint-disable-next-line
  }, [requestFunction])

  return (
    <div className="page">
      <main style={{ display: 'flex', alignItems: 'center' }}>
        <WithPreloader
          component={ModalAlert}
          isActive={isVerifySuccess === null}
          size={100}
          isOk={isVerifySuccess}
          title={isVerifySuccess ? 'Thank you!' : 'Oops..'}
          scope={scope}
          content={isVerifySuccess ? 'Your email successfully verified.' : 'Something went wrong. Link is invalid.'}
        />
      </main>
    </div>
  )
}

export default SignupVerifyPage
