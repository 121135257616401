import React from 'react'
import styles from './Features.module.scss'
import profileSvg from '../../../assets/icons/features-profile.svg'
import bellSvg from '../../../assets/icons/features-bell.svg'
import dbSvg from '../../../assets/icons/features-db.svg'
import shipSvg from '../../../assets/icons/features-ship.svg'

const features = [
  {
    icon: profileSvg,
    text: 'NextShip service helps seafarers “keep fit” their resume.',
    alt_text: 'profile',
    id: 0,
  },
  {
    icon: bellSvg,
    text: 'Reminds of licenses and certificates expired',
    alt_text: 'bell',
    id: 1,
  },
  {
    icon: dbSvg,
    text: 'Keeps all documents of the seaman, without limitation of space.',
    alt_text: 'database',
    id: 2,
  },
  {
    icon: shipSvg,
    text: 'Shows information about ships and shipowners',
    alt_text: 'ship',
    id: 3,
  },
]

function Features() {
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        {features.map(({ icon, text, alt_text, id }) => (
          <div key={id} className={styles.item}>
            <div style={{ backgroundImage: `url(${icon})` }} className={styles.icon}></div>
            <div className={styles.text}>{text}</div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Features
