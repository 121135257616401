export const CHAT = {
  SET_IS_DIALOG_CHECKED: 'CHAT_SET_DIALOG_CHECKED',
  DELETE_SELECTED: 'CHAT_DELETE_SELECTED',
  SET_ALL_SELECTED: 'CHAT_SET_ALL_SELECTED',
  SET_ALL_UNSELECTED: 'CHAT_SET_ALL_UNSELECTED',
  SET_DIALOGS: 'CHAT_SET_DIALOGS',
  SET_MESSAGES: 'CHAT_SET_MESSAGES',
  NEW_MESSAGE: 'CHAT_NEW_MESSAGE',
  DELETE_MESSAGE: 'CHAT_DELETE_MESSAGE',
  EDIT_MESSAGE_START: 'CHAT_EDIT_MESSAGE_START',
  REPLY_MESSAGE_START: 'REPLY_MESSAGE_START',
  CHANGE_MESSAGE_VALUE: 'CHANGE_MESSAGE_VALUE',
  ACTION_MESSAGE_CLOSE: 'ACTION_MESSAGE_CLOSE',
  UPDATE_MESSAGE: 'CHAT_UPDATE_MESSAGE',
  SET_GLOBAL_UNDELIVERED: 'CHAT_SET_GLOBAL_UNDELIVERED',
  RESTORE_ROOM: 'CHAT_RESTORE_ROOM',
  CLEAR_ROOM_TOTAL_UNDELIVERED: 'CHAT_CLEAR_ROOM_TOTAL_UNDELIVERED',
  LAST_MESSAGE_WAS_UPDATED: 'CHAT_LAST_MESSAGE_WAS_UPDATED',
  START_PAGINATION_LOADING: 'CHAT_START_PAGINATION_LOADING',
  SET_NEXT_PAGE_MESSAGES: 'CHAT_SET_NEXT_PAGE_MESSAGES',
  SET_EDIT_MESSAGE_ERROR: 'SET_EDIT_MESSAGE_ERROR',
  SET_DELETE_MESSAGE_ERROR: 'SET_DELETE_MESSAGE_ERROR',
  SEARCH_CHAT_MESSAGE: 'SEARCH_CHAT_REPLY_MESSAGE',
}

export const SUPPORT = {
  GET_MESSAGES: 'SUPPORT_GET_MESSAGES',
  NEW_MESSAGE: 'SUPPORT_NEW_MESSAGE',
  SET_LOADING: 'SUPPORT_SET_LOADING',
  SET_UNDELIVERED: 'SUPPORT_SET_UNDELIVERED',
  RESET_TOTAL_UNDELIVERED: 'RESET_TOTAL_UNDELIVERED',
  SET_IS_OPENED: 'SET_IS_OPENED',
  SET_MESSAGE_TEXT: 'SET_MESSAGE_TEXT',
  SET_STATUS: 'SET_STATUS',
  SET_NEXT_PAGE_SUPPORT_MESSAGES: 'SET_NEXT_PAGE_SUPPORT_MESSAGES',
  DELETE_SUPPORT_MESSAGE: 'DELETE_SUPPORT_MESSAGE',
  REPLY_SUPPORT_MESSAGE: 'REPLY_SUPPORT_MESSAGE',
  EDIT_SUPPORT_MESSAGE: 'EDIT_SUPPORT_MESSAGE',
  ACTION_SUPPORT_MESSAGE_CLOSE: 'ACTION_SUPPORT_MESSAGE_CLOSE',
  UPDATE_SUPPORT_MESSAGE: 'UPDATE_SUPPORT_MESSAGE',
  SET_SUPPORT_EDIT_MESSAGE_ERROR: 'SET_SUPPORT_EDIT_MESSAGE_ERROR',
  SET_SUPPORT_DELETE_MESSAGE_ERROR: 'SET_SUPPORT_DELETE_MESSAGE_ERROR',
  SEARCH_SUPPORT_MESSAGE: 'SEARCH_SUPPORT_REPLY_MESSAGE',
  SET_ANONYMOUS_UUID: 'SET_ANONYMOUS_UUID',
}

export const REVIEWS = {
  SET_REVIEWS: 'SET_REVIEWS',
  CLEAR_REVIEWS: 'CLEAR_REVIEWS',
}

export const COMPANY = {
  GET_COMPANY_INFO: 'GET_COMPANY_INFO',
  UPDATE_COMPANY_INFO: 'UPDATE_COMPANY_INFO',
  CHANGE_COMPANY_PICTURE: 'CHANGE_COMPANY_PICTURE',
  SET_PERMISSIONS_LIST: 'SET_PERMISSIONS_LIST',
  SET_BILLING_PERMISSIONS_CURRENT: 'SET_BILLING_PERMISSIONS_CURRENT',
}
