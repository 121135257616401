import * as Yup from 'yup'
import { format } from 'date-fns'

export const getValidationSchema = (maxDate) => {
  return Yup.object().shape({
    from: Yup.date()
      .required('Field is required')
      .min(new Date('1900-01-01'), `Min date is ${format(new Date('1900-01-01'), 'dd/MM/yyyy')}`)
      .test('Check maxDate', `Max date is ${format(new Date(), 'dd/MM/yyyy')}`, (value) => {
        return maxDate ? value <= new Date() : true
      })
      .typeError('Invalid date format, valid - dd/MM/yyyy')
      .nullable(),
    to: Yup.date()
      .required('Field is required')
      .min(Yup.ref('from'), 'Date current be later than date previous')
      .test('Check maxDate', `Max date is ${format(new Date(), 'dd/MM/yyyy')}`, (value) => {
        return maxDate ? value <= new Date() : true
      })
      .typeError('Invalid date format, valid - dd/MM/yyyy')
      .nullable(),
  })
}
