import React from 'react'
import styles from '../userCard.module.scss'
import ColleaguesItem from './ColleaguesItem/ColleaguesItem'
import { isEmpty } from 'lodash/lang'
import { ReactComponent as NoInfoImage } from '../../../assets/images/colegues_stub.svg'

const Colleagues = ({ userCardData }) => {
  const { colleagues } = userCardData

  if (isEmpty(colleagues)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={400} height={260} />
        <p>
          At the moment, we are unable to identify any colleagues for this sailor. This may occur if <br />
          the sailor has not fully detailed their professional experience or if potential colleagues <br />
          are not yet members of the NextShip platform.
        </p>
      </div>
    )
  }

  return (
    <>
      <div className={styles.colleagues_section}>
        <div className={styles.colleagues_head}>
          <span>Seaman</span>

          <span>Rank</span>

          <span>Time</span>
        </div>

        <div className={styles.colleagues_list_section}>
          {colleagues?.map((colleague) => (
            <ColleaguesItem key={colleague.id} colleague={colleague} />
          ))}
        </div>
      </div>
    </>
  )
}
export default Colleagues
