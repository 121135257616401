import { labelType } from '../../config'

export const initialValues = {
  ranks: { type: labelType.multiple, value: [] },
  vessel_types: { type: labelType.multiple, value: [] },
  vessel_engines: { type: labelType.multiple, value: [] },
  nationalities: { type: labelType.multiple, value: [] },
  documents: { type: labelType.multiple, value: [] },
  template: { type: labelType.select, value: { name: '', id: null } },
  teu: { type: labelType.string, value: '', label: 'TEU' },
  dwt: { type: labelType.string, value: '', label: 'DWT' },
  grt: { type: labelType.string, value: '', label: 'GRT' },
  hp: { type: labelType.string, value: '', label: 'HP' },
  kw: { type: labelType.string, value: '', label: 'KW' },
  search: { type: labelType.default, value: '' },
}
