import React from 'react'
import { isEmpty } from 'lodash/lang'
import Masonry from 'react-responsive-masonry'
import EducationItem from './EducationItem/EducationItem'
import styles from '../userCard.module.scss'
import { ReactComponent as NoInfoImage } from '../../../assets/images/education_stub.svg'

const Education = ({ userCardData, userName }) => {
  const { education } = userCardData

  if (isEmpty(education)) {
    return (
      <div className={styles.noInfo}>
        <NoInfoImage width={320} height={290} />
        <p>
          <b>{userName}</b> doesn’t have any experience yet.
        </p>
      </div>
    )
  } else {
    return (
      <Masonry columnsCount={2} gutter="16px">
        {education?.map((education, index) => {
          return (
            <EducationItem
              key={index}
              title={education.collegeName}
              items={education.values}
              period={education.period}
              files={education.files}
            />
          )
        })}
      </Masonry>
    )
  }
}

export default Education
