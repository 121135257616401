import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUniqueCursorHelper } from '../helpers/apiHelper'

export default function useInfiniteScroll(requestFunction, updateAction, apiHelper, query, clearFunction) {
  const [loading, setLoading] = useState(true)
  const [nextPage, setNextPage] = useState(1)
  const [dataCount, setDataCount] = useState(null)
  const [nextCursorLink, setNextCursorLink] = useState('')

  const dispatch = useDispatch()
  const currentPage = useRef()
  const isFetching = useRef(false)

  currentPage.current = nextPage

  useEffect(() => {
    setNextPage(1)
    setNextCursorLink(null)

    currentPage.current = 1

    if (clearFunction) dispatch(clearFunction())

    // eslint-disable-next-line
  }, [query, clearFunction])

  useEffect(() => {
    if (isFetching.current) return

    if (nextPage) {
      setLoading(true)

      isFetching.current = true

      requestFunction(nextCursorLink ?? '', query).then(({ results, next, count }) => {
        dispatch(updateAction(apiHelper(results)))

        setDataCount(count)
        setNextCursorLink(getUniqueCursorHelper(next))
        setLoading(false)

        isFetching.current = false

        if (!next) {
          setNextPage(null)
        }
      })
    }

    //eslint-disable-next-line
  }, [nextPage, requestFunction, apiHelper, updateAction, nextCursorLink])

  const handleScroll = ({ target }) => {
    if (nextPage) {
      if (target.scrollTop + 850 >= target.scrollHeight && !loading) {
        setLoading(true)
        setNextPage(currentPage.current + 1)
        setNextCursorLink((nextCursorLink) => nextCursorLink)
      } else {
        return null
      }
    }
  }

  return {
    loading,
    handleScroll,
    setNextPage,
    dataCount,
    setDataCount,
    nextCursorLink,
    query,
  }
}
