import React, { useEffect, useState } from 'react'
import styles from './MobileAppForCompany.module.scss'
import mobileAppImageWebP from '../../../assets/images/mobile_app_company.webp'
import AppImagePNG from '../../../assets/images/banner-mobile-apps-crewmanager.svg'
import qrCode from '../../../assets/images/QRCode.svg'
import mobileAppImagePNG from '../../../assets/images/app_mobile.png'
import GooglePlaySVG from '../../../assets/images/google_play.svg'
import AppleStoreSVG from '../../../assets/images/app-store.svg'

const MobileAppForCompany = () => {
  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 762)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <section className={styles.mobile_app_section}>
      <div className={`container-landing ${styles.wrapper}`}>
        <div className={styles.content}>
          <h2 className={styles.title}>
            Mobile <span className={styles.app}>App</span> {/*<span className={styles.soon}>*/}
            {/*  Android <Android />*/}
            {/*</span>*/}
          </h2>
          <p>Manage the crew recruitment process for your vessels with our mobile application!</p>
          <p>Stay up-to-date on all job applications and find suitable candidates faster.</p>
          <p>
            Use the search function to find seafarers based on criteria and analyze statistics to optimize job postings.
            Our application is free for NextShip users. Install it now!
          </p>
          <p>
            <b>
              Note: Register your company on our website first. Approved companies get full access to the mobile app.
            </b>
          </p>
        </div>
        <div className={styles.picture_wrapper}>
          <picture className={styles.mobile_app_image}>
            <source src={mobileAppImageWebP} type="image/webp" />
            <img src={isMobileView ? mobileAppImagePNG : AppImagePNG} alt="future mobile application" />
          </picture>
          {!isMobileView && (
            <picture className={styles.qrCode}>
              <source src={qrCode} type="image/svg+xml" />
              <img src={qrCode} alt="mobile application QRCode" />
            </picture>
          )}
        </div>
        {isMobileView && (
          <div className={styles.mob_store_links}>
            <div className={styles.playMarket}>
              <a
                href="https://play.google.com/store/apps/details?id=com.nextship.owner"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={GooglePlaySVG} alt="google play" />
              </a>
            </div>
            <div className={styles.appStore}>
              <a
                href="https://apps.apple.com/us/app/nextship-employers/id6445947568"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={AppleStoreSVG} alt="app store" className={styles.app_store_svg} />
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default MobileAppForCompany
