import * as Yup from 'yup'

export const signUpSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').min(6, 'Too Short!').max(50, 'Too Long!').required('Field is required'),
  password: Yup.string()
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/,
      'Password should only contain Latin letters, numbers, and special characters'
    )
    .matches(/[a-zA-Z]+/, 'Password should contain at least one letter')
    .matches(/[0-9]+/, 'Password should contain at least one number')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(20, 'Password is too long - should be 20 chars maximum.')

    .required('Field is required'),
  confirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Field is required'),
  agree: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
})

export const signInSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').min(1, 'Too Short!').max(50, 'Too Long!').required('Field is required'),
  password: Yup.string().min(8, 'Password is too short - should be 8 chars minimum.').required('Field is required'),
})

export const initialValuesSignUp = {
  email: '',
  password: '',
  confirm: '',
  agree: false,
}

export const initialValuesSignIn = {
  email: '',
  password: '',
}
