import React from 'react'
import styles from './DateExpiredChecker.module.scss'
import ReactTooltip from 'react-tooltip'
import { diffDays, stringToISO } from '../../helpers/time-helper/time-helper'
import WarningSvg from '../Icons/Warning'

export default function DateExpiredChecker({ children }) {
  const isoDate = stringToISO(children)

  if (!children) return 'current'

  const diff = diffDays(new Date(isoDate), new Date())
  const result = {}

  if (new Date(isoDate) < new Date()) {
    result.warning = 'Document expired'
    result.color = '#EB5757'
  } else if (diff <= 30) {
    result.warning = `Document expires in ${diff} days`
    result.color = '#E7B92A'
  } else {
    result.warning = ''
    result.color = 'inherit'
  }

  return (
    <div className={styles.wrapper}>
      <span>{children}</span>
      <span data-tip={result.warning} data-for="date-checker">
        <WarningSvg circleFill={result.color} />
      </span>
      <ReactTooltip arrowColor="#fff" className={styles.tooltip} id="date-checker" />
    </div>
  )
}
