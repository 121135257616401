import React, { useState } from 'react'
import Button from '../../UI/Button/Button'
import { Link, Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { changePasswordConfirmRequest } from '../../api/postApi'
import InputPassword from '../../UI/Input/InputPassword'
import { getTokenFromUrl } from '../../helpers/apiHelper'
import styles from './changePassword.module.scss'
import { userLogout } from '../../redux/actions/auth'
import { useDispatch } from 'react-redux'
import { useNotify } from '../../hooks/useNotify'
import { profilePaths } from '../../constants/constants'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/,
      'Password should only contain Latin letters, numbers, and special characters'
    )
    .matches(/[a-zA-Z]+/, 'Password should contain at least one letter')
    .matches(/[0-9]+/, 'Password should contain at least one number')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(20, 'Password is too long - should be 20 chars maximum.')

    .required('Field is required'),
  // .notOneOf([Yup.ref('old_password')], 'Your new password must not be the same as your old password'),
  confirm: Yup.string()
    .required('Field is required')
    .oneOf([Yup.ref('password'), null], 'The password must match the new password'),
})

const ChangePassword = () => {
  const [status, setStatus] = useState(null)

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlue: true,

    onSubmit: (values) => handleSubmit(values),
  })

  const { notify } = useNotify()
  const dispatch = useDispatch()

  const scope = new URL(window.location).searchParams.get('scope')
  const action_type = new URL(window.location).searchParams.get('action_type')

  if (status === true) return <Redirect to={profilePaths.auth} />
  if (status === false) return <Redirect to={scope === 'seafarer' ? '/' : '/employers'} />

  const handleSubmit = async (values) => {
    try {
      await changePasswordConfirmRequest({
        new_password: values.password,
        ...getTokenFromUrl(),
      })

      setStatus(true)

      dispatch(userLogout())

      notify('Success', 'Your Password has been changed!')
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const handleChangeFormikValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  const { values, errors, touched } = formik

  return (
    <div className={`page ${styles.page}`}>
      <div className={styles.form}>
        <h3 className={styles.title}>{action_type === 'create_password' ? 'Create' : 'Change'} Password</h3>

        <form onSubmit={formik.handleSubmit}>
          <fieldset className="auth-form__fieldset">
            <div className="form-group">
              <label className={styles.label}>New password</label>

              <InputPassword
                theme="password"
                name="password"
                value={values.password}
                onChange={handleChangeFormikValue}
                isInvalid={Boolean(errors.password && touched.password)}
              />

              {errors.password && touched.password ? <div className={styles.error}>{errors.password}</div> : null}
            </div>

            <div className="form-group">
              <label className={styles.label}>Confirm</label>

              <InputPassword
                theme="password"
                name="confirm"
                value={values.confirm}
                onChange={handleChangeFormikValue}
                isInvalid={Boolean(errors.confirm && touched.confirm)}
              />

              {errors.confirm && touched.confirm ? <div className={styles.error}>{errors.confirm}</div> : null}
            </div>
          </fieldset>

          <div className={styles.footer}>
            <Link className={styles.close} to="/">
              Close
            </Link>

            <Button
              style={{
                backgroundColor: '#94CEBE',
                borderColor: '#94CEBE',
                color: '#06251E',
                fontWeight: 'bold',
                width: 167,
              }}
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
