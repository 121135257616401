import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import EnvelopeSvg from '../../UI/Icons/EnvelopeSvg'
import styles from './styles.module.scss'
import { profilePaths } from '../../constants/constants'
const ModalAlert = ({ isOk, title, content }) => {
  return (
    <div className={cn(styles.modal, { [styles.modal_invalid]: isOk === false })}>
      <div className={styles.envelope}>
        <EnvelopeSvg color={isOk ? '#067D5D' : '#E77A03'} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{content}</div>

      <div className={styles.cloudFirst} />
      <div className={styles.cloudSecond} />
      <div className={styles.cloudThird} />
      {isOk ? <Link to={profilePaths.auth}>Log in</Link> : <Link to="/">Main page</Link>}
    </div>
  )
}

ModalAlert.propTypes = {
  isOk: PropTypes.bool.isRequired,
}

export default ModalAlert
