import React from 'react'
import { useFormik } from 'formik'
import Button from '../../UI/Button/Button'
import { Link } from 'react-router-dom'
import InputPassword from '../../UI/Input/InputPassword'
import Input from '../../UI/Input/Input'
import { registerCompanyRequest } from '../../api/postApi'
import './auth-form.scss'
import { useNotify } from '../../hooks/useNotify'
import { setIsButtonState } from '../../redux/actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentDomain } from '../../helpers/apiHelper'
import { profilePaths } from '../../constants/constants'
import { signUpSchema } from './sign.schemas'

export const SignUpForm = () => {
  const dispatch = useDispatch()
  const { notify } = useNotify()
  const { isButtonEnable } = useSelector(({ authentication }) => authentication)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirm: '',
    },
    validateOnChange: false,
    validationSchema: signUpSchema,

    onSubmit: (values) => {
      dispatch(setIsButtonState(false))
      registerCompanyRequest({
        email: values.email,
        password: values.password,
      })
        .then(() => {
          dispatch(setIsButtonState(true))
          const message = 'A confirmation letter has been sent to your email!'
          notify('Success', message)
        })
        .catch((error) => {
          dispatch(setIsButtonState(true))
          notify.errorsList(error.errors)
        })
    },
  })

  return (
    <div className="auth-form auth-form-employers-signup">
      <form onSubmit={formik.handleSubmit}>
        <div className="auth-form-container">
          <div className="auth-form__title">Create Account as</div>
          <div className="auth-form__scope_selection">
            <span className="scope_selection_active">CrewManager</span>

            <a className="scope_selection_link" href={`${getCurrentDomain()}nextship.net/`}>
              Seafarer
            </a>
          </div>

          <fieldset className="auth-form__fieldset">
            <div className="form-group">
              <Input
                className="auth-form__input"
                name="email"
                isInvalid={!!formik.errors.email}
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && <div className="input-error-message-static">{formik.errors.email}</div>}
            </div>
            <div className="form-group">
              <InputPassword
                theme="password"
                className="auth-form__input"
                name="password"
                isInvalid={!!formik.errors.password}
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.errors.password && <div className="input-error-message-static">{formik.errors.password}</div>}
            </div>

            <div className="form-group">
              <InputPassword
                theme="password"
                className="auth-form__input"
                name="confirm"
                isInvalid={!!formik.errors.confirm}
                placeholder="Confirm password"
                value={formik.values.confirm}
                onChange={formik.handleChange}
              />
              {formik.errors.confirm && <div className="input-error-message-static">{formik.errors.confirm}</div>}
            </div>
          </fieldset>

          <div className="auth-form__agreement">
            By clicking <b>Create account</b>, you <b>AGREE</b> to our
            <b>
              <Link to="/terms"> Terms & Conditions </Link>
            </b>
            and that you have read our
            <b>
              <Link to="/privacy"> Data Use Policy</Link>
            </b>
            .
          </div>
          <Button theme="default-dark" type="submit" disabled={!isButtonEnable}>
            Create Account
          </Button>
        </div>
      </form>
      <div className="auth-form__footer">
        Have an account?{' '}
        <b>
          <Link to={profilePaths.auth}>Log In</Link>
        </b>
      </div>
    </div>
  )
}
