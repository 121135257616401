import React from 'react'
import styles from './WhyUs.module.scss'
import { reasons } from './why-us.info'
import cn from 'classnames'

export default function WhyUs() {
  return (
    <section className={styles.why_should_next_ship}>
      <div className={styles.wrapper}>
        <h3 className={styles.why_should_next_ship_title}>
          Why you <br className={styles.br} /> should choose <span className="accent">NextShip</span>
        </h3>
        <div className={styles.why_should_next_ship_container}>
          <div className={styles.why_should_next_ship_reasons}>
            {reasons.map(({ className, icon, title, description, id }) => (
              <div className={cn(styles.why_should_next_ship_reasons_reason, styles[className])} key={id}>
                <div
                  style={{ backgroundImage: `url(${icon})` }}
                  className={styles.why_should_next_ship_reasons_reason_icon}
                ></div>
                <div className={styles.why_should_next_ship_reasons_reason_text}>
                  <div className={styles.why_should_next_ship_reasons_reason_title}> {title} </div>
                  <div className={styles.why_should_next_ship_reasons_reason_description}> {description} </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
