import React from 'react'

import SearchLabel from './SearchLabel/SearchLabel'

export const profileFilters = ['currency', 'rank', 'vessel_type', 'vessel_engine_type', 'country', 'document_types']

export const labelType = {
  default: 'default',
  string: 'string',
  select: 'select',
  multiple: 'multiple',
}

const actionDeleteForStringType = (updateAction, name, updateSearch) => {
  updateAction((prev) => {
    const newValues = { ...prev, [name]: { ...prev[name], value: '' } }

    updateSearch(newValues)

    return newValues
  })
}

const actionDeleteForSelectType = (updateAction, name, updateSearch) => {
  updateAction((prev) => {
    const newValues = { ...prev, [name]: { ...prev[name], value: { id: null, name: '' } } }

    updateSearch(newValues)

    return newValues
  })
}

const actionDeleteForMultipleType = (updateAction, name, id, updateSearch) => {
  updateAction((prev) => {
    const newValues = {
      ...prev,
      [name]: { ...prev[name], value: prev[name].value.filter(({ id: _id }) => _id !== id) },
    }

    updateSearch(newValues)

    return newValues
  })
}

export const labelsMap = {
  [labelType.default]: ({ value, updateSearchValues, updateSearch, ...props }) => (
    <SearchLabel
      value={value}
      onDeleteClick={() => actionDeleteForStringType(updateSearchValues, props.name, updateSearch)}
      {...props}
    />
  ),
  [labelType.string]: ({ label, value, updateSearchValues, updateSearch, ...props }) => (
    <SearchLabel
      label={label}
      value={value}
      onDeleteClick={() => actionDeleteForStringType(updateSearchValues, props.name, updateSearch)}
      {...props}
    />
  ),
  [labelType.select]: ({ value, updateSearchValues, updateSearch, ...props }) => (
    <SearchLabel
      value={value.name}
      onDeleteClick={() => {
        actionDeleteForSelectType(updateSearchValues, props.name, updateSearch)
      }}
      {...props}
    />
  ),
  [labelType.multiple]: ({ value: arr, updateSearchValues, updateSearch, ...props }) =>
    arr.map(({ id, name }) => (
      <SearchLabel
        key={id}
        value={name}
        onDeleteClick={() => actionDeleteForMultipleType(updateSearchValues, props.name, id, updateSearch)}
        {...props}
      />
    )),
}
