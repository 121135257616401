import React from 'react'
import './Logo.jsx'
import logoWhiteSvg from '../../assets/images/logo-white.svg'
import logoDarkSvg from '../../assets/images/logo-dark.svg'
import mainLogo from '../../assets/images/main-logo.svg'
import { Link } from 'react-router-dom'
import styles from './Logo.module.scss'
import cn from 'classnames'

const Logo = ({ theme = 'white', width = 357, className }) => {
  const option = {
    main: mainLogo,
    white: logoWhiteSvg,
    dark: logoDarkSvg,
  }

  return (
    <Link to="/" className={cn(styles.logo, className)}>
      <img width={width} src={option[theme]} alt="company logo" />
    </Link>
  )
}

export default Logo
