import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Indicator from './Indicator'

const TabNav = ({ links = [] }) => {
  return (
    <div className="profile-tabs-white">
      <div className="container profile-tabs-white-container">
        <div className="profile-tabs-white-wrapper">
          {links.map(({ url, title, indicator }, index) => (
            <NavLink key={index} to={url} activeClassName="active">
              <span>{title}</span>

              {indicator && <Indicator value={indicator} />}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  )
}

TabNav.propTypes = {
  links: PropTypes.array.isRequired,
}

export default TabNav
