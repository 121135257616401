import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import styles from './CompanyDataPage.module.scss'
import ModerationStatus from '../../components/ModerationStatus/ModerationStatus'
import useCanEdit from '../../hooks/useCanEdit'
import GeneralForm from './CompanyDataGeneralForm/GeneralForm'
import AdditionalForm from './CompanyDataAdditionalForm/AdditionalForm'
import {
  getAuthProfileFilters,
  getCompanyAdditionalInfo,
  getCompanyContactsList,
  getCompanyContactTypes,
} from '../../api/getApi'
import { additionalDataHelper } from '../../helpers/companyInformation.helper'

export const CompanyDataPage = () => {
  const isCanEdit = useCanEdit()
  const companyData = useSelector((state) => state.company)
  const isNotificationVisible = useSelector(({ ui }) => ui.company.moderationNotification.isVisible)
  const [additionalData, setAdditionalData] = useState(null)
  const [loading, setLoading] = useState(false)
  const containerRef = useRef(null)
  const [filters, setFilters] = useState({ country: [] })
  const [additionalFormEdit, setAdditionalFormEdit] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (additionalFormEdit === true) return
      setLoading(true)
      try {
        const additionalData = await getCompanyAdditionalInfo()
        const updatedAdditionalData = additionalDataHelper(additionalData[0])
        const profileFilters = await getAuthProfileFilters('country')

        const data = {
          ...updatedAdditionalData,
          contact_types: await getCompanyContactTypes(),
          phone_contacts: await getCompanyContactsList('phone'),
          email_contacts: await getCompanyContactsList('email'),
        }

        setAdditionalData(data)
        setFilters(profileFilters)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [additionalFormEdit])

  return (
    <div className={cn(styles.company, 'container', { [styles.company_inactive]: !isCanEdit })}>
      <div
        className={cn(styles.company_moderation__status, {
          [styles.company_moderation__status__active]: companyData.on_moderation && companyData.is_active,
        })}
      >
        {isNotificationVisible && <ModerationStatus data={companyData} />}
      </div>

      <div className={styles.formsBlock}>
        <GeneralForm isCanEdit={isCanEdit} companyData={companyData} />
        <AdditionalForm
          isEditMode={additionalFormEdit}
          setIsEditMode={setAdditionalFormEdit}
          isCanEdit={isCanEdit}
          documentData={additionalData}
          isLoading={loading}
          setLoading={setLoading}
          filters={filters}
          container={containerRef}
          companyData={companyData}
        />
      </div>
    </div>
  )
}

export default CompanyDataPage
