import Input from '../../../../UI/Input/Input'
import cn from 'classnames'
import styles from '../../CompanyDataPage.module.scss'
import { ReactComponent as CrossSvg } from '../../../../assets/icons/circle_cross_icon.svg'

import React from 'react'

const ContactBlock = ({
  propsName = 'email',
  onChange,
  item,
  isInputsDisabled,
  index,
  formik,
  isEditMode,
  setValidPhoneContact,
  setValidEmailContact,
}) => {
  const { values } = formik

  const validateEmail = (value) => {
    if (
      formik.values.email_contacts.results.filter(
        ({ contact, comment }) => item.contact === contact && item.comment === comment
      ).length > 1
    ) {
      setValidEmailContact(item)
      return 'this contact already exist'
    }
    if (value.length < 1) {
      setValidEmailContact(item)
      return 'is Required'
    }
    if (typeof value !== 'string') {
      setValidEmailContact(item)

      return 'Email must be a string'
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(value)) {
      setValidEmailContact(item)

      return 'Invalid email'
    }

    if (value.length < 6) {
      setValidEmailContact(item)
      return 'too Short '
    }

    if (value.length > 100) {
      setValidEmailContact(item)
      return 'too Long '
    }

    // setValidEmailContact(null)
    return null
  }
  const validatePhoneNumber = (value) => {
    if (
      formik.values.phone_contacts.results.filter(
        ({ contact, comment }) => item.contact === contact && item.comment === comment
      ).length > 1
    ) {
      setValidPhoneContact(item)
      return 'this contact already exist'
    }
    if (value.length < 1) {
      setValidPhoneContact(item)
      return 'is Required'
    }

    if (typeof value !== 'string') {
      setValidPhoneContact(item)
      return 'Phone number must be a string'
    }

    const numberRegex = /^[+\d\s()]+$/
    if (!numberRegex.test(value)) {
      setValidPhoneContact(item)
      return 'Phone number must contain only digits'
    }

    if (value.length < 8) {
      setValidPhoneContact(item)
      return 'too Short'
    }

    if (value.includes('.')) {
      setValidPhoneContact(item)
      return "A phone number can't include a decimal point"
    }

    // setValidPhoneContact(null)
    return null
  }

  const handleDeleteContact = (name, data) => {
    if (name === 'phone') {
      const value = values.phone_contacts.results.filter((item) => item !== data)
      formik.setFieldValue('phone_contacts.results', value)
    }
    if (name === 'email') {
      const value = values.email_contacts.results.filter((item) => item !== data)
      formik.setFieldValue('email_contacts.results', value)
    }
  }
  const validation = () => (propsName === 'email' ? validateEmail(item?.contact) : validatePhoneNumber(item?.contact))
  return (
    <div className={styles.contactItem}>
      {isEditMode && (
        <div className={styles.deleteContactBlock}>
          Delete {propsName}
          <button
            onClick={(e) => {
              e.preventDefault()
              handleDeleteContact(propsName, item)
            }}
          >
            {' '}
            <CrossSvg />
          </button>
        </div>
      )}

      <Input
        className={cn(styles.company_data_input, { [styles.phoneInput]: true })}
        name={`${propsName}_${index}`}
        label={propsName === 'phone' ? 'Phone number' : 'Email'}
        value={item?.contact}
        onChange={(event) => {
          onChange(event, index)
        }}
        isInvalid={!!validation()}
        errorMessage={validation()}
        disabled={isInputsDisabled}
      />
      <Input
        className={cn(styles.company_data_input, { [styles.commentInput]: true })}
        name={`${propsName}_comment_${index}`}
        label="Comment"
        value={item?.comment || null}
        onChange={(event) => {
          onChange(event, index)
        }}
        disabled={isInputsDisabled}
      />
    </div>
  )
}
export default ContactBlock
