import React from 'react'
import styles from './SupportFab.module.scss'
import cn from 'classnames'
import { ArrowDownSvg } from '../Icons/Icons'
import { ReactComponent as Headphones } from '../../assets/icons/operator.svg'
import { ReactComponent as Envelope } from '../../assets/icons/udelivered-convert.svg'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

function SupportFab({ handleClick, isOpen, className }) {
  const { undelivered_count, work } = useSelector((state) => state.support)

  return (
    <button onClick={handleClick} className={cn(styles.button, { [styles.stopAnimation]: isOpen }, className)}>
      {isOpen && <ArrowDownSvg height="11px" width="19px" strokeColor="#fff" />}

      {Boolean(!isOpen && undelivered_count && work) ? (
        <div className="envelope">
          <Envelope height={20} width={28} />
        </div>
      ) : (
        !isOpen && <Headphones height={29} width={23} />
      )}
    </button>
  )
}

export default SupportFab

SupportFab.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
}
