import React, { PureComponent } from 'react'

import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import cn from 'classnames'

import arrowSvg from '../../../../assets/icons/flex-arrow.svg'

import Image from '../../../../UI/Image/Image'

import { dateToTwelveHourFormat } from '../../../../helpers/time-helper/time-helper'
import { SENDER_TYPE_COMPANY } from '../../constants'

export default class AsideItem extends PureComponent {
  render() {
    const {
      isOnline,
      isSelected,
      isActive,
      isActiveCheckBox,
      onCheck,
      picture,
      id,
      unread,
      user_name,
      company_name,
      lastMessage,
      url,
    } = this.props

    return (
      <NavLink className={styles.item} activeClassName={styles.active} to={url}>
        {unread ? <div className={styles.unread}>{unread}</div> : null}

        <div
          className={cn(styles.checker, { [styles.checker__inactive]: !isActiveCheckBox })}
          onClick={(e) => onCheck(e, id)}
        >
          <div
            className={cn(styles.checkerMark, {
              [styles.checkerMark__active]: isActive,
              [styles.checkerMark__selected]: isSelected,
            })}
          />
        </div>

        <div className={styles.avatarWrapper}>
          <Image className={cn(styles.avatar, { [styles.avatar__online]: isOnline })} src={picture} alt="user avatar" />
        </div>

        <div className={styles.preview}>
          <div className={styles.previewTop}>
            <div className={styles.previewTitle}>{company_name ? `${company_name} (${user_name})` : user_name}</div>

            {lastMessage && <div className={styles.previewTime}>{dateToTwelveHourFormat(lastMessage.date)}</div>}
          </div>

          {lastMessage?.message && (
            <div className={styles.previewBottom}>
              <div className={styles.previewStatus}>
                <img
                  src={arrowSvg}
                  alt="indicator"
                  style={{
                    transform:
                      lastMessage.is_outgoing || lastMessage.sender_type === SENDER_TYPE_COMPANY
                        ? 'unset'
                        : 'rotate(180deg)',
                  }}
                />
              </div>

              <div className={styles.previewMessage}>{lastMessage.message}</div>
            </div>
          )}
        </div>
      </NavLink>
    )
  }
}

AsideItem.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  picture: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  unread: PropTypes.number.isRequired,
  user_name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  lastMessage: PropTypes.object,
}
