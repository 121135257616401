import React, { memo } from 'react'

import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './styles.scss'
import cn from 'classnames'

import Spinner from '../Spinner/Spinner'

const Button = memo(
  ({ children, theme = 'default', size = 'regular', link, loading, disabled, className, ...rest }) => {
    const Tag = link ? Link : 'button'

    const classes = `button button-${size} button-${theme} ${className}`

    return (
      <Tag disabled={disabled || loading} className={cn(classes, { 'button--loading': loading })} {...rest}>
        {loading && <Spinner size={22} strokeWidth={4} />}

        {children}
      </Tag>
    )
  }
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
  size: PropTypes.string,
}

export default Button
